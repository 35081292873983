import React from 'react'
import LoaderImg from 'assets/loader/loader.gif'

import styles from 'styles/loader.module.scss'

interface LoaderProps {
  open: boolean
}

const Loader: React.FC<LoaderProps> = ({ open }) => {
  return open ? (
    <div className={styles['loader-background']}>
      <div className={styles['loader-container']}>
        <img style={{ borderRadius: '50%' }} src={LoaderImg} alt="Loader" />
      </div>
    </div>
  ) : null
}

export default Loader
