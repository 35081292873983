import React, { useState } from 'react'
import { UserModalEntryPoints } from 'interfaces/EntryPoints'
import { IUser } from 'interfaces/User'
import PasswordResetIcon from 'assets/icons/password.svg'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TextDisplayEdit from 'components/TextDisplayEdit'
import UserService from 'services/UserService'
import EmailTest from 'utils/EmailTest'

import styles from 'styles/dialog.module.scss'

interface ProfileDialogProps {
  open: boolean
  entryPoint: UserModalEntryPoints
  onClose: () => void
  initialUser: IUser
  updateList: () => void
}

const UserModal: React.FC<ProfileDialogProps> = ({
  entryPoint,
  onClose,
  open,
  initialUser,
  updateList,
}) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const [resetPassword, setResetPassword] = useState(initialUser.recovery)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [passwordEquals, setPasswordEquals] = useState(true)
  const [displayPasswordRuleError, setDisplayPasswordRuleError] = useState(
    false
  )
  const [user, setUser] = useState(initialUser)
  const [validData, setValidaData] = useState<boolean>(true)
  const userService = new UserService()

  const handleValueChange = (
    field: 'firstname' | 'lastname' | 'role',
    value: any
  ) => {
    const userCopy = { ...user }
    userCopy[field] = value
    setUser(userCopy)
  }

  React.useEffect(() => {
    setUser(initialUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const resetState = () => {
    setEdit(false)
    setResetPassword(false)
    setDisplayPasswordRuleError(false)
    setOldPassword('')
    setNewPassword('')
    setRepeatPassword('')
    setValidaData(true)
    onClose()
  }

  const handleClose = () => {
    if (!initialUser.recovery) {
      resetState()
    }
  }

  const updatePassword = async () => {
    if (newPassword === repeatPassword) {
      const response = await userService.updatePassword(
        oldPassword,
        newPassword
      )
      if (response && response.jwt) {
        initialUser.recovery = false
        handleClose()
      } else {
        if (response && response.message === '400') {
          setDisplayPasswordRuleError(true)
        }
      }
    } else {
      setPasswordEquals(false)
    }
  }

  const updateThisUser = async () => {
    if (validationTest()) {
      user.sites = user.sites.map((site) => site._id) as any
      await userService.updateUser(user)
      updateList()
      handleClose()
    } else {
      setValidaData(false)
    }
  }

  const validationTest = () => {
    return user.firstname && user.lastname && user.username && user.role
      ? EmailTest.test(user.username)
      : false
  }

  const renderResetPassword = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography>{t('profile:oldPassword')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="password"
              fullWidth
              error={displayPasswordRuleError}
              value={oldPassword}
              onChange={(event: any) => setOldPassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>{t('profile:newPassword')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="password"
              fullWidth
              error={displayPasswordRuleError}
              value={newPassword}
              onChange={(event: any) => setNewPassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography color={passwordEquals ? 'textPrimary' : 'error'}>
              {t('profile:repeatPassword')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="password"
              fullWidth
              error={displayPasswordRuleError}
              value={repeatPassword}
              onChange={(event: any) => setRepeatPassword(event.target.value)}
            />
          </Grid>
        </Grid>
        <Box marginTop="20px">
          {displayPasswordRuleError && (
            <Typography color="error">
              {t('errors:passwordRulesError')}
            </Typography>
          )}
          <Typography>{t('profile:passwordRequirements')}</Typography>
          <ul>
            <li>{t('profile:atLeast10Alphanumeric')}</li>
            <li>{t('profile:atLeast1UpperCaseAndNumberChar')}</li>
            <li>{t('profile:atLeast1SpecialCharacter')}</li>
            <li>{t('profile:noConsecutiveCharacters')}</li>
            <li>{t('profile:noInitials')}</li>
            <li>{t('profile:noCharactersFromOldPassword')}</li>
          </ul>
        </Box>
      </React.Fragment>
    )
  }

  const renderUserDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography>{t('profile:username')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{ fontWeight: 'bold' }}>
            {user.username}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{t('profile:firstName')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextDisplayEdit
            fullWidth
            value={user.firstname}
            onChange={(event: any) =>
              handleValueChange('firstname', event.target.value)
            }
            edit={edit}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{t('profile:lastName')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextDisplayEdit
            fullWidth
            value={user.lastname}
            onChange={(event: any) =>
              handleValueChange('lastname', event.target.value)
            }
            edit={edit}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{t('profile:role')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{user.role}</Typography>
        </Grid>
        {!validData && (
          <Grid item xs={12}>
            <Typography color={'error'}>{t('users:allFields')}</Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  const getDialogActions = () => {
    if (resetPassword || edit) {
      return (
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button
              variant="contained"
              onClick={handleClose}
              disabled={initialUser.recovery}>
              {t('close')}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={resetPassword ? updatePassword : updateThisUser}>
            {t('save')}
          </Button>
        </div>
      )
    }
    if (entryPoint === UserModalEntryPoints.Profile) {
      return (
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button
              variant="contained"
              onClick={handleClose}
              disabled={initialUser.recovery}>
              {t('close')}
            </Button>
          </Box>
          <Box
            mx={3}
            alignItems="center"
            display="flex"
            className={styles['change-password-container']}
            onClick={() => setResetPassword(true)}>
            <Box mr={1}>
              <Typography>{t('profile:changePassword')}</Typography>
            </Box>
            <img
              src={PasswordResetIcon}
              height="30px"
              width="30px"
              alt={t('profile:resetPassword')}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEdit(true)}>
            {t('edit')}
          </Button>
        </div>
      )
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { borderRadius: '10px' } }}
      onBackdropClick={handleClose}>
      <DialogTitle className={styles['dialog-title-user']}>
        {t('header:profile')}
      </DialogTitle>
      <DialogContent>
        {resetPassword ? renderResetPassword() : renderUserDetails()}
      </DialogContent>
      <DialogActions>{getDialogActions()}</DialogActions>
    </Dialog>
  )
}

export default UserModal
