import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import defer from 'lodash/defer'
import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import moment from 'moment'

import ReactDataGrid, {
  Column,
  DataGridHandle,
  RowsUpdateEvent,
  UpdateActions,
} from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'

import RootRef from '@material-ui/core/RootRef'
import { Tooltip, Typography } from '@material-ui/core'

import Edit from 'assets/icons/square-edit-outline.svg'
import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import SiteStore from 'stores/SiteStore'
import styles from 'styles/capacitiesManagement.module.scss'
import CapacityStore from 'stores/CapacityStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import {
  CapacityType,
  getEmptyCapacity,
  ICapacity,
  ICapacityChanged,
  TimeType,
  CapacityLegendNames,
  WorkcenterType,
} from 'interfaces/Capacity'
import CapHlp from 'utils/CapacityHelper'
import {
  IWorkcenterReduced,
  IWorkcenterMapping,
  IWorkcenter,
  getEmptyWorkcenter,
} from 'interfaces/Workcenter'
import { ISite, getSite } from 'interfaces/Site'
import { ChartTileState } from 'stores/CapacityDashboardStore'
import { notify } from 'components/Notification'
import Controlbar from 'components/CapacityManagement/Controlbar'
import DemandTransferTargetWcDialog from 'components/DemandTransferTargetWcDialog'
import Notifications from 'components/CapacityManagement/Notifications'
import { TimeframeLevel } from 'interfaces/CapacityDashboard'
import { fixedDecimalNumber } from 'utils/MiscFunctions'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'

interface DemandTransferProps extends WithTranslation {
  siteId: string
  capacityType: CapacityType
  authenticationStore?: AuthenticationStore
  capacityStore?: CapacityStore
  workcenterStore?: WorkcenterStore
  siteStore?: SiteStore
}

interface IRow {
  [key: string]: any
}

const currentDate = moment(new Date()).endOf('day').toDate()

interface IColumnDate {
  year: string
  timeValue: string
}
interface IColumnsDates {
  [key: string]: IColumnDate
}

const lastCellPosition = {
  idx: 0,
  rowIdx: 0,
}
const currentCellPosition = {
  idx: 0,
  rowIdx: 0,
  inEditMode: false,
}

let $gridHandle: DataGridHandle | null = null

const DemandTransfer: React.FC<DemandTransferProps> = inject(
  Stores.authenticationStore,
  Stores.capacityStore,
  Stores.workcenterStore,
  Stores.siteStore
)(
  observer((props: DemandTransferProps) => {
    const {
      authenticationStore,
      capacityStore,
      workcenterStore,
      siteStore,
      siteId,
      capacityType,
    } = props

    const { t } = useTranslation()
    const visibleWeekColumns = 53
    const numOfColsBeforeDataCols: number = 2
    const [changed, setChanged] = useState<ICapacityChanged>({})
    const [gridDataUpdateCount, setGridDataUpdateCount] = useState(0)
    const [compareDate, setCompareDate] = useState<Date>(
      moment(new Date()).endOf('day').toDate()
    )
    const [isTableModified, setIsTableModified] = React.useState(false)
    const [currentOriginWorkcenter, setCurrentOriginWorkcenter] = useState<
      string
    >('')

    const [forecastDelta, setIncludeForeCast] = React.useState(false)
    const [currentSite, setCurrentSite] = React.useState<ISite>(getSite())

    const timeType: TimeType =
      currentSite.capacityMaintenance[
        capacityStore!.convertCapacityToCapMaintenance(capacityType)
      ]

    const [momentPeriod, setMomentPeriod] = React.useState<'month' | 'isoWeek'>(
      timeType === TimeType.month ? 'month' : 'isoWeek'
    )

    const currentColumnDate: IColumnDate = {
      year:
        timeType === TimeType.month
          ? moment(currentDate).format('YYYY')
          : moment(currentDate).isoWeekYear().toString(),
      timeValue:
        timeType === TimeType.month
          ? moment(currentDate).format('M')
          : moment(currentDate).format('W'),
    }

    const [controlDate, setControlDate] = useState<Date>(
      moment(new Date())
        .startOf(momentPeriod as any)
        .toDate()
    )

    React.useEffect(() => {
      let newControlDate = new Date()
      newControlDate = moment(newControlDate).startOf(momentPeriod).toDate()
      setControlDate(newControlDate)
    }, [momentPeriod])

    React.useEffect(() => {
      setCurrentSite(
        authenticationStore!.currentUser.sites.filter(
          (site) => site._id === siteId
        )[0]
      )
    }, [siteId])

    React.useEffect(() => {
      setMomentPeriod(timeType === TimeType.week ? 'isoWeek' : 'month')
    }, [timeType])

    const colIdx2Key: string[] = Object.keys(getRow(timeType))
    const colKey2Idx = (key: string) => colIdx2Key.indexOf(key)

    const dataColumnDef = {
      editable: true,
      formatter: basicTableCellFormatter,
      headerCellClass: '',
      cellClass: '',
      minWidth: 100,
      unsafe_onCellInput: (event: any, row: any) => {
        onKeyDownAny(event)
        onKeyDownDelete(event)
      },
    }

    const workcenterColumnDef = {
      editable: false,
      formatter: workcenterTableCellFormatter,
      headerCellClass: '',
      cellClass: '',
      minWidth: 190,
    }

    const tfColumnDef = {
      editable: false,
      formatter: tfTableCellFormatter,
      headerCellClass: '',
      cellClass: '',
      minWidth: 50,
    }

    const getPeriod = () => (momentPeriod === 'isoWeek' ? 'week' : momentPeriod)

    const year1 = moment(controlDate).format('YYYY')
    const workcenters: IWorkcenterReduced[] = React.useMemo(() => {
      const workCenterArr: string[] = capacityStore!.sortByCapacities(
        workcenterStore!.allActiveWorkcenter.map((wc) => wc.number).sort(),
        controlDate,
        CapacityType.demand_transfer
      )
      const workcentersSorted: IWorkcenterReduced[] = workCenterArr
        .map((wcNumber) =>
          find(workcenterStore!.allActiveWorkcenter, { number: wcNumber })
        )
        .map((wc) => ({
          _id: wc ? wc._id : '',
          number: wc ? wc.number : '',
          siteId: wc ? wc.siteId : '',
        }))
      return workcentersSorted
    }, [workcenterStore!.allActiveWorkcenter, year1])

    const columnsDates: IColumnsDates = React.useMemo(() => {
      const columnsDates: IColumnsDates = {}
      const monthCount = colIdx2Key.length - numOfColsBeforeDataCols

      columnsDates['workcenter'] = { year: '0000', timeValue: '0' }
      columnsDates['tf'] = { year: '0000', timeValue: '0' }

      for (let i: number = 0; i < monthCount; i++) {
        const momentDate = moment(controlDate).add(i, getPeriod())
        const columnDate = {
          year:
            timeType === TimeType.month
              ? momentDate.format('YYYY')
              : momentDate.isoWeekYear().toString(),
          timeValue:
            timeType === TimeType.month
              ? momentDate.format('M')
              : momentDate.format('W'),
        }
        columnsDates[colIdx2Key[i + numOfColsBeforeDataCols]] = columnDate
      }

      return columnsDates
    }, [controlDate, timeType, momentPeriod])

    const columns = (() => {
      const columns: Column<any, any>[] = [...getColumnValDef(timeType)]
      const timePeriodCount = colIdx2Key.length - numOfColsBeforeDataCols

      for (let i: number = 0; i < timePeriodCount; i++) {
        const colIdx = i + numOfColsBeforeDataCols
        const momentColDate = moment(controlDate).add(i, getPeriod())

        const isActualPeriod =
          columnsDates[colIdx2Key[colIdx]].timeValue ===
            currentColumnDate.timeValue &&
          columnsDates[colIdx2Key[colIdx]].year === currentColumnDate.year
        const isLastPeriodYear =
          columnsDates[colIdx2Key[colIdx + 1]] &&
          columnsDates[colIdx2Key[colIdx]].year !==
            columnsDates[colIdx2Key[colIdx + 1]].year
        const isFirstPeriodYear =
          columnsDates[colIdx2Key[colIdx - 1]] &&
          columnsDates[colIdx2Key[colIdx]].year !==
            columnsDates[colIdx2Key[colIdx - 1]].year
        let colDateText =
          timeType === TimeType.month
            ? momentColDate.format('MMM')
            : 'CW' + momentColDate.format('W')
        if (
          i === 0 ||
          i === timePeriodCount - 1 ||
          isLastPeriodYear ||
          isFirstPeriodYear
        ) {
          colDateText +=
            " '" + columnsDates[colIdx2Key[colIdx]].year.substring(2, 4)
        }

        let columnClass = isLastPeriodYear
          ? styles['capacitiesTableHeader-lastCol']
          : isActualPeriod
          ? styles['capacitiesTableHeader-actualMonth']
          : ''
        columns[i + numOfColsBeforeDataCols].name = colDateText
        columns[i + numOfColsBeforeDataCols].headerCellClass = columnClass
        columns[i + numOfColsBeforeDataCols].cellClass = isActualPeriod
          ? ''
          : columnClass
      }
      // freeze workcenter and transfer factor column
      columns[0].frozen = true
      columns[1].frozen = true
      return columns
    })()

    const rows = React.useMemo(() => {
      let newRows: any[] = [] // any[] needed for different sum row
      let sumRowCurrent: IRow = { ...getRow(timeType) }
      let sumRowChanged: IRow = { ...getRow(timeType) }

      const getNewRow = (workcenter: string, tf: string): IRow => {
        return { ...getRow(timeType), workcenter: workcenter, tf: tf }
      }

      const mappings: IWorkcenterMapping[] = [
        ...workcenterStore!.workcenterMappings,
      ]
      for (const workcenter of workcenters) {
        const currentWcId: string = workcenterStore!.workcenterNumber2Id[
          workcenter.number
        ]
        // Right now we are only using one target workcenter. If we want to use more, we have to change the following code
        const [wcMappings]: (
          | IWorkcenterMapping
          | undefined
        )[] = mappings.filter(
          (mapping: IWorkcenterMapping) =>
            mapping.originSiteId === siteId &&
            mapping.originWorkCenterId === currentWcId
        )

        // Add new origin workcenter row
        let newOriginWcRow = getNewRow(workcenter.number, '')
        for (const sColKey of Object.keys(newOriginWcRow)) {
          let colKey: keyof IRow = sColKey as keyof IRow
          if (colKey !== 'workcenter') newOriginWcRow[colKey] = ''
        }
        newRows.push(newOriginWcRow)

        // Add new target workcenter row
        if (wcMappings !== undefined) {
          let newTargetWcRow = getNewRow(
            `MAPPING/${wcMappings._id}/${workcenter.number}`,
            wcMappings.transferFactor
          )
          for (const sColKey of Object.keys(newTargetWcRow)) {
            const colKey: keyof IRow = sColKey as keyof IRow

            if (colKey !== 'workcenter' && colKey !== 'tf') {
              let { year, timeValue } = columnsDates[sColKey]
              let changedCapacity = getFromChanged(
                workcenter.number,
                year,
                timeValue,
                CapacityType.demand_transfer
              )
              let cachedCapacity = getFromCacheLatest(
                workcenter.number,
                year,
                timeValue,
                CapacityType.demand_transfer
              )
              let capacity = changedCapacity
              if (!capacity) capacity = cachedCapacity
              newTargetWcRow[colKey] = capacity ? capacity.value : 0

              if (changedCapacity) {
                sumRowChanged[colKey] =
                  sumRowChanged[colKey] + changedCapacity.value
              }
              if (cachedCapacity) {
                sumRowCurrent[colKey] =
                  sumRowCurrent[colKey] + cachedCapacity.value
                if (!changedCapacity) {
                  sumRowChanged[colKey] =
                    sumRowChanged[colKey] + cachedCapacity.value
                }
              }
            }
          }
          newRows.push(newTargetWcRow)
        }
      }

      // Adding sum row
      const sumRow: IRow = {
        ...getRow(timeType, true),
        workcenter: t('capacity-management:sum'),
        tf: '',
      }
      for (const sColKey of Object.keys({ ...getRow(timeType) })) {
        const colKey: keyof IRow = sColKey as keyof IRow
        if (colKey !== 'workcenter' && colKey !== 'tf') {
          let { year, timeValue } = columnsDates[sColKey]
          let demandCap = {
            value: getCapFromMetricQueryData(year, timeValue, ''),
          }
          if (sumRowCurrent[colKey] === sumRowChanged[colKey]) {
            sumRow[colKey] = tableDeltaValues(
              demandCap.value,
              sumRowCurrent[colKey]
            )
          } else {
            sumRow[colKey] = tableDeltaValues(
              demandCap.value,
              sumRowCurrent[colKey],
              sumRowChanged[colKey]
            )
          }
        }
      }
      newRows.push(sumRow)

      setGridDataUpdateCount(gridDataUpdateCount + 1)
      return newRows
    }, [
      changed,
      capacityStore!.cache,
      capacityStore!.metricQueryData,
      capacityStore!.cacheRenderCounter,
      columnsDates,
      workcenters,
      capacityType,
      capacityStore!.currentWorkcenterMapping,
    ])

    const getCompareCapacity = (
      capacities: ICapacity[],
      capType: CapacityType
    ): ICapacity | undefined => {
      const compareDateNumber = +moment(compareDate)
      let olderCapacities: ICapacity[] = capacities.filter(
        (capacity: ICapacity) =>
          capacity.created.createdTime <= compareDateNumber &&
          capacity.capacityType === capType
      )
      return olderCapacities.length > 0
        ? olderCapacities[olderCapacities.length - 1]
        : undefined
    }

    function getLatestCapacity(
      capacities: ICapacity[],
      capType: CapacityType
    ): ICapacity | undefined {
      const compareDateNumber = +moment(currentDate)
      let olderCapacities: ICapacity[] = capacities.filter(
        (capacity: ICapacity) =>
          capacity.created.createdTime <= compareDateNumber &&
          capacity.capacityType === capType
      )
      return olderCapacities.length > 0
        ? olderCapacities[olderCapacities.length - 1]
        : undefined
    }

    function getFromChanged(
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType?: CapacityType
    ): ICapacity | undefined {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = changed[key]
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    const getFromCache = (
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType: CapacityType
    ): ICapacity | undefined => {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = getCompareCapacity(capacityStore!.cache[key], capacityType)
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    function getFromCacheLatest(
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType: CapacityType
    ): ICapacity | undefined {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = getLatestCapacity(capacityStore!.cache[key], capacityType)
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    React.useEffect(() => {
      if (workcenterStore && siteId) {
        workcenterStore!.setAll(siteId)
        workcenterStore!.fetchWorkcenterMappings()
      }
    }, [workcenterStore, siteId])

    React.useEffect(() => {
      siteStore!.setAllSites()
      siteStore!.setAllRegions()
    }, [siteStore])

    React.useEffect(() => {
      if (Object.keys(changed).length > 0)
        notify('Unsaved capacity data was lost!', 'warning')
      setGridDataUpdateCount(gridDataUpdateCount + 1)
      setChanged({})
      capacityStore!.clearCache()
    }, [siteId])

    let formatedControlDate = moment(controlDate).format('YYYYMMW')

    React.useEffect(() => {
      triggerLoadCapacities()
    }, [siteId, capacityType, timeType, formatedControlDate, forecastDelta])

    function triggerLoadCapacities() {
      if (!capacityStore!.rootStore.capacityLoading) {
        const year1 = moment(controlDate).format('YYYY')
        const year2 = moment(controlDate).add(1, 'years').format('YYYY')
        if (
          !capacityStore!.loadedYears.includes(year1) ||
          capacityType !== capacityStore!.loadedCapacityTab ||
          timeType !== capacityStore!.loadedTimeType ||
          forecastDelta !== capacityStore!.forecastDelta
        ) {
          capacityStore!.loadSiteYearTypeCapacities(
            siteId,
            year1,
            [capacityType],
            timeType,
            true,
            forecastDelta
          )
        }
        if (
          !capacityStore!.loadedYears.includes(year2) ||
          capacityType !== capacityStore!.loadedCapacityTab ||
          timeType !== capacityStore!.loadedTimeType ||
          forecastDelta !== capacityStore!.forecastDelta
        ) {
          capacityStore!.loadSiteYearTypeCapacities(
            siteId,
            year2,
            [capacityType],
            timeType,
            true,
            forecastDelta
          )
        }
      }
    }

    async function refreshTable() {
      capacityStore!.clearCache()
      setIsTableModified(false)
      await workcenterStore!.setAll(siteId)
      await workcenterStore!.fetchWorkcenterMappings()
      await siteStore!.setAllRegions()
      triggerLoadCapacities()
      setGridDataUpdateCount(gridDataUpdateCount + 1)
    }

    const onKeyDownAny = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      const editKeys = '01234567890,.'
      if (editKeys.includes(event.key) && !currentCellPosition.inEditMode) {
        if (dataGridWrapper.current) {
          const childPosX = 0 + currentCellPosition.idx
          const childPosY = 3 + currentCellPosition.rowIdx
          const rdgRef = dataGridWrapper.current

          if (rdgRef && rdgRef.children) {
            const ref: any = rdgRef.children[childPosY].children[childPosX]

            if (ref) {
              ref.dispatchEvent(
                new MouseEvent('dblclick', {
                  view: window,
                  bubbles: true,
                  cancelable: true,
                  buttons: 1,
                })
              )
            }
          }
        }
        return
      } else if (event.key === 'Enter' && !currentCellPosition.inEditMode) {
        currentCellPosition.inEditMode = true
        return
      } else if (event.key === 'Enter' && currentCellPosition.inEditMode) {
        currentCellPosition.inEditMode = false
        return
      }
      if (event.key === 'Escape') {
        currentCellPosition.inEditMode = false
        return
      }
    }

    const onKeyDownDelete = async (
      event: React.KeyboardEvent<HTMLDivElement>
    ) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const colName = colIdx2Key[currentCellPosition.idx]
        const rowIdx = currentCellPosition.rowIdx
        const inEditMode = currentCellPosition.inEditMode
        const e: RowsUpdateEvent<{ [key: string]: string }> = {
          action: UpdateActions.CELL_UPDATE,
          cellKey: colName,
          updated: { [colName]: '' },
          fromRow: rowIdx,
          toRow: rowIdx,
        }

        if (!inEditMode) {
          updateChanged(e)
        }
      }
    }

    const controlDateMod = (fast: boolean, subtract: boolean) => {
      const period = fast ? 'year' : getPeriod()
      let newDate: Date
      if (subtract) newDate = moment(controlDate).subtract(1, period).toDate()
      else newDate = moment(controlDate).add(1, period).toDate()
      setControlDate(newDate)
    }

    const cancelChanges = () => {
      setGridDataUpdateCount(gridDataUpdateCount + 1)
      setChanged({})
      setIsTableModified(false)
    }

    const confirmChanges = () => {
      // Execute data storing.
      capacityStore!.saveChangedCapacities(changed).then(() => {
        setChanged({})
        triggerLoadCapacities()
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setIsTableModified(false)
      })
    }

    const updateOneCapacity = (
      frontColumn: string,
      year: string,
      timeValue: string,
      newCapacityValue: any,
      capacityType: CapacityType,
      changedCopy: ICapacityChanged
    ) => {
      const [, workcenterMappingId, workcenterNumber] = frontColumn.split('/')
      const currentCapacity = getFromCacheLatest(
        workcenterNumber,
        year,
        timeValue,
        capacityType
      )
      let newCapacity: ICapacity

      const valueAsNumber =
        newCapacityValue === '' ? 0 : parseFloat(newCapacityValue)
      const isValid = !isNaN(valueAsNumber) && valueAsNumber >= 0

      if (isValid) {
        if (currentCapacity) {
          newCapacity = { ...currentCapacity, value: valueAsNumber }
        } else {
          const workcenterId = workcenterStore!.workcenterNumber2Id[
            workcenterNumber
          ]

          newCapacity = getEmptyCapacity()
          newCapacity.capacityType = capacityType
          newCapacity.timeType = timeType.toUpperCase() as TimeType
          newCapacity.timeValue = (timeValue as unknown) as number
          newCapacity.year = (year as unknown) as number
          newCapacity.workcenterId = workcenterId
          newCapacity.value = valueAsNumber
          newCapacity.workcenterMappingId = workcenterMappingId
        }

        if (!currentCapacity || newCapacityValue !== currentCapacity!.value) {
          const key = `${workcenterNumber}_${year}_${timeValue}_${capacityType}`
          changedCopy[key] = newCapacity
        }
      }

      return changedCopy
    }

    const updateChanged = (e: any) => {
      // console.log('===== updateChanged e: ' + JSON.stringify(e))
      setIsTableModified(true)

      const pos = { idx: colKey2Idx(e.cellKey), rowIdx: e.fromRow }
      // console.log('===== pos: ' + JSON.stringify(pos))

      const frontCol = rows[e.fromRow].workcenter
      const newCapacityValue = e.updated[e.cellKey]

      if (pos.idx > 0) {
        const workcenter = frontCol
        const changedCopy = { ...changed }
        const { year, timeValue } = columnsDates[e.cellKey]

        const newChangedCopy = updateOneCapacity(
          workcenter,
          year,
          timeValue,
          newCapacityValue,
          CapacityType.demand_transfer,
          changedCopy
        )

        // console.log('changedCopy', changedCopy)

        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setChanged(newChangedCopy)
        // TODO: When this is triggered, the table refreshes and the selected cell is changing to the first cell of the table.
      }
    }

    function getLegendEntries() {
      const legendEntries: CapacityLegendNames[] = []
      legendEntries.push(CapacityLegendNames.HOUR_FREE_OFFER)
      legendEntries.push(CapacityLegendNames.OVERLOAD)
      if (isTableModified) legendEntries.push(CapacityLegendNames.NEW_CAPACITY)

      return legendEntries
    }

    const enableConfirm = () => {
      return isTableModified
    }

    const dataGridWrapper: React.MutableRefObject<HTMLElement | null> = React.useRef(
      null
    )
    const { current: dataGridRef }: { current: any } = dataGridWrapper
    const dataGridcrollPosition = dataGridRef ? dataGridRef.scrollTop || 0 : 0

    const restoreSelectionAndScrollPosition = () => {
      const { current: dataGridRef }: { current: any } = dataGridWrapper

      if ($gridHandle) {
        $gridHandle.selectCell(currentCellPosition)
      }

      // restore exact scroll position
      if (dataGridRef) {
        dataGridRef.scrollTop = dataGridcrollPosition
      }
    }

    React.useEffect(() => {
      if (
        $gridHandle &&
        (lastCellPosition.idx !== currentCellPosition.idx ||
          lastCellPosition.rowIdx !== currentCellPosition.rowIdx)
      ) {
        // momorize last cell position
        lastCellPosition.idx = currentCellPosition.idx
        lastCellPosition.rowIdx = currentCellPosition.rowIdx
        defer(() => {
          if ($gridHandle) {
            restoreSelectionAndScrollPosition()
          }
        })
      }
      // here it's important to use the same dependencies that are responsible to rerender the grid table
      // eslint-disable-next-line
    }, [
      capacityType,
      gridDataUpdateCount,
      // eslint-disable-next-line
      capacityStore!.cacheRenderCounter,
    ])

    React.useEffect(() => {
      lastCellPosition.idx = 0
      lastCellPosition.rowIdx = 0
      currentCellPosition.idx = 0
      currentCellPosition.rowIdx = 0
      currentCellPosition.inEditMode = false
      $gridHandle = null
    }, [siteId])

    const isEditView = () => Object.keys(changed).length > 0
    return (
      <div className={styles['container-box']}>
        <Notifications
          isWorkDays={false}
          hasOptionButton={true}
          disableOptionButton={isEditView()}
          capacityType={capacityType}
          timeType={currentSite.capacityMaintenance.transfer}
          currentSite={currentSite}
        />
        <Controlbar
          includeForecast
          forecastDelta={forecastDelta}
          setIncludeDelta={() => setIncludeForeCast(!forecastDelta)}
          controlDate={controlDate}
          controlDateMod={controlDateMod}
          timeType={timeType}
          confirmChanges={confirmChanges}
          enableConfirm={enableConfirm()}
          cancelChanges={cancelChanges}
          isEditView={Object.keys(changed).length > 0}
          loadLegendValues={getLegendEntries()}
        />
        <div
          className={
            styles[
              isEditView()
                ? 'capacitiesTabs-editModalHeaderOn'
                : 'capacitiesTabs-editModalHeaderOff'
            ]
          }
        />
        <div
          className={
            styles[
              isEditView()
                ? 'capacitiesTabs-editModalOn'
                : 'capacitiesTabs-editModalOff'
            ]
          }
        />

        <RootRef rootRef={dataGridWrapper}>
          <ReactDataGrid
            key={
              'ReactDataGrid_key_' +
              gridDataUpdateCount +
              capacityStore!.cacheRenderCounter
            }
            ref={(gridHandle) => {
              if (gridHandle) {
                $gridHandle = gridHandle
              }
            }}
            columns={columns}
            rows={rows}
            height={500}
            onRowsUpdate={updateChanged}
            // enableCellCopyPaste
            // enableCellDragAndDrop
            /** Function called whenever selected cell is changed */
            onSelectedCellChange={({ idx, rowIdx }) => {
              if (
                currentCellPosition.idx !== idx ||
                currentCellPosition.rowIdx !== rowIdx
              ) {
                currentCellPosition.idx = idx
                currentCellPosition.rowIdx = rowIdx
                currentCellPosition.inEditMode = false
              }
            }}
            onCheckCellIsEditable={(event: any) => {
              // disallow editing of workcenter group header rows
              const currentRow = rows[currentCellPosition.rowIdx]
              if (
                currentRow.workcenter.startsWith('MAPPING') === false ||
                currentRow.workcenter === t('capacity-management:sum')
              ) {
                return false
              }

              if (!event.status || event.status !== 'SELECT') {
                currentCellPosition.inEditMode = true
              }

              return true
            }}
          />
        </RootRef>
        {capacityStore?.isModalOpen && siteStore?.allSites && (
          <DemandTransferTargetWcDialog
            siteId={siteId}
            allSites={siteStore?.allSites}
            refreshTable={refreshTable}
            deleteDisable
          />
        )}
      </div>
    )

    function getRow(timeFormat: string, sum?: boolean) {
      let row = {
        workcenter: '',
        tf: '',
      }

      const value = sum ? '' : 0
      const timeSpan =
        timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12
      for (let i = 0; i < timeSpan; i++) {
        const key = timeFormat + (i + 1)
        row = {
          ...row,
          [key]: value,
        }
      }

      return row
    }

    function getColumnValDef(timeFormat: string) {
      let columns = [
        {
          key: 'workcenter',
          name: t('workcenters:WORKCENTER'),
          ...workcenterColumnDef,
        },
        {
          key: 'tf',
          name: t('capacity-management:tf'),
          ...tfColumnDef,
        },
      ]

      const timeSpan =
        timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12

      for (let i = 0; i < timeSpan; i++) {
        const key = timeFormat + (i + 1)
        columns = [
          ...columns,
          {
            key: key,
            name: '',
            ...dataColumnDef,
          },
        ]
      }

      return columns
    }

    function getCapFromMetricQueryData(
      year: string,
      timeVal: string,
      wcNum: string
    ) {
      let cap = 0
      const metricQuery = capacityStore?.metricQueryData[year]
      const workcenterToMatch = workcenterStore?.allActiveWorkcenter.filter(
        (wc: IWorkcenter) => wc.number === wcNum
      )[0]
      const id = workcenterToMatch ? workcenterToMatch._id : null
      const correctedTimeValToColumn: number = parseInt(timeVal, 10) - 1
      const wcQuery = metricQuery
        ? metricQuery.series.filter((query: any) => query.dimensionId === id)
        : undefined

      if (wcQuery && wcQuery.length > 1) {
        // two series should remain, one for free capacity and one for capacity overload, take the value that is not empty
        cap =
          wcQuery[0].data[correctedTimeValToColumn] ||
          wcQuery[1].data[correctedTimeValToColumn] ||
          0
      }
      return cap
    }

    function basicTableCellFormatter(props: any) {
      const frontColumn = props.row['workcenter']
      const isWorkCenterGroupHeader =
        frontColumn.startsWith('MAPPING') === false
      const [, , workcenterNumber] = isWorkCenterGroupHeader
        ? frontColumn
        : frontColumn.split('/')
      const value = props.row[props.column.key]
      const { year, timeValue } = columnsDates[props.column.key]
      const isSumRow = props.row['workcenter'] === t('capacity-management:sum')
      const currentCapacity = getFromCacheLatest(
        workcenterNumber,
        year,
        timeValue,
        CapacityType.demand_transfer
      )
      let changedCapacity = getFromChanged(
        workcenterNumber,
        year,
        timeValue,
        CapacityType.demand_transfer
      )

      // Next line for future delta capacity rows
      if (isWorkCenterGroupHeader || isSumRow) {
        const cap = getCapFromMetricQueryData(year, timeValue, frontColumn)
        if (isSumRow) return value
        return currentCapacity === changedCapacity
          ? tableDeltaValues(cap, currentCapacity)
          : tableDeltaValues(cap, currentCapacity, changedCapacity)
      }

      const oldCap = currentCapacity //black
      const newCap = changedCapacity //blue

      if (value < 0) {
        return CapHlp.tableErrValue(value)
      }

      return CapHlp.tableValue(value, oldCap, newCap)
    }

    function workcenterTableCellFormatter(props: any) {
      const isWorkCenterGroupHeader =
        props.row['workcenter'].startsWith('MAPPING') === false

      if (isWorkCenterGroupHeader) {
        return tableWorkcenterValue(
          props,
          WorkcenterType.ORIGIN,
          props.row['workcenter']
        )
      }

      return tableWorkcenterValue(
        props,
        WorkcenterType.TARGET,
        props.row['workcenter']
      )
    }

    function tfTableCellFormatter(props: any) {
      const isWorkCenterGroupHeader =
        props.row['workcenter'].startsWith('MAPPING') === false
      const value = props.row[props.column.key]
      const workcenterType: WorkcenterType = isWorkCenterGroupHeader
        ? WorkcenterType.ORIGIN
        : WorkcenterType.TARGET

      return tableTfValue(value, workcenterType)
    }

    function tableWorkcenterValue(
      props: any,
      workcenterType: WorkcenterType,
      workcenterNumber?: string
    ) {
      const maxLength = 8
      let sValue: any = props.row[props.column.key] as any
      if (sValue.length > maxLength)
        sValue = sValue.substr(0, maxLength) + '...'
      switch (workcenterType) {
        case WorkcenterType.ORIGIN:
          if (workcenterNumber === t('capacity-management:sum')) {
            return (
              <div className={styles['capacityGroupHeaderRowTableCell']}>
                <span className={styles['capacitiesTableCell-blackBold']}>
                  {sValue}
                </span>
              </div>
            )
          } else if (rows[props.rowIdx + 1]['tf'] !== '') {
            return (
              <div className={styles['capacityGroupHeaderRowTableCell']}>
                <span className={styles['capacitiesTableCell-greyBold']}>
                  {`${sValue} ${t('capacity-management:delta-capacity')}`}
                </span>
              </div>
            )
          } else {
            return (
              <div className={styles['capacityGroupHeaderRowTableCell']}>
                <span className={styles['capacitiesTableCell-greyBold']}>
                  {`${sValue} ${t('capacity-management:delta-capacity')}`}
                </span>
                {getNewAndEditButton(true, workcenterNumber || '')}
              </div>
            )
          }
        case WorkcenterType.TARGET:
          const mappings: IWorkcenterMapping[] = [
            ...workcenterStore!.workcenterMappings,
          ]
          const currentWcId: string =
            workcenterStore!.workcenterNumber2Id[
              rows[props.rowIdx - 1]['workcenter']
            ] || ''
          // Right now we are only using one target workcenter. If we want to use more, we have to change the following code
          const wcMapping: IWorkcenterMapping | undefined = mappings.filter(
            (mapping: IWorkcenterMapping) =>
              mapping.originSiteId === siteId &&
              mapping.originWorkCenterId === currentWcId
          )[0]

          const targetWc: IWorkcenter =
            workcenterStore!.targetWorkcenters.filter(
              (wc) => wcMapping && wc._id === wcMapping.targetWorkCenterId
            )[0] || getEmptyWorkcenter()

          const targetSite: ISite =
            siteStore?.allSites.filter(
              (site: ISite) => site._id === targetWc.siteId
            )[0] || getSite()
          const countryCode: string = targetSite.countryCode
            ? targetSite.countryCode
            : siteStore!.countryCodeMap[targetSite.country]

          const renderTooltipDetails = (
            targetSite: ISite,
            targetWC: IWorkcenter
          ) => {
            return (
              <div>
                <Typography>{`Target site: ${targetSite.name}`}</Typography>
                <Typography>{`Target workcenter: ${targetWC.name}`}</Typography>
              </div>
            )
          }
          const maxLength = 8
          let sTargetWc: string = targetWc.number as string
          if (sTargetWc.length > maxLength)
            sTargetWc = sTargetWc.substr(0, maxLength) + '...'
          return (
            <div>
              <Tooltip title={renderTooltipDetails(targetSite, targetWc)}>
                <span className={styles['capacitiesTableCell-blackBold']}>
                  {`${countryCode ? countryCode + ' / ' : ''}${
                    sTargetWc ? sTargetWc : ''
                  }`}
                </span>
              </Tooltip>
              {getNewAndEditButton(
                false,
                rows[props.rowIdx - 1]['workcenter'] || ''
              )}
            </div>
          )

        default:
          return <div></div>
      }
    }

    function tableTfValue(value: any, workcenterType: WorkcenterType) {
      if (workcenterType === WorkcenterType.ORIGIN) {
        return <div className={styles['capacityGroupHeaderRowTableCell']} />
      } else {
        return (
          <div>
            <span className={styles['capacitiesTableCell-blackValue']}>
              {value}%
            </span>
          </div>
        )
      }
    }

    function getNewAndEditButton(
      isNewEntry: boolean,
      workcenterNumber: string
    ) {
      capacityStore!.currentWorkcenterMapping.originSiteId = siteId
      if (workcenterNumber === t('capacity-management:sum')) return

      return (
        <img
          src={Edit}
          alt="edit_button"
          width={'16px'}
          className={`${styles['capacitiesTableCell-editMappingButton']} clickable hover-grow`}
          onClick={() =>
            isNewEntry
              ? capacityStore!.setModalNewTargetWorkcenter(
                  siteId,
                  workcenterNumber
                )
              : capacityStore!.setModalEditTargetWorkcenter(
                  siteId,
                  workcenterNumber
                )
          }
        />
      )
    }

    function getCapLabels(cap: any) {
      const labelNumber = fixedDecimalNumber(cap)
      if (cap > 0) {
        return (
          <span className={styles['capacitiesTableCell-greenValue']}>
            +{labelNumber}h
          </span>
        )
      } else if (cap < 0) {
        return (
          <span className={styles['capacitiesTableCell-redValue']}>
            {labelNumber}h
          </span>
        )
      } else {
        return (
          <span className={styles['capacitiesTableCell-blackValue']}>
            {labelNumber}h
          </span>
        )
      }
    }

    function tableDeltaValues(
      deltaValue: any,
      currentValue: any,
      changedValue?: any
    ) {
      if (changedValue)
        return (
          <div className={styles['capacityGroupHeaderRowTableCell']}>
            {getCapLabels(deltaValue)}
            <span>&nbsp;</span>
            <span className={styles['capacitiesTableCell-blackValue']}>
              {' › '}
              {getCapLabels(deltaValue + (changedValue + currentValue))}
            </span>
          </div>
        )
      else
        return (
          <div className={styles['capacityGroupHeaderRowTableCell']}>
            {getCapLabels(deltaValue)}
          </div>
        )
    }
  })
)

export default withTranslation()(DemandTransfer)
