import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import differenceBy from 'lodash/differenceBy'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core'

import { Stores } from 'stores/stores'
import UserManagementStore from 'stores/UserManagementStore'
import SiteStore from 'stores/SiteStore'
import { IUser, User, UserRoles } from 'interfaces/User'
import UserService from 'services/UserService'
import UserDialog from 'components/UserDialog'
import PlusIcon from 'assets/icons/plus.svg'
import AuthenticationStore from 'stores/AuthenticationStore'
import { DefaultTooltip, HeaderTableCell } from 'styles/createMyTheme'
import { handleSort, SortOrder, renderTableSortHeader } from 'utils/TableSort'
import LockOpen from 'assets/icons/open.svg'
import LockClosed from 'assets/icons/close.svg'
import { ISite } from '../../interfaces/Site'

import styles from 'styles/userManagement.module.scss'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 250,
    border: '1px solid #00A6EB',
  },
}))(Tooltip)

interface UserManagementProps extends WithTranslation {
  userManagementStore?: UserManagementStore
  authenticationStore?: AuthenticationStore
  siteStore?: SiteStore
}

interface UserManagementState {
  selectedColumn: keyof IUser | ''
  sortOrder: SortOrder
}

@inject(
  Stores.userManagementStore,
  Stores.authenticationStore,
  Stores.siteStore
)
@observer
class UserManagement extends React.Component<
  UserManagementProps,
  UserManagementState
> {
  userManagementStore: UserManagementStore
  authenticationStore: AuthenticationStore
  siteStore: SiteStore
  userService: UserService

  User: IUser = User
  t: TFunction

  constructor(props: UserManagementProps) {
    super(props)
    this.state = {
      selectedColumn: '',
      sortOrder: 'desc',
    }
    this.userManagementStore = props.userManagementStore!
    this.authenticationStore = props.authenticationStore!
    this.siteStore = props.siteStore!
    this.userService = new UserService()
    this.t = props.t
  }

  componentDidMount() {
    this.userManagementStore.setAllUsers()
  }

  tooltipRender = (list: any) => {
    const renderList: any = []
    for (let index = 0; index < list.length; index++) {
      renderList.push(
        <div
          key={list[index].name}
          style={{ marginTop: '10px', marginBottom: '10px' }}>
          <div
            style={{
              position: 'absolute',
              marginTop: index * 30 + 'px',
              width: '20px',
              height: '20px',
              backgroundColor: list[index].color,
              borderRadius: '50%',
            }}
          />
          <div
            style={{
              position: 'absolute',
              marginTop: index * 30 + 5 + 'px',
              marginLeft: '30px',
            }}>
            {list[index].name}
          </div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <div style={{ height: list.length * 30 + 'px' }}>{renderList}</div>
      </React.Fragment>
    )
  }

  sortBy = (header: keyof IUser) => {
    if (header !== 'divisions' && header !== 'sites') {
      this.setState({ selectedColumn: header })
      const sameHeader = header === this.state.selectedColumn
      this.setState(
        {
          sortOrder:
            this.state.sortOrder === 'asc' && sameHeader ? 'desc' : 'asc',
        },
        () => {
          const sortedList = handleSort(
            this.userManagementStore.allUsers,
            header,
            this.state.sortOrder
          )
          if (sortedList) this.userManagementStore.allUsers = sortedList
        }
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('header:userManagement')}
          </Typography>
          <div className="add-new-container">
            <Typography>
              {this.t(`users:usersInTotal`)}{' '}
              {this.userManagementStore.allUsers.length}
            </Typography>
            <DefaultTooltip
              title={`${this.t('users:addNewUser')}`}
              placement="top">
              <img
                src={PlusIcon}
                className="add-new-icon"
                alt="AddImage"
                onClick={() => this.userManagementStore.setModalNewUser()}
              />
            </DefaultTooltip>
          </div>
          {this.renderUserTable()}
        </Box>
      </React.Fragment>
    )
  }

  renderUserTable = () => {
    const allHeaders = Object.keys(omit(this.User, 'p13n')) as (keyof IUser)[]
    const headers = allHeaders.filter((val) => val !== 'preferredDivision')
    return (
      <div>
        <TableContainer style={{ maxHeight: 'calc(100vh - 240px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow color="primary">
                {headers.map((header: keyof IUser) => (
                  <HeaderTableCell
                    key={header}
                    align={header === 'userBlocked' ? 'center' : 'left'}
                    onClick={() => this.sortBy(header)}>
                    <Box display="flex">
                      <Typography className={styles['table-header']}>
                        {this.t(`users:${header}`)}
                      </Typography>
                      {renderTableSortHeader(
                        this.state.selectedColumn,
                        header,
                        this.state.sortOrder
                      )}
                    </Box>
                  </HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.userManagementStore.allUsers.map(
                (user: IUser, index: number) => (
                  <TableRow key={user._id + index} hover={true}>
                    {headers.map((header: keyof IUser) => (
                      <TableCell
                        onClick={() =>
                          this.userManagementStore.setModalUser(user)
                        }
                        key={user._id + header + index}
                        align={header === 'userBlocked' ? 'center' : 'left'}>
                        {this.renderCell(user, header)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <UserDialog
          open={this.userManagementStore.modalOpen}
          onClose={() => this.userManagementStore.setModalClose()}
          initialUser={this.userManagementStore.user}
          modalType={this.userManagementStore.newUser}
          updateList={() => this.userManagementStore.setAllUsers()}
          currentUser={this.authenticationStore.currentUser}
        />
      </div>
    )
  }

  renderCell = (user: IUser, header: keyof IUser) => {
    if (header === 'timeOfCreation' || header === 'lastUpdate') {
      const value = user[header]
      return <Typography>{moment(value).format('DD/MM/YYYY')}</Typography>
    }
    if (header === 'userBlocked') {
      const isBlocked = user[header]
      return (
        <img
          src={isBlocked ? LockClosed : LockOpen}
          alt={isBlocked ? 'Blocked' : 'Active'}
          height="30px"
          width="30px"
        />
      )
    }
    if (header === 'sites')
      return (
        <Tooltip
          title={
            <Typography>{this.getCompleteSiteList(user[header])}</Typography>
          }>
          <Typography>{this.getFormattedSiteList(user[header])}</Typography>
        </Tooltip>
      )
    if (header === 'divisions') return this.getDivisionByUser(user)
    if (header === 'role') {
      if (user[header] === UserRoles.VIEWER)
        return <Typography>{UserRoles.VIEWER}</Typography>
    }
    return <Typography>{user[header].toString()}</Typography>
  }

  public getCompleteSiteList = (sites: ISite[]) => {
    // all sites is already loaded through user dialog
    const allSites = this.siteStore!.allSites
    const userSitesDiff = differenceBy(allSites, sites, '_id')
    const userHasAllSites = userSitesDiff.length === 0
    const LocationsArray: string[] = sites.map(
      (site: ISite) =>
        `${site.name} - ${site.region.name} - ${site.country} - ${site.city}`
    )

    return userHasAllSites ? this.t('users:all') : LocationsArray.join(', ')
  }

  public getFormattedSiteList = (sites: ISite[]) => {
    const LocationString: string = this.getCompleteSiteList(sites)

    return LocationString.length > 20
      ? LocationString.substring(0, 19) + '...'
      : LocationString
  }

  public getDivisionByUser = (user: any) => {
    if (user.role === UserRoles.GLOBAL) {
      return this.t('users:allDivisions')
    } else if (user.sites.length > 0) {
      const finalList: any[] =
        uniqBy(
          user.sites.map((site: ISite) => ({
            name: site.division.name,
            color: site.division.colorCode,
          })) || [],
          'name'
        ) || []

      return (
        <HtmlTooltip title={this.tooltipRender(finalList)}>
          <Box display="flex" alignItems="center">
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: finalList[0].color,
                borderRadius: '50%',
              }}
            />
            <div
              style={{
                whiteSpace: 'nowrap',
                marginLeft: '5px',
              }}>
              {finalList[0].name.length > 15
                ? finalList[0].name.slice(0, 10) + ', ...'
                : finalList[0].name}
            </div>
          </Box>
        </HtmlTooltip>
      )
    } else {
      return ''
    }
  }
}

export default withTranslation()(UserManagement)
