import moment from 'moment'
import isDate from 'lodash/isDate'

import {
  IServiceQueryResult,
  IQueryResult,
  ISeries,
  IFilter,
  ITimeframe,
  TimeframeLevel,
} from 'interfaces/CapacityDashboard'
import { notify } from 'components/Notification'
import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { commonFunctions } from './ServiceConfig'
import indexedDB from 'utils/IndexedDB'

const { handleResponse } = commonFunctions

const openDB = indexedDB.open().catch((e) => console.error(e))

interface RequestBody {
  series: ISeries[]
  startDate: string
  endDate: string
  timeLevel: TimeframeLevel
  referenceDate: string
  filters: IFilter[]
}

export default class CapacityDashboardService {
  mapServiceQueryResult(serviceQueryResult: IServiceQueryResult[]) {
    const queryResult: IQueryResult = {
      labels: [],
      series: [],
    }
    const formatTimeLabelByLevel = (
      timeLevel: TimeframeLevel,
      label: string,
      isFirstLabel: boolean
    ) => {
      switch (timeLevel) {
        case TimeframeLevel.Year: // YYYY
          return label
        case TimeframeLevel.Quarter: // YYYY-MM-DD - YYYY-MM-DD
          const [start, end] = label.split(' - ')
          const startDate = moment(start)
          const endDate = moment(end)
          const quarter =
            ~~Math.ceil((startDate.toDate().getMonth() + 1) / 3 - 1) + 1
          const yearPrefixQuarter =
            isFirstLabel || (!isFirstLabel && quarter === 1)
              ? `${startDate.toDate().getFullYear()} `
              : ''

          return (
            yearPrefixQuarter +
            `Q${quarter} (${startDate.format('MMM').charAt(0)}-${endDate
              .format('MMM')
              .charAt(0)})`
          )
        case TimeframeLevel.Month: // YYYY - (M?)M
          const [yearOfMonth = '', month = label] = label.split(' - ')
          const yearPrefixMonth =
            isFirstLabel || (!isFirstLabel && month === '1')
              ? `${yearOfMonth} `
              : ''

          return (
            yearPrefixMonth +
            moment(`${yearOfMonth}-${month.padStart(2, '0')}`).format('MMM')
          )
        case TimeframeLevel.Week: // YYYY - (W?)W
          const [yearOfWeek = '', week = label] = label.split(' - ')
          const yearPrefixWeek =
            isFirstLabel || (!isFirstLabel && week === '1')
              ? `${yearOfWeek} `
              : ''

          // TODO: use i18n translation to distinguish between CW (en) and KW (de)
          return yearPrefixWeek + `CW ${week.padStart(2, '0')}`
        default:
          return label
      }
    }

    serviceQueryResult.forEach((metricElement, index) => {
      queryResult.series.push({
        ...metricElement,
        data: metricElement.data.map((dataPoint, pointIndex) => {
          if (index === 0) {
            queryResult.labels.push(
              formatTimeLabelByLevel(
                metricElement.timeLevel,
                dataPoint.label,
                pointIndex === 0
              )
            )
          }
          return dataPoint.value
        }),
        label: metricElement.metric.toLowerCase(),
      })
    })

    return queryResult
  }

  public getMetricsQuery = async (
    series: ISeries[],
    timeframe: ITimeframe,
    referenceDate: Date,
    filter: IFilter[],
    cache: boolean = true
  ) => {
    const requestBody: RequestBody = {
      series,
      startDate: moment(timeframe.start).format('YYYY-MM-DD'),
      endDate: moment(timeframe.end).format('YYYY-MM-DD'),
      timeLevel: timeframe.level,
      referenceDate: moment(
        isDate(referenceDate) ? referenceDate : undefined
      ).format('YYYY-MM-DD'),
      filters: filter,
    }
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(requestBody),
    }
    const TIME_TO_LIVE = 6 * 60 * 60 * 1000 // 6 hours
    const requestKey = this.generateRequestKey(requestBody)
    const query = () =>
      fetch(
        `${EndpointService.getEndpoint()}/metricQuery`,
        requestOptions as any
      )
        .then(handleResponse)
        .then(
          (response: IServiceQueryResult[]) =>
            this.mapServiceQueryResult(response),
          (error: Error) => {
            notify(
              error.message !== '500'
                ? 'errors:fetchDashboardQuery'
                : 'errors:server errors:fetchDashboardQuery',
              'error'
            )
            return error
          }
        )

    if (!cache) {
      return query()
    }

    const db = await openDB

    return db ? db.cacheQuery(requestKey, TIME_TO_LIVE, query) : query()
  }

  private generateRequestKey(requestBody: RequestBody) {
    return `S${requestBody.series
      .map((s) => `${s.metric}-${s.drilldown || '*'}`)
      .join('|')}-SD/${requestBody.startDate}-ED/${requestBody.endDate}-TL/${
      requestBody.timeLevel
    }-RD/${requestBody.referenceDate}-F${requestBody.filters
      .map((f) => `${f.filterDimension}${f.operator}${f.values.join('-')}`)
      .join('|')}`
  }
}
