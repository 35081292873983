import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { notify } from '../components/Notification'
import { commonFunctions } from './ServiceConfig'

const { handleResponse } = commonFunctions
export default class SAPImportReportService {
  public getLastReportList = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/importing/report`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:SAPImportReportGetFailure'
          notify(message, 'error')
          return []
        }
      )
  }
}
