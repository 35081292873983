import React from 'react'

import { ChartOptions } from 'chart.js'
import ReactChart from 'react-chartjs-2'

import { merge, isNumber, find } from 'lodash'

import {
  ChartDataset,
  ChartHorizontalLine,
  MicroChartDataset,
} from 'interfaces/CapacityDashboard'
import {
  ChartPlugin,
  abbreviateNumber,
  colorToRgba,
  tooltipRenderer,
} from 'utils/Chart'

interface Props {
  chartInstance?: (chart: any) => void
  scaleMin?: number
  scaleMax?: number
  series?: MicroChartDataset[]
  labels?: string[]
  horizontalLines?: ChartHorizontalLine[]
}

export const MicroChart: React.FunctionComponent<Props> = (props: Props) => {
  const {
    chartInstance = () => {},
    scaleMin,
    scaleMax,
    series = [],
    labels = [],
    horizontalLines = [],
  } = props

  const processedData = React.useMemo(
    () => (canvas?: any) => {
      const ctx = canvas.getContext('2d')

      return {
        labels,
        datasets: series.map((set: MicroChartDataset) => {
          const fillPatternGradient = ctx.createLinearGradient(
            0,
            0,
            0,
            canvas.height
          )

          fillPatternGradient.addColorStop(
            0,
            colorToRgba(set.color || '#ffffff', 0.5)
          )
          fillPatternGradient.addColorStop(
            0.5,
            colorToRgba(set.color || '#ffffff', 1)
          )

          return {
            label: set.label,
            data: set.data,
            backgroundColor: fillPatternGradient,
            borderColor: set.color,
            barPercentage: 0.9,
            categoryPercentage: 1,
            // add additional metadata
            $data: {
              unit: set.unit,
            },
          }
        }),
      }
    },
    [labels, series]
  )

  const processedOptions = React.useMemo(
    () =>
      merge({}, defaultOptions, {
        horizontalLines: horizontalLines.map((line) => ({
          ...line,
          label: !line.yAxisLabel ? line.label : '',
          // the micro chart only supports one axis
          yAxisRight: false,
        })),
        scales: {
          yAxes: [
            {
              ticks: {
                // TODO: enable cutom color for 100% tick (v3.0 required): https://github.com/chartjs/Chart.js/issues/2442
                // fontColor: '#51BF30',
                min: isNumber(scaleMin)
                  ? isNumber(scaleMax) && scaleMax !== scaleMin
                    ? scaleMin
                    : -100
                  : -100,
                max: isNumber(scaleMax)
                  ? isNumber(scaleMin) && scaleMax !== scaleMin
                    ? scaleMax
                    : 100
                  : 100,
                callback: (value: number) => {
                  const line = find(horizontalLines, { value: value })
                  const { yAxisLabel, label, yAxisRight } = line || {}

                  return line && yAxisLabel && yAxisRight
                    ? label
                    : abbreviateNumber(value)
                },
              },
            },
          ],
        },
      }),
    [scaleMax, scaleMin, horizontalLines]
  )

  return (
    <ReactChart
      datasetKeyProvider={(set: ChartDataset) =>
        set.label || ~~(Math.random() * 100000)
      }
      data={processedData}
      type="bar"
      options={processedOptions}
      plugins={[
        {
          afterDraw: chartInstance,
          afterDatasetsDraw: (chart: any, easing: any) => {
            ChartPlugin.afterDatasetsDraw.drawHorizontalLine(chart, easing)
          },
        },
      ]}
    />
  )
}

const defaultOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    mode: 'label',
    // disable the on-canvas tooltip (replace by custom renderer)
    enabled: false,
    custom: tooltipRenderer,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  legend: {
    display: false,
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        type: 'linear',
        scaleLabel: {
          display: false,
        },
        display: true,
        position: 'right',
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
        },
      },
    ],
  },
}
