import React from 'react'
import { inject, observer } from 'mobx-react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Redirect, Switch } from 'react-router-dom'
import find from 'lodash/find'
import flatten from 'lodash/flatten'

import { Typography, Box, Tabs, Tab } from '@material-ui/core'

import Tooltip from '@material-ui/core/Tooltip'
import DemandTransfer from './DemandTransfer'
import DemandSuperMarket from './DemandSupermarket'
import Workdays from './Workdays'
import PrivateRoute from 'components/PrivateRoute'
import SitePicker from 'components/SitePicker'
// import BadgeCustom from 'components/Badge'
import { ISite } from 'interfaces/Site'
import Routes from 'interfaces/Routes'
import AuthenticationStore from 'stores/AuthenticationStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import WorkDaysStore from 'stores/WorkDaysStore'
import RouterStore, { history } from 'stores/RouterStore'
import { Stores } from 'stores/stores'

import styles from 'styles/capacitiesManagement.module.scss'
import { CapacityType } from '../../../interfaces/Capacity'
import CapacityTable from './CapacityTable'

interface Props extends WithTranslation {
  authenticationStore?: AuthenticationStore
  routerStore?: RouterStore
  workcenterStore?: WorkcenterStore
  workDaysStore?: WorkDaysStore
}

type State = {
  workDaysYear: number
  tabIndex: number
  selectedSiteId: string
}

@inject(
  Stores.authenticationStore,
  Stores.routerStore,
  Stores.workcenterStore,
  Stores.workDaysStore
)
@observer
class CapabilitiesManagement extends React.Component<Props, State> {
  t: TFunction
  authenticationStore: AuthenticationStore
  routerStore: RouterStore
  workcenterStore: WorkcenterStore
  workDaysStore: WorkDaysStore

  constructor(props: Props) {
    super(props)
    this.t = props.t
    this.authenticationStore = props.authenticationStore!
    this.routerStore = props.routerStore!
    this.workcenterStore = props.workcenterStore!
    this.workDaysStore = props.workDaysStore!

    const { location } = history
    const sites = this.getSites()
    const [initialSite] = sites || []

    this.state = {
      workDaysYear: new Date().getFullYear(),
      tabIndex: this.getTabIndexByLocation(location),
      selectedSiteId: initialSite ? initialSite._id : '',
    }
  }

  getSites() {
    return this.authenticationStore!.currentUser.sites
  }

  getSelectedSites() {
    const selectedSiteId = this.state ? this.state.selectedSiteId : ''
    const sites = this.getSites()
    const [fallbackSite] = sites || []
    const selectedSite: ISite | undefined = find(this.getSites(), {
      _id: selectedSiteId,
    })

    if (!selectedSite && fallbackSite) {
      return [fallbackSite]
    } else if (!selectedSite) {
      return []
    }

    return [selectedSite]
  }

  getTabIndexByLocation(location: any) {
    const plainRoute = (s: string) => s.replace(/:.*\??\/?/, '')

    switch (plainRoute(location.pathname)) {
      case plainRoute(Routes.CAPACITY_MANAGEMENT_DEMAND_SUPERMARKET):
        return 5

      case plainRoute(Routes.CAPACITY_MANAGEMENT_DEMAND_TRANSFER):
        return 4

      case plainRoute(Routes.CAPACITY_MANAGEMENT_WORKDAYS):
        return 3

      case plainRoute(Routes.CAPACITY_MANAGEMENT_FORECAST):
        return 2

      case plainRoute(Routes.CAPACITY_MANAGEMENT_DEMAND):
        return 1

      case plainRoute(Routes.CAPACITY_MANAGEMENT_AVAILABLE):
      default:
        return 0
    }
  }

  handleTabSwitch(event: any, newValue: number) {
    this.setState({ tabIndex: newValue })
    switch (newValue) {
      case 0:
        this.routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT_AVAILABLE)
        break
      case 1:
        this.routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT_DEMAND)
        break
      case 2:
        this.routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT_FORECAST)
        break
      case 3:
        this.routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT_WORKDAYS)
        break
      case 4:
        this.routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT_DEMAND_TRANSFER)
        break
      case 5:
        this.routerStore.navigateTo(
          Routes.CAPACITY_MANAGEMENT_DEMAND_SUPERMARKET
        )
        break
    }
  }

  componentDidMount() {
    const { tabIndex, selectedSiteId, workDaysYear } = this.state
    const workDayYears = this.workDaysStore.all[selectedSiteId] || {}
    const workDays = flatten(
      Object.values(workDayYears[workDaysYear.toString()] || {})
    )
    const WORK_DAY_TAB_INDEX = 3

    if (
      (!workDays || workDays.length === 0) &&
      this.workDaysStore &&
      selectedSiteId &&
      tabIndex !== WORK_DAY_TAB_INDEX
    ) {
      this.workDaysStore!.getBySiteAndYear(selectedSiteId, workDaysYear)
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const { selectedSiteId: prevSelectedSiteId } = prevState || {}
    const { tabIndex, selectedSiteId, workDaysYear } = this.state || {}
    const WORK_DAY_TAB_INDEX = 3

    if (
      selectedSiteId &&
      prevSelectedSiteId !== selectedSiteId &&
      tabIndex !== WORK_DAY_TAB_INDEX
    ) {
      this.workDaysStore!.getBySiteAndYear(selectedSiteId, workDaysYear)
    }
  }

  isWorkDaysIncomplete() {
    const { selectedSiteId, workDaysYear } = this.state
    const workDayYears = this.workDaysStore.all[selectedSiteId] || {}
    const workDays = flatten(
      Object.values(workDayYears[workDaysYear.toString()] || {})
    )
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const emptyMonth = { workDays: [] }

    return months.some(
      (monthNumber) =>
        (find(workDays, { month: monthNumber }) || emptyMonth).workDays
          .length === 0
    )
  }

  render() {
    const { tabIndex, selectedSiteId } = this.state

    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('header:capacityManagement')}
          </Typography>
          <Typography className={styles.subtitle} color="primary">
            {`${this.t('workcenters:activeAvailable')} ${
              this.workcenterStore!.allActiveWorkcenter.length || 0
            }`}
          </Typography>
          <SitePicker
            className={styles['site-picker']}
            handleSiteSelect={(sites: ISite[]) => {
              const [selectedSite] = sites || []
              const { _id = '' } = selectedSite

              this.setState({ selectedSiteId: _id })
            }}
            sites={this.getSites()}
            selectedSites={this.getSelectedSites()}
            selectMultiple={false}
            showBadges={false}
          />
        </Box>
        <Tabs
          className={styles['tabbar']}
          value={tabIndex}
          onChange={this.handleTabSwitch.bind(this)}
          indicatorColor="primary"
          textColor="primary">
          <Tab label={this.t('capacity-management:tab-available')} />
          <Tab label={this.t('capacity-management:tab-demand')} />
          <Tab label={this.t('capacity-management:tab-forecast')} />
          <Tab
            label={
              <span>
                {this.t('capacity-management:tab-workdays')}
                {/* FIXME: add badge if work days ar not maintained */}
                {this.isWorkDaysIncomplete() && (
                  <Tooltip
                    title={`${this.t('capacity-management:work-days-missing')}`}
                    aria-label={this.t(
                      'capacity-management:work-days-missing'
                    )}>
                    <div
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '16px',
                        width: '20px',
                        height: '20px',
                        border: '1px solid #fff',
                        borderRadius: '10px',
                        backgroundColor: '#e53935',
                      }}></div>
                  </Tooltip>
                )}
              </span>
            }
          />
          <Tab label={this.t('capacity-management:tab-demandTransfer')} />
          <Tab label={this.t('capacity-management:tab-demandSupermarket')} />
        </Tabs>

        <div role="tabpanel">
          <Box p={4}>
            {this.authenticationStore!.currentUser.sites.length > 0 ? (
              <Switch>
                <PrivateRoute exact path={Routes.CAPACITY_MANAGEMENT}>
                  <Redirect to={Routes.CAPACITY_MANAGEMENT_AVAILABLE} />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT}
                  component={CapacityTable}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.available}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_AVAILABLE}
                  component={CapacityTable}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.available}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_DEMAND}
                  component={CapacityTable}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.demand}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_FORECAST}
                  component={CapacityTable}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.forecast}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_WORKDAYS}
                  component={Workdays}
                  onYearChange={(newYear: number) =>
                    this.setState({ workDaysYear: newYear })
                  }
                  siteId={selectedSiteId}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_DEMAND_TRANSFER}
                  component={DemandTransfer}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.demand_transfer}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_MANAGEMENT_DEMAND_SUPERMARKET}
                  component={DemandSuperMarket}
                  siteId={selectedSiteId}
                  capacityType={CapacityType.demand_supermarket_share}
                />
              </Switch>
            ) : (
              <Typography>{this.t('capabilities:userHasNoSites')}</Typography>
            )}
          </Box>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CapabilitiesManagement)
