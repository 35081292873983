import React from 'react'
import {
  AppBar,
  Toolbar,
  Tooltip,
  makeStyles,
  createStyles,
  Menu,
  Fade,
  MenuItem,
  Typography,
  Box,
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { Stores } from 'stores/stores'

import worldWhite from 'assets/icons/world-white.svg'
import dashWhite from 'assets/icons/dash-white.svg'
import processWhite from 'assets/icons/process-white.svg'
import masterDataWhite from 'assets/icons/MD-white.svg'
import logoutWhite from 'assets/icons/logout-white.svg'
import worldBlue from 'assets/icons/world-blue.svg'
import dashBlue from 'assets/icons/dash-blue.svg'
import processBlue from 'assets/icons/process-blue.svg'
import masterDataBlue from 'assets/icons/MD-blue.svg'
import HuebnerIcon from 'assets/icons/huebnerLogo.svg'

import { UserRoles } from 'interfaces/User'
import { ISite } from 'interfaces/Site'
import { Capability } from 'interfaces/Capabilities'
import Routes from 'interfaces/Routes'
import ProfileDialog from './ProfileDialog'
import BadgeCustom from './Badge'
import { version } from '../../package.json'

import styles from 'styles/header.module.scss'

const Header: React.FC = inject(
  Stores.authenticationStore,
  Stores.routerStore
)(
  observer((props: any) => {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { authenticationStore, routerStore, location } = props
    const path = location.pathname

    const useStyles = makeStyles(() =>
      createStyles({
        custom: {
          backgroundColor: '#00A6EB',
          textAlign: 'center',
          color: '#fff',
          minWidth: '62px',
          maxWidth: '100px',
          paddingBottom: '10px',
          minHeight: '30px',
          fontSize: '15px',
          marginTop: '0px',
          borderRadius: '0% 0% 100% 100%',
        },
      })
    )

    const classes = useStyles()

    const pathIsMasterData = () => {
      return (
        path === Routes.LOCAL_CAPABILITIES ||
        path === Routes.CAPABILITY_MANAGEMENT ||
        path === Routes.SITE_MANAGEMENT ||
        path === Routes.USER_MANAGEMENT
      )
    }

    const handleLogout = () => {
      routerStore.navigateTo(Routes.LOGIN)
      authenticationStore.cleanLoginData()
    }

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      authenticationStore.setBadge(false)
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const isInternalUser = () => {
      return (
        authenticationStore.currentUser.role === UserRoles.LOCAL ||
        authenticationStore.currentUser.role === UserRoles.GLOBAL
      )
    }

    const badgetHadler = (sites: Array<ISite>) => {
      const capabilitiesSume: Array<any> = []
      // eslint-disable-next-line
      sites.map((site) => {
        // eslint-disable-next-line
        site.capabilities.map((capability) => {
          capabilitiesSume.push(capability)
        })
      })

      capabilitiesTester(capabilitiesSume)
      return authenticationStore.badge
    }

    const capabilitiesTester = (capabilitiesSume: Array<Capability>) => {
      for (
        let index = 0;
        index < capabilitiesSume.length && !authenticationStore.badge;
        index++
      ) {
        if (capabilitiesSume[index].children.length !== 0) {
          capabilitiesTester(capabilitiesSume[index].children)
        } else {
          if (
            capabilitiesSume[index].internal === 2 ||
            capabilitiesSume[index].external === 2
          ) {
            authenticationStore.setBadge(true)
          }
        }
      }
    }

    return (
      <AppBar position="sticky" elevation={0} style={{ marginBottom: '-64px' }}>
        <Toolbar>
          <div className={styles['header-container']}>
            <img
              src={HuebnerIcon}
              style={{ marginLeft: '-20px' }}
              alt="Logo"
              width="300px"
              className={styles['header-icon']}
            />
            {authenticationStore.loggedIn ? (
              <React.Fragment>
                <Tooltip
                  title={`${t('header:map')}`}
                  classes={{ tooltip: classes.custom }}>
                  <div
                    className={
                      path === Routes.MAP
                        ? styles['icon-container-active']
                        : styles['icon-container']
                    }
                    onClick={() => routerStore.navigateTo(Routes.MAP)}>
                    <img
                      src={path === Routes.MAP ? worldBlue : worldWhite}
                      alt={t('header:map')}
                      className={styles['menu-icon']}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={`${t('header:capacities')}`}
                  classes={{ tooltip: classes.custom }}>
                  <div
                    className={
                      path === Routes.CAPACITY_DASHBOARD
                        ? styles['icon-container-active']
                        : styles['icon-container']
                    }
                    onClick={() =>
                      routerStore.navigateTo(Routes.CAPACITY_DASHBOARD)
                    }>
                    <img
                      src={
                        path === Routes.CAPACITY_DASHBOARD
                          ? dashBlue
                          : dashWhite
                      }
                      alt={t('header:capacities')}
                      className={styles['menu-icon']}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={`${t('header:capabilities')}`}
                  classes={{ tooltip: classes.custom }}>
                  <div
                    className={
                      path === Routes.CAPABILITY_MATRIX
                        ? styles['icon-container-active']
                        : styles['icon-container']
                    }
                    onClick={() =>
                      routerStore.navigateTo(Routes.CAPABILITY_MATRIX)
                    }>
                    <img
                      src={
                        path === Routes.CAPABILITY_MATRIX
                          ? processBlue
                          : processWhite
                      }
                      alt={t('header:capabilities')}
                      className={styles['menu-icon']}
                    />
                  </div>
                </Tooltip>

                {authenticationStore!.currentUser.role !== UserRoles.VIEWER && (
                  <Tooltip
                    title={`${t('header:masterData')}`}
                    classes={{ tooltip: classes.custom }}>
                    <div
                      className={
                        pathIsMasterData()
                          ? styles['icon-container-active']
                          : styles['icon-container']
                      }
                      onClick={handleClick}>
                      {authenticationStore.currentUser.role ===
                      UserRoles.LOCAL ? (
                        <div
                          onClick={() =>
                            routerStore.navigateTo(Routes.LOCAL_CAPABILITIES)
                          }>
                          <img
                            src={
                              pathIsMasterData()
                                ? masterDataBlue
                                : masterDataWhite
                            }
                            alt={t('header:masterData')}
                            className={styles['menu-icon']}
                          />
                        </div>
                      ) : (
                        <img
                          src={
                            pathIsMasterData()
                              ? masterDataBlue
                              : masterDataWhite
                          }
                          alt={t('header:masterData')}
                          className={styles['menu-icon']}
                        />
                      )}
                    </div>
                  </Tooltip>
                )}
                {badgetHadler(authenticationStore.currentUser.sites) && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '155px',
                    }}>
                    <BadgeCustom />
                  </div>
                )}
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl}
                  color="primary"
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  style={{ marginTop: 58 }}>
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      routerStore.navigateTo(Routes.LOCAL_CAPABILITIES)
                    }}>
                    <div
                      onClick={() =>
                        routerStore.navigateTo(Routes.LOCAL_CAPABILITIES)
                      }>
                      <Typography variant="h6">
                        {t('header:localCapabilities')}
                      </Typography>
                    </div>
                    {badgetHadler(authenticationStore.currentUser.sites) && (
                      <BadgeCustom />
                    )}
                  </MenuItem>
                  {isInternalUser() && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.CAPACITY_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:capacityManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {authenticationStore.currentUser.role ===
                    UserRoles.GLOBAL && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.CAPABILITY_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.CAPABILITY_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:capabilityManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {authenticationStore.currentUser.role ===
                    UserRoles.GLOBAL && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.SITE_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.SITE_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:siteManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {authenticationStore.currentUser.role ===
                    UserRoles.GLOBAL && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.DIVISION_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.DIVISION_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:divisionManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {authenticationStore.currentUser.role ===
                    UserRoles.GLOBAL && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.WORKCENTER_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.WORKCENTER_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:workcenterManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {authenticationStore.currentUser.role ===
                    UserRoles.GLOBAL && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.USER_MANAGEMENT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.USER_MANAGEMENT)
                        }>
                        <Typography variant="h6">
                          {t('header:userManagement')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                  {isInternalUser() && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        routerStore.navigateTo(Routes.SAP_IMPORT_REPORT)
                      }}>
                      <div
                        onClick={() =>
                          routerStore.navigateTo(Routes.SAP_IMPORT_REPORT)
                        }>
                        <Typography variant="h6">
                          {t('header:sapImportReport')}
                        </Typography>
                      </div>
                    </MenuItem>
                  )}
                </Menu>
                <ProfileDialog />
                <Tooltip
                  title={`${t('header:logout')}`}
                  classes={{ tooltip: classes.custom }}>
                  <div className={styles['icon-container']}>
                    <img
                      onClick={handleLogout}
                      src={logoutWhite}
                      alt={t('header:logout')}
                      className={styles['menu-icon']}
                    />
                  </div>
                </Tooltip>
              </React.Fragment>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                V{version}
              </Box>
            )}
          </div>
        </Toolbar>
      </AppBar>
    )
  })
)

export default withRouter(Header)
