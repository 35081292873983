const today = new Date()

export interface IWorkDays {
  _id: string
  month: number
  siteId: string
  workDays: number[]
  year: number
}

export const WorkDays: any = {
  month: today.getMonth(),
  workDays: [],
  year: today.getFullYear(),
}

export const getEmptyWorkDays = (): IWorkDays => {
  return { _id: '', siteId: '', ...WorkDays }
}
