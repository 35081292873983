import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import {
  getEmptyWorkcenter,
  IWorkcenter,
  WorkcenterType,
  WorkcenterState,
  IFlatCap,
} from 'interfaces/Workcenter'
import { IErrorMessage } from 'interfaces/Common'
import {
  WorkcentersManagement,
  getSupportLabelByInternalInfo,
} from 'pages/MasterData/WorkcenterManagement'
import { ISite } from 'interfaces/Site'
import TextDisplayEdit from 'components/TextDisplayEdit'
import { Stores } from 'stores/stores'
import WorkcenterService from 'services/WorkcenterService'
import AuthenticationStore from 'stores/AuthenticationStore'
import TrashIcon from 'assets/icons/trash.svg'
import TrashDisabledIcon from 'assets/icons/trash-gray.svg'

import { ConfirmButton, DefaultTooltip } from 'styles/createMyTheme'
import styles from 'styles/dialog.module.scss'
import WorkcenterStore from '../stores/WorkcenterStore'

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
export const checkedIcon = <CheckBoxIcon fontSize="small" />

interface WorkcenterDialogProps {
  open: boolean
  onClose: () => void
  initialWorkcenter: IWorkcenter
  modalType: boolean
  updateWorkcenters: () => void
  workcenters: IWorkcenter[]
  site: ISite
  siteFlatCaps: IFlatCap[]
  authenticationStore?: AuthenticationStore
  workcenterStore?: WorkcenterStore
  numberAndtypeDisabled: boolean
}

const WorkcenterDialog: React.FC<WorkcenterDialogProps> = inject(
  Stores.authenticationStore,
  Stores.workcenterStore
)(
  observer((props: WorkcenterDialogProps) => {
    const getInitWorkcenter = () => {
      const newWorkcenter = getEmptyWorkcenter()
      newWorkcenter.siteId = site._id
      return initialWorkcenter._id || initialWorkcenter.number
        ? { ...initialWorkcenter }
        : newWorkcenter
    }
    const {
      onClose,
      open,
      initialWorkcenter,
      modalType,
      updateWorkcenters,
      workcenters,
      site,
      siteFlatCaps,
      authenticationStore,
      workcenterStore,
      numberAndtypeDisabled,
    } = props
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [workcenter, setWorkcenter] = useState<IWorkcenter>(
      getInitWorkcenter()
    )
    const [selectedCapabilityIds, setSelectedCapabilityIds] = useState<
      string[]
    >([])

    const [validData, setValidData] = useState<boolean>(true)
    const [askState, setAskState] = useState<boolean>(false)
    const [errorMessages, setErrorMessages] = useState<IErrorMessage[]>([])
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(
      false
    )
    const [
      stateSubWorkcentersDialogOpen,
      setStateSubWorkcentersDialogOpen,
    ] = useState(false)

    const hasChildren: boolean = React.useMemo(() => {
      return (
        workcenters.findIndex(
          (anyWrkCntr: IWorkcenter) => anyWrkCntr.parentId === workcenter._id
        ) !== -1
      )
    }, [workcenter, workcenters])

    const workcenterService = new WorkcenterService()

    const handleValueChange = (
      field:
        | 'number'
        | 'name'
        | 'parentId'
        | 'type'
        | 'state'
        | 'capabilityIds',
      value: any
    ) => {
      const workcenterCopy: any = { ...workcenter }
      workcenterCopy[field] = value
      if (field === 'state' && hasChildren) {
        setAskState(true)
      }
      setWorkcenter(workcenterCopy)
    }

    useEffect(() => {
      setWorkcenter(getInitWorkcenter())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
      setEditMode(modalType)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType])

    useEffect(() => {
      setSelectedCapabilityIds(workcenter.capabilityIds || [])
      // eslint-disable-next-line
    }, [workcenter])

    const resetState = () => {
      setEditMode(false)
      setDeleteMode(false)
      setValidData(true)
      onClose()
    }

    const handleClose = () => {
      resetState()
    }

    const registerNewWorkcenter = async () => {
      let userName =
        authenticationStore!.currentUser.firstname +
        ' ' +
        authenticationStore!.currentUser.lastname

      const newWorkcenter = {
        siteId: workcenter.siteId,
        number: workcenter.number,
        name: workcenter.name,
        parentId: workcenter.parentId,
        type: workcenter.type,
        state: workcenter.state,
        capabilityIds: workcenter.capabilityIds,
        created: { createdUser: userName },
        lastEditBy: { lastEditUser: userName },
      }
      if (validationTest(true)) {
        workcenterStore?.setWorkCenterToAdd(newWorkcenter.number)
        if (await workcenterService.registerWorkcenter(newWorkcenter)) {
          workcenterStore?.setWorkCenterToSuccess()
          await updateWorkcenters() //TOASK
        } else workcenterStore?.setWorkCenterToFailure()
        handleClose()
      } else {
        setValidData(false)
      }
    }

    const updateThisWorkcenter = async (deleteCapabilities?: boolean) => {
      if (deleteCapabilities) workcenter.capabilityIds = []
      if (validationTest(false)) {
        if (await workcenterService.updateWorkcenter(workcenter)) {
          if (askState) {
            setStateSubWorkcentersDialogOpen(true)
          } else {
            await updateWorkcenters() //TOASK
            handleClose()
          }
        }
      } else {
        setValidData(false)
      }
    }

    const handleWorkcenterUpdate = async () => {
      await updateThisWorkcenter()
    }

    const workcenterDeletion = async () => {
      if (await workcenterService.deleteWorkcenter(workcenter._id)) {
        await updateWorkcenters() //TOASK
      }
      handleClose()
    }

    const subWorkcentersState = async () => {
      await workcenterStore!.updateSubWorkcentersState(workcenter)
      await updateWorkcenters() //TOASK
      handleClose()
    }

    const validationTest = (isNew: boolean) => {
      let errorMessages = []

      let uniquePerSite =
        workcenters.filter(
          (w) =>
            w.siteId === workcenter.siteId &&
            w.number === workcenter.number &&
            w._id !== workcenter._id
        ).length === 0
      let idExists =
        workcenters.filter((w) => w._id === workcenter._id).length > 0
      let parentExists =
        workcenters.filter((w) => w._id === workcenter.parentId).length > 0

      if (!workcenter.number) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['number'] })
      }
      if (!workcenter.name) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['name'] })
      }
      if (!workcenter.type) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['type'] })
      }
      if (!workcenter.state) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['state'] })
      }

      if (!uniquePerSite) {
        errorMessages.push({ error: 'idNotUnique' })
      }

      if (workcenter.parentId && !parentExists) {
        errorMessages.push({ error: 'parentNotExists' })
      }

      if (!isNew && !(workcenter._id && workcenter.siteId && idExists)) {
        errorMessages.push({ error: 'uknownError' })
      }

      setErrorMessages(errorMessages)
      return errorMessages.length === 0
    }

    const otherWorkcenterGroups = (currentWorkcenter: IWorkcenter) => {
      return workcenters.filter(
        (workcenter) =>
          workcenter.type === WorkcenterType.group &&
          workcenter._id !== currentWorkcenter._id
      )
    }

    const renderParentOptions = (currentWorkcenter: IWorkcenter) => {
      return otherWorkcenterGroups(currentWorkcenter).map(
        (workcenter, index) => (
          <MenuItem key={index + workcenter._id} value={workcenter._id}>
            {t(`workcenters:${workcenter.name}`)}
          </MenuItem>
        )
      )
    }

    const renderParentName = (currentWorkcenter: IWorkcenter) => {
      let parentWorkcenterList: IWorkcenter[] = workcenters.filter(
        (workcenter) => workcenter._id === currentWorkcenter.parentId
      )
      return parentWorkcenterList.length ? parentWorkcenterList[0].name : ''
    }

    const renderTypeOptions = () => {
      return Object.values(WorkcenterType).map((value, index) => (
        <MenuItem key={index + value} value={value}>
          {t(`workcenters:${value}`)}
        </MenuItem>
      ))
    }

    const renderStateOptions = () => {
      return Object.values(WorkcenterState).map((value, index) => (
        <MenuItem key={index + value} value={value}>
          {t(`workcenters:${value}`)}
        </MenuItem>
      ))
    }

    function isCapabilitySelected(flatCap: IFlatCap) {
      return (
        (selectedCapabilityIds && selectedCapabilityIds.includes(flatCap.id)) ||
        false
      )
      //      return workcenter.capabilityIds.includes(flatCap.id) || false
    }

    function getSelectedCapabilities() {
      return (siteFlatCaps || []).filter(
        (flatCap) => (selectedCapabilityIds || []).includes(flatCap.id) || false
      )
    }

    function handleSelectCapabilitiesOption(selectedFlatCaps: IFlatCap[]) {
      if (editMode) {
        let selectedCapabilityIds = selectedFlatCaps.reduce(
          (r: string[], c: IFlatCap, i, a): string[] => [...r, c.id],
          []
        )
        setSelectedCapabilityIds(selectedCapabilityIds)
        handleValueChange('capabilityIds', selectedCapabilityIds)
      }
    }

    const Mandatory = () => <span style={{ color: 'red' }}> *</span>

    const renderInfo = () => {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography>
                {t('workcenters:number')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                disabled={numberAndtypeDisabled}
                fullWidth
                value={workcenter.number ? workcenter.number : ''}
                onChange={(event: any) =>
                  handleValueChange('number', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('workcenters:name')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={workcenter.name ? workcenter.name : ''}
                onChange={(event: any) =>
                  handleValueChange('name', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('workcenters:type')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Select
                  disabled={numberAndtypeDisabled}
                  fullWidth
                  labelId="type-select"
                  id="workcenter-type-select"
                  value={workcenter.type}
                  onChange={(event: any) =>
                    handleValueChange('type', event.target.value)
                  }>
                  {renderTypeOptions()}
                </Select>
              ) : (
                <Typography>{t(`workcenters:${workcenter.type}`)}</Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography>{t('workcenters:parentId')}</Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Tooltip title={`${t('workcenters:parentId')}`} arrow={true}>
                  <Select
                    fullWidth
                    labelId="parent-select"
                    id="workcenter-parent-select"
                    value={workcenter.parentId}
                    onChange={(event: any) =>
                      handleValueChange('parentId', event.target.value)
                    }>
                    <MenuItem key={'_' + workcenter._id} value={''}></MenuItem>
                    {renderParentOptions(workcenter)}
                  </Select>
                </Tooltip>
              ) : (
                <Typography>{renderParentName(workcenter)}</Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('workcenters:state')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Select
                  fullWidth
                  labelId="state-select"
                  id="workcenter-state-select"
                  value={workcenter.state}
                  onChange={(event: any) =>
                    handleValueChange('state', event.target.value)
                  }>
                  {renderStateOptions()}
                </Select>
              ) : (
                <Typography>{t(`workcenters:${workcenter.state}`)}</Typography>
              )}
            </Grid>
            {workcenter.type === WorkcenterType.workcenter && (
              <React.Fragment>
                <Grid item xs={4}>
                  <Typography>{t('workcenters:capabilityIds')}</Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ maxHeight: '215px', overflow: 'auto' }}>
                  {!editMode ? (
                    WorkcentersManagement.renderCapabilitiesChips(
                      workcenter,
                      siteFlatCaps
                    )
                  ) : (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id={`workcenter-capability-select`}
                      getOptionLabel={(flatCap) =>
                        flatCap.shortName +
                        ` (${getSupportLabelByInternalInfo(flatCap.internal)})`
                      }
                      options={siteFlatCaps}
                      value={getSelectedCapabilities()}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        selectedFlatCaps: any
                      ) => handleSelectCapabilitiesOption(selectedFlatCaps)}
                      renderOption={(flatCap, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            color="primary"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={isCapabilitySelected(flatCap)}
                          />
                          {flatCap.fullName +
                            ` (${getSupportLabelByInternalInfo(
                              flatCap.internal
                            )})`}
                        </React.Fragment>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((flatCap, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            label={
                              flatCap.shortName +
                              ` (${getSupportLabelByInternalInfo(
                                flatCap.internal
                              )})`
                            }
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder={editMode ? t('profile:siteFilter') : ''}
                          margin="normal"
                          InputProps={{ ...params.InputProps }}
                          InputLabelProps={{ ...params.InputLabelProps }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </React.Fragment>
            )}
            {!validData && (
              <Grid item xs={12}>
                {getErrors()}
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )
    }

    const getErrors = () => {
      const textGroup = 'workcenters'
      return errorMessages.map((errMsg) => (
        <Typography color={'error'}>
          {errMsg.parameters
            ? errMsg.parameters.reduce(
                (p, c, i, a) => p.replace(`%${i}%`, t(`${textGroup}:${c}`)),
                t(`${textGroup}:${errMsg.error}`)
              )
            : t(`${textGroup}:${errMsg.error}`)}
        </Typography>
      ))
    }

    const getConfirmButton = () => {
      return (
        deleteMode && (
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              onClick={() => setConfirmDeleteDialogOpen(true)}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        )
      )
    }

    const getDialogActions = () => {
      if (editMode) {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                modalType ? registerNewWorkcenter() : handleWorkcenterUpdate()
              }}>
              {modalType ? t('create') : t('save')}
            </Button>
          </div>
        )
      } else {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setDeleteMode(false)
              }}>
              {t('edit')}
            </Button>
          </div>
        )
      }
    }

    const getConfirmDeleteDialog = () => {
      return (
        <Dialog
          open={confirmDeleteDialogOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={() => setConfirmDeleteDialogOpen(false)}
          maxWidth="lg">
          <DialogTitle className={styles['dialog-title-deletion']}>
            {t('workcenters:confirmToDelete')}
          </DialogTitle>
          <DialogContent>
            <Box minWidth="400px">
              <Typography>
                {workcenter.capabilityIds?.length
                  ? t('workcenters:deleteWithCapabilities')
                  : t('workcenters:delete')}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className={styles['action-button-container']}>
              <Box mr="auto">
                <Button
                  variant="contained"
                  onClick={() => setConfirmDeleteDialogOpen(false)}>
                  {t('close')}
                </Button>
              </Box>
              <Box style={{ marginRight: '10px' }}>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    workcenterDeletion()
                    setConfirmDeleteDialogOpen(false)
                  }}>
                  {t('confirm')}
                </ConfirmButton>
              </Box>
            </div>
          </DialogActions>
        </Dialog>
      )
    }

    const getStateSubWorkcentersDialog = () => {
      return (
        <Dialog
          open={stateSubWorkcentersDialogOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={() => setStateSubWorkcentersDialogOpen(false)}
          maxWidth="lg">
          <DialogTitle className={styles['dialog-title-division']}>
            {t('workcenters:confirmToSetAll')}
          </DialogTitle>
          <DialogContent>
            <Box minWidth="400px">
              <Typography>
                {t('workcenters:stateSubWorkcenters').replace(
                  '%0%',
                  workcenter.state === WorkcenterState.active
                    ? t('workcenters:ACTIVE')
                    : t('workcenters:INACTIVE')
                )}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className={styles['action-button-container']}>
              <Box mr="auto">
                <Button
                  variant="contained"
                  onClick={async () => {
                    await updateWorkcenters()
                    handleClose()
                    setStateSubWorkcentersDialogOpen(false)
                  }}>
                  {t('close')}
                </Button>
              </Box>
              <Box style={{ marginRight: '10px' }}>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    subWorkcentersState()
                    setStateSubWorkcentersDialogOpen(false)
                  }}>
                  {t('confirm')}
                </ConfirmButton>
              </Box>
            </div>
          </DialogActions>
        </Dialog>
      )
    }

    return (
      <React.Fragment>
        {/* actual edit/create modal */}
        <Dialog
          open={open}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={handleClose}>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle className={styles['dialog-title']}>
              {modalType
                ? t('workcenters:addNewWorkcenter')
                : t('workcenters:editWorkcenter')}
            </DialogTitle>
            {!modalType && !deleteMode && (
              <DefaultTooltip
                title={
                  hasChildren
                    ? `${t('workcenters:deleteChildrenFirstTooltip')}`
                    : `${t('workcenters:deleteTooltip')}`
                }
                placement="left">
                <div style={{ display: 'inline-block' }}>
                  <Button
                    onClick={() => setDeleteMode(true)}
                    disabled={hasChildren}
                    style={
                      hasChildren
                        ? {
                            position: 'relative',
                            marginTop: '10px',
                            cursor: 'pointer',
                            pointerEvents: 'none',
                          }
                        : {
                            position: 'relative',
                            marginTop: '10px',
                            cursor: 'pointer',
                          }
                    }>
                    <img
                      src={hasChildren ? TrashDisabledIcon : TrashIcon}
                      alt="Filter"
                      style={{ width: 50, height: 40 }}
                    />
                  </Button>
                </div>
              </DefaultTooltip>
            )}
          </Box>
          <DialogContent>{renderInfo()}</DialogContent>
          <DialogActions>{getDialogActions()}</DialogActions>
        </Dialog>

        {getConfirmDeleteDialog()}
        {getStateSubWorkcentersDialog()}
      </React.Fragment>
    )
  })
)

export default WorkcenterDialog
