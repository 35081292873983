import React from 'react'
import { Router, Switch } from 'react-router-dom'
import Login from 'pages/Login'
import PrivateRoute from 'components/PrivateRoute'
import Header from 'components/Header'
import SiteMap from 'pages/SiteMap'
import CapabilityMatrix from 'pages/CapabilityMatrix'
import UserManagement from 'pages/MasterData/UserManagement'
import SAPImportReport from 'pages/MasterData/SAPImportReport'
import CapabilitiesManagement from 'pages/MasterData/CapabilitiesManagement'
import LocalCapabilities from 'pages/MasterData/LocalCapabilities'
import Sites from 'pages/MasterData/Sites'
import WorkcenterManagement from 'pages/MasterData/WorkcenterManagement'
import Notification from '../components/Notification'
import LoginRoute from 'components/LoginRoute'
import Routes from 'interfaces/Routes'
import Loader from 'components/Loader'
import { Stores } from 'stores/stores'
import { inject, observer } from 'mobx-react'
import RootStore from 'stores/RootStore'
import DivisionsManagement from 'pages/MasterData/DivisionsManagement'
import RouterStore, { history } from 'stores/RouterStore'
import LeaveDialog from 'components/LeaveDialog'
import CapacityDashboard from 'pages/CapacityDashboard/Container'
import CapacitiesManagement from 'pages/MasterData/CapacityManagement/Container'

interface RouterProps {
  rootStore?: RootStore
  routerStore?: RouterStore
}

const MyRouter: React.FC<RouterProps> = inject(
  Stores.rootStore,
  Stores.routerStore
)(
  observer(({ rootStore, routerStore }) => {
    const appIsLoading = () => {
      const { anyLoading } = rootStore!
      return anyLoading
    }

    return (
      <Router history={history}>
        <Header />
        <Notification />
        <LeaveDialog
          open={routerStore!.leaveModalOpen}
          onClose={() => (routerStore!.leaveModalOpen = false)}
          onLeave={routerStore!.handleLeave}
        />
        <Loader open={appIsLoading()} />
        <div className="header-offset">
          <Switch>
            <LoginRoute exact path={Routes.LOGIN} component={Login} />
            <PrivateRoute exact path={Routes.MAP} component={SiteMap} />
            <PrivateRoute
              path={Routes.CAPACITY_DASHBOARD}
              component={CapacityDashboard}
            />
            <PrivateRoute
              exact
              path={Routes.CAPABILITY_MATRIX}
              component={CapabilityMatrix}
            />
            <PrivateRoute
              path={Routes.CAPACITY_MANAGEMENT}
              component={CapacitiesManagement}
            />
            <PrivateRoute
              exact
              path={Routes.CAPABILITY_MANAGEMENT}
              component={CapabilitiesManagement}
            />
            <PrivateRoute
              exact
              path={Routes.LOCAL_CAPABILITIES}
              component={LocalCapabilities}
            />
            <PrivateRoute
              exact
              path={Routes.SITE_MANAGEMENT}
              component={Sites}
            />
            <PrivateRoute
              exact
              path={Routes.DIVISION_MANAGEMENT}
              component={DivisionsManagement}
            />
            <PrivateRoute
              exact
              path={Routes.WORKCENTER_MANAGEMENT}
              component={WorkcenterManagement}
            />
            <PrivateRoute
              exact
              path={Routes.USER_MANAGEMENT}
              component={UserManagement}
            />
            <PrivateRoute
              exact
              path={Routes.SAP_IMPORT_REPORT}
              component={SAPImportReport}
            />
            <PrivateRoute component={SiteMap} />
          </Switch>
        </div>
      </Router>
    )
  })
)

export default MyRouter
