import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { IUser } from 'interfaces/User'
import { notify } from 'components/Notification'
import { commonFunctions } from './ServiceConfig'
import { ISite } from '../interfaces/Site'

const { handleResponse } = commonFunctions

interface IUpdateUserOptions {
  successMsg?: string
  errorMsg?: string
}

export default class UserService {
  public getAllUsers = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/users/allusers`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:loadUsersFailure'
          notify(message, 'error')
          return []
        }
      )
  }

  public deleteUser = (userId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/users/delete?id=${userId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:userDelete', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:userDeleteFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public registerUser = (user: IUser) => {
    const sitesList = user.sites.map((site: ISite) => site._id)
    let siteListReducedUser = {
      ...user,
      sites: sitesList,
    }
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(siteListReducedUser),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/users/registeruser`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:userCreate', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:userRegistrationFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public updatePassword = (oldPassword: string, newPassword: string) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify({
        oldPassword,
        newPassword,
      }),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/users/updatepassword`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:passwordUpdate', 'success')
          localStorage.setItem('jwt', response.jwt)
          HeaderService.headers['Authorization'] = `jwt:${response.jwt}`
          return response
        },
        (error: any) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:passwordUpdateFailure'
          notify(message, 'error')
          return error
        }
      )
  }

  public updateUser = (
    user: IUser,
    options: IUpdateUserOptions | undefined = {}
  ) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(user),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/users/updateuser`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify(
            options.successMsg ? options.successMsg : 'notify:userUpdate',
            'success'
          )
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:userUpdateFailure'
          notify(options.errorMsg ? options.errorMsg : message, 'error')
          return null
        }
      )
  }
}
