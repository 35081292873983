import { action, observable } from 'mobx'
import assign from 'lodash/assign'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import map from 'lodash/map'

import {
  IWorkcenter,
  getEmptyWorkcenter,
  WorkcenterType,
  IWorkcenterMapping,
  WorkcenterState,
} from 'interfaces/Workcenter'
import RootStore from './RootStore'
import WorkcenterService from 'services/WorkcenterService'

export default class WorkcenterStore {
  rootStore: RootStore
  @observable all: IWorkcenter[] = []
  @observable allActiveGroups: IWorkcenter[] = []
  @observable allActiveWorkcenter: IWorkcenter[] = []
  @observable modalOpen: boolean = false
  @observable workcenter: IWorkcenter = getEmptyWorkcenter()
  @observable new: boolean = false
  @observable hasChildren: boolean = false
  @observable workcentersBySite: {
    [siteId: string]: IWorkcenter[]
  } = {}
  @observable workcenterMappings: IWorkcenterMapping[] = []
  @observable targetWorkcenters: IWorkcenter[] = []

  @observable workcenterToAdd: {
    [wcNumber: string]: string
  } = {}

  workcenterId2Number: { [id: string]: string } = {}
  workcenterNumber2Id: { [number: string]: string } = {}

  workcenterService = new WorkcenterService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.all = []
  }

  @action
  async setAll(siteId: string) {
    this.rootStore.setWorkcentersLoading(true)
    const workcenters = await this.workcenterService.getSiteWorkcenters(siteId)
    this.all = workcenters

    const allActiveGroups: IWorkcenter[] = []
    const allActiveWorkcenters: IWorkcenter[] = []

    workcenters.forEach((workcenter: IWorkcenter) => {
      if (workcenter.state === WorkcenterState.active) {
        workcenter.type === WorkcenterType.workcenter
          ? allActiveWorkcenters.push(workcenter)
          : allActiveGroups.push(workcenter)
      }
      this.workcenterId2Number[workcenter._id] = workcenter.number
      this.workcenterNumber2Id[workcenter.number] = workcenter._id
    })

    this.allActiveGroups = [...allActiveGroups]
    this.allActiveWorkcenter = [...allActiveWorkcenters]

    this.rootStore.setWorkcentersLoading(false)
    return workcenters
  }

  @action
  async setAllBySites(siteIds: string[]) {
    this.rootStore.setWorkcentersLoading(true)

    const addParentIdList: (
      workcenter: IWorkcenter,
      _i: number,
      allWorkcenters: IWorkcenter[]
    ) => IWorkcenter = (workcenter, _i, allWorkcenters) => {
      const getParentIds: (
        parentIdList: string[],
        workcenter: IWorkcenter,
        allWorkcenters: IWorkcenter[]
      ) => string[] = (parentIdList, workcenter, allWorkcenters) => {
        const parent = find(allWorkcenters, { _id: workcenter.parentId })
        if (parent) {
          parentIdList.push(parent._id)
        }

        return parent
          ? getParentIds(parentIdList, parent, allWorkcenters)
          : parentIdList
      }

      return assign(workcenter, {
        parentIdList: getParentIds([], workcenter, allWorkcenters),
      })
    }

    await siteIds.forEach((siteId) =>
      this.workcenterService
        .getSiteWorkcenters(siteId)
        .then((fetchedWorkcenters) =>
          assign(this.workcentersBySite, {
            [siteId]: isArray(fetchedWorkcenters)
              ? map(fetchedWorkcenters, addParentIdList)
              : [],
          })
        )
        .catch(() =>
          assign(this.workcentersBySite, {
            [siteId]: [],
          })
        )
    )

    this.rootStore.setWorkcentersLoading(false)
    return this.workcentersBySite
  }

  @action
  async fetchWorkcenterMappings() {
    return await this.workcenterService
      .getWorkcenterMappings()
      .then((mapping: IWorkcenterMapping[]) => {
        this.workcenterMappings = [...mapping]
        const targetWcIds: string[] = mapping.map(
          (map) => map.targetWorkCenterId
        )
        this.workcenterService
          .getAllWorkcenters()
          .then((allWcs: IWorkcenter[]) => {
            const targetWcs: IWorkcenter[] = []
            targetWcIds.forEach((wcId: string) => {
              let tmpWcs = allWcs.filter((wc: IWorkcenter) => wc._id === wcId)
              if (tmpWcs.length > 0) targetWcs.push(tmpWcs[0])
            })
            this.targetWorkcenters = [...targetWcs]
          })
      })
  }

  @action
  async createWorkcenterMapping(
    originSiteId: string,
    originWorkcenterId: string,
    targetSiteId: string,
    targetWorkcenterId: string,
    transferFactor: string
  ) {
    const mapping: IWorkcenterMapping = {
      _id: '',
      originSiteId: originSiteId,
      originWorkCenterId: originWorkcenterId,
      targetSiteId: targetSiteId,
      targetWorkCenterId: targetWorkcenterId,
      transferFactor: transferFactor,
    }
    this.workcenterService.createWorkcenterMapping(mapping)
  }

  @action getSubWorkcenters(parentWorkcenter: IWorkcenter): IWorkcenter[] {
    let subWorkcenters: IWorkcenter[] = []

    let parentsIds: string[] = [parentWorkcenter._id]
    while (parentsIds.length > 0) {
      // eslint-disable-next-line no-loop-func
      let newSubWorkcenters = this.all.filter((wc) =>
        parentsIds.includes(wc.parentId)
      )
      parentsIds = newSubWorkcenters.map((wc) => wc._id)
      subWorkcenters.push(...newSubWorkcenters)
    }

    return subWorkcenters
  }

  @action async updateSubWorkcentersState(parentWorkcenter: IWorkcenter) {
    let subWorkcenters = this.getSubWorkcenters(parentWorkcenter)
    subWorkcenters.map((wc) => (wc.state = parentWorkcenter.state))

    subWorkcenters.forEach((wc) => this.workcenterService.updateWorkcenter(wc))
  }

  @action resetWorkCenterToAdd() {
    this.workcenterToAdd = {}
  }

  @action setWorkCenterToAdd(wc: string) {
    this.workcenterToAdd = { number: wc }
  }

  @action setWorkCenterToSuccess() {
    this.workcenterToAdd = { ...this.workcenterToAdd, status: 'success' }
  }
  @action setWorkCenterToFailure() {
    this.workcenterToAdd = { ...this.workcenterToAdd, status: 'failure' }
  }

  @action
  setModal(value: IWorkcenter) {
    console.log(JSON.parse(JSON.stringify(value)))
    this.workcenter = value
    this.new = false
    this.modalOpen = true
  }

  @action
  setModalNew() {
    this.workcenter = getEmptyWorkcenter()
    this.new = true
    this.modalOpen = true
  }

  @action
  setModalNewForCapability(value: IWorkcenter) {
    console.log(JSON.parse(JSON.stringify(value)))
    this.workcenter = value
    this.new = true
    this.modalOpen = true
  }

  @action
  setModalClose() {
    this.modalOpen = false
    this.resetWorkCenterToAdd()
  }
}
