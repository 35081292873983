import React from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'

import { ThemeProvider, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { abbreviateNumber } from 'utils/Chart'

import myTheme from 'styles/createMyTheme'
import styles from 'styles/capacityDashboard.module.scss'

export enum TrendTileMetricState {
  Success = 'Success',
  Error = 'Error',
}

export interface ITrendTileMetric {
  value: string
  unit: string
  description: string
  state?: TrendTileMetricState
}

export enum TrendTileVariant {
  Grey = 'linear-gradient(170deg, #8A8A8A, #484848)',
  Teal = 'linear-gradient(170deg, #3B6475, #1D323B)',
  Indigo = 'linear-gradient(170deg, #003764, #00192E)',
}

interface IProps {
  title?: string
  info?: string
  loading?: boolean
  error?: boolean
  metrics?: ITrendTileMetric[]
  variant: TrendTileVariant
}

const TrendTile: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation()
  const {
    title = '',
    info = '',
    metrics = [],
    loading = false,
    error = false,
    variant = TrendTileVariant.Grey,
  } = props

  return (
    <Paper
      className={styles['tile-chart']}
      style={{
        backgroundImage: `url(${require('assets/graphics/wave.svg')}), ${variant}`,
      }}>
      <Box p={2}>
        <ThemeProvider<Theme>
          theme={merge({}, myTheme, {
            palette: { type: 'dark', text: { primary: '#ffffff' } },
          })}>
          <Box pb={1} display="flex">
            <Typography className={styles['title']} color="textPrimary">
              {title}
            </Typography>
            <Box display="flex" flexGrow={1}></Box>
            <Typography className={styles['info']} color="textPrimary">
              {info}
            </Typography>
          </Box>

          {(loading || error) && (
            <Box display="flex" alignItems="center" justifyContent="center">
              {loading && <CircularProgress size={48} />}
              {error && <ErrorOutlineIcon fontSize="large" color="error" />}
            </Box>
          )}
          {!loading && !error && (
            <Box display="flex">
              {metrics.map((metric, index) => (
                <Box className={styles['metric']} key={index}>
                  <Box>
                    <Typography
                      className={`${styles['value']} ${
                        styles[metric.state?.toLowerCase() || '']
                      }`}
                      color="textPrimary"
                      display="inline">
                      {abbreviateNumber(parseFloat(metric.value), 1)}
                    </Typography>
                    <Typography
                      className={`${styles['unit']} ${
                        styles[metric.state?.toLowerCase() || '']
                      }`}
                      color="textPrimary"
                      display="inline">
                      {metric.unit}
                    </Typography>
                  </Box>
                  <Typography
                    className={styles['description']}
                    color="textPrimary">
                    {metric.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </ThemeProvider>
      </Box>
    </Paper>
  )
}

export default TrendTile
