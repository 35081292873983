import React from 'react'
import { ISite } from 'interfaces/Site'
import FactoryIcon from 'assets/icons/factory.svg'
import {
  Typography,
  Menu,
  Fade,
  MenuItem,
  Checkbox,
  Box,
} from '@material-ui/core'
import ArrowIcon from 'assets/icons/arrow-blue.svg'
import BadgeCustom from './Badge'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import { Capability } from 'interfaces/Capabilities'
import { useTranslation } from 'react-i18next'

import styles from 'styles/sitePicker.module.scss'

interface SitePickerProps {
  className?: string
  sites: ISite[]
  selectedSites: ISite[]
  handleSiteSelect: (sites: ISite[]) => void
  authenticationStore?: AuthenticationStore
  selectMultiple?: boolean
  showBadges?: boolean
}

const SitePicker: React.FC<SitePickerProps> = inject(
  Stores.authenticationStore
)(
  observer(
    ({
      className = '',
      sites,
      selectedSites,
      handleSiteSelect,
      authenticationStore,
      selectMultiple,
      showBadges,
    }) => {
      const { t } = useTranslation()
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
      const isOpen = React.useMemo(() => !!anchorEl, [anchorEl])

      const handleSingleSelection = (site: ISite) => {
        if (selectedSites.length < 1 || selectedSites[0]._id !== site._id) {
          handleSiteSelect([site])
        }
      }

      const handleMultipleSelection = (clickedSite: ISite) => {
        const ids = selectedSites.map((site) => site._id)
        const currentlySelected = ids.findIndex((id) => id === clickedSite._id)

        const selectedSitesCopy = [...selectedSites]
        if (currentlySelected === -1) selectedSitesCopy.push(clickedSite)
        else selectedSitesCopy.splice(currentlySelected, 1)
        handleSiteSelect(selectedSitesCopy)
      }

      const capabilitiesTesterSite = (capabilities: Array<Capability>) => {
        let response: boolean = false

        const tester = (capas: Array<Capability>) => {
          for (const capa of capas) {
            if (capa.children.length !== 0) {
              tester(capa.children)
            } else {
              if (capa.internal === 2 || capa.external === 2) {
                response = true
              }
            }
          }
        }

        for (const capability of capabilities) {
          if (capability.children.length !== 0) {
            tester(capability.children)
          } else {
            if (capability.internal === 2 || capability.external === 2) {
              response = true
            }
          }
        }

        return response
      }

      const isSelected = (id: string) => {
        const ids = selectedSites.map((site) => site._id)
        return ids.includes(id)
      }

      return (
        <div className={`${styles['site-picker-container']} ${className}`}>
          {showBadges && authenticationStore!.badge && <BadgeCustom />}
          <img
            src={FactoryIcon}
            alt=""
            height="25px"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />

          <Typography
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className={styles['site-name']}>
            {selectMultiple
              ? t('capabilities:selectSites')
              : selectedSites[0] && (
                  <React.Fragment>
                    {selectedSites[0].name}, {selectedSites[0].city},{' '}
                    {selectedSites[0].division.name}
                  </React.Fragment>
                )}
          </Typography>
          <img
            onClick={(event) => setAnchorEl(event.currentTarget)}
            src={ArrowIcon}
            alt=""
            height="25px"
          />
          {isOpen && (
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              color="primary"
              onClose={() => setAnchorEl(null)}
              style={{ marginTop: 40 }}>
              <Box marginX="15px" display="inline">
                {sites.length === 0 ? (
                  <Box marginX="15px">
                    <Typography>
                      {t('capabilities:divisionHasNoSitesAssigned')}
                    </Typography>
                  </Box>
                ) : (
                  sites.map((site) => (
                    <MenuItem
                      key={site._id}
                      onClick={() => {
                        setAnchorEl(null)
                        selectMultiple
                          ? handleMultipleSelection(site)
                          : handleSingleSelection(site)
                      }}>
                      {selectMultiple && (
                        <Checkbox
                          color="primary"
                          checked={isSelected(site._id)}
                        />
                      )}
                      <Typography>
                        {site.name}, {site.city}, {site.division.name}
                      </Typography>
                      {showBadges &&
                        capabilitiesTesterSite(site.capabilities) && (
                          <BadgeCustom />
                        )}
                    </MenuItem>
                  ))
                )}
              </Box>
            </Menu>
          )}
        </div>
      )
    }
  )
)

export default SitePicker
