import { geoContains } from 'd3-geo'
import React from 'react'
import {
  GeoJSON,
  Map as LeafletMap,
  Marker,
  Point,
  TileLayer,
  Tooltip,
} from 'react-leaflet'
import L, {
  DivIconOptions,
  LatLng,
  LatLngBounds,
  LatLngTuple,
  PathOptions,
} from 'leaflet'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import { ISite } from 'interfaces/Site'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import MapStore from 'stores/MapStore'
import { withRouter } from 'react-router'
import { History } from 'history'
import Routes from 'interfaces/Routes'
import { toJS } from 'mobx'
import { Button } from '@material-ui/core'
import DivisionStore from 'stores/DivisionStore'
import { useTranslation } from 'react-i18next'
import { IRegionGeo, Regions } from 'utils/Regions'
import { getDivision } from 'interfaces/Division'
import SiteStore from 'stores/SiteStore'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { huebnerBlue } from 'styles/createMyTheme'

import styles from 'styles/customCluster.module.scss'
import mapStyles from 'styles/map.module.scss'

interface IControlSettings {
  center: LatLngTuple
  zoom: number
}

const settingsInitialState: IControlSettings = {
  center: [50, 11],
  zoom: 3,
}

interface MapProps {
  divisionStore?: DivisionStore
  mapStore?: MapStore
  siteStore?: SiteStore
  history?: History
}

const Map: React.FunctionComponent<any> = inject(
  Stores.divisionStore,
  Stores.mapStore,
  Stores.siteStore
)(
  observer((props: MapProps) => {
    const controlSettings: IControlSettings = settingsInitialState
    const selectedDivision =
      props.divisionStore!.selectedDivision || getDivision()
    const selectMode = props.mapStore!.selectMode || false
    const [selectedSites, setSelectedSites] = React.useState<Array<ISite>>([])
    const [selectedRegions, setSelectedRegions] = React.useState<string[]>([])
    const [currentHoveredRegion, setCurrentHoveredRegion] = React.useState<
      string
    >('')

    const { t } = useTranslation()

    const resetSelectedData = () => {
      setSelectedSites([])
      setSelectedRegions([])
      setCurrentHoveredRegion('')
    }

    const cancelSelect = () => {
      resetSelectedData()
      props.mapStore!.disableSelect()
    }

    const handleSelect = () => {
      props.divisionStore!.setSelectedDivision(selectedSites[0].division)
      props.mapStore!.setSelectedMapSites(selectedSites)
      props.history!.push(Routes.CAPABILITY_MATRIX)
    }

    const createClusterCustomIcon = (cluster: any) => {
      const count = cluster.getChildCount()
      let size = 'small'
      if (count >= 10 && count) size = 'medium'
      const options = { cluster: mapStyles[`marker-cluster-${size}`] }
      return L.divIcon({
        html: `
          <div class=${styles['cluster-container']}>
          <div class=${styles['circle-' + size.toString()]}>
            <ul>${pieRenderer(props.mapStore!.filteredSites)}</ul>
            <div class=${styles['circle-top']}>
            <span ${styles['counter']}>${count}</span>
          </div>
          <div>
          </div>`,
        className: options.cluster,
        iconAnchor: cluster.getBounds(),
      })

      function pieRenderer(sites: ISite[]) {
        const markers = cluster.getAllChildMarkers()
        const pieces: Array<any> = []
        let colors: Array<string> = []

        markers.forEach((marker: any) => {
          sites.forEach((site, index) => {
            if (
              sites[index].location.lat === marker.options.position[0] &&
              sites[index].location.lon === marker.options.position[1]
            ) {
              pieces.push(toJS(sites[index]))
            }
          })
        })

        pieces.forEach((piece: any) => colors.push(piece.division.colorCode))

        const colorsFilter = Array.from(new Set(colors))
        colors = []

        const repeatFunction = (numToPush: number, idx: number) => {
          for (let index = 0; index < numToPush; index++)
            colors.push(colorsFilter[idx])
        }

        if (colorsFilter.length === 1) {
          repeatFunction(12, 0)
        }

        if (colorsFilter.length === 2) {
          repeatFunction(6, 0)
          repeatFunction(6, 1)
        }

        if (colorsFilter.length === 3) {
          repeatFunction(4, 0)
          repeatFunction(4, 1)
          repeatFunction(4, 2)
        }

        if (colorsFilter.length >= 4) {
          repeatFunction(3, 0)
          repeatFunction(3, 1)
          repeatFunction(3, 2)
          repeatFunction(3, 3)
        }

        let circleToReturn: string = ''
        for (let index = 0; index < 12; index++) {
          circleToReturn =
            circleToReturn + `<li style="background: ${colors[index]}"/>`
        }

        return circleToReturn
      }
    }

    React.useEffect(() => {
      if (props.mapStore && props.siteStore)
        props.mapStore.setAllSites(props.siteStore.allSites)
      // eslint-disable-next-line
    }, [props.mapStore, props.siteStore!.allSites])
    React.useEffect(() => {
      if (!selectMode) resetSelectedData()
    }, [props.mapStore, selectMode])
    React.useEffect(() => () => props.mapStore!.disableSelect(), [
      props.mapStore,
    ])
    return (
      <div className={mapStyles['map-wrapper']}>
        <LeafletMap
          animate
          className={mapStyles['map-container']}
          zoom={controlSettings.zoom}
          center={controlSettings.center}
          maxZoom={18}
          minZoom={3}
          maxBounds={
            new LatLngBounds(new LatLng(85, -180), new LatLng(-85, 180))
          }
          zoomAnimation
          fadeAnimation
          zoomControl={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
          />
          {selectMode && Regions.map((region: IRegionGeo) => getRegion(region))}
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            spiderLegPolylineOptions={{
              weight: 0,
              opacity: 0,
            }}
            spiderfyOnMaxZoom
            showCoverageOnHover={false}
            animate>
            {props.mapStore!.filteredSites.length > 0 &&
              props.mapStore!.filteredSites.map((site) => getMarker(site))}
          </MarkerClusterGroup>
        </LeafletMap>
        {selectMode && (
          <div className={mapStyles['map-select-buttons-wrapper']}>
            <div className={mapStyles['map-buttons-container']}>
              <Button variant="contained" onClick={cancelSelect}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSelect}
                disabled={selectedSites.length === 0}>
                {t('show')}
              </Button>
            </div>
          </div>
        )}
      </div>
    )

    function getRegion(region: IRegionGeo) {
      const state = getState()
      return (
        <GeoJSON
          id={`geo-json-${region.title}-${state.type}`}
          key={`geo-json-${region.title}-${state.type}`}
          data={region.data}
          style={state.style}
          onMouseOver={() => setCurrentHoveredRegion(region.title)}
          onMouseOut={() => setCurrentHoveredRegion('')}
          onClick={() =>
            selectedDivision._id &&
            !sitesSelectedBeforeRegion() &&
            selectRegion()
          }
        />
      )

      function isRegionSelected() {
        return selectedRegions.includes(region.title)
      }

      function sitesSelectedBeforeRegion() {
        return selectedSites.length > 0 && selectedRegions.length === 0
      }

      function getState() {
        const hoveredRegionStyle: PathOptions = {
          color: huebnerBlue,
          weight: 1,
          fillOpacity: 0.5,
        }
        const normalRegionStyle: PathOptions = {
          color: huebnerBlue,
          weight: 0,
          fillOpacity: 0,
        }
        const selectedRegionStyle: PathOptions = {
          color: huebnerBlue,
          weight: 3,
          fillOpacity: 0.8,
        }
        if (isRegionSelected())
          return {
            type: 'selected',
            style: selectedRegionStyle,
          }
        if (
          currentHoveredRegion === region.title &&
          !isRegionSelected() &&
          !sitesSelectedBeforeRegion()
        )
          return {
            type: 'hover',
            style: hoveredRegionStyle,
          }
        else
          return {
            type: 'normal',
            style: normalRegionStyle,
          }
      }

      function selectRegion() {
        const regionArray: string[] = [...selectedRegions]
        let selectedSitesCopy: ISite[] = [...selectedSites]
        const availableSites: ISite[] = props.mapStore!.filteredSites
        if (isRegionSelected()) {
          availableSites.forEach((site: ISite) => {
            const sitePoint: Point = [+site.location.lon, +site.location.lat]
            const isSiteInRegion: boolean = geoContains(region.data, sitePoint)
            if (isSiteSelected(site) && isSiteInRegion) {
              selectedSitesCopy = selectedSitesCopy.filter(
                (e: ISite) => e._id !== site._id
              )
            }
          })
          setSelectedSites(selectedSitesCopy)
          setSelectedRegions(
            regionArray.filter((reg: string) => reg !== region.title)
          )
        } else {
          availableSites.forEach((site: ISite) => {
            const sitePoint: Point = [+site.location.lon, +site.location.lat]
            const isSiteInRegion: boolean = geoContains(region.data, sitePoint)
            if (
              !isSiteSelected(site) &&
              isSiteInRegion &&
              site.division._id === selectedDivision._id
            ) {
              selectedSitesCopy.push(site)
            }
          })
          setSelectedSites(selectedSitesCopy)
          regionArray.push(region.title)
          setSelectedRegions(regionArray)
        }

        function isSiteSelected(site: ISite) {
          return (
            selectedSites.filter((e: ISite) => e._id === site._id).length > 0
          )
        }
      }
    }

    function getMarker(site: ISite) {
      return (
        <Marker
          key={site._id}
          icon={getIcon()}
          position={getCoords()}
          onclick={() => selectedRegions.length === 0 && handleMarkerClick()}
          opacity={isSiteSameDivision() ? 1 : 0.5}
          riseOnHover={true}>
          <Tooltip position={getCoords()} direction={'top'} offset={[0, -15]}>
            {site.name}
          </Tooltip>
        </Marker>
      )

      function getCoords() {
        return [+site.location.lat, +site.location.lon] as LatLngTuple
      }

      function isSelected() {
        return selectedSites.filter((e: ISite) => e._id === site._id).length > 0
      }

      function handleMarkerClick() {
        if (!selectMode) {
          props.divisionStore!.setSelectedDivision(site.division)
          props.mapStore!.setSelectedMapSites([site])
          props.history!.push(Routes.CAPABILITY_MATRIX)
        }
        if (
          selectMode &&
          isSiteSameDivision() &&
          selectedRegions.length === 0
        ) {
          const copyArr = [...selectedSites]
          const existInCopy = isSelected()
          const newCopyArr = existInCopy
            ? copyArr.filter((e) => e._id !== site._id)
            : [...copyArr, site]
          setSelectedSites(newCopyArr)
        }
      }

      function getIcon() {
        const black = '#000000',
          white = '#FFFFFF',
          gray = '#b1b1b1'
        const siteColor = site.division.colorCode
          ? site.division.colorCode
          : gray
        const borderColor = isSelected() ? white : hexToRGB(siteColor, 0.5)
        const factoryColor = getContrast(siteColor)
        const factoryIcon = () =>
          `<svg width="100%" height="100%" viewBox="0 0 794 744" version="1.1" xmlns="http://www.w3.org/2000/svg"  xml:space="preserve"  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1,0,0,1,0,74.375)"><path d="M0,298L121,264L121,0L205,0L205,240L320,208L320,0L404,0L404,185L518,152L518,0L602,0L602,129L794,75L794,595L670,595L670,400L496,400L496,595L0,595L0,298ZM281,409L385,409L385,462L281,462L281,409ZM96,409L200,409L200,462L96,462L96,409Z" style="fill:${factoryColor};"/></g></svg>`

        const markerProps: DivIconOptions = {
          html: `<div style='background-color: ${siteColor}; border: 3px solid ${borderColor};' class=${
            mapStyles['marker-pin']
          }></div>${factoryIcon()}`,
          iconSize: [30, 42],
          className: mapStyles['custom-div-icon'],
        }
        return L.divIcon({ ...markerProps })

        function getContrast(hexColor: string) {
          // If a leading # is provided, remove it
          if (hexColor.slice(0, 1) === '#') hexColor = hexColor.slice(1)
          // If a three-character hex-code, make six-character
          if (hexColor.length === 3)
            hexColor = hexColor
              .split('')
              .map((hex: string) => hex + hex)
              .join('')
          // Convert to RGB value
          const r = parseInt(hexColor.substr(0, 2), 16)
          const g = parseInt(hexColor.substr(2, 2), 16)
          const b = parseInt(hexColor.substr(4, 2), 16)
          // Get YIQ ratio
          const yiq = (r * 299 + g * 587 + b * 114) / 1000
          // Check contrast
          return yiq >= 128 ? black : white
        }

        function hexToRGB(hex: string, alpha?: number) {
          const r = parseInt(hex.slice(1, 3), 16)
          const g = parseInt(hex.slice(3, 5), 16)
          const b = parseInt(hex.slice(5, 7), 16)
          return alpha
            ? `rgba(${r}, ${g}, ${b}, ${alpha})`
            : `rgba(${r}, ${g}, ${b})`
        }
      }

      function isSiteSameDivision() {
        return selectedSites.length > 0
          ? site.division._id === selectedSites[0].division._id
          : true
      }
    }
  })
)
export default withRouter(Map)
