export interface ILocation {
  lat: string
  lon: string
}

export interface ICountry {
  name: string
  code: string
}

export interface IRegion {
  _id: string
  name: string
  countries: ICountry[]
}

export interface ILastEdit {
  lastEditedTime: number
  lastEditedUser: string
}

export interface ICreated {
  createdTime: number
  createdUser: string
  createdBy: string
}

export const region: IRegion = {
  _id: '',
  name: '',
  countries: [],
}

export interface IErrorMessage {
  error: string
  parameters?: string[]
}
