import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidthOpen = 260;
const drawerWidthClosed = 73;

const UserFilter = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		menuButton: {
			marginRight: 36
		},
		hide: {
			display: "none"
		},
		drawer: {
			width: drawerWidthOpen,
			flexShrink: 0,
			whiteSpace: "nowrap"
		},
		drawerPaper: {
			background: "#484848",
			overflow: "hidden"
		},
		drawerOpen: {
			marginLeft: 0,
			marginTop: 64,
			width: drawerWidthOpen,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		drawerClose: {
			marginTop: 64,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: "hidden",
			marginLeft: -drawerWidthOpen + drawerWidthClosed,
			width: drawerWidthOpen
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		inputs: {
			marginLeft: "12px",
			marginRight: "12px",
			display: "flex",
			flexDirection: "column"
		},
		selectBtn: {
			marginLeft: "12px",
			marginRight: "12px",
			marginTop: "20px",
			display: "flex",
			flexDirection: "column"
		},
		filterContent: {
			display: "flex",
			justifyContent: "flex-end"
		},
		iconContainer: {
			width: drawerWidthClosed,
			display: "flex",
			justifyContent: "center"
		},
		contentShiftOpen: {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			marginLeft: drawerWidthOpen
		},

		contentShiftClosed: {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			marginLeft: drawerWidthClosed
		},
		cssFocused: {},
		notchedOutline: {
			borderWidth: "1px",
			borderColor: `${"#ffffff"} !important`,
			color: `${"#ffffff"} !important`
		},
		cssLabel: {
			color: "#ffffff"
		}
	})
);

export default UserFilter;