import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import find from 'lodash/find'
import times from 'lodash/times'
import union from 'lodash/union'
import xor from 'lodash/xor'
import clone from 'lodash/clone'
import assign from 'lodash/assign'
import flatten from 'lodash/flatten'
import moment from 'moment'

import ReactDataGrid, { Position } from 'react-data-grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interaction from '@fullcalendar/interaction'

import Notifications from 'components/CapacityManagement/Notifications'
import Controlbar from '../../../components/CapacityManagement/Controlbar'
import { getEmptyWorkDays, IWorkDays } from 'interfaces/WorkDays'
import {
  ISAPImportReport,
  getSAPImportReport,
} from 'interfaces/SAPImportReport'
import WorkDaysStore from 'stores/WorkDaysStore'
import SAPImportReportStore from 'stores/SAPImportReportStore'
import { Stores } from 'stores/stores'

import 'react-data-grid/dist/react-data-grid.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import styles from 'styles/capacitiesManagement.module.scss'

interface WorkdaysProps extends WithTranslation {
  siteId: string
  workDaysStore?: WorkDaysStore
  sapImportReportStore?: SAPImportReportStore
  onYearChange: (newYear: number) => void
}

const Workdays: React.FC<WorkdaysProps> = inject(
  Stores.workDaysStore,
  Stores.sapImportReportStore
)(
  observer(
    ({
      siteId,
      workDaysStore: wdStore,
      sapImportReportStore: sirStore,
      onYearChange = () => {},
    }) => {
      const { t } = useTranslation()
      const today: Date = React.useMemo(() => new Date(), [])
      const [year, setYear] = React.useState<number>(today.getFullYear())
      const columnSeed = React.useMemo(
        () =>
          times(12, (i) => ({
            key: `month-${i + 1}`,
            name: moment(new Date(year, i)).format('MMM'),
            month: i + 1,
            workDays: [],
            workDaysTmp: [],
            reset: () => {},
          })),
        [year]
      )

      const [columns, setColumns] = React.useState<any[]>(columnSeed)
      const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false)
      const [selectedMonthKey, setSelectedMonthKey] = React.useState<string>('')

      const sapReport: ISAPImportReport = React.useMemo(() => {
        if (sirStore && siteId) {
          return sirStore!.getReportById(siteId)
        } else {
          return getSAPImportReport()
        }
      }, [siteId])

      React.useEffect(() => {
        async function init() {
          await sirStore!.initImportReport()
        }
        if (sirStore) {
          init()
        }
      }, [])

      React.useEffect(() => {
        if (wdStore && siteId && year) {
          wdStore!.getBySiteAndYear(siteId, year).then((workDaysEntities) => {
            const updatedColumns: any[] = columnSeed.map((column) => {
              const workDeysEntry =
                find(workDaysEntities || [], {
                  siteId: siteId,
                  year: year,
                  month: column.month,
                }) || getEmptyWorkDays()
              const { workDays } = workDeysEntry

              return {
                ...column,
                workDays: [...workDays],
                workDaysTmp: [...workDays],
              }
            })

            setColumns(updatedColumns)
          })
        }
      }, [wdStore, siteId, columnSeed, year])

      const row = React.useMemo(
        () =>
          columns.reduce(
            (row, { key, workDaysTmp }) => ({
              ...row,
              [key]: workDaysTmp.length,
            }),
            {}
          ),
        [columns]
      )

      const { monthDate, workDays, reset } = React.useMemo(() => {
        const selectedColumn = find(columns, { key: selectedMonthKey })
        const { month, workDaysTmp, reset } = selectedColumn || {}

        return workDaysTmp !== undefined && month !== undefined
          ? {
              monthDate: new Date(year, month - 1),
              workDays: workDaysTmp.map((day: number) => ({
                start: new Date(year, month - 1, day),
                allDay: true,
                rendering: 'background',
                backgroundColor: '#00A6EB',
                editable: false,
              })),
              reset,
            }
          : {
              monthDate: today,
              workDays: [],
              reset: () => {},
            }
      }, [selectedMonthKey, columns, year, today])

      const controlDate: Date = React.useMemo(() => new Date(year, 0, 1), [
        year,
      ])

      return (
        <div className={styles['container-box']}>
          <div className={styles['workdays-info-container']}>
            <Controlbar
              controlDate={controlDate}
              controlDateMod={(fast: boolean, subtract: boolean) => {
                if (subtract) {
                  setYear(year - 1)
                  onYearChange(year - 1)
                } else {
                  setYear(year + 1)
                  onYearChange(year + 1)
                }
              }}
              isWorkDays
            />
            <Notifications sapReport={sapReport} isWorkDays />
          </div>
          <ReactDataGrid
            columns={columns}
            rows={[row]}
            height={72}
            onSelectedCellChange={handleCellSelectionCHange}
          />
          <Dialog open={calendarOpen}>
            <DialogTitle>{moment(monthDate).format('MMM YYYY')}</DialogTitle>
            <DialogContent>
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin, interaction]}
                selectable
                select={handleSelect}
                defaultDate={monthDate}
                events={workDays}
                header={false}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  reset()
                  setCalendarOpen(false)
                }}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={closeCalendar}>
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )

      function getWorkDaysEntityID(
        siteId: string,
        year: number | string,
        month: number | string
      ) {
        if (!wdStore!.all[siteId]) return ''
        if (!wdStore!.all[siteId][year]) return ''
        if (!wdStore!.all[siteId][year][month]) return ''

        return wdStore!.all[siteId][year][month]._id || ''
      }

      async function closeCalendar() {
        const selectedColumn = find(columns, { key: selectedMonthKey })
        const { month, workDaysTmp } = selectedColumn
        const workDaysEntity: IWorkDays = assign({}, getEmptyWorkDays(), {
          _id: getWorkDaysEntityID(siteId, year, month),
          siteId: siteId,
          year: year,
          month: month,
          workDays: clone([...workDaysTmp]),
        })

        await wdStore!.save(workDaysEntity)

        setCalendarOpen(false)
        setSelectedMonthKey('')
      }

      function handleCellSelectionCHange(position: Position) {
        const { idx } = position

        setSelectedMonthKey(columnSeed[idx].key)
        setCalendarOpen(true)
      }

      function handleSelect(selectInfo: any) {
        const selectedColumn = find(columns, { key: selectedMonthKey })
        const { month = today.getMonth(), workDaysTmp } = selectedColumn || {}

        // cancel if selection was invalid
        if (
          selectInfo.end <= new Date(year, month - 1, 1) ||
          selectInfo.start > new Date(year, month, 0)
        ) {
          return
        }

        const start =
          selectInfo.start.getFullYear() === year &&
          selectInfo.start.getMonth() === month - 1
            ? new Date(year, month - 1, selectInfo.start.getDate())
            : new Date(year, month - 1, 1)
        const end =
          selectInfo.end.getFullYear() === year &&
          selectInfo.end.getMonth() === month - 1
            ? new Date(year, month - 1, selectInfo.end.getDate() - 1)
            : new Date(year, month, 0)

        const selectedWorkDays = times(
          new Date(year, month, 0).getDate(),
          (i) => i + 1
        ).filter((date) => date >= start.getDate() && date <= end.getDate())
        const isRangeSelection = start < end && selectedWorkDays.length > 1
        const newWorkDays = isRangeSelection
          ? union(workDaysTmp, selectedWorkDays)
          : xor(workDaysTmp, selectedWorkDays)

        const resetColumns = columns.map((column) =>
          column.month === month
            ? {
                ...column,
                workDaysTmp: [...column.workDays],
                reset: () => {},
              }
            : column
        )
        const updatedColumns = columns.map((column) =>
          column.month === month
            ? {
                ...column,
                workDaysTmp: [...newWorkDays],
                reset: () => {
                  setColumns(resetColumns)
                },
              }
            : column
        )

        // toggle selection
        setColumns(updatedColumns)
      }
    }
  )
)

export default withTranslation()(Workdays)
