import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  withStyles,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import {
  DefaultTooltip,
  GreyBadge,
  HeaderTableCell,
  AllBelowButton,
} from 'styles/createMyTheme'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import CapabilityStore from 'stores/CapabilityStore'
import {
  Capability,
  CapabilityPages,
  IGlobalCapability,
  getCapability,
} from 'interfaces/Capabilities'
import Arrow from 'assets/icons/arrow-blue.svg'
import Info from 'assets/icons/info.svg'
import Plus from 'assets/icons/plus.svg'
import QuestionImg from 'assets/icons/question.svg'
import CapabilityManagementDialog from './CapabilityManagementDialog'
import { useTranslation } from 'react-i18next'
import AuthenticationStore from 'stores/AuthenticationStore'
import SitePicker from 'components/SitePicker'
import ThreeStateSelector from './ThreeStateSelector'
import SetAllModal from './SetAllModal'
import SiteService from 'services/SiteService'
import BadgeCustom from './Badge'
import _ from 'lodash'
import { ISite } from 'interfaces/Site'
import GreyX from 'assets/icons/undone.svg'
import BlueCheck from 'assets/icons/done.svg'
import GreyCheck from 'assets/icons/done-grey.svg'
import CSVImg from 'assets/icons/csv.svg'
import Downloader from 'utils/Downloader'
import MapStore from 'stores/MapStore'
import DivisionStore from 'stores/DivisionStore'
import DivisionSelect from './DivisionSelect'
import RouterStore from 'stores/RouterStore'
import SiteStore from 'stores/SiteStore'

import styles from 'styles/capabilitiesTable.module.scss'

const maintainHeaders = ['capabilities', 'capableSites', 'capableRegions', '']
const siteService = new SiteService()
const IconSize = '25px'

const DescriptionTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    border: '1px solid #00a6eb',
    whiteSpace: 'pre-wrap',
  },
}))(Tooltip)

interface ISiteObject {
  [key: string]: ISite[]
}

interface CapabilitiesTableProps {
  capabilityType: CapabilityPages
  capabilityStore?: CapabilityStore
  authenticationStore?: AuthenticationStore
  mapStore?: MapStore
  divisionStore?: DivisionStore
  routerStore?: RouterStore
  siteStore?: SiteStore
}

const CapabilitiesTable: React.FC<CapabilitiesTableProps> = inject(
  Stores.capabilityStore,
  Stores.authenticationStore,
  Stores.mapStore,
  Stores.divisionStore,
  Stores.routerStore,
  Stores.siteStore
)(
  observer(
    ({
      capabilityStore,
      capabilityType,
      authenticationStore,
      mapStore,
      divisionStore,
      routerStore,
      siteStore,
    }) => {
      const { t } = useTranslation()
      const [selectedSites, setSelectedSites] = useState<ISite[]>([])
      const [allBelowModalOpen, setAllBelowModalOpen] = useState(false)
      const [newSites, setNewSites] = useState<ISite[]>([])

      const [selectedEntry, setSelectedEntry] = useState(getCapability())
      const [func, setFunc] = useState({ func: () => {} })
      const [capable, setCapable] = useState<0 | 1>(0)
      const showTable =
        (capabilityType !== CapabilityPages.MaintainCapability &&
          selectedSites.length > 0) ||
        (capabilityType === CapabilityPages.MaintainCapability &&
          capabilityStore!.capabilities.length > 0)

      // will unmount
      useEffect(() => {
        return () => {
          mapStore!.setSelectedMapSites([])
        }
        // eslint-disable-next-line
      }, [])

      // open modal when there are newSites
      useEffect(() => {
        if (newSites.length > 0) routerStore!.setLeaveModalOpen(true)
        // eslint-disable-next-line
      }, [newSites])

      // init selectedSites when divisions are initialized
      useEffect(() => {
        setSelectedSites(getInitialSites())
        // eslint-disable-next-line
      }, [divisionStore!.selectedDivision._id])

      const getSitesByDivision = () => {
        if (capabilityType === CapabilityPages.AssignCapability)
          return authenticationStore!.currentUser.sites
        return siteStore!.allSites.filter(
          (site) => site.division._id === divisionStore!.selectedDivision._id
        )
      }

      const getInitialSites = () => {
        if (capabilityType === CapabilityPages.ProcessMatrix) {
          if (mapStore!.selectedMapSites.length > 0) {
            return mapStore!.selectedMapSites
            // disabled division auto select
            // } else {
            // 	const sites = getSitesByDivision()
            // 	if (sites.length > 0) {
            // 		return [sites[0]]
            // 	}
          }
        } else if (authenticationStore!.currentUser.sites.length > 0)
          return [authenticationStore!.currentUser.sites[0]]
        return []
      }

      const handleSitesSelect = (sites: ISite[]) => {
        mapStore!.setSelectedMapSites([])
        if (routerStore!.valuesChanged) {
          routerStore!.navigateAway = false
          setNewSites(sites)
        } else {
          setSelectedSites(sites)
        }
      }

      const handleLeave = async (save: boolean) => {
        if (save) await siteService.updateSite(selectedSites[0])
        await authenticationStore!.JWTLogin()
        setSelectedSites([...newSites])
        setNewSites([])
        routerStore!.setValuesChanged(false)
        routerStore!.setLeaveModalOpen(false)
        if (routerStore!.navigateAway)
          routerStore!.navigateTo(routerStore!.initialPath)
      }
      routerStore!.setHandleLeave(handleLeave)

      const sortSitesByRegion = () => {
        const sortSites = (a: ISite, b: ISite) => {
          if (a.region.name < b.region.name) return -1
          if (a.region.name > b.region.name) return 1
          return 0
        }
        // construct region/site object
        const allRegions = _.uniq(
          selectedSites.map((site) => site.region.name)
        ).sort()
        const siteObject: ISiteObject = {}
        allRegions.forEach((region) => (siteObject[region] = []))
        selectedSites.forEach((site) => siteObject[site.region.name].push(site))

        // sort selected sites this needs to be done in order to keep the capabilities data in line with the sorted site object
        selectedSites.sort(sortSites)
        return siteObject
      }

      const toggleChildren = (row: Capability[]) => {
        row.forEach((cap) => (cap.open = !cap.open))
      }

      const handleDialogClosed = () => {
        capabilityStore!.resetCapabilityCreation()
        capabilityStore!.setMaintainCapabilityDialogOpen(false)
      }

      const handleSetAllBelowTo = (
        event: React.MouseEvent,
        entry: Capability,
        value: 0 | 1,
        type: 'internal' | 'external'
      ) => {
        event.stopPropagation()
        setCapable(value)
        setSelectedEntry(entry)
        setFunc({
          func: () => {
            setAllBelowTo(entry, value, type)
          },
        })
        setAllBelowModalOpen(true)
      }

      const setAllBelowTo = (
        entry: Capability,
        value: 0 | 1,
        type: 'internal' | 'external'
      ) => {
        const setEntryValueFor = (currentEntry: Capability) => {
          if (currentEntry.children.length === 0) {
            currentEntry[type] = value
          } else {
            currentEntry.children.forEach((child) => setEntryValueFor(child))
          }
        }
        setEntryValueFor(entry)
        routerStore!.setValuesChanged(true)
      }

      const handleDivisionChange = (id: string) => {
        if (id !== divisionStore!.selectedDivision._id) {
          divisionStore!.setSelectedDivisionById(id)
          if (capabilityType === CapabilityPages.MaintainCapability)
            setSelectedSites(getSitesByDivision())
        }
      }

      const getCSVFile = async () => {
        const sitesList: Array<string> = []
        selectedSites.map((site) => sitesList.push(site._id))
        const file = await siteService.getCSVFile(sitesList)
        Downloader.getFile(file)
      }

      const handleSave = async () => {
        const siteId = selectedSites[0]._id
        authenticationStore!.setBadge(false)
        await siteService.updateSite(selectedSites[0])
        await authenticationStore!.JWTLogin()
        const updatedSites = authenticationStore!.currentUser.sites
        const currentSite = updatedSites.find(
          (site: ISite) => site._id === siteId
        )
        if (currentSite) setSelectedSites([currentSite])
        routerStore!.setValuesChanged(false)
      }

      const sitesByRegion = sortSitesByRegion()
      const regions = Object.keys(sitesByRegion)
      const internalExternalSpacing = 110

      const renderTableHeader = () => {
        const addEmptyCells = (amount: number) => {
          return (
            <React.Fragment>
              {new Array(amount).fill(false).map((e, index) => (
                <HeaderTableCell key={index} />
              ))}
            </React.Fragment>
          )
        }

        if (capabilityType === CapabilityPages.MaintainCapability) {
          return (
            <TableRow>
              {maintainHeaders.map((header) => (
                <HeaderTableCell
                  align={
                    header === 'capabilities'
                      ? 'left'
                      : header === ''
                      ? 'right'
                      : 'center'
                  }
                  key={header}>
                  <Box
                    display="flex"
                    justifyContent={
                      header === 'capabilities' ? 'space-between' : 'center'
                    }
                    maxWidth="460px">
                    {t('capabilities:' + header)}
                    {header === 'capabilities' && (
                      <Box>
                        {t('capabilities:subCapabilities') +
                          '  /  ' +
                          t('capabilities:description')}
                      </Box>
                    )}
                  </Box>
                </HeaderTableCell>
              ))}
            </TableRow>
          )
        } else if (capabilityType === CapabilityPages.AssignCapability) {
          return (
            <React.Fragment>
              <TableRow>
                {addEmptyCells(2)}
                <HeaderTableCell colSpan={2} align="center">
                  {selectedSites[0].region.name}
                </HeaderTableCell>
              </TableRow>
              <TableRow>
                {addEmptyCells(2)}
                <HeaderTableCell colSpan={2} align="center">
                  {selectedSites[0].country}, {selectedSites[0].city}
                </HeaderTableCell>
              </TableRow>
              <TableRow>
                <HeaderTableCell>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    maxWidth="460px">
                    {t('capabilities:capabilities')}
                    <Box>
                      {t('capabilities:subCapabilities') +
                        '  /  ' +
                        t('capabilities:description')}
                    </Box>
                  </Box>
                </HeaderTableCell>
                <HeaderTableCell align="center">
                  {t('capabilities:internallyCapable')}
                </HeaderTableCell>
                <HeaderTableCell align="center">
                  {t('capabilities:externallyCapable')}
                </HeaderTableCell>
              </TableRow>
            </React.Fragment>
          )
        } else if (capabilityType === CapabilityPages.ProcessMatrix) {
          return (
            <React.Fragment>
              <TableRow>
                {addEmptyCells(1)}
                {regions.map((region) => (
                  <HeaderTableCell
                    key={region}
                    colSpan={sitesByRegion[region].length}
                    align="center">
                    {region}
                  </HeaderTableCell>
                ))}
              </TableRow>
              <TableRow>
                <HeaderTableCell>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    maxWidth="395px">
                    {t('capabilities:capabilities')}
                    <Box>{t('capabilities:subCapabilities')}</Box>
                  </Box>
                </HeaderTableCell>
                {regions.map((region) =>
                  sitesByRegion[region].map((site) => (
                    <HeaderTableCell
                      key={region + (site && site._id)}
                      align="center">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                        alignItems="center">
                        <Box display="flex" justifyContent="center">
                          {site.country}, {site.name}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-around"
                          width={internalExternalSpacing}>
                          <div style={{ width: IconSize }}>I</div>
                          <div style={{ width: IconSize }}>E</div>
                        </Box>
                      </Box>
                    </HeaderTableCell>
                  ))
                )}
              </TableRow>
            </React.Fragment>
          )
        }
      }

      const renderTableContent = () => {
        if (capabilityType === CapabilityPages.MaintainCapability)
          return capabilityStore!.capabilities.map((capability) =>
            getTableRow([capability])
          )
        if (capabilityType === CapabilityPages.AssignCapability)
          return selectedSites[0].capabilities.map((capability) =>
            getTableRow([capability])
          )

        if (capabilityType === CapabilityPages.ProcessMatrix) {
          // Get all capabilities of the selected sites in the form [[site1Cap1, site2Cap1, site3Cap1, ...], [site1Cap2, site2Cap2, ...], ...]
          const selectedCapabilities: Capability[][] = []
          if (selectedSites[0])
            selectedSites[0].capabilities.forEach(() =>
              selectedCapabilities.push([])
            )
          for (let i = 0; i < selectedCapabilities.length; i++) {
            selectedSites.forEach((site) => {
              selectedCapabilities[i].push(site.capabilities[i])
            })
          }
          return selectedCapabilities.map((capabilities) =>
            getTableRow(capabilities)
          )
        }
      }

      // Accepts either a single capability or multiple ones in a zipped form (the first capabilities of all sites in one array)
      const getTableRow = (capabilities: Capability[]) => {
        const renderEntryName = (entry: Capability) => {
          if (capabilityType === CapabilityPages.ProcessMatrix) {
            return (
              <DescriptionTooltip title={entry.description}>
                <div style={{ textAlign: 'left' }}>{entry.name}</div>
              </DescriptionTooltip>
            )
          } else return <div style={{ textAlign: 'left' }}>{entry.name}</div>
        }

        const entry = capabilities[0]
        const styles = {
          paddingLeft: '',
          display: 'flex',
          alignItems: 'center',
          maxWidth: '400px',
          overflow: 'auto',
        }

        styles.paddingLeft = `${entry.level * 20}px`
        if (entry.level !== 0 && entry.children.length === 0) {
          styles.paddingLeft = `${(entry.level + 1) * 20 + 10}px`
        }
        return (
          <React.Fragment key={'row' + entry.capId}>
            <TableRow hover={true} onClick={() => toggleChildren(capabilities)}>
              <TableCell align="center">
                <Box display="flex" alignItems="center">
                  <Box minWidth="350px" maxWidth="350px" whiteSpace="no-wrap">
                    <span style={styles}>
                      {renderImage(entry)}
                      {renderEntryName(entry)}
                      {capabilityType === CapabilityPages.AssignCapability && (
                        <Box width="24px">{renderWarningBadge(entry)}</Box>
                      )}
                      <GreyBadge
                        badgeContent={entry.children.length}
                        style={{
                          marginLeft: 'auto',
                          width: '20px',
                          marginRight: '20px',
                        }}>
                        {''}
                      </GreyBadge>
                    </span>
                  </Box>

                  {capabilityType !== CapabilityPages.ProcessMatrix && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginLeft="50px">
                      <DescriptionTooltip
                        arrow
                        title={entry.description}
                        onClick={(event) => {
                          event.stopPropagation()
                          if (
                            capabilityType ===
                            CapabilityPages.MaintainCapability
                          )
                            capabilityStore!.openEditCapabilityModal(entry)
                        }}>
                        <img
                          src={Info}
                          alt=""
                          width={IconSize}
                          className={
                            capabilityType ===
                            CapabilityPages.MaintainCapability
                              ? 'clickable hover-grow'
                              : ''
                          }
                        />
                      </DescriptionTooltip>
                    </Box>
                  )}
                </Box>
              </TableCell>
              {capabilities.map((cap, index) =>
                renderRowContent(cap, selectedSites[index])
              )}
            </TableRow>
            {entry.open &&
              (capabilityType !== CapabilityPages.ProcessMatrix
                ? entry.children.map((child) => getTableRow([child]))
                : renderCapabilitiesChildrenRows(capabilities))}
          </React.Fragment>
        )
      }

      // zips the children of the capabilities into an array in the form
      // [[site1Cap1Child1, site2Cap1Child1, ...], [site1Cap1Child2, site2Cap1Child2, ...], ...] and renders the row
      const renderCapabilitiesChildrenRows = (capabilities: Capability[]) => {
        if (capabilities[0].children.length > 0) {
          const rows: JSX.Element[] = []
          for (let i = 0; i < capabilities[0].children.length; i++) {
            const children: Capability[] = []
            capabilities.forEach((capability) => {
              if (capability.children.length > 0 && capability.children[i])
                children.push(capability.children[i])
            })
            if (children.length > 0) rows.push(getTableRow(children))
          }
          return rows.map((element) => element)
        }
      }

      const renderImage = (entry: Capability) => {
        const arrow = (
          <img
            src={Arrow}
            className={`${styles['arrow']} clickable${
              entry.open ? '' : ` ${styles['right']}`
            }`}
            alt=""
          />
        )

        const image = (
          <img
            src={entry.image}
            alt=""
            height="30px"
            style={{ marginRight: '5px' }}
            className={entry.children.length > 0 ? 'clickable' : ''}
          />
        )

        if (entry.open && entry.children.length > 0) return arrow
        else if ((!entry.open && entry.image) || entry.children.length === 0)
          return image
        else if (entry.children.length !== 0) return arrow
        return null
      }

      const renderWarningBadge = (entry: Capability): JSX.Element | null => {
        if (entry.children.length === 0) {
          if (entry.internal === 2 || entry.external === 2)
            return <BadgeCustom pointer={false} />
          return null
        }
        let badge: JSX.Element | null = null
        for (let i = 0; i < entry.children.length && !badge; i++) {
          badge = renderWarningBadge(entry.children[i])
        }
        return badge
      }

      const renderRowContent = (entry: Capability, site: ISite) => {
        if (capabilityType === CapabilityPages.MaintainCapability) {
          const rowData: IGlobalCapability = entry as IGlobalCapability
          return (
            <React.Fragment key={entry.capId + (site && site._id)}>
              <TableCell align="center">
                {`${rowData.capableSites} / ${
                  authenticationStore!.currentUser.sites.length
                }`}
              </TableCell>
              <TableCell align="center">
                {rowData.capableRegions
                  ? rowData.capableRegions.join(', ')
                  : ''}
              </TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end">
                  <DefaultTooltip
                    title={`${t('capabilities:addNewCapability')}`}
                    placement="left">
                    <img
                      src={Plus}
                      className={styles['add-new-icon']}
                      width={IconSize}
                      height={IconSize}
                      alt=""
                      onClick={(event) => {
                        event.stopPropagation()
                        capabilityStore!.openCreateNestedCapabilityModal(entry)
                      }}
                    />
                  </DefaultTooltip>
                </Box>
              </TableCell>
            </React.Fragment>
          )
        } else if (capabilityType === CapabilityPages.AssignCapability) {
          return (
            <React.Fragment key={entry.capId + (site && site._id)}>
              <TableCell align="center">
                {entry.children.length === 0 ? (
                  <ThreeStateSelector
                    infoCapability={entry.internal}
                    onChange={(value) => {
                      entry.internal = value
                      routerStore!.setValuesChanged(true)
                    }}
                  />
                ) : (
                  entry.level !== 0 && (
                    <React.Fragment>
                      <AllBelowButton
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) =>
                          handleSetAllBelowTo(e, entry, 0, 'internal')
                        }>
                        {t('capabilities:allBelowToNo')}
                      </AllBelowButton>
                      <AllBelowButton
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) =>
                          handleSetAllBelowTo(e, entry, 1, 'internal')
                        }>
                        {t('capabilities:allBelowToYes')}
                      </AllBelowButton>
                    </React.Fragment>
                  )
                )}
              </TableCell>
              <TableCell align="center">
                {entry.children.length === 0 ? (
                  <ThreeStateSelector
                    infoCapability={entry.external}
                    onChange={(value) => {
                      entry.external = value
                      routerStore!.setValuesChanged(true)
                    }}
                  />
                ) : (
                  entry.level !== 0 && (
                    <React.Fragment>
                      <AllBelowButton
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) =>
                          handleSetAllBelowTo(e, entry, 0, 'external')
                        }>
                        {t('capabilities:allBelowToNo')}
                      </AllBelowButton>
                      <AllBelowButton
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) =>
                          handleSetAllBelowTo(e, entry, 1, 'external')
                        }>
                        {t('capabilities:allBelowToYes')}
                      </AllBelowButton>
                    </React.Fragment>
                  )
                )}
              </TableCell>
            </React.Fragment>
          )
        } else if (capabilityType === CapabilityPages.ProcessMatrix) {
          return (
            <React.Fragment key={entry.capId + (site && site._id)}>
              <TableCell align="center">
                <Box width="100%" display="flex" justifyContent="center">
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    width={internalExternalSpacing}>
                    <img
                      src={getCheckIcon('internal', entry)}
                      alt=""
                      width={IconSize}
                    />
                    <img
                      src={getCheckIcon('external', entry)}
                      alt=""
                      width={IconSize}
                    />
                  </Box>
                </Box>
              </TableCell>
            </React.Fragment>
          )
        }
      }

      const getCheckIcon = (
        type: 'internal' | 'external',
        entry: Capability
      ) => {
        if (entry[type] === 1) return BlueCheck
        else if (entry[type] === 0) return GreyX
        else if (entry[type] === 3) return GreyCheck
        else if (entry[type] === 2) return QuestionImg
        return ''
      }

      const getCapableCapabilities = () => {
        let total = 0
        let capableCaps = 0
        const count = (capabilities: Capability[]) => {
          capabilities.forEach((capability) => {
            if (capability.children.length === 0) {
              total++
              if (capability.internal === 1 || capability.external === 1) {
                capableCaps++
              }
            } else {
              count(capability.children)
            }
          })
        }
        if (selectedSites.length > 0) {
          count(selectedSites[0].capabilities)
        }
        return `${capableCaps}/${total} ${t('capabilities:capabilities')}`
      }

      return (
        <React.Fragment>
          {capabilityType === CapabilityPages.AssignCapability && (
            <div className="add-new-container" style={{ marginBottom: '20px' }}>
              <Typography>
                {t(`capabilities:siteCapableCapabilities`)}{' '}
                {getCapableCapabilities()}
              </Typography>
            </div>
          )}
          {capabilityType !== CapabilityPages.AssignCapability && (
            <Box position="absolute" top="2rem" right="2rem">
              <DivisionSelect
                handleChange={handleDivisionChange}
                disabled={mapStore!.selectedMapSites.length > 0}
              />
            </Box>
          )}
          {capabilityType === CapabilityPages.ProcessMatrix && (
            <div
              onClick={getCSVFile}
              style={{
                color: '#00a6eb',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                margin: ' 5px 250px 5px 200px',
              }}>
              <img
                style={{ width: '30px', marginTop: '-5px' }}
                src={CSVImg}
                alt="csv"
              />
              <span>{t('capabilities:exportCSV')}</span>
            </div>
          )}

          {(capabilityType === CapabilityPages.AssignCapability ||
            capabilityType === CapabilityPages.ProcessMatrix) && (
            <div style={{ marginTop: '-35px' }}>
              <SitePicker
                handleSiteSelect={handleSitesSelect}
                sites={getSitesByDivision()}
                selectedSites={selectedSites}
                selectMultiple={
                  capabilityType === CapabilityPages.ProcessMatrix
                }
                showBadges={capabilityType === CapabilityPages.AssignCapability}
              />
            </div>
          )}
          {showTable ? (
            <TableContainer
              style={{
                maxHeight:
                  'calc(100vh - ' +
                  (capabilityType === CapabilityPages.MaintainCapability
                    ? '240px'
                    : '270px') +
                  ')',
              }}>
              <Table size="small" stickyHeader className="capabilities-table">
                <TableHead>{renderTableHeader()}</TableHead>
                <TableBody>{renderTableContent()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              position="absolute"
              top="50%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center">
              <Typography variant="h4">
                {capabilityType === CapabilityPages.ProcessMatrix
                  ? t('capabilities:pleaseSelectASite')
                  : ''}
              </Typography>
            </Box>
          )}
          {capabilityType === CapabilityPages.AssignCapability &&
            routerStore!.valuesChanged && (
              <Box position="absolute" right="30px" bottom="30px">
                <Button
                  variant="contained"
                  onClick={() => handleSave()}
                  color="primary">
                  {t('save')}
                </Button>
              </Box>
            )}
          {capabilityStore!.maintainCapabilityDialogOpen && (
            <CapabilityManagementDialog
              open={capabilityStore!.maintainCapabilityDialogOpen}
              onClose={handleDialogClosed}
              initialCapability={capabilityStore!.selectedCapability}
              createNewCapability={capabilityStore!.createNewCapability}
              registerCapability={capabilityStore!.registerCapability}
              updateCapability={capabilityStore!.updateCapability}
              deleteCapability={capabilityStore!.deleteCapability}
              forceDeleteCapability={capabilityStore!.forceDeleteCapability}
            />
          )}
          <SetAllModal
            open={allBelowModalOpen}
            onClose={() => setAllBelowModalOpen(false)}
            capable={capable}
            capability={selectedEntry}
            onConfirm={func.func}
          />
        </React.Fragment>
      )
    }
  )
)

export default CapabilitiesTable
