import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import uniq from 'lodash/uniq'
import isString from 'lodash/isString'
import isDate from 'lodash/isDate'
import flatten from 'lodash/flatten'
import assign from 'lodash/assign'
import isArray from 'lodash/isArray'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import difference from 'lodash/difference'
import slugify from 'slugify'
import { Content } from 'pdfmake/interfaces'

import RootRef from '@material-ui/core/RootRef'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Paper,
  Box,
  BoxProps,
  Button,
  Tooltip,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TablePagination from '@material-ui/core/TablePagination'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Link from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { DatePicker } from '@material-ui/pickers'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import WorkcenterService from 'services/WorkcenterService'
import { Stores } from 'stores/stores'
import RouterStore from 'stores/RouterStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import CapabilityStore from 'stores/CapabilityStore'
import UserManagementStore from 'stores/UserManagementStore'
import DivisionStore from 'stores/DivisionStore'
import SiteStore from 'stores/SiteStore'
import AuthenticationStore from 'stores/AuthenticationStore'
import CapacityDashboardStore, {
  ChartKey,
  ChartTileState,
  getInitialChartTileState,
} from 'stores/CapacityDashboardStore'
import Routes from 'interfaces/Routes'
import { ISite } from 'interfaces/Site'
import { IUser } from 'interfaces/User'
import {
  IWorkcenter,
  WorkcenterType,
  WorkcenterState,
  IFlatCap,
} from 'interfaces/Workcenter'
import {
  P13nKey,
  P13nRouteConfig,
  P13nPinnedDimensionValues,
  IFilter,
  IRouteParams,
  getUnitByMetric,
  getSeriesColorByMetric,
  getSeriesTypeByMetric,
  getStackGroupByMetric,
  getYAxisRightByMetric,
  Dimension,
  FilterOperator,
  Metric,
  ISeriesResult,
  ITimeframe,
  TileConfigKey,
  ChartHorizontalLine,
} from 'interfaces/CapacityDashboard'
import { IDivision } from 'interfaces/Division'
import { ICapability, ILocalCapability } from 'interfaces/Capabilities'
import {
  getDashboardRoute,
  getStateByUtilization,
  parseSites,
  parseForecast,
  parseTimeframe,
  parseFilter,
  parseTileConfig,
  parseDashboardParams,
  getDebouncedFunction,
  abbreviateNumber,
} from 'utils/Chart'
import { getContentByChart, getContentImageByHtml } from 'utils/PDF'
import { notify } from 'components/Notification'
import Filterbar from 'components/Dashboard/Filterbar'
import TrendTile, { TrendTileVariant } from 'components/Dashboard/TrendTile'
import { MicroChart, Chart } from 'components/Dashboard/Chart/'
import indexedDB from 'utils/IndexedDB'

import styles from 'styles/capacityDashboard.module.scss'

interface Props {
  match: any
  routerStore?: RouterStore
  capacityDashboardStore?: CapacityDashboardStore
  authenticationStore?: AuthenticationStore
  workcenterStore?: WorkcenterStore
  capabilityStore?: CapabilityStore
  userManagementStore?: UserManagementStore
  divisionStore?: DivisionStore
  siteStore?: SiteStore
}

const StyledTable = withStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
  })
)(Table)

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey['100'],
      color: theme.palette.common.black,
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.action.hover}`,
      },
    },
    body: {
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.action.hover}`,
      },
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey['50'],
      },
    },
  })
)(TableRow)

interface ITreeShowCount {
  show: number
  showAll: boolean
}

const SHOW_HIERARCHY_MAX_ITEMS = 3
const defaultShowCount: ITreeShowCount = {
  show: SHOW_HIERARCHY_MAX_ITEMS,
  showAll: false,
}

const RegionalDashboard: React.FC<Props> = (props: Props) => {
  const {
    routerStore,
    capacityDashboardStore: chartStore,
    authenticationStore,
    workcenterStore,
    capabilityStore,
    userManagementStore,
    divisionStore,
    siteStore,
    match,
  } = props
  const userService = userManagementStore?.userService
  const { params } = match || {}
  const { t } = useTranslation()

  /**
   * H E L P E R S
   */
  const getCountryCode = (
    map: {
      [countryName: string]: string
    },
    countryName: string
  ) => {
    return map[countryName] || countryName
  }

  /**
   * S T A T E S
   */
  const [
    multiChartMenuAnchor,
    setMultiChartMenuAnchor,
  ] = React.useState<null | HTMLElement>(null)
  const [
    drilldownTableMenuAnchor,
    setDrilldownOverviewMenuAnchor,
  ] = React.useState<null | HTMLElement>(null)
  const [page, setPage] = React.useState<number>(0)
  const [expandedTreeNodes, setExpandedTreeNodes] = React.useState<string[]>([])
  const [treeNodeShowCounts, setTreeNodeShowCounts] = React.useState<{
    [nodeId: string]: { show: number; showAll: boolean }
  }>({})
  const [forceRefreshTrigger, setForceRefreshTrigger] = React.useState<boolean>(
    true
  )

  /**
   * M E M O S
   */
  const countryCodeMap = siteStore!.countryCodeMap || {}
  const workcentersBySite = workcenterStore?.workcentersBySite || {}
  const capsByDivision = capabilityStore?.capsByDivision || {}
  const countFetchedSiteWorkcenters = Object.keys(workcentersBySite).length
  const countFetchedDivisionCaps = Object.keys(capsByDivision).length

  const currentUser = authenticationStore!.currentUser
  const { p13n = {}, sites: userSites = [] } = currentUser
  const allUserSites: ISite[] = React.useMemo(() => userSites, [userSites])
  const initialSite: ISite = React.useMemo(() => allUserSites[0], [
    allUserSites,
  ])
  const selectedSites: ISite[] = React.useMemo(() => {
    const siteIDs = parseSites(params.sites)
    const selectedSites = allUserSites.filter((site) =>
      siteIDs.includes(site._id)
    )

    return selectedSites.length > 0 ? selectedSites : [initialSite]
  }, [params.sites, allUserSites, initialSite])
  const selectedWorkcenter: string[] = React.useMemo(() => {
    const filterMap = parseFilter(params.filter) || {}
    const selectedWorkcenter: string[] = filterMap[Dimension.Workcenter] || []

    // filter by workcenter available for this site
    const validSelectedWorkcenters = selectedWorkcenter.filter(
      (workcenterID) => {
        const isAvailableForSite = selectedSites.some((site) => {
          const workcentersOfSite = workcentersBySite[site._id] || []

          return workcentersOfSite.some(
            (workcenter) => workcenter._id === workcenterID
          )
        })

        return isAvailableForSite
      }
    )

    return validSelectedWorkcenters
  }, [params.filter, selectedSites, workcentersBySite])
  const selectedCapabilities: string[] = React.useMemo(() => {
    const filterMap = parseFilter(params.filter) || {}
    const selectedCapabilities = filterMap[Dimension.Capability] || []

    return selectedCapabilities
  }, [params.filter])

  // PARAMS
  const timeframe = React.useMemo(
    () => cloneDeep(parseTimeframe(params.timeframe)),
    [params.timeframe]
  )
  const forecast = React.useMemo(
    () => cloneDeep(parseForecast(params.forecast)),
    [params.forecast]
  )
  const siteFilter: IFilter = React.useMemo(
    () => ({
      filterDimension: Dimension.Site,
      operator: FilterOperator.EQ,
      values: selectedSites.map((site) => site._id),
    }),
    [selectedSites]
  )
  const generalFilters: IFilter[] = React.useMemo(
    () =>
      [
        selectedCapabilities.length > 0 ? Dimension.Capability : null,
        selectedWorkcenter.length > 0 ? Dimension.Workcenter : null,
      ]
        .filter(isString)
        .map((filterDimension) => ({
          filterDimension: filterDimension as Dimension,
          operator: FilterOperator.EQ,
          values:
            filterDimension === Dimension.Capability
              ? cloneDeep(selectedCapabilities)
              : filterDimension === Dimension.Workcenter
              ? cloneDeep(selectedWorkcenter)
              : [],
        })),
    [selectedCapabilities, selectedWorkcenter]
  )
  const tileConfig = React.useMemo(
    () => cloneDeep(parseTileConfig(params.tileConfig)),
    [params.tileConfig]
  )
  const [multiChartSel = multiChartMenuItems[0].key] =
    tileConfig[TileConfigKey.RegionalMultiChartSelection] || []
  const [drilldownChartSel = drilldownTableMenuItems[0].key] =
    tileConfig[TileConfigKey.RegionalDrilldownChartSelection] || []
  const drilldownChartCols = tileConfig[
    TileConfigKey.RegionalDrilldownChartColumns
  ] || ['capacity', 'backlog', 'utilization']
  const [deltaCapacityAccRaw] =
    tileConfig[TileConfigKey.RegionalDeltaCapacityAcc] || []
  const deltaCapacityAcc = deltaCapacityAccRaw === 'true' ? true : false
  const [timetravelReferenceDateRaw] =
    tileConfig[TileConfigKey.RegionalTimetravelReferenceDate] || []
  const timetravelReferenceDate = React.useMemo(
    () =>
      isDate(new Date(timetravelReferenceDateRaw)) &&
      new Date(timetravelReferenceDateRaw).toString() !== 'Invalid Date'
        ? new Date(timetravelReferenceDateRaw)
        : new Date(moment().format('YYYY-MM-DD')),
    [timetravelReferenceDateRaw]
  )
  const [rowsPerPageRaw] =
    tileConfig[TileConfigKey.RegionalDrilldownChartRowsPerPage] || []
  const rowsPerPage = parseInt(rowsPerPageRaw, 10) || 3

  // TREND TILES
  const trendCurrentCapTile: ChartTileState =
    chartStore?.tileCurrentCapacity || getInitialChartTileState()
  const trendCurrentCapSeries = trendCurrentCapTile.series || []
  const [firstCurrentCapSeries] = trendCurrentCapSeries || []
  const trendCurrentCap = React.useMemo(
    () => ({
      label: firstCurrentCapSeries
        ? `${moment(firstCurrentCapSeries.start).format(
            'YYYY [CW]WW'
          )} - ${moment(firstCurrentCapSeries.end).format(
            moment(firstCurrentCapSeries.start).year() ===
              moment(firstCurrentCapSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendCurrentCapSeries.map((set) => {
          const value = set.data.reduce((sum, v) => sum + v, 0)

          return {
            value: value.toString(),
            unit: 'h',
            description: t(`metric:${set.label}`),
          }
        })
      ),
    }),
    [t, trendCurrentCapSeries, firstCurrentCapSeries]
  )
  const trendCurrentUtilTile: ChartTileState =
    chartStore?.tileCurrentUtilization || getInitialChartTileState()
  const trendCurrentUtilSeries = trendCurrentUtilTile.series || []
  const [firstCurrentUtilSeries] = trendCurrentUtilSeries || []
  const trendCurrentUtil = React.useMemo(
    () => ({
      label: firstCurrentUtilSeries
        ? `${moment(firstCurrentUtilSeries.start).format(
            'YYYY [CW]WW'
          )} - ${moment(firstCurrentUtilSeries.end).format(
            moment(firstCurrentUtilSeries.start).year() ===
              moment(firstCurrentUtilSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendCurrentUtilSeries.map((set) => {
          const value =
            set.data.reduce((sum, val) => sum + val, 0) / (set.data.length || 1)

          return {
            value: value.toString(),
            unit: '%',
            description: t(`metric:${set.label}`),
            state: getStateByUtilization(value),
          }
        })
      ),
    }),
    [t, trendCurrentUtilSeries, firstCurrentUtilSeries]
  )
  const trendAvgUtilTile: ChartTileState =
    chartStore?.tileAvgUtilization || getInitialChartTileState()
  const trendAvgUtilSeries = trendAvgUtilTile.series || []
  const [firstAvgUtilSeries] = trendAvgUtilSeries || []
  const trendAvgUtil = React.useMemo(
    () => ({
      label: firstAvgUtilSeries
        ? `${moment(firstAvgUtilSeries.start).format('YYYY [CW]WW')} - ${moment(
            firstAvgUtilSeries.end
          ).format(
            moment(firstAvgUtilSeries.start).year() ===
              moment(firstAvgUtilSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendAvgUtilSeries.map((set) => {
          const value =
            set.data.reduce((sum, val) => sum + val, 0) / (set.data.length || 1)

          return {
            value: value.toString(),
            unit: '%',
            description: t(`metric:${set.label}`),
            state: getStateByUtilization(value),
          }
        })
      ),
    }),
    [t, trendAvgUtilSeries, firstAvgUtilSeries]
  )
  const trendTiles = React.useMemo(
    () => [
      {
        ...trendCurrentCap,
        title: 'Current Capacity',
        tile: trendCurrentCapTile,
        variant: TrendTileVariant.Grey,
      },
      {
        ...trendCurrentUtil,
        title: 'Current Utilization',
        tile: trendCurrentUtilTile,
        variant: TrendTileVariant.Teal,
      },
      {
        ...trendAvgUtil,
        title: 'Average Utilization',
        tile: trendAvgUtilTile,
        variant: TrendTileVariant.Indigo,
      },
    ],
    [
      trendCurrentCapTile,
      trendCurrentUtilTile,
      trendAvgUtilTile,
      trendCurrentCap,
      trendCurrentUtil,
      trendAvgUtil,
    ]
  )

  // CHART TILES
  const capOverviewTile: ChartTileState =
    chartStore?.tileCapacityOverview || getInitialChartTileState()
  const capOverviewSeries = capOverviewTile ? capOverviewTile.series : []
  const tooltipOnly = [
    Metric.CapacityTransferIn,
    Metric.CapacityTransferOut,
    Metric.CapacitySupermarket,
    Metric.CapacitySupermarketIncrease,
    Metric.CapacitySupermarketReduction,
  ]
  const capOverviewDataset = React.useMemo(
    () =>
      cloneDeep(
        capOverviewSeries.map((set, index) => ({
          key: `${slugify(set.label)}-${index}`,
          label: t(`metric:${set.label}`),
          unit: getUnitByMetric(set.metric),
          data: set.data,
          color: getSeriesColorByMetric(set.metric),
          type: getSeriesTypeByMetric(set.metric),
          group: getStackGroupByMetric(set.metric),
          yAxisRight: getYAxisRightByMetric(set.metric),
          hidden: false,
          tooltipOnly: tooltipOnly.includes(set.metric),
        }))
      ),
    [t, capOverviewSeries, tooltipOnly]
  )
  const selectedMultiChartTile: ChartTileState =
    ((key) => {
      switch (key) {
        case ChartKey.Accumulations:
          return chartStore?.tileAccumulations
        case ChartKey.CapacityDelta:
          return chartStore?.tileCapacityDelta
        case ChartKey.CapacityTimetravel:
          return chartStore?.tileCapacityTimetravel
        case ChartKey.CapacityTransfer:
          return chartStore?.tileCapacityTransfer
        case ChartKey.Supermarket:
        default:
          return chartStore?.tileSupermarket
      }
    })(multiChartSel) || getInitialChartTileState()
  const selectedMultiChartTileSeries = selectedMultiChartTile
    ? selectedMultiChartTile.series
    : []
  const selectedMultiChartTileDataset = React.useMemo(
    () =>
      cloneDeep(
        selectedMultiChartTileSeries.map((set, index) => ({
          key: `${slugify(set.label)}-${index}`,
          label: t(`metric:${set.label}`),
          unit: getUnitByMetric(set.metric),
          data: set.data,
          color: getSeriesColorByMetric(set.metric),
          type: getSeriesTypeByMetric(set.metric),
          group: getStackGroupByMetric(set.metric),
          yAxisRight: getYAxisRightByMetric(set.metric),
          hidden: false,
          tooltipOnly:
            multiChartSel === ChartKey.CapacityTimetravel &&
            tooltipOnly.includes(set.metric),
        }))
      ),
    [t, selectedMultiChartTileSeries, multiChartSel, tooltipOnly]
  )

  // DRILLDOWN TABLE
  interface IDrilldownTableRow {
    id: string
    dimension: Dimension
    name: string
    description: string
    pinned: boolean
  }
  interface IDrilldownItem {
    id: string
    dimension: Dimension
  }
  interface IDrilldownTree {
    dataRowIndex: number | null
    id: string
    dimension: Dimension
    name: string
    info?: string
    forceSelected?: boolean
    selectable?: boolean
    children?: IDrilldownTree[]
    show?: number
    showAll?: boolean
  }
  const drilldown = React.useMemo(
    () =>
      find(drilldownTableMenuItems, {
        key: drilldownChartSel,
      }) || drilldownTableMenuItems[0],
    [drilldownChartSel]
  )
  const {
    drilldownTable,
    visibleTableItems,
  }: {
    drilldownTable: IDrilldownTableRow[]
    visibleTableItems: IDrilldownItem[]
  } = React.useMemo(() => {
    const toVisibleTableData: (
      list: IDrilldownTableRow[]
    ) => IDrilldownItem[] = (list) =>
      list
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((entry) => ({
          id: entry.id,
          dimension: entry.dimension,
        }))
    const { isHierarchy, dimension } = drilldown

    // read pinned data
    const pinnedValuesRaw = p13n[P13nKey.PinnedDimensionValues]
    const parsedDimValues: P13nPinnedDimensionValues = pinnedValuesRaw
      ? JSON.parse(pinnedValuesRaw)
      : {}
    const pinnedDimValues = parsedDimValues[dimension]
      ? parsedDimValues[dimension]
      : []

    if (isHierarchy) {
      return { drilldownTable: [], visibleTableItems: [] }
    }

    const selectedSiteIDs = selectedSites.map((s) => s._id)
    const allWorkcenter = flatten(Object.values(workcentersBySite)).filter(
      (w) => {
        const isActive = w.state === WorkcenterState.active
        const isWorkcenter = w.type === WorkcenterType.workcenter
        const isInSiteSelection = selectedSiteIDs.includes(w.siteId)
        const isInWorkcenterSelction =
          selectedWorkcenter.length > 0
            ? selectedWorkcenter.includes(w._id) ||
              (w.parentIdList || []).some((parentId) =>
                selectedWorkcenter.includes(parentId)
              )
            : true

        return (
          isActive &&
          isWorkcenter &&
          isInSiteSelection &&
          isInWorkcenterSelction
        )
      }
    )

    switch (dimension) {
      case Dimension.Capability:
        const allCaps = flatten(Object.values(capsByDivision))
        const capabiltyFlatList = WorkcenterService.toFlatCaps(allCaps)
        const getCapabilityByKey = (capKey: string) => {
          const capability: IFlatCap | undefined = find(capabiltyFlatList, {
            id2: capKey,
          })
          return capability
        }
        const filterBySelectedCapabilities = (c: ICapability) =>
          selectedCapabilities.length > 0
            ? selectedCapabilities.includes(c._id || c.capId)
            : true
        const filterBySelectedSites = (c: ICapability) => {
          const capabilityExternallyCapable = (
            capability: ILocalCapability
          ): boolean => capability.external === 1 || capability.external === 3
          const capabilityInternallyCapable = (
            capability: ILocalCapability
          ): boolean => capability.internal === 1 || capability.internal === 3
          const capabilityIsCapable = (capability: ILocalCapability): boolean =>
            capabilityExternallyCapable(capability) ||
            capabilityInternallyCapable(capability)
          const localRootCapability:
            | ILocalCapability
            | undefined = find(
            flatten(selectedSites.map((s) => s.capabilities)),
            { _id: c._id }
          )

          return selectedSites.length > 0 && localRootCapability
            ? capabilityIsCapable(localRootCapability)
            : true
        }
        const filterBySelectedWorkcenter = (c: ICapability) =>
          allWorkcenter.length > 0
            ? allWorkcenter.some((w) => {
                const rootCapabilities: string[] = (w.capabilityIds || []).map(
                  (capKey) => {
                    const capability = getCapabilityByKey(capKey)

                    return capability ? capability.level0Id : ''
                  }
                )
                return rootCapabilities.includes(c._id || c.capId)
              })
            : true
        const rootLevelCapabilities = allCaps.filter(
          (c) =>
            filterBySelectedCapabilities(c) &&
            filterBySelectedSites(c) &&
            filterBySelectedWorkcenter(c)
        )
        const drilldownByCapability = sortBy(
          rootLevelCapabilities.map((c) => {
            return {
              id: c._id || '',
              dimension: Dimension.Capability,
              name: c.name,
              description: '',
              pinned: pinnedDimValues.includes(c._id || ''),
            }
          }),
          'pinned'
        ).reverse()

        return {
          drilldownTable: drilldownByCapability,
          visibleTableItems: toVisibleTableData(drilldownByCapability),
        }
      case Dimension.Workcenter:
        const drilldownByWorkcenter = sortBy(
          allWorkcenter.map((w) => {
            const site = find(selectedSites, { _id: w.siteId })
            const { country = '', city = '', name: siteName = '' } = site || {}

            return {
              id: w._id,
              dimension: Dimension.Workcenter,
              name: w.name,
              description: `${w.number} (${getCountryCode(
                countryCodeMap,
                country
              )}, ${city} • ${siteName})`,
              pinned: pinnedDimValues.includes(w._id),
            }
          }),
          'pinned'
        ).reverse()

        return {
          drilldownTable: drilldownByWorkcenter,
          visibleTableItems: toVisibleTableData(drilldownByWorkcenter),
        }
      case Dimension.Site:
      default:
        const drilldownBySites = sortBy(
          selectedSites.map((s) => ({
            id: s._id,
            dimension: Dimension.Site,
            name: s.name,
            description: `${getCountryCode(countryCodeMap, s.country)}, ${
              s.city
            }`,
            pinned: pinnedDimValues.includes(s._id),
          })),
          'pinned'
        ).reverse()

        return {
          drilldownTable: drilldownBySites,
          visibleTableItems: toVisibleTableData(drilldownBySites),
        }
    }
    // "countFetchedDivisionCaps" and "countFetchedSiteWorkcenters" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [
    p13n,
    drilldown,
    page,
    rowsPerPage,
    selectedSites,
    selectedWorkcenter,
    selectedCapabilities,
    countryCodeMap,
    workcentersBySite,
    capsByDivision,
    countFetchedSiteWorkcenters,
    countFetchedDivisionCaps,
  ])
  const allDivisions = divisionStore?.allDivisions
  const relevantDivisions: IDivision[] = React.useMemo(() => {
    const divisions: IDivision[] = uniqBy(
      selectedSites
        .map((site) => site.division)
        .filter((division) => division && division._id),
      '_id'
    )

    return divisions.length > 0
      ? divisions
      : (allDivisions || []).filter((division) => division && division._id)
  }, [selectedSites, allDivisions])
  const {
    drilldownTree,
    visibleTreeItems,
  }: {
    drilldownTree: IDrilldownTree[]
    visibleTreeItems: IDrilldownItem[]
  } = React.useMemo(() => {
    const { isHierarchy, dimension } = drilldown
    const isChildVisible = (
      parentId: string,
      parentShowCount: ITreeShowCount,
      index: number
    ) =>
      expandedTreeNodes.includes(parentId) &&
      (parentShowCount.showAll || index < parentShowCount.show)
    const pushVisibleTreeItem = (id: string, dimension: Dimension) => {
      countRowsDisplayed = countRowsDisplayed + 1

      visibleTreeItems.push({
        id: id,
        dimension: dimension,
      })
    }

    let countRowsDisplayed = 0
    let visibleTreeItems: IDrilldownItem[] = []

    const mapCapability: (
      capability: ICapability,
      parentId: string,
      parentShowCount: ITreeShowCount,
      index: number
    ) => IDrilldownTree = (capability, parentId, parentShowCount, index) => {
      const itemId = capability._id || capability.capId
      const showCount = treeNodeShowCounts[itemId] || defaultShowCount
      const isVisible = isChildVisible(parentId, parentShowCount, index)
      const dataRowIndex = isVisible ? countRowsDisplayed : null

      if (isVisible) {
        pushVisibleTreeItem(itemId, Dimension.Capability)
      }

      const children = capability.children.map((childCapability, index) =>
        mapCapability(childCapability, itemId, showCount, index)
      )

      return {
        dataRowIndex,
        id: itemId,
        dimension: Dimension.Capability,
        name: capability.name,
        info:
          children.length > 0
            ? `${children.length.toString()} sub-capabilities`
            : '',
        forceSelected: false,
        selectable: true,
        children: children,
        ...showCount,
      }
    }
    const mapWorkcenter: (
      allWorkcenter: IWorkcenter[],
      workcenter: IWorkcenter,
      parentId: string,
      parentShowCount: ITreeShowCount,
      index: number
    ) => IDrilldownTree = (
      allWorkcenter,
      workcenter,
      parentId,
      parentShowCount,
      index
    ) => {
      const itemId = workcenter._id
      const showCount = treeNodeShowCounts[itemId] || defaultShowCount
      const isVisible = isChildVisible(parentId, parentShowCount, index)
      const dataRowIndex = isVisible ? countRowsDisplayed : null

      if (isVisible) {
        pushVisibleTreeItem(itemId, Dimension.Workcenter)
      }

      const children = allWorkcenter
        .filter((workcenter) => workcenter.parentId === itemId)
        .map((workcenter, index) =>
          mapWorkcenter(allWorkcenter, workcenter, itemId, showCount, index)
        )

      return {
        dataRowIndex,
        id: itemId,
        dimension: Dimension.Workcenter,
        name: workcenter.name,
        info: workcenter.number,
        forceSelected: false,
        selectable: true,
        children: children,
        ...showCount,
      }
    }

    if (!isHierarchy) {
      return { drilldownTree: [], visibleTreeItems: [] }
    }

    const drilldownTree: IDrilldownTree[] =
      dimension === Dimension.Capability
        ? relevantDivisions.map((division) => {
            const rootLevelItemId = division._id || slugify(division.name)
            const children = isArray(capsByDivision[rootLevelItemId])
              ? capsByDivision[rootLevelItemId]
              : []
            const showCount =
              treeNodeShowCounts[rootLevelItemId] || defaultShowCount
            const dataRowIndex = countRowsDisplayed

            pushVisibleTreeItem(rootLevelItemId, Dimension.Division)

            return {
              dataRowIndex,
              id: rootLevelItemId,
              dimension: Dimension.Division,
              name: division.name,
              info: `${children.length.toString()} sub-capabilities`,
              children: children.map((childCapability, index) =>
                mapCapability(
                  childCapability,
                  rootLevelItemId,
                  showCount,
                  index
                )
              ),
              forceSelected: true,
              selectable: false,
              ...showCount,
            }
          })
        : dimension === Dimension.Workcenter
        ? selectedSites.map((site) => {
            const rootLevelItemId = site._id
            const children = isArray(workcentersBySite[rootLevelItemId])
              ? workcentersBySite[rootLevelItemId].filter(
                  (workcenter) => workcenter.state === WorkcenterState.active
                )
              : []
            const showCount =
              treeNodeShowCounts[rootLevelItemId] || defaultShowCount
            const dataRowIndex = countRowsDisplayed

            pushVisibleTreeItem(rootLevelItemId, Dimension.Site)

            return {
              dataRowIndex,
              id: rootLevelItemId,
              dimension: Dimension.Site,
              name: site.name,
              info: `${getCountryCode(countryCodeMap, site.country)}, ${
                site.city
              }`,
              children: children
                .filter((workcenter) => !workcenter.parentId)
                .map((workcenter, index) =>
                  mapWorkcenter(
                    children,
                    workcenter,
                    rootLevelItemId,
                    showCount,
                    index
                  )
                ),
              forceSelected: true,
              selectable: false,
              ...showCount,
            }
          })
        : []

    return { drilldownTree, visibleTreeItems }
    // "countFetchedDivisionCaps" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [
    drilldown,
    treeNodeShowCounts,
    expandedTreeNodes,
    // for capabilities:
    relevantDivisions,
    capsByDivision,
    countFetchedDivisionCaps,
    // for workcenters:
    selectedSites,
    workcentersBySite,
    countFetchedSiteWorkcenters,
  ])
  const drilldownVisibleDataList = React.useMemo(
    () => (drilldown.isHierarchy ? visibleTreeItems : visibleTableItems),
    [drilldown, visibleTreeItems, visibleTableItems]
  )
  const drilldownMicroChartRows = drilldownVisibleDataList.map((row) => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    const rowFilter = {
      filterDimension: row.dimension,
      operator: FilterOperator.EQ,
      values: [row.id],
    }
    const isNewDimension = filter.every(
      (filter) => filter.filterDimension !== rowFilter.filterDimension
    )
    const mergedFilter: IFilter[] = isNewDimension
      ? filter.concat(rowFilter)
      : filter.map((filter) =>
          filter.filterDimension === rowFilter.filterDimension
            ? rowFilter
            : // // do not merge, but overwrite general filter, casue row filter is more specific
              // mergeWith(filter, rowFilter, (objValue, srcValue) =>
              //   isArray(objValue) ? uniq(union(objValue, srcValue)) : objValue
              // )
              filter
        )
    const rowKey = `${JSON.stringify([
      params.timeframe,
      params.forecast,
      mergedFilter,
    ])}`

    return chartStore?.tileMicroChartSet[rowKey] || getInitialChartTileState()
  })
  const drilldownData = React.useMemo(
    () =>
      cloneDeep(
        drilldownVisibleDataList.map((_row, rowIndex) => {
          const rowTile = drilldownMicroChartRows[rowIndex]

          const filterCapMetrics = (set: ISeriesResult) =>
            [
              Metric.CapacityOverload,
              Metric.CapacityOverloadWithForecast,
              Metric.CapacityFree,
              Metric.CapacityFreeWithForecast,
            ].includes(set.metric)
          const filterBlgMetric = (set: ISeriesResult) =>
            [
              Metric.AccumulatedBacklog,
              Metric.AccumulatedBacklogWithForecast,
            ].includes(set.metric)
          const filterUtlMetric = (set: ISeriesResult) =>
            [Metric.Utilization, Metric.UtilizationWithForecast].includes(
              set.metric
            )
          const allCapData = flatten(
            drilldownMicroChartRows.map((r) =>
              flatten(r.series.filter(filterCapMetrics).map((s) => s.data))
            )
          )
          const allBlgData = flatten(
            drilldownMicroChartRows.map((r) =>
              flatten(r.series.filter(filterBlgMetric).map((s) => s.data))
            )
          )
          const allUtlData = flatten(
            drilldownMicroChartRows.map((r) =>
              flatten(r.series.filter(filterUtlMetric).map((s) => s.data))
            )
          )

          return {
            loading: rowTile.loading,
            loaded: rowTile.loaded,
            error: rowTile.error,
            labels: rowTile.labels,
            capDataset: rowTile.series.filter(filterCapMetrics).map((set) => ({
              label: t(`metric:${set.label}`),
              unit: getUnitByMetric(set.metric),
              data: set.data || [],
              color: getSeriesColorByMetric(set.metric),
              type: getSeriesTypeByMetric(set.metric),
              group: getStackGroupByMetric(set.metric),
              yAxisRight: false,
            })),
            capScale: {
              min: Math.min(0, ...allCapData),
              max: Math.max(0, Math.min(...allCapData) * -1, ...allCapData),
            },
            blgDataset: rowTile.series.filter(filterBlgMetric).map((set) => ({
              label: t(`metric:${set.label}`),
              unit: getUnitByMetric(set.metric),
              data: set.data || [],
              color: getSeriesColorByMetric(set.metric),
              type: getSeriesTypeByMetric(set.metric),
              group: getStackGroupByMetric(set.metric),
              yAxisRight: false,
            })),
            blgScale: {
              min: Math.min(0, ...allBlgData),
              max: 20,
            },
            utlDataset: rowTile.series.filter(filterUtlMetric).map((set) => ({
              label: t(`metric:${set.label}`),
              unit: getUnitByMetric(set.metric),
              data: set.data || [],
              // TODO: split series by 100% mark
              color: '#00A6EB',
              type: 'bar',
              group: 'utilization',
              yAxisRight: false,
            })),
            utlScale: {
              min: Math.min(0, ...allUtlData),
              max: Math.max(100, ...allUtlData),
            },
          }
        })
      ),
    [t, drilldownVisibleDataList, drilldownMicroChartRows]
  )
  const getCurrentCapacity = getDebouncedFunction(
    chartStore?.getCurrentCapacity,
    chartStore,
    'getCurrentCapacity'
  )
  const getCurrentUtilization = getDebouncedFunction(
    chartStore?.getCurrentUtilization,
    chartStore,
    'getCurrentUtilization'
  )
  const getAvgUtilization = getDebouncedFunction(
    chartStore?.getAvgUtilization,
    chartStore,
    'getAvgUtilization'
  )
  const getCapacityOverview = getDebouncedFunction(
    chartStore?.getCapacityOverview,
    chartStore,
    'getCapacityOverview'
  )
  const getAccumulations = getDebouncedFunction(
    chartStore?.getAccumulations,
    chartStore,
    'getAccumulations'
  )
  const getCapacityDelta = getDebouncedFunction(
    chartStore?.getCapacityDelta,
    chartStore,
    'getCapacityDelta'
  )
  const getCapacityTimetravel = getDebouncedFunction(
    chartStore?.getCapacityTimetravel,
    chartStore,
    'getCapacityTimetravel'
  )
  const getCapacityTransfer = getDebouncedFunction(
    chartStore?.getCapacityTransfer,
    chartStore,
    'getCapacityTransfer'
  )
  const getCapacitySupermarket = getDebouncedFunction(
    chartStore?.getCapacitySupermarket,
    chartStore,
    'getCapacitySupermarket'
  )

  /**
   * P D F   C O N T E N T
   */
  const overviewChart = React.useRef(null)
  const multiChart = React.useRef(null)
  const microChartTable = React.useRef(null)
  const microChartTreeBody = React.useRef(null)
  const microChartTreeHeader = React.useRef(null)
  const pdfButtonDisabled = React.useMemo(
    () =>
      chartStore?.anyLoading === true ||
      !overviewChart.current ||
      !multiChart.current,
    // "chartStore?.anyLoading" and "xxxx.current" is required to recognize if DOM elements are available
    // eslint-disable-next-line
    [chartStore?.anyLoading, overviewChart.current, multiChart.current]
  )

  const getPdfContent: () => Promise<Content> = async () => {
    const { current: overviewChartRef }: { current: any } = overviewChart
    const { current: multiChartRef }: { current: any } = multiChart
    const { current: microChartTableRef }: { current: any } = microChartTable
    const {
      current: microChartTreeHeaderRef,
    }: { current: any } = microChartTreeHeader
    const {
      current: microChartTreeBodyRef,
    }: { current: any } = microChartTreeBody

    const pdfContent: Content = [
      { text: 'HUEBNER • Regional Report', style: 'header' },
      // TREND TILES
      { text: 'Overall Overview', style: 'subheader' },
      {
        style: 'table',
        table: {
          body: [
            trendTiles.map((t) => ({ text: t.title, style: 'th' })),
            trendTiles.map((t) => [
              t.label,
              {
                style: 'table',
                table: {
                  body: [
                    t.data.map((d) => ({ text: d.description, style: 'th' })),
                    t.data.map(
                      (d) =>
                        `${abbreviateNumber(parseFloat(d.value), 1)} ${d.unit}`
                    ),
                  ],
                },
              },
            ]),
          ],
        },
      },
      // OVERVIEW TILES
      // TODO: add info about capacity type selection
      overviewChartRef
        ? [
            { text: 'Capacity Overview', style: 'subheader' },
            ...getContentByChart(overviewChartRef),
          ]
        : [],
      // TODO: add info about accumulation flag, reference date, etc.
      multiChartRef
        ? [
            {
              text:
                (find(multiChartMenuItems, { key: multiChartSel }) || {})
                  .label || '',
              style: 'subheader',
            },
            ...getContentByChart(multiChartRef),
          ]
        : [],
      // DRILLDOWN TABLE
      // TODO: tranform into table incl. info about selected dimension
      [
        { text: 'Drilldown Overview', style: 'subheader' },
        microChartTableRef
          ? await getContentImageByHtml(microChartTableRef)
          : [],
        microChartTreeHeaderRef
          ? await getContentImageByHtml(microChartTreeHeaderRef)
          : [],
        microChartTreeBodyRef
          ? await getContentImageByHtml(microChartTreeBodyRef)
          : [],
      ],
    ]

    return pdfContent
  }

  /**
   * E F F E C T S
   */
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getCurrentCapacity) {
      getCurrentCapacity.cancel()
      getCurrentCapacity(parseForecast(params.forecast), filter)
    }
  }, [
    params.forecast,
    getCurrentCapacity,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getCurrentUtilization) {
      getCurrentUtilization.cancel()
      getCurrentUtilization(filter)
    }
  }, [getCurrentUtilization, generalFilters, siteFilter, forceRefreshTrigger])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getAvgUtilization) {
      getAvgUtilization.cancel()
      getAvgUtilization(filter)
    }
  }, [getAvgUtilization, generalFilters, siteFilter, forceRefreshTrigger])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getCapacityOverview) {
      getCapacityOverview.cancel()
      getCapacityOverview(parseTimeframe(params.timeframe), filter)
    }
  }, [
    params.timeframe,
    getCapacityOverview,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (multiChartSel === ChartKey.Accumulations) {
      if (getAccumulations) {
        getAccumulations.cancel()
        getAccumulations(
          parseTimeframe(params.timeframe),
          parseForecast(params.forecast),
          filter
        )
      }
    }
  }, [
    multiChartSel,
    params.timeframe,
    params.forecast,
    getAccumulations,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (multiChartSel === ChartKey.CapacityDelta) {
      if (getCapacityDelta) {
        getCapacityDelta.cancel()
        getCapacityDelta(
          parseTimeframe(params.timeframe),
          parseForecast(params.forecast),
          filter,
          deltaCapacityAcc
        )
      }
    }
  }, [
    multiChartSel,
    params.timeframe,
    params.forecast,
    getCapacityDelta,
    deltaCapacityAcc,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (multiChartSel === ChartKey.CapacityTimetravel) {
      if (getCapacityTimetravel) {
        getCapacityTimetravel.cancel()
        getCapacityTimetravel(
          parseTimeframe(params.timeframe),
          timetravelReferenceDate,
          filter
        )
      }
    }
  }, [
    multiChartSel,
    params.timeframe,
    timetravelReferenceDate,
    getCapacityTimetravel,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (multiChartSel === ChartKey.CapacityTransfer) {
      if (getCapacityTransfer) {
        getCapacityTransfer.cancel()
        getCapacityTransfer(parseTimeframe(params.timeframe), filter)
      }
    }
  }, [
    multiChartSel,
    params.timeframe,
    getCapacityTransfer,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (multiChartSel === ChartKey.Supermarket) {
      if (getCapacitySupermarket) {
        getCapacitySupermarket.cancel()
        getCapacitySupermarket(parseTimeframe(params.timeframe), filter)
      }
    }
  }, [
    multiChartSel,
    params.timeframe,
    getCapacitySupermarket,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)

    drilldownVisibleDataList.forEach((row, rowIndex) => {
      const rowFilter = {
        filterDimension: row.dimension,
        operator: FilterOperator.EQ,
        values: [row.id],
      }
      const isNewDimension = filter.every(
        (filter) => filter.filterDimension !== rowFilter.filterDimension
      )
      const mergedFilter: IFilter[] = isNewDimension
        ? filter.concat(rowFilter)
        : filter.map((filter) =>
            filter.filterDimension === rowFilter.filterDimension
              ? rowFilter
              : // // do not merge, but overwrite general filter, casue row filter is more specific
                // mergeWith(filter, rowFilter, (objValue, srcValue) =>
                //   isArray(objValue) ? uniq(union(objValue, srcValue)) : objValue
                // )
                filter
          )
      const rowKey = `${JSON.stringify([
        params.timeframe,
        params.forecast,
        mergedFilter,
      ])}`
      const getMicroChartSet = getDebouncedFunction(
        chartStore?.getMicroChartSet,
        chartStore,
        rowIndex.toString()
      )

      if (
        !chartStore?.tileMicroChartSet[rowKey] ||
        (chartStore?.tileMicroChartSet[rowKey].loaded === false &&
          chartStore?.tileMicroChartSet[rowKey].loading === false &&
          chartStore?.tileMicroChartSet[rowKey].error === false)
      )
        if (getMicroChartSet) {
          getMicroChartSet.cancel()
          getMicroChartSet(
            rowKey,
            parseTimeframe(params.timeframe),
            parseForecast(params.forecast),
            mergedFilter
          )
        }
    })
  }, [
    drilldownVisibleDataList,
    params.timeframe,
    params.forecast,
    chartStore,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])

  /**
   * M E T H O D S
   */
  const getMultiChartPropsBySel = (selection: string) => {
    switch (selection) {
      // DEMAND SUPERMARKET
      case ChartKey.Supermarket:
        return {
          yAxesLabels: ['Demand Supermarket [h]'],
          yAxesConfig: [{ grid: true, display: true }, { display: false }],
        }
      // DEMAND TRANSFER
      case ChartKey.CapacityTransfer:
        return {
          yAxesLabels: ['Demand Transfer [h]'],
          yAxesConfig: [{ grid: true, display: true }, { display: false }],
        }
      // CAPACITY TIMETRAVEL
      case ChartKey.CapacityTimetravel:
        return {
          yAxesLabels: ['Capacity [h]', 'Utilization [%]'],
          yAxesConfig: [
            {
              grid: true,
              display: true,
            },
            {
              min: 0,
              max: 140,
              grid: true,
              display: true,
            },
          ],
          horizontalLines: [horizintalLine],
        }
      // DELTA CAPACITY
      case ChartKey.CapacityDelta:
        return {
          yAxesLabels: ['Δ Capacity [h]'],
          yAxesConfig: [{ grid: true, display: true }, { display: false }],
        }

      // ACCUMULATIONS
      case ChartKey.Accumulations:
      default:
        return {
          yAxesLabels: ['Acc. Backlog [d]', 'Acc. Utilization [%]'],
          yAxesConfig: [
            { min: 0, max: 20, grid: true, display: true },
            {
              min: 0,
              max: 140,
              display: true,
            },
          ],
          horizontalLines: [horizintalLine],
        }
    }
  }

  const onClearCache = async () => {
    const db = await indexedDB.open().catch((e) => console.error(e))

    if (db) {
      db.clearData()
        .then(() => {
          setForceRefreshTrigger(!forceRefreshTrigger)
          notify('capacity-dashboard:clear-cache-success', 'success')
        })
        .catch((e) => {
          console.error(e)
          notify('capacity-dashboard:clear-cache-error', 'error')
        })
    } else {
      notify('capacity-dashboard:clear-cache-error', 'error')
    }
  }

  const updateRoute = (config: IRouteParams) => {
    routerStore!.navigateTo(
      getDashboardRoute(Routes.CAPACITY_DASHBOARD_REGIONAL, params, config)
    )
  }

  const onSitesChange = (selectedSites: ISite[]) => {
    updateRoute({
      sites: selectedSites.map((site) => site._id),
    })
  }

  const onForecastChange = (isSelected: boolean) =>
    updateRoute({
      forecast: isSelected,
    })

  const onTimeframeChange = (timeframe: ITimeframe) =>
    updateRoute({
      timeframe: {
        start: timeframe.start,
        end: timeframe.end,
        level: timeframe.level,
        span: timeframe.span,
      },
    })

  const onFilterChange = (filter: IFilter[]) =>
    updateRoute({
      filter: Object.fromEntries(
        filter.map((f) => [f.filterDimension as string, f.values])
      ),
    })

  const onTileConfigChange = (tileConfigChunk: { [key: string]: string[] }) =>
    updateRoute({
      tileConfig: assign(tileConfig, tileConfigChunk),
    })

  const onSaveConfiguration = async () => {
    const { p13n = {}, sites = [] } = currentUser
    const newConfig: P13nRouteConfig = {
      config: parseDashboardParams(params),
      timestamp: moment().format('YYYY-MM-DD'),
    }
    const userPatch = {
      p13n: {
        ...p13n,
        [P13nKey.RegionalConfig]: JSON.stringify(newConfig),
      },
    }
    const updatedUser: IUser = assign({}, currentUser, {
      sites: sites.map((site) => site._id),
      ...userPatch,
    })

    await userService
      ?.updateUser(updatedUser, {
        successMsg: 'capacity-dashboard:configuration-saved',
      })
      .then(() => assign(currentUser, userPatch))
  }

  const onPinDrilldownDimensionValue = async (
    dimension: Dimension,
    value: string,
    isPinned: boolean
  ) => {
    const pinnedValuesRaw = p13n[P13nKey.PinnedDimensionValues]
    const parsedDimValues: P13nPinnedDimensionValues = pinnedValuesRaw
      ? JSON.parse(pinnedValuesRaw)
      : {}
    const newDimValues: P13nPinnedDimensionValues = {
      ...parsedDimValues,
      [dimension]: parsedDimValues[dimension]
        ? isPinned
          ? uniq(parsedDimValues[dimension].concat(value))
          : parsedDimValues[dimension].filter((d) => d !== value)
        : [value],
    }
    const userPatch = {
      p13n: {
        ...p13n,
        [P13nKey.PinnedDimensionValues]: JSON.stringify(newDimValues),
      },
    }
    const updatedUser: IUser = assign({}, currentUser, {
      sites: userSites.map((site) => site._id),
      ...userPatch,
    })
    const backupUser = cloneDeep(updatedUser)

    // optimistic update
    assign(currentUser, userPatch)

    userService
      ?.updateUser(updatedUser, {
        successMsg: 'capacity-dashboard:pins-saved',
      })
      .catch(() => {
        // reset user if update fails
        assign(currentUser, backupUser)
      })
  }

  const DividerVertical = (props: BoxProps) => (
    <Box
      display="flex"
      width="1px"
      height={77}
      ml={4}
      mr={2}
      flexGrow={0}
      flexShrink={0}
      {...props}
      style={{ background: 'rgba(0, 0, 0, 0.04)' }}
    />
  )

  const MicroChartCell = (props: {
    drilldownDataRow: any
    drilldownDataFieldPrefex: string
    horizontalLines: ChartHorizontalLine[]
  }) => (
    <Box
      display="flex"
      height="64px"
      width="100px"
      m={0}
      p={0}
      flexGrow={1}
      flexShrink={1}
      alignItems="center"
      justifyContent="center"
      style={{ position: 'relative' }}>
      {(!props.drilldownDataRow || props.drilldownDataRow.loading) && (
        <CircularProgress size={48} />
      )}
      {props.drilldownDataRow && props.drilldownDataRow.error && (
        <ErrorOutlineIcon fontSize="default" color="error" />
      )}
      {props.drilldownDataRow &&
        props.drilldownDataRow.loaded &&
        !props.drilldownDataRow.loading &&
        !props.drilldownDataRow.error && (
          <MicroChart
            scaleMin={
              props.drilldownDataRow[`${props.drilldownDataFieldPrefex}Scale`]
                .min
            }
            scaleMax={
              props.drilldownDataRow[`${props.drilldownDataFieldPrefex}Scale`]
                .max
            }
            labels={props.drilldownDataRow.labels}
            series={
              props.drilldownDataRow[`${props.drilldownDataFieldPrefex}Dataset`]
            }
            horizontalLines={props.horizontalLines}
          />
        )}
    </Box>
  )

  const renderTree = (
    nodes: IDrilldownTree[] | undefined,
    level: number = 0
  ) => {
    const LEVEL_INSERT = 16

    return (nodes || []).map((node) => {
      const drilldownDataRow =
        node.dataRowIndex !== null ? drilldownData[node.dataRowIndex] : null
      const isParent = isArray(node.children) && node.children.length > 0
      const children = node.showAll
        ? node.children
        : (node.children || []).slice(0, node.show || SHOW_HIERARCHY_MAX_ITEMS)
      const remainingChildren = Math.max(
        0,
        (node.children || []).length - (node.show || SHOW_HIERARCHY_MAX_ITEMS)
      )
      const moreChildrenAvailable =
        isArray(node.children) &&
        node.children.length > (node.show || SHOW_HIERARCHY_MAX_ITEMS) &&
        remainingChildren > 0 &&
        !node.showAll
      const nextLevel = level + 1

      return (
        <TreeItem
          key={slugify(node.id || node.name)}
          className={styles['filter-tree-item']}
          nodeId={node.id}
          label={
            <Box
              className={styles['filter-tree-item-labelContent']}
              height={77}
              display="flex"
              alignItems="center">
              <Box
                className={styles['filter-tree-item-iconContainer']}
                p={2}
                height={56}
                width={56}
                display="flex"
                flexGrow={0}
                flexShrink={0}>
                {isParent && (
                  <>
                    <ExpandMoreIcon
                      className={styles['filter-tree-item-expandIcon']}
                    />
                    <ChevronRightIcon
                      className={styles['filter-tree-item-collapseIcon']}
                    />
                  </>
                )}
              </Box>
              <Tooltip
                title={`${[node.name, node.info]
                  .filter((s) => s && s.length > 0)
                  .join(' / ')}`}>
                <Box
                  width={150 - level * LEVEL_INSERT}
                  display="flex"
                  flexGrow={0}
                  flexShrink={0}
                  flexDirection="column">
                  <Typography variant="subtitle2" noWrap>
                    {node.name}
                  </Typography>
                  <Typography variant="caption" noWrap>
                    {node.info}
                  </Typography>
                </Box>
              </Tooltip>
              {drilldownChartCols.includes('capacity') && (
                <DividerVertical height={77} />
              )}
              {drilldownChartCols.includes('capacity') && (
                <MicroChartCell
                  drilldownDataRow={drilldownDataRow}
                  drilldownDataFieldPrefex="cap"
                  horizontalLines={[zeroHorizontalLine]}
                />
              )}
              {drilldownChartCols.includes('backlog') && (
                <DividerVertical height={77} />
              )}
              {drilldownChartCols.includes('backlog') && (
                <MicroChartCell
                  drilldownDataRow={drilldownDataRow}
                  drilldownDataFieldPrefex="blg"
                  horizontalLines={[zeroHorizontalLine]}
                />
              )}
              {drilldownChartCols.includes('utilization') && (
                <DividerVertical height={77} />
              )}
              {drilldownChartCols.includes('utilization') && (
                <MicroChartCell
                  drilldownDataRow={drilldownDataRow}
                  drilldownDataFieldPrefex="utl"
                  horizontalLines={[horizintalLine, zeroHorizontalLine]}
                />
              )}
              <DividerVertical width={0} height={77} mx={1} />
            </Box>
          }>
          {isParent ? renderTree(children, nextLevel) : null}
          {moreChildrenAvailable && (
            <TreeItem
              key={`more-${slugify(node.id || node.name)}`}
              className={styles['filter-tree-item']}
              nodeId={`more-${node.id}`}
              label={
                <Box display="flex" alignItems="center" justifyContent="center">
                  {remainingChildren > SHOW_HIERARCHY_MAX_ITEMS && (
                    <>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          const showCount =
                            treeNodeShowCounts[node.id] || defaultShowCount

                          setTreeNodeShowCounts({
                            ...treeNodeShowCounts,
                            [node.id]: {
                              ...showCount,
                              show: showCount.show + SHOW_HIERARCHY_MAX_ITEMS,
                            },
                          })
                        }}>
                        load next{' '}
                        {Math.min(remainingChildren, SHOW_HIERARCHY_MAX_ITEMS)}{' '}
                        entries
                      </Link>
                      <Box m={1} />
                    </>
                  )}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      const showCount =
                        treeNodeShowCounts[node.id] || defaultShowCount

                      setTreeNodeShowCounts({
                        ...treeNodeShowCounts,
                        [node.id]: {
                          ...showCount,
                          showAll: true,
                        },
                      })
                    }}>
                    load all {remainingChildren} remaining entries
                  </Link>
                </Box>
              }
            />
          )}
        </TreeItem>
      )
    })
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filterbar
            pdfContent={getPdfContent}
            pdfDownloadDisabled={pdfButtonDisabled}
            forecastChecked={forecast}
            allSitesDisabled
            selectedSites={selectedSites}
            selectedWorkcenter={selectedWorkcenter}
            selectedCapabilities={selectedCapabilities}
            timeframe={timeframe}
            onClearCache={onClearCache}
            onSitesChange={onSitesChange}
            onForecastChange={onForecastChange}
            onTimeframeChange={onTimeframeChange}
            onFilterChange={onFilterChange}
            onSaveConfiguration={onSaveConfiguration}
          />
        </Grid>

        {trendTiles.map((trendTile, index) => (
          <Grid key={index} item xs={12} md={4}>
            <TrendTile
              title={trendTile.title}
              loading={trendTile.tile.loading}
              error={trendTile.tile.error}
              info={trendTile.label}
              metrics={trendTile.data}
              variant={trendTile.variant}
            />
          </Grid>
        ))}

        <Grid item xs={12} md={6}>
          <Paper className={styles['tile-chart']}>
            <Box p={2}>
              <Box height={52} pb={1} display="flex">
                <Typography className={styles['title']} color="textPrimary">
                  Capacity Overview
                </Typography>
              </Box>
              <Box
                height={304}
                display="flex"
                alignItems="center"
                justifyContent="center">
                {capOverviewTile.loading && <CircularProgress size={48} />}
                {capOverviewTile.error && (
                  <ErrorOutlineIcon fontSize="large" color="error" />
                )}
                {capOverviewTile.error === false &&
                  !capOverviewTile.loading &&
                  capOverviewTile.loaded && (
                    <Chart
                      chartInstance={(chart) => {
                        overviewChart.current = chart
                      }}
                      labels={capOverviewTile.labels}
                      series={capOverviewDataset}
                      horizontalLines={[horizintalLine]}
                      yAxesLabels={['Capacity [h]', 'Utilization [%]']}
                      yAxesConfig={[
                        {
                          grid: true,
                          display: true,
                        },
                        {
                          min: 0,
                          max: 140,
                          grid: true,
                          display: true,
                        },
                      ]}
                    />
                  )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={styles['tile-chart']}>
            <Box p={2}>
              <Box height={52} pb={1} display="flex">
                <Box mr={1} flexShrink={0}>
                  <Button
                    className={styles['filterbar-item']}
                    variant="text"
                    size="small"
                    endIcon={<ArrowDropDownIcon />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setMultiChartMenuAnchor(event.currentTarget)
                    }}>
                    {
                      (find(multiChartMenuItems, { key: multiChartSel }) || {})
                        .label
                    }
                  </Button>
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={multiChartMenuAnchor}
                  keepMounted
                  open={Boolean(multiChartMenuAnchor)}
                  onClose={() => {
                    setMultiChartMenuAnchor(null)
                  }}>
                  {multiChartMenuItems.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      disabled={multiChartSel === item.key}
                      onClick={() => {
                        onTileConfigChange({
                          [TileConfigKey.RegionalMultiChartSelection]: [
                            item.key,
                          ],
                        })
                        setMultiChartMenuAnchor(null)
                      }}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Box display="flex" flexGrow={1}></Box>
                {multiChartSel === ChartKey.CapacityTimetravel && (
                  <>
                    <Box mr={1}>
                      {/* TODO: make date aware of local browser */}
                      <DatePicker
                        autoOk
                        inputFormat="DD.MM.YYYY"
                        label={'Reference Date'}
                        value={timetravelReferenceDate}
                        onChange={(date) =>
                          onTileConfigChange({
                            [TileConfigKey.RegionalTimetravelReferenceDate]: [
                              date
                                ? date.toDate().toISOString()
                                : timetravelReferenceDate.toISOString(),
                            ],
                          })
                        }
                        renderInput={(props) => (
                          // @ts-ignore
                          <TextField
                            variant="outlined"
                            size="small"
                            {...props}
                            helperText={null}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
                {multiChartSel === ChartKey.CapacityDelta && (
                  <FormControlLabel
                    className={styles['filterbar-item']}
                    control={
                      <Switch
                        color="primary"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onTileConfigChange({
                            [TileConfigKey.RegionalDeltaCapacityAcc]: [
                              event.target.checked.toString(),
                            ],
                          })
                        }}
                        checked={deltaCapacityAcc}
                      />
                    }
                    label={'Accumulate'}
                  />
                )}
              </Box>
              <Box
                height={304}
                display="flex"
                alignItems="center"
                justifyContent="center">
                {selectedMultiChartTile.loading && (
                  <CircularProgress size={48} />
                )}
                {selectedMultiChartTile.error && (
                  <ErrorOutlineIcon fontSize="large" color="error" />
                )}
                {selectedMultiChartTile.error === false &&
                  !selectedMultiChartTile.loading &&
                  selectedMultiChartTile.loaded && (
                    <Chart
                      chartInstance={(chart) => {
                        multiChart.current = chart
                      }}
                      labels={selectedMultiChartTile.labels}
                      series={selectedMultiChartTileDataset}
                      {...getMultiChartPropsBySel(multiChartSel)}
                    />
                  )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={styles['tile-chart']}>
            <Box pt={2} px={2}>
              <Box height={52} pb={1} display="flex">
                <Typography className={styles['title']} color="textPrimary">
                  Drilldown Overview
                </Typography>
                <Box display="flex" flexGrow={1}></Box>
                <ToggleButtonGroup
                  size="small"
                  value={drilldownChartCols}
                  onChange={(_e, selections: string[]) => {
                    onTileConfigChange({
                      [TileConfigKey.RegionalDrilldownChartColumns]: selections,
                    })
                  }}>
                  <ToggleButton value="capacity">Capacity</ToggleButton>
                  <ToggleButton value="backlog">Backlog</ToggleButton>
                  <ToggleButton value="utilization">Utilization</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {!drilldown.isHierarchy && (
                <RootRef rootRef={microChartTable}>
                  <>
                    {/* force rerender table by updating "key" to get a proper resizing of the charts */}
                    <TableContainer key={drilldownChartCols.join('-')}>
                      <StyledTable size="small">
                        <TableHead>
                          <TableRow className={styles['flexed-table-row']}>
                            <StyledTableCell
                              className={styles['flexed-table-cell']}>
                              <Box width={150}>
                                <Button
                                  className={styles['filterbar-item']}
                                  variant="text"
                                  size="small"
                                  endIcon={<ArrowDropDownIcon />}
                                  onClick={(
                                    event: React.MouseEvent<HTMLButtonElement>
                                  ) => {
                                    setDrilldownOverviewMenuAnchor(
                                      event.currentTarget
                                    )
                                    setExpandedTreeNodes([])
                                    setTreeNodeShowCounts({})
                                  }}>
                                  {
                                    (
                                      find(drilldownTableMenuItems, {
                                        key: drilldownChartSel,
                                      }) || {}
                                    ).label
                                  }
                                </Button>
                                <Menu
                                  id="simple-menu-table"
                                  anchorEl={drilldownTableMenuAnchor}
                                  keepMounted
                                  open={Boolean(drilldownTableMenuAnchor)}
                                  onClose={() => {
                                    setDrilldownOverviewMenuAnchor(null)
                                  }}>
                                  {drilldownTableMenuItems.map(
                                    (item: any, index: number) => (
                                      <MenuItem
                                        key={index}
                                        disabled={
                                          drilldownChartSel === item.key
                                        }
                                        onClick={() => {
                                          onTileConfigChange({
                                            [TileConfigKey.RegionalDrilldownChartSelection]: [
                                              item.key,
                                            ],
                                          })
                                          setPage(0)
                                          setDrilldownOverviewMenuAnchor(null)
                                        }}>
                                        {item.label}
                                      </MenuItem>
                                    )
                                  )}
                                </Menu>
                              </Box>
                            </StyledTableCell>
                            {drilldownChartCols.includes('capacity') && (
                              <StyledTableCell
                                className={styles['flexed-table-cell']}
                                align="left">
                                <Box
                                  width={100}
                                  display="flex"
                                  flexGrow={1}
                                  flexShrink={1}
                                  alignItems="center"
                                  justifyContent="start">
                                  Δ Capacity [h]
                                </Box>
                              </StyledTableCell>
                            )}
                            {drilldownChartCols.includes('backlog') && (
                              <StyledTableCell
                                className={styles['flexed-table-cell']}
                                align="left">
                                <Box
                                  width={100}
                                  display="flex"
                                  flexGrow={1}
                                  flexShrink={1}
                                  alignItems="center"
                                  justifyContent="start">
                                  Accumulated Backlog [d]
                                </Box>
                              </StyledTableCell>
                            )}
                            {drilldownChartCols.includes('utilization') && (
                              <StyledTableCell
                                className={styles['flexed-table-cell']}
                                align="left">
                                <Box
                                  width={100}
                                  display="flex"
                                  flexGrow={1}
                                  flexShrink={1}
                                  alignItems="center"
                                  justifyContent="start">
                                  Utilization [%]
                                </Box>
                              </StyledTableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {drilldownTable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, rowIndex) => (
                              <StyledTableRow
                                className={styles['flexed-table-row']}
                                key={row.name}>
                                <StyledTableCell
                                  className={styles['flexed-table-cell']}
                                  component="th"
                                  scope="row">
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    flexShrink={1}>
                                    <Box mr={1}>
                                      <Tooltip
                                        title={
                                          'Starred rows will be pinned at the top of the list.'
                                        }>
                                        <ToggleButton
                                          size="small"
                                          value="check"
                                          selected={row.pinned}
                                          onChange={() => {
                                            if (row.id) {
                                              onPinDrilldownDimensionValue(
                                                row.dimension,
                                                row.id,
                                                !row.pinned
                                              )
                                            }
                                          }}>
                                          {row.pinned ? (
                                            <StarIcon />
                                          ) : (
                                            <StarBorderIcon />
                                          )}
                                        </ToggleButton>
                                      </Tooltip>
                                    </Box>
                                    {/* TODO: display image of capability
                                    <Box p={1}>
                                      <img
                                        src={require('assets/icons/factory.svg')}
                                        alt={row.name}
                                        height="20px"
                                        style={{ marginRight: '5px' }}
                                      />
                                    </Box> */}
                                    <Tooltip
                                      title={`${[row.name, row.description]
                                        .filter((s) => s.length > 0)
                                        .join(' / ')}`}>
                                      <Box
                                        width={100}
                                        display="flex"
                                        flexDirection="column"
                                        flexShrink={1}>
                                        <Typography variant="subtitle2" noWrap>
                                          {row.name}
                                        </Typography>
                                        {row.description && (
                                          <Typography variant="caption" noWrap>
                                            {row.description}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                </StyledTableCell>
                                {drilldownChartCols.includes('capacity') && (
                                  <StyledTableCell
                                    className={styles['flexed-table-cell']}
                                    align="left">
                                    <MicroChartCell
                                      drilldownDataRow={drilldownData[rowIndex]}
                                      drilldownDataFieldPrefex="cap"
                                      horizontalLines={[zeroHorizontalLine]}
                                    />
                                  </StyledTableCell>
                                )}
                                {drilldownChartCols.includes('backlog') && (
                                  <StyledTableCell
                                    className={styles['flexed-table-cell']}
                                    align="left">
                                    <MicroChartCell
                                      drilldownDataRow={drilldownData[rowIndex]}
                                      drilldownDataFieldPrefex="blg"
                                      horizontalLines={[zeroHorizontalLine]}
                                    />
                                  </StyledTableCell>
                                )}
                                {drilldownChartCols.includes('utilization') && (
                                  <StyledTableCell
                                    className={styles['flexed-table-cell']}
                                    align="left">
                                    <MicroChartCell
                                      drilldownDataRow={drilldownData[rowIndex]}
                                      drilldownDataFieldPrefex="utl"
                                      horizontalLines={[
                                        horizintalLine,
                                        zeroHorizontalLine,
                                      ]}
                                    />
                                  </StyledTableCell>
                                )}
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </StyledTable>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[3, 5, 10]}
                      component="div"
                      count={drilldownTable.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={(event: unknown, newPage: number) => {
                        setPage(newPage)
                      }}
                      onChangeRowsPerPage={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onTileConfigChange({
                          [TileConfigKey.RegionalDrilldownChartRowsPerPage]: [
                            event.target.value.toString(),
                          ],
                        })
                        setPage(0)
                      }}
                    />
                  </>
                </RootRef>
              )}

              {drilldown.isHierarchy && (
                <>
                  {/* TREE VIEW HEADER */}
                  <RootRef rootRef={microChartTreeHeader}>
                    <Box
                      key={`tree-header-${drilldownChartCols.join('-')}`}
                      className={styles['filter-tree-header']}
                      height={42}
                      display="flex"
                      alignItems="center">
                      <Box
                        pl={2}
                        width={206}
                        display="flex"
                        flexGrow={0}
                        flexShrink={0}
                        alignItems="start">
                        <Button
                          className={styles['filterbar-item']}
                          variant="text"
                          size="small"
                          endIcon={<ArrowDropDownIcon />}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            setDrilldownOverviewMenuAnchor(event.currentTarget)
                            setExpandedTreeNodes([])
                            setTreeNodeShowCounts({})
                          }}>
                          {
                            (
                              find(drilldownTableMenuItems, {
                                key: drilldownChartSel,
                              }) || {}
                            ).label
                          }
                        </Button>
                        <Menu
                          id="simple-menu-tree"
                          anchorEl={drilldownTableMenuAnchor}
                          keepMounted
                          open={Boolean(drilldownTableMenuAnchor)}
                          onClose={() => {
                            setDrilldownOverviewMenuAnchor(null)
                          }}>
                          {drilldownTableMenuItems.map(
                            (item: any, index: number) => (
                              <MenuItem
                                key={index}
                                disabled={drilldownChartSel === item.key}
                                onClick={() => {
                                  onTileConfigChange({
                                    [TileConfigKey.RegionalDrilldownChartSelection]: [
                                      item.key,
                                    ],
                                  })
                                  setDrilldownOverviewMenuAnchor(null)
                                }}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </Box>
                      {drilldownChartCols.includes('capacity') && (
                        <DividerVertical height={42} />
                      )}
                      {drilldownChartCols.includes('capacity') && (
                        <Box
                          display="flex"
                          height="64px"
                          width="100%"
                          alignItems="center"
                          justifyContent="start">
                          <Typography variant="subtitle2" align="left" noWrap>
                            Δ Capacity [h]
                          </Typography>
                        </Box>
                      )}

                      {drilldownChartCols.includes('backlog') && (
                        <DividerVertical height={42} />
                      )}
                      {drilldownChartCols.includes('backlog') && (
                        <Box
                          display="flex"
                          height="64px"
                          width="100%"
                          alignItems="center"
                          justifyContent="start">
                          <Typography variant="subtitle2" align="left" noWrap>
                            Accumulated Backlog [d]
                          </Typography>
                        </Box>
                      )}

                      {drilldownChartCols.includes('utilization') && (
                        <DividerVertical height={42} />
                      )}
                      {drilldownChartCols.includes('utilization') && (
                        <Box
                          display="flex"
                          height="64px"
                          width="100%"
                          alignItems="center"
                          justifyContent="start">
                          <Typography variant="subtitle2" align="left" noWrap>
                            Utilization [%]
                          </Typography>
                        </Box>
                      )}
                      <DividerVertical width={0} height={42} mx={1} />
                    </Box>
                  </RootRef>

                  <RootRef rootRef={microChartTreeBody}>
                    <TreeView
                      key={`tree-${drilldownChartCols.join('-')}`}
                      className={styles['filter-tree-root-light']}
                      disableSelection
                      expanded={expandedTreeNodes}
                      onNodeToggle={(
                        event: React.ChangeEvent<{}>,
                        nodeIds: string[]
                      ) => {
                        const clickTarget = event.target as HTMLElement
                        const isCheckboxToggle =
                          clickTarget && clickTarget.nodeName === 'INPUT'
                            ? (
                                clickTarget.attributes.getNamedItem('type') ||
                                {}
                              ).value === 'checkbox'
                            : false
                        const isCollapse =
                          nodeIds.length < expandedTreeNodes.length
                        const [collapsedId] = difference(
                          expandedTreeNodes,
                          nodeIds
                        )

                        // prevent toggle expand if selection via checkbox was changed
                        if (!isCheckboxToggle) {
                          setExpandedTreeNodes(nodeIds)
                          if (isCollapse) {
                            setTreeNodeShowCounts({
                              ...treeNodeShowCounts,
                              [collapsedId]: defaultShowCount,
                            })
                          }
                        }
                      }}>
                      {renderTree(drilldownTree, 0)}
                    </TreeView>
                  </RootRef>
                  <Box m={2}>&nbsp;</Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default inject(
  Stores.routerStore,
  Stores.capacityDashboardStore,
  Stores.authenticationStore,
  Stores.workcenterStore,
  Stores.capabilityStore,
  Stores.userManagementStore,
  Stores.divisionStore,
  Stores.siteStore
)(observer(RegionalDashboard))

const multiChartMenuItems = [
  {
    label: 'Accumulations',
    key: ChartKey.Accumulations,
  },
  {
    label: 'Δ Capacity Overview',
    key: ChartKey.CapacityDelta,
  },
  {
    label: 'Capacity Timetravel',
    key: ChartKey.CapacityTimetravel,
  },
  {
    label: 'Demand Transfer',
    key: ChartKey.CapacityTransfer,
  },
  {
    label: 'Demand Supermarket',
    key: ChartKey.Supermarket,
  },
]

const drilldownTableMenuItems = [
  {
    label: 'L1 Capability',
    key: 'l1-capability',
    dimension: Dimension.Capability,
    isHierarchy: false,
  },
  {
    label: 'Capability Hierarchy',
    key: 'capability-hierarchy',
    dimension: Dimension.Capability,
    isHierarchy: true,
  },
  {
    label: 'Workcenter',
    key: 'workcenter',
    dimension: Dimension.Workcenter,
    isHierarchy: false,
  },
  {
    label: 'Workcenter Hierarchy',
    key: 'workcenter-hierarchy',
    dimension: Dimension.Workcenter,
    isHierarchy: true,
  },
  {
    label: 'Site',
    key: 'site',
    dimension: Dimension.Site,
    isHierarchy: false,
  },
]

const zeroHorizontalLine = {
  color: '#cccccc',
  value: 0,
  yAxisRight: true,
}

const horizintalLine = {
  color: '#51BF30',
  value: 100,
  label: '100%',
  yAxisRight: true,
  yAxisLabel: true,
}
