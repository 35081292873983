import { action, observable } from 'mobx'
import moment from 'moment'

import {
  ISeriesResult,
  IFilter,
  ISeries,
  IQueryResult,
  Dimension,
  Metric,
  ITimeframe,
  TimeframeSpan,
  TimeframeLevel,
  getSeriesResult,
} from 'interfaces/CapacityDashboard'
import CapacityDashboardService from 'services/CapacityDashboardService'
import RootStore from './RootStore'

export interface ChartTileState {
  error: boolean
  loaded: boolean
  loading: boolean
  series: ISeriesResult[]
  labels: string[]
}

export const getInitialChartTileState = () => ({
  error: false,
  loaded: false,
  loading: false,
  series: [],
  labels: [],
})

export enum ChartKey {
  // COMMON
  CurrentCapacity = 'CurrentCapacity',
  CurrentUtilization = 'CurrentUtilization',
  AvgUtilization = 'AvgUtilization',

  // REGIONAL DASHBOARD
  CapacityOverview = 'CapacityOverview',
  Accumulations = 'Accumulations',
  CapacityDelta = 'CapacityDelta',
  CapacityTimetravel = 'CapacityTimetravel',
  CapacityTransfer = 'CapacityTransfer',
  Supermarket = 'Supermarket',
  MicroChartSet = 'MicroChartSet',

  // GLOBAL DASHBOARD
  UtilizationOverview = 'UtilizationOverview',
  TopSites = 'TopSites',
  TopUtils = 'TopUtils',
}

export const updateTileState = (
  result: IQueryResult | Error,
  tile: ChartTileState
) => {
  if (!result || result instanceof Error) {
    tile.error = true
    tile.loaded = false
    tile.loading = false
    tile.labels = []
    tile.series = []
    return tile
  }

  tile.error = false
  tile.loaded = true
  tile.loading = false
  tile.series = result.series || []
  tile.labels = result.labels || []

  return tile
}

export default class CapacityDashboardStore {
  rootStore: RootStore
  capacityDashboardService = new CapacityDashboardService()
  loadingRegistry: {
    [key: string]: boolean
  } = {}

  // COMMON
  @observable anyLoading: boolean = false
  @observable tileCurrentCapacity: ChartTileState = getInitialChartTileState()
  @observable
  tileCurrentUtilization: ChartTileState = getInitialChartTileState()
  @observable tileAvgUtilization: ChartTileState = getInitialChartTileState()

  // REGIONAL DASHBOARD
  @observable tileCapacityOverview: ChartTileState = getInitialChartTileState()
  @observable tileAccumulations: ChartTileState = getInitialChartTileState()
  @observable tileCapacityDelta: ChartTileState = getInitialChartTileState()
  @observable
  tileCapacityTimetravel: ChartTileState = getInitialChartTileState()
  @observable tileCapacityTransfer: ChartTileState = getInitialChartTileState()
  @observable tileSupermarket: ChartTileState = getInitialChartTileState()
  @observable tileMicroChartSet: {
    [rowKey: string]: ChartTileState
  } = {}

  // GLOBAL DASHBOARD
  @observable
  tileUtilizationOverview: ChartTileState = getInitialChartTileState()
  @observable tileTopSites: ChartTileState = getInitialChartTileState()
  @observable tileTopUtils: ChartTileState = getInitialChartTileState()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  start(key: string) {
    this.loadingRegistry[key] = true
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
  }

  end(key: string) {
    this.loadingRegistry[key] = false
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
  }

  /**
   * C O M M O N   A C T I O N S
   */

  @action
  async getCurrentCapacity(forecaset: boolean, filter: IFilter[]) {
    this.start(ChartKey.CurrentCapacity)
    this.tileCurrentCapacity.loading = true

    const series: ISeries[] = [
      Metric.CapacityAvailable,
      forecaset ? Metric.CapacityDemandWithForecast : Metric.CapacityDemand,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const timeframe: ITimeframe = {
      start: moment().startOf('week').toDate(),
      end: moment().startOf('week').add(4, 'weeks').toDate(),
      span: TimeframeSpan.OneMonths,
      level: TimeframeLevel.Week,
    }
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileCurrentCapacity)

    this.end(ChartKey.CurrentCapacity)

    return tile
  }

  @action
  async getCurrentUtilization(filter: IFilter[]) {
    this.start(ChartKey.CurrentUtilization)
    this.tileCurrentUtilization.loading = true

    // TODO: calculate utilization manually, as query can't be aggregated on backend side to one data point
    const series: ISeries[] = [
      // Metric.UtilizationWithForecast,
      // Metric.Utilization,
      Metric.CapacityAvailable,
      Metric.CapacityDemand,
      Metric.CapacityForecaset,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const timeframe: ITimeframe = {
      start: moment().startOf('week').toDate(),
      end: moment().startOf('week').add(4, 'weeks').toDate(),
      span: TimeframeSpan.OneMonths,
      level: TimeframeLevel.Week,
    }
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const [available, demand, forecast] = result
      ? (result as IQueryResult).series
      : []
    const sumAvailable = available.data.reduce((sum, v) => sum + v, 0)
    const sumDemand = demand.data.reduce((sum, v) => sum + v, 0)
    const sumForecast = forecast.data.reduce((sum, v) => sum + v, 0)
    const calcedSeries = [
      {
        ...getSeriesResult(Metric.UtilizationWithForecast),
        end: available.end,
        start: available.start,
        timeLevel: available.timeLevel,
        data: [
          sumAvailable > 0
            ? ((sumDemand + sumForecast) / sumAvailable) * 100
            : 0,
        ],
      },
      {
        ...getSeriesResult(Metric.Utilization),
        end: available.end,
        start: available.start,
        timeLevel: available.timeLevel,
        data: [sumAvailable > 0 ? (sumDemand / sumAvailable) * 100 : 0],
      },
    ]
    const tile = updateTileState(
      { ...result, series: calcedSeries },
      this.tileCurrentUtilization
    )

    this.end(ChartKey.CurrentUtilization)

    return tile
  }

  @action
  async getAvgUtilization(filter: IFilter[]) {
    this.start(ChartKey.AvgUtilization)
    this.tileAvgUtilization.loading = true

    // TODO: calculate utilization manually, as query can't be aggregated on backend side to one data point
    const series: ISeries[] = [
      // Metric.UtilizationWithForecast,
      // Metric.Utilization,
      Metric.CapacityAvailable,
      Metric.CapacityDemand,
      Metric.CapacityForecaset,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const timeframe: ITimeframe = {
      start: moment().startOf('week').toDate(),
      end: moment().startOf('week').add(1, 'years').toDate(),
      span: TimeframeSpan.OneYear,
      level: TimeframeLevel.Week,
    }
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const [available, demand, forecast] = result
      ? (result as IQueryResult).series
      : []
    const sumAvailable = available.data.reduce((sum, v) => sum + v, 0)
    const sumDemand = demand.data.reduce((sum, v) => sum + v, 0)
    const sumForecast = forecast.data.reduce((sum, v) => sum + v, 0)
    const calcedSeries = [
      {
        ...getSeriesResult(Metric.UtilizationWithForecast),
        end: available.end,
        start: available.start,
        timeLevel: available.timeLevel,
        data: [
          sumAvailable > 0
            ? ((sumDemand + sumForecast) / sumAvailable) * 100
            : 0,
        ],
      },
      {
        ...getSeriesResult(Metric.Utilization),
        end: available.end,
        start: available.start,
        timeLevel: available.timeLevel,
        data: [sumAvailable > 0 ? (sumDemand / sumAvailable) * 100 : 0],
      },
    ]

    const tile = updateTileState(
      { ...result, series: calcedSeries },
      this.tileAvgUtilization
    )

    this.end(ChartKey.AvgUtilization)

    return tile
  }

  /**
   * R E G I O N A L   A C T I O N S
   */

  @action
  async getCapacityOverview(timeframe: ITimeframe, filter: IFilter[]) {
    this.start(ChartKey.CapacityOverview)
    this.tileCapacityOverview.loading = true

    const series: ISeries[] = [
      Metric.Utilization,
      Metric.UtilizationWithForecast,
      Metric.CapacityAvailable,
      Metric.CapacityDemand,
      Metric.CapacityForecaset,
      // Tooltip Metrics:
      Metric.CapacityTransferIn,
      Metric.CapacityTransferOut,
      Metric.CapacitySupermarket,
      Metric.CapacitySupermarketIncrease,
      Metric.CapacitySupermarketReduction,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileCapacityOverview)

    this.end(ChartKey.CapacityOverview)

    return tile
  }

  @action
  async getAccumulations(
    timeframe: ITimeframe,
    withForecast: boolean,
    filter: IFilter[]
  ) {
    this.start(ChartKey.Accumulations)
    this.tileAccumulations.loading = true

    const series: ISeries[] = [
      withForecast
        ? Metric.AccumulatedUtilizationWithForecast
        : Metric.AccumulatedUtilization,
      withForecast
        ? Metric.AccumulatedBacklogWithForecast
        : Metric.AccumulatedBacklog,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileAccumulations)

    this.end(ChartKey.Accumulations)

    return tile
  }

  @action
  async getCapacityDelta(
    timeframe: ITimeframe,
    withForecast: boolean,
    filter: IFilter[],
    accumulated: boolean
  ) {
    this.start(ChartKey.CapacityDelta)
    this.tileCapacityDelta.loading = true

    const series: ISeries[] = (accumulated
      ? [
          withForecast
            ? Metric.AccumulatedCapacityFreeWithForecast
            : Metric.AccumulatedCapacityFree,
          withForecast
            ? Metric.AccumulatedCapacityOverloadWithForecast
            : Metric.AccumulatedCapacityOverload,
        ]
      : [
          withForecast ? Metric.CapacityFreeWithForecast : Metric.CapacityFree,
          withForecast
            ? Metric.CapacityOverloadWithForecast
            : Metric.CapacityOverload,
        ]
    ).map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileCapacityDelta)

    this.end(ChartKey.CapacityDelta)

    return tile
  }

  @action
  async getCapacityTimetravel(
    timeframe: ITimeframe,
    referenceDate: Date,
    filter: IFilter[]
  ) {
    this.start(ChartKey.CapacityTimetravel)
    this.tileCapacityTimetravel.loading = true

    const series: ISeries[] = [
      Metric.Utilization,
      Metric.UtilizationWithForecast,
      Metric.CapacityAvailable,
      Metric.CapacityDemand,
      Metric.CapacityForecaset,
      // Tooltip Metrics:
      Metric.CapacityTransferIn,
      Metric.CapacityTransferOut,
      Metric.CapacitySupermarket,
      Metric.CapacitySupermarketIncrease,
      Metric.CapacitySupermarketReduction,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        referenceDate,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileCapacityTimetravel)

    this.end(ChartKey.CapacityTimetravel)

    return tile
  }

  @action
  async getCapacityTransfer(timeframe: ITimeframe, filter: IFilter[]) {
    this.start(ChartKey.CapacityTransfer)
    this.tileCapacityTransfer.loading = true

    const series: ISeries[] = [
      Metric.CapacityTransferIn,
      Metric.CapacityTransferOut,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileCapacityTransfer)

    this.end(ChartKey.CapacityTransfer)

    return tile
  }

  @action
  async getCapacitySupermarket(timeframe: ITimeframe, filter: IFilter[]) {
    this.start(ChartKey.Supermarket)
    this.tileSupermarket.loading = true

    const series: ISeries[] = [
      Metric.CapacitySupermarket,
      Metric.CapacitySupermarketIncrease,
      Metric.CapacitySupermarketReduction,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileSupermarket)

    this.end(ChartKey.Supermarket)

    return tile
  }

  @action
  async getMicroChartSet(
    rowKey: string,
    timeframe: ITimeframe,
    forecast: boolean,
    filter: IFilter[]
  ) {
    this.start(`${ChartKey.MicroChartSet}-${rowKey}`)
    this.tileMicroChartSet[rowKey] = getInitialChartTileState()
    this.tileMicroChartSet[rowKey].loading = true

    const series: ISeries[] = [
      forecast ? Metric.CapacityFreeWithForecast : Metric.CapacityFree,
      forecast ? Metric.CapacityOverloadWithForecast : Metric.CapacityOverload,
      forecast
        ? Metric.AccumulatedBacklogWithForecast
        : Metric.AccumulatedBacklog,
      forecast ? Metric.UtilizationWithForecast : Metric.Utilization,
    ].map((metric) => ({
      metric: metric,
      drilldown: null,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileMicroChartSet[rowKey])

    this.end(`${ChartKey.MicroChartSet}-${rowKey}`)

    return tile
  }

  /**
   * G L O B A L   A C T I O N S
   */

  @action
  async getUtilizationOverview(
    drilldown: Dimension,
    timeframe: ITimeframe,
    forecast: boolean,
    filter: IFilter[]
  ) {
    this.start(ChartKey.UtilizationOverview)
    this.tileUtilizationOverview.loading = true

    const series: ISeries[] = [
      {
        metric: forecast ? Metric.UtilizationWithForecast : Metric.Utilization,
        drilldown: drilldown,
      },
    ]
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileUtilizationOverview)

    this.end(ChartKey.UtilizationOverview)

    return tile
  }

  @action
  async getTopSites(
    timeframe: ITimeframe,
    forecast: boolean,
    filter: IFilter[]
  ) {
    this.start(ChartKey.TopSites)
    this.tileTopSites = getInitialChartTileState()
    this.tileTopSites.loading = true

    const series: ISeries[] = [
      forecast ? Metric.UtilizationWithForecast : Metric.Utilization,
    ].map((metric) => ({
      metric: metric,
      drilldown: Dimension.Site,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}
    const tile = updateTileState(result, this.tileTopSites)

    this.end(ChartKey.TopSites)

    return tile
  }

  @action
  async getTopUtils(
    dimension: Dimension,
    timeframe: ITimeframe,
    forecast: boolean,
    filter: IFilter[]
  ) {
    this.start(ChartKey.TopUtils)
    this.tileTopUtils = getInitialChartTileState()
    this.tileTopUtils.loading = true

    const series: ISeries[] = [
      Metric.CapacityAvailable,
      forecast ? Metric.CapacityDemandWithForecast : Metric.CapacityDemand,
      forecast ? Metric.UtilizationWithForecast : Metric.Utilization,
    ].map((metric) => ({
      metric: metric,
      drilldown: dimension,
    }))
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter
      )) || {}

    const tile = updateTileState(result, this.tileTopUtils)

    this.end(ChartKey.TopUtils)

    return tile
  }
}
