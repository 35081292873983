import { action, observable } from 'mobx'
import { omit } from 'lodash'

import { IWorkDays } from 'interfaces/WorkDays'
import WorkDaysService from 'services/WorkDaysService'
import RootStore from './RootStore'

export default class WorkDaysStore {
  rootStore: RootStore
  workcenterService = new WorkDaysService()

  @observable all: {
    // site
    [site: string]: {
      // year
      [year: string]: {
        // month
        [month: string]: IWorkDays
      }
    }
  } = {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.all = {}
  }

  @action
  async getBySiteAndYear(siteId: string, year: number | string) {
    this.rootStore.setWorkDaysLoading(true)
    const workDays:
      | IWorkDays[]
      | void = await this.workcenterService.getWorkDaysBySiteAndYear(
      siteId,
      year
    )
    this.all[siteId] = {
      ...this.all[siteId],
      [year]: {
        ...(this.all[siteId] || {})[year],
        ...(workDays || []).reduce(
          (monthMap, workDays) => ({
            ...monthMap,
            [workDays.month]: workDays,
          }),
          {}
        ),
      },
    }
    this.rootStore.setWorkDaysLoading(false)

    return workDays
  }

  @action
  async save(workDays: IWorkDays) {
    this.rootStore.setWorkDaysLoading(true)

    const workDaysConfirm: IWorkDays | void = !workDays._id
      ? await this.workcenterService.createWorkDays(omit(workDays, '_id'))
      : await this.workcenterService.updateWorkDays(workDays)

    if (workDaysConfirm) {
      const { siteId, year, month } = workDaysConfirm || {}

      this.all[siteId] = {
        ...this.all[siteId],
        [year]: {
          ...(this.all[siteId] || {})[year],
          [month]: workDaysConfirm,
        },
      }
    }
    this.rootStore.setWorkDaysLoading(false)
  }
}
