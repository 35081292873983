import React from 'react'
import { observer } from 'mobx-react'

import styles from 'styles/threeStateSelector.module.scss'

interface SelectorProps {
  infoCapability: 0 | 1 | 2 | 3
  onChange: (value: 0 | 1 | 2) => void
}

const ThreeStateSelector: React.FC<SelectorProps> = observer(
  ({ infoCapability, onChange }) => {
    const selectionHandler = (
      event: React.MouseEvent,
      newSelection: 0 | 1 | 2
    ) => {
      // TODO service to update
      event.stopPropagation()
      onChange(newSelection)
    }

    const selectorPosition = () => {
      let newPosition
      switch (infoCapability) {
        case 0:
          newPosition = `${styles.selector} ${styles['selector-one']}`
          break
        case 2:
          newPosition = `${styles.selector} ${styles['selector-two']}`
          break
        case 1:
          newPosition = `${styles.selector} ${styles['selector-three']}`
          break
        default:
          newPosition = `${styles.selector} ${styles['selector-two']}`
          break
      }
      return <div className={newPosition}></div>
    }

    return (
      <div className={styles['three-state-wrapper']}>
        <div
          className={styles['selector-no']}
          onClick={(e) => selectionHandler(e, 0)}>
          No
        </div>
        <div className={styles['slider']}>
          <div
            className={styles['selector-1']}
            onClick={(e) => selectionHandler(e, 0)}></div>
          <div
            className={styles['selector-2']}
            onClick={(e) => selectionHandler(e, 2)}></div>
          <div
            className={styles['selector-3']}
            onClick={(e) => selectionHandler(e, 1)}></div>
          {selectorPosition()}
        </div>
        <div
          className={styles['selector-yes']}
          onClick={(e) => selectionHandler(e, 1)}>
          Yes
        </div>
      </div>
    )
  }
)

export default ThreeStateSelector
