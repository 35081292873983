import { commonFunctions } from './ServiceConfig'
import HeaderService from './HeaderService'
import EndpointService from './EndpointService'
import { notify } from 'components/Notification'
import { Capability } from 'interfaces/Capabilities'
import indexedDB, { QueryCache } from 'utils/IndexedDB'

const openDB = indexedDB.open().catch((e) => console.error(e))

const { handleResponse } = commonFunctions
export default class CapabilityService {
  public getAllCapabilities = (divisionId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capabilities/getall?id=${divisionId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilitiesGetAll'
          notify(message, 'error')
          return []
        }
      )
  }

  public getAllCapabilitiesFromCache = async (divisionId: string) => {
    const TIME_TO_LIVE = 0.5 * 60 * 60 * 1000 // 30 minutes
    const requestKey = `capabilities-${divisionId}`
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    const query = () =>
      fetch(
        `${EndpointService.getEndpoint()}/capabilities/getall?id=${divisionId}`,
        requestOptions as any
      )
        .then(handleResponse)
        .then(
          (response: any) => {
            return response
          },
          (error: Error) => {
            notify(
              error.message !== '500'
                ? 'errors:fetchDashboardQuery'
                : 'errors:server errors:fetchDashboardQuery',
              'error'
            )
            return error
          }
        )

    const db = await openDB

    return db ? db.cacheQuery(requestKey, TIME_TO_LIVE, query) : query()
  }

  public registerCapablity = async (
    capability: Capability,
    divisionId: string
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify({
        name: capability.name,
        divisionId,
        description: capability.description,
        image: capability.image,
      }),
    }

    const response = await fetch(
      `${EndpointService.getEndpoint()}/capabilities/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        async (response: any) => {
          notify('notify:capabilityRegister', 'success')

          const db = await openDB
          const requestKey = `capabilities-${capability.divisionId}`

          // clear capability cache
          if (db) {
            await db.remove(requestKey)
          }

          return response || {}
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilityRegisterFailure'
          notify(message, 'error')
          return null
        }
      )

    return response
  }

  public updateCapability = async (capability: Capability) => {
    const body = JSON.stringify(capability)
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body,
    }

    const response = await fetch(
      `${EndpointService.getEndpoint()}/capabilities/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        async (response: any) => {
          notify('notify:capabilityUpdate', 'success')

          const db = await openDB
          const requestKey = `capabilities-${capability.divisionId}`

          // clear capability cache
          if (db) {
            await db.remove(requestKey)
          }

          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilityUpdateFailure'
          notify(message, 'error')
          return null
        }
      )

    if (response) {
      const db = await openDB
      const requestKey = `capabilities-${capability.divisionId}`

      // clear capability cache
      if (db) {
        await db.remove(requestKey)
      }
    }

    return response
  }

  public updateExistingCapability = async (capability: Capability) => {
    const body = JSON.stringify(capability)
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body,
    }

    const response = await fetch(
      `${EndpointService.getEndpoint()}/capabilities/updateExistingCapability`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        async (response: any) => {
          notify('notify:capabilityUpdate', 'success')

          const db = await openDB
          const requestKey = `capabilities-${capability.divisionId}`

          // clear capability cache
          if (db) {
            await db.remove(requestKey)
          }

          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilityUpdateFailure'
          notify(message, 'error')
          return null
        }
      )

    return response
  }

  public deleteCapability = async (capability: Capability) => {
    capability._id === '' && (capability._id = null)
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
      body: JSON.stringify(capability),
    }

    const response = await fetch(
      `${EndpointService.getEndpoint()}/capabilities/delete`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        async (response: any) => {
          notify('notify:capabilityDelete', 'success')

          const db = await openDB
          const requestKey = `capabilities-${capability.divisionId}`

          // clear capability cache
          if (db) {
            await db.remove(requestKey)
          }

          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilityDeleteFailure'
          error.message.match('500') && notify(message, 'error')
          return error
        }
      )

    return response
  }

  public forceDeleteCapability = (capability: Capability) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
      body: JSON.stringify(capability),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capabilities/forcedelete`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        async (response: any) => {
          notify('notify:capabilityDelete', 'success')

          const db = await openDB
          const requestKey = `capabilities-${capability.divisionId}`

          // clear capability cache
          if (db) {
            await db.remove(requestKey)
          }

          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:capabilityDeleteFailure'
          notify(message, 'error')
          return handleResponse
        }
      )
  }
}
