import React from 'react'
import { matchPath, Redirect, Switch } from 'react-router-dom'
import { Box, Tab, Tabs } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import assign from 'lodash/assign'
import defer from 'lodash/defer'
import moment from 'moment'

import { Stores } from 'stores/stores'
import RouterStore, { history } from 'stores/RouterStore'
import AuthenticationStore from 'stores/AuthenticationStore'

import { getDashboardRoute } from 'utils/Chart'
import Routes from 'interfaces/Routes'
import { P13nKey, P13nRouteConfig } from 'interfaces/CapacityDashboard'
import { getUser, UserRoles } from 'interfaces/User'
import PrivateRoute from 'components/PrivateRoute'
import RegionalDashboard from './RegionalDashboard'
import GlobalDashboard from './GlobalDashboard'
import indexedDB, { Event } from 'utils/IndexedDB'

import styles from 'styles/capacityDashboard.module.scss'

interface Props extends WithTranslation {
  match: any
  location: any
  routerStore?: RouterStore
  authenticationStore?: AuthenticationStore
}

interface State {
  tabIndex: number
  cacheAge: string | null
}

@inject(Stores.routerStore, Stores.authenticationStore)
@observer
class CapacityDashboard extends React.Component<Props, State> {
  t: TFunction
  routerStore: RouterStore
  authenticationStore: AuthenticationStore

  constructor(props: Props) {
    super(props)
    this.t = props.t
    this.routerStore = props.routerStore!
    this.authenticationStore = props.authenticationStore!

    this.state = {
      tabIndex: this.getTabIndexByLocation(),
      cacheAge: null,
    }

    indexedDB.registerEventObserver(
      Event.ClearCache,
      this.onCacheChange.bind(this, Event.ClearCache)
    )
    indexedDB.registerEventObserver(Event.ReadFromCache, (timestamp: number) =>
      this.onCacheChange(Event.ReadFromCache, timestamp)
    )

    history.listen((location) => {
      const isGlobalTab = this.state.tabIndex === 1
      const anyDashboardMatch = matchPath(location.pathname, {
        path: Routes.CAPACITY_DASHBOARD,
        exact: true,
        strict: false,
      })
      const savedConfig = this.getSavedConfig(
        isGlobalTab ? P13nKey.GlobalConfig : P13nKey.RegionalConfig
      )

      if (anyDashboardMatch) {
        // trigger redirect
        this.routerStore.navigateTo(
          getDashboardRoute(
            isGlobalTab
              ? Routes.CAPACITY_DASHBOARD_GLOBAL
              : Routes.CAPACITY_DASHBOARD_REGIONAL,
            {},
            savedConfig || undefined
          )
        )
      }
    })
  }

  onCacheChange(event: Event, timestamp: number) {
    switch (event) {
      case Event.ClearCache:
        this.setState({ cacheAge: null })
        break
      case Event.ReadFromCache:
        const ageString = moment(timestamp).from(new Date())
        if (ageString !== this.state.cacheAge) {
          this.setState({ cacheAge: ageString })
        }
        break
      default:
        break
    }
  }

  getTabIndexByLocation() {
    const isGlobalTab = this.getIsGlobalTabDefault()
    const savedConfig = this.getSavedConfig(
      isGlobalTab ? P13nKey.GlobalConfig : P13nKey.RegionalConfig
    )

    defer(() =>
      this.routerStore.navigateTo(
        getDashboardRoute(
          isGlobalTab
            ? Routes.CAPACITY_DASHBOARD_GLOBAL
            : Routes.CAPACITY_DASHBOARD_REGIONAL,
          {},
          savedConfig || undefined
        )
      )
    )

    return isGlobalTab ? 1 : 0
  }

  getIsGlobalTabDefault() {
    const user = this.authenticationStore?.currentUser || getUser()
    const isGlobalTab = user.role === UserRoles.GLOBAL

    return isGlobalTab
  }

  getSavedConfig(key: P13nKey) {
    const user = this.authenticationStore?.currentUser || getUser()
    const { p13n = {} } = user
    const configRaw = p13n[key]
    const parsedConfig: P13nRouteConfig = configRaw ? JSON.parse(configRaw) : {}
    const { config: routeConfig } = parsedConfig || {}

    // date objects must be reinitialized after prsed from JSON string
    return routeConfig
      ? assign(routeConfig, {
          timeframe: {
            ...routeConfig.timeframe,
            start: routeConfig.timeframe?.start
              ? new Date(routeConfig.timeframe?.start)
              : null,
            end: routeConfig.timeframe?.end
              ? new Date(routeConfig.timeframe?.end)
              : null,
          },
        })
      : null
  }

  handleTabSwitch(event: any, newValue: number) {
    const { match } = this.props
    const { params } = match || {}
    const isGlobalTab = newValue === 1
    const savedConfig =
      this.getSavedConfig(
        isGlobalTab ? P13nKey.GlobalConfig : P13nKey.RegionalConfig
      ) || {}

    this.setState({ tabIndex: newValue })

    switch (newValue) {
      case 0:
        this.routerStore.navigateTo(
          getDashboardRoute(
            Routes.CAPACITY_DASHBOARD_REGIONAL,
            params,
            savedConfig
          )
        )
        break

      case 1:
      default:
        this.routerStore.navigateTo(
          getDashboardRoute(
            Routes.CAPACITY_DASHBOARD_GLOBAL,
            params,
            savedConfig
          )
        )
        break
    }
  }

  render() {
    const { tabIndex, cacheAge } = this.state

    return (
      <React.Fragment>
        <Box pt={2}>
          <Tabs
            className={styles['tabbar']}
            value={tabIndex}
            onChange={this.handleTabSwitch.bind(this)}
            indicatorColor="primary"
            textColor="primary">
            <Tab label="Regional Dashboard" />
            <Tab label="Global Dashboard" />
            <Tab
              label={
                cacheAge === null
                  ? 'Data loaded live from database.'
                  : `Data loaded from cache, ${cacheAge}.`
              }
              style={{
                marginLeft: 'auto',
                textTransform: 'none',
                maxWidth: 'none',
              }}
              disabled
              fullWidth
              wrapped
            />
          </Tabs>
          <div role="tabpanel">
            <Box px={4}>
              <Switch>
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_DASHBOARD_REGIONAL}
                  component={RegionalDashboard}
                />
                <PrivateRoute
                  exact
                  path={Routes.CAPACITY_DASHBOARD_GLOBAL}
                  component={GlobalDashboard}
                />
              </Switch>
            </Box>
          </div>
        </Box>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CapacityDashboard)
