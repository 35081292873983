import React from 'react'
import { observer, inject } from 'mobx-react'
import { WithTranslation, useTranslation } from 'react-i18next'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isNumber from 'lodash/isNumber'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import assign from 'lodash/assign'
import reduce from 'lodash/reduce'
import slugify from 'slugify'
import { Content } from 'pdfmake/interfaces'

import {
  withStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import InfoIcon from '@material-ui/icons/Info'

import { Stores } from 'stores/stores'
import RouterStore from 'stores/RouterStore'
import SiteStore from 'stores/SiteStore'
import AuthenticationStore from 'stores/AuthenticationStore'
import UserManagementStore from 'stores/UserManagementStore'
import CapabilityStore from 'stores/CapabilityStore'
import CapacityDashboardStore, {
  ChartTileState,
  getInitialChartTileState,
} from 'stores/CapacityDashboardStore'
import {
  P13nKey,
  P13nRouteConfig,
  IFilter,
  Dimension,
  FilterOperator,
  Metric,
  IRouteParams,
  DefaultTimeframes,
  OneSpanTimeframes,
  ISeriesResult,
  getUnitByMetric,
  TileConfigKey,
  ITimeframe,
} from 'interfaces/CapacityDashboard'
import { IFlatCap } from 'interfaces/Workcenter'
import WorkcenterService from 'services/WorkcenterService'
import { ISite } from 'interfaces/Site'
import Routes from 'interfaces/Routes'
import { IRegion } from 'interfaces/Common'
import { IDivision } from 'interfaces/Division'
import { IUser } from 'interfaces/User'
import { notify } from 'components/Notification'
import Filterbar from 'components/Dashboard/Filterbar'
import TrendTile, { TrendTileVariant } from 'components/Dashboard/TrendTile'
import TimeframeSelect from 'components/Dashboard/TimeframeSelect'
import { Chart } from 'components/Dashboard/Chart/'
import {
  getDashboardRoute,
  getStateByUtilization,
  getStateClassByUtilization,
  parseSites,
  parseForecast,
  parseFilter,
  parseDashboardParams,
  parseTileConfig,
  abbreviateNumber,
  getDebouncedFunction,
} from 'utils/Chart'
import { getContentByChart } from 'utils/PDF'
import indexedDB from 'utils/IndexedDB'

import styles from 'styles/capacityDashboard.module.scss'

interface Props extends WithTranslation {
  match: any
  routerStore?: RouterStore
  capacityDashboardStore?: CapacityDashboardStore
  authenticationStore?: AuthenticationStore
  userManagementStore?: UserManagementStore
  capabilityStore?: CapabilityStore
  siteStore?: SiteStore
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    head: {
      backgroundColor: theme.palette.grey['100'],
      color: theme.palette.common.black,
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.action.hover}`,
      },
    },
    body: {
      fontSize: 20,
      fontWeight: 'bold',
      '&.info': {
        color: theme.palette.grey['600'],
      },
      '&.success': {
        color: theme.palette.success.main,
      },
      '&.warning': {
        color: theme.palette.warning.main,
      },
      '&.error': {
        color: theme.palette.error.main,
      },
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.action.hover}`,
      },
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey['50'],
      },
    },
  })
)(TableRow)

const GlobalDashboard: React.FC<Props> = (props: Props) => {
  const {
    routerStore,
    capacityDashboardStore: chartStore,
    authenticationStore,
    userManagementStore,
    capabilityStore,
    siteStore,
    match,
  } = props
  const userService = userManagementStore?.userService
  const { params } = match || {}
  const { t } = useTranslation()
  const theme = useTheme()

  /**
   * H E L P E R S
   */
  const getCountryCode = (
    map: {
      [countryName: string]: string
    },
    countryName: string
  ) => {
    return map[countryName] || countryName
  }

  /**
   * S T A T E S
   */
  const [
    utilizationChartMenuOpen,
    setUtilizationChartMenuOpen,
  ] = React.useState<boolean>(false)
  const [pageSites, setPageSites] = React.useState<number>(0)
  const [pageUtils, setPageUtils] = React.useState<number>(0)
  const [
    drilldownTableMenuAnchorEl,
    setDrilldownTableMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null)
  const [forceRefreshTrigger, setForceRefreshTrigger] = React.useState<boolean>(
    true
  )

  /**
   * M E M O S
   */
  const currentUser = authenticationStore!.currentUser
  const { p13n = {}, sites: userSites = [] } = currentUser
  const allSitesFromStore: ISite[] = siteStore!.allSites || []
  const allSites: ISite[] = React.useMemo(() => allSitesFromStore, [
    allSitesFromStore,
  ])
  const countryCodeMap = siteStore!.countryCodeMap || {}
  const selectedSites: ISite[] = React.useMemo(() => {
    const siteIDs = parseSites(params.sites)
    const selectedSites = allSites.filter((site) => siteIDs.includes(site._id))

    return selectedSites
  }, [params.sites, allSites])
  const selectedCapabilities: string[] = React.useMemo(() => {
    const filterMap = parseFilter(params.filter) || {}
    const selectedCapabilities = filterMap[Dimension.Capability] || []

    return selectedCapabilities
  }, [params.filter])
  const capsByDivision = capabilityStore?.capsByDivision || {}
  const countFetchedDivisionCaps = Object.keys(capsByDivision).length

  // PARAMS
  const siteFilter: IFilter = React.useMemo(() => {
    const siteIDs = parseSites(params.sites)

    return {
      filterDimension: Dimension.Site,
      operator: FilterOperator.EQ,
      values: siteIDs,
    }
  }, [params.sites])
  const generalFilters: IFilter[] = React.useMemo(
    () => [
      {
        filterDimension: Dimension.Capability,
        operator: FilterOperator.EQ,
        values: selectedCapabilities,
      },
    ],
    [selectedCapabilities]
  )
  const forecast = React.useMemo(
    () => cloneDeep(parseForecast(params.forecast)),
    [params.forecast]
  )
  const tileConfig = React.useMemo(
    () => cloneDeep(parseTileConfig(params.tileConfig)),
    [params.tileConfig]
  )
  const [utilizationChartSelectionRaw] =
    tileConfig[TileConfigKey.GlobalUtilizationChartSelection] || []
  const utilizationChartSelection =
    parseInt(utilizationChartSelectionRaw, 10) || 0
  const [utilizationChartTimeframeRaw] =
    tileConfig[TileConfigKey.GlobalUtilizationChartTimeframe] || []
  const utilizationChartTimeframe = React.useMemo(
    () =>
      cloneDeep(
        find(DefaultTimeframes, {
          key: parseInt(utilizationChartTimeframeRaw, 10),
        }) || DefaultTimeframes[3]
      ),
    [utilizationChartTimeframeRaw]
  )
  const [rowsPerPageSitesRaw] =
    tileConfig[TileConfigKey.GlobalRowsPerPageSites] || []
  const rowsPerPageSites = parseInt(rowsPerPageSitesRaw, 10) || 3
  const [topSitesTimeframeRaw] =
    tileConfig[TileConfigKey.GlobalTopSitesTimeframe] || []
  const topSitesTimeframe = React.useMemo(
    () =>
      cloneDeep(
        find(OneSpanTimeframes, { key: parseInt(topSitesTimeframeRaw, 10) }) ||
          OneSpanTimeframes[2]
      ),
    [topSitesTimeframeRaw]
  )
  const [topSitesTableSortDirection = 'desc'] =
    tileConfig[TileConfigKey.GlobalTopSitesTableSortDirection] || []
  const [rowsPerPageUtilsRaw] =
    tileConfig[TileConfigKey.GlobalRowsPerPageUtils] || []
  const rowsPerPageUtils = parseInt(rowsPerPageUtilsRaw, 10) || 3
  const [drilldownTableSelection = drilldownTableMenuItems[0].key] =
    tileConfig[TileConfigKey.GlobalDrilldownTableSelection] || []
  const [topUtilsTimeframeRaw] =
    tileConfig[TileConfigKey.GlobalTopUtilsTimeframe] || []
  const topUtilsTimeframe = React.useMemo(
    () =>
      cloneDeep(
        find(OneSpanTimeframes, { key: parseInt(topUtilsTimeframeRaw, 10) }) ||
          OneSpanTimeframes[2]
      ),
    [topUtilsTimeframeRaw]
  )
  const [drilldownTableSortDirection = 'desc'] =
    tileConfig[TileConfigKey.GlobalDrilldownTableSortDirection] || []
  const [drilldownTableSortType = Metric.Utilization] =
    tileConfig[TileConfigKey.GlobalDrilldownTableSortType] || []
  const [drilldownShowAllSitesRaw] =
    tileConfig[TileConfigKey.GlobalDrilldownShowAllSites] || []
  const drilldownShowAllSites =
    drilldownShowAllSitesRaw === 'true' ? true : false

  // TREND TILES
  const trendCurrentCapTile: ChartTileState =
    chartStore?.tileCurrentCapacity || getInitialChartTileState()
  const trendCurrentCapSeries = trendCurrentCapTile.series || []
  const [firstCurrentCapSeries] = trendCurrentCapSeries || []
  const trendCurrentCap = React.useMemo(
    () => ({
      label: firstCurrentCapSeries
        ? `${moment(firstCurrentCapSeries.start).format(
            'YYYY [CW]WW'
          )} - ${moment(firstCurrentCapSeries.end).format(
            moment(firstCurrentCapSeries.start).year() ===
              moment(firstCurrentCapSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendCurrentCapSeries.map((set) => {
          const value = set.data.reduce((sum, v) => sum + v, 0)

          return {
            value: value.toString(),
            unit: 'h',
            description: t(`metric:${set.label}`),
          }
        })
      ),
    }),
    [t, trendCurrentCapSeries, firstCurrentCapSeries]
  )
  const trendCurrentUtilTile: ChartTileState =
    chartStore?.tileCurrentUtilization || getInitialChartTileState()
  const trendCurrentUtilSeries = trendCurrentUtilTile.series || []
  const [firstCurrentUtilSeries] = trendCurrentUtilSeries || []
  const trendCurrentUtil = React.useMemo(
    () => ({
      label: firstCurrentUtilSeries
        ? `${moment(firstCurrentUtilSeries.start).format(
            'YYYY [CW]WW'
          )} - ${moment(firstCurrentUtilSeries.end).format(
            moment(firstCurrentUtilSeries.start).year() ===
              moment(firstCurrentUtilSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendCurrentUtilSeries.map((set) => {
          const value =
            set.data.reduce((sum, val) => sum + val, 0) / (set.data.length || 1)

          return {
            value: value.toString(),
            unit: '%',
            description: t(`metric:${set.label}`),
            state: getStateByUtilization(value),
          }
        })
      ),
    }),
    [t, trendCurrentUtilSeries, firstCurrentUtilSeries]
  )
  const trendAvgUtilTile: ChartTileState =
    chartStore?.tileAvgUtilization || getInitialChartTileState()
  const trendAvgUtilSeries = trendAvgUtilTile.series || []
  const [firstAvgUtilSeries] = trendAvgUtilSeries || []
  const trendAvgUtil = React.useMemo(
    () => ({
      label: firstAvgUtilSeries
        ? `${moment(firstAvgUtilSeries.start).format('YYYY [CW]WW')} - ${moment(
            firstAvgUtilSeries.end
          ).format(
            moment(firstAvgUtilSeries.start).year() ===
              moment(firstAvgUtilSeries.end).year()
              ? '[CW]WW'
              : 'YYYY [CW]WW'
          )}`
        : '',
      data: cloneDeep(
        trendAvgUtilSeries.map((set) => {
          const value =
            set.data.reduce((sum, val) => sum + val, 0) / (set.data.length || 1)

          return {
            value: value.toString(),
            unit: '%',
            description: t(`metric:${set.label}`),
            state: getStateByUtilization(value),
          }
        })
      ),
    }),
    [t, trendAvgUtilSeries, firstAvgUtilSeries]
  )
  const trendTiles = React.useMemo(
    () => [
      {
        ...trendCurrentCap,
        title: 'Current Capacity',
        tile: trendCurrentCapTile,
        variant: TrendTileVariant.Grey,
      },
      {
        ...trendCurrentUtil,
        title: 'Current Utilization',
        tile: trendCurrentUtilTile,
        variant: TrendTileVariant.Teal,
      },
      {
        ...trendAvgUtil,
        title: 'Average Utilization',
        tile: trendAvgUtilTile,
        variant: TrendTileVariant.Indigo,
      },
    ],
    [
      trendCurrentCapTile,
      trendCurrentUtilTile,
      trendAvgUtilTile,
      trendCurrentCap,
      trendCurrentUtil,
      trendAvgUtil,
    ]
  )

  // UTILIZATION OVERVIEW
  const chartColorTheme = [
    { color: '#003764', name: 'darkBlue' },
    { color: '#dbdd4c', name: 'green' },
    { color: '#ffcb04', name: 'yellow' },
    { color: '#ee7523', name: 'orange' },
    { color: '#A8353A', name: 'red' },
    { color: theme.palette.primary.main, name: 'primary' },
    { color: '#E7EDF3', name: 'secondary' },
    { color: '#484848', name: 'darkGray' },
    { color: '#8A8A8A', name: 'gray' },
    { color: '#C5C6C6', name: 'lightGray' },
  ]
  interface IDrilldownValue {
    _id: string | undefined
    name: string
  }
  const utilOverviewDrilldown: IDrilldownValue[] = React.useMemo(() => {
    const selectedDimension =
      utilizationChartDrilldownItems[utilizationChartSelection].key
    const sites: ISite[] = selectedSites.length > 0 ? selectedSites : allSites
    const regions: IRegion[] = uniqBy(
      sites.map((s) => s.region),
      '_id'
    )
    const divisions: IDivision[] = uniqBy(
      sites.map((s) => s.division),
      '_id'
    )

    switch (selectedDimension) {
      case Dimension.Site:
        return sites.map((s) => ({
          _id: s._id,
          name: `${s.name} / ${getCountryCode(countryCodeMap, s.country)}, ${
            s.city
          }`,
        }))
      case Dimension.Region:
        return regions.map((r) => ({
          _id: r._id,
          name: r.name,
        }))
      case Dimension.Division:
      default:
        return divisions.map((d) => ({
          _id: d._id,
          name: d.name,
        }))
    }
  }, [utilizationChartSelection, selectedSites, allSites, countryCodeMap])
  const utilOverviewTile: ChartTileState =
    chartStore?.tileUtilizationOverview || getInitialChartTileState()
  const utilOverviewSeries = utilOverviewTile ? utilOverviewTile.series : []
  const utilOverviewDataset = React.useMemo(
    () =>
      cloneDeep(
        utilOverviewDrilldown.map((drilldownValue, index) => {
          const drilldownSeries: ISeriesResult | undefined = find(
            utilOverviewSeries,
            {
              dimensionId: drilldownValue._id,
            }
          )

          return {
            key: `${slugify(
              drilldownSeries
                ? drilldownSeries.label
                : drilldownValue._id || drilldownValue.name
            )}-${index}`,
            label: drilldownValue.name,
            unit: getUnitByMetric(Metric.Utilization),
            data: drilldownSeries ? drilldownSeries.data : [],
            color: chartColorTheme[index % chartColorTheme.length].color,
            type: 'line',
            hidden: !drilldownShowAllSites && !drilldownSeries,
            tooltip: {
              sortByValue: true,
            },
          }
        })
      ),
    [
      utilOverviewDrilldown,
      utilOverviewSeries,
      chartColorTheme,
      drilldownShowAllSites,
    ]
  )

  // TOP SITES TILE
  const topSitesTile: ChartTileState =
    chartStore?.tileTopSites || getInitialChartTileState()
  const topSitesSeries = topSitesTile ? topSitesTile.series : []
  const topSitesDataRows = React.useMemo(() => {
    const sites: ISite[] = selectedSites.length > 0 ? selectedSites : allSites

    const sortedResult = sortBy(
      sites.map((s) => {
        const utilSeries = find(topSitesSeries, { dimensionId: s._id })
        const [utilizationValue] = utilSeries ? utilSeries.data || [] : []
        const utilizationLabel = isNumber(utilizationValue)
          ? utilizationValue.toFixed(1)
          : ''

        return {
          _id: s._id,
          dimension: Dimension.Site,
          name: s.name,
          description: `${getCountryCode(countryCodeMap, s.country)}, ${
            s.city
          }`,
          ...topSitesTile,
          utilization: utilizationLabel,
          utilizationValue: utilizationValue,
          timeframe: utilSeries
            ? `${moment(utilSeries.start).format('YYYY MMM')} - ${moment(
                utilSeries.end
              ).format('YYYY MMM')}`
            : '',
        }
      }),
      (row) =>
        isNumber(row.utilizationValue) ? row.utilizationValue : -Infinity
    )

    if (topSitesTableSortDirection === 'desc') {
      sortedResult.reverse()
    }

    return sortedResult
    // "topSitesTile.loading" is a mandatory dependency to recognize changing in the loading state, for any reason, all other tiles ar working
    // eslint-disable-next-line
  }, [
    selectedSites,
    allSites,
    topSitesTile,
    topSitesSeries,
    countryCodeMap,
    topSitesTableSortDirection,
    // topSitesTile.loading,
  ])
  const topUtilsTile: ChartTileState =
    chartStore?.tileTopUtils || getInitialChartTileState()
  const topUtilsSeries = topUtilsTile ? topUtilsTile.series : []
  const topUtilsDataRows = React.useMemo(() => {
    const drilldown =
      find(drilldownTableMenuItems, { key: drilldownTableSelection }) ||
      drilldownTableMenuItems[0]
    const sites: ISite[] = selectedSites.length > 0 ? selectedSites : allSites
    const regions: IRegion[] = uniqBy(
      sites.map((s) => s.region),
      '_id'
    )

    const flatCapabilities = WorkcenterService.convertToFlatCaps(
      flatten(Object.values(capsByDivision))
    ).filter((fcap) =>
      selectedCapabilities.length > 0
        ? selectedCapabilities.includes(fcap.id2)
        : true
    )

    interface CapEntry {
      id: string
    }
    interface CapDrilldownEntry {
      capId: string
      drilldownId: string | null
    }

    const dimensionDivider = ' - '
    const isMultiDimDrilldown =
      drilldown.dimension === Dimension.CapabilitySite ||
      drilldown.dimension === Dimension.CapabilityRegion
    const capIds: CapEntry[] = topUtilsSeries.map((series) => {
      const [, capId] = isMultiDimDrilldown
        ? (series.dimensionId || '').split(dimensionDivider)
        : [null, series.dimensionId]
      return {
        id: capId || '',
      }
    })
    const uniqCapIds = uniqBy(capIds, 'id')
    const initCollection: CapDrilldownEntry[] = []
    const extendedCapIds: CapDrilldownEntry[] = reduce(
      uniqCapIds,
      (collection, cap) => {
        if (drilldown.dimension === Dimension.CapabilitySite) {
          return flatten(
            collection.concat(
              sites.map((site) => ({
                capId: cap.id,
                drilldownId: site._id,
              }))
            )
          )
        } else if (drilldown.dimension === Dimension.CapabilityRegion) {
          return flatten(
            collection.concat(
              regions.map((region) => ({
                capId: cap.id,
                drilldownId: region._id,
              }))
            )
          )
        }

        return flatten(
          collection.concat({
            capId: cap.id,
            drilldownId: null,
          })
        )
      },
      initCollection
    )

    // return dummy table as long as data is loading
    if (topUtilsTile.loading || topUtilsTile.error || !topUtilsTile.loaded) {
      const emptyDummyRows = []

      for (let i = 0; i < rowsPerPageUtils; i++) {
        emptyDummyRows.push({
          _id: `dummy-${i}`,
          dimension: drilldown.dimension,
          name: '',
          image: '',
          description: '',
          ...topUtilsTile,
          available: '',
          availableValue: 0,
          demand: '',
          demandValue: 0,
          utilization: '',
          utilizationValue: 0,
          timeframe: '',
        })
      }

      return emptyDummyRows
    }

    const sortedResult = sortBy(
      extendedCapIds.map((c) => {
        const joinedId = isMultiDimDrilldown
          ? [c.drilldownId, c.capId].join(dimensionDivider)
          : c.capId
        const capacitySeries = topUtilsSeries.filter(
          (series) => series.dimensionId === joinedId
        )
        const availableSeries = find(capacitySeries, {
          metric: Metric.CapacityAvailable,
          dimension: drilldown.dimension,
        })
        const [availableValue] = availableSeries
          ? [availableSeries.data.reduce((sum, val) => sum + val, 0)]
          : [0]
        const availableLabel = isNumber(availableValue)
          ? abbreviateNumber(availableValue)
          : ''
        const demandSeries = find(capacitySeries, {
          metric: forecast
            ? Metric.CapacityDemandWithForecast
            : Metric.CapacityDemand,
          dimension: drilldown.dimension,
        })
        const [demandValue] = demandSeries
          ? [demandSeries.data.reduce((sum, val) => sum + val, 0)]
          : [0]
        const demandLabel = isNumber(demandValue)
          ? abbreviateNumber(demandValue)
          : ''
        const utilSeries = find(capacitySeries, {
          metric: forecast
            ? Metric.UtilizationWithForecast
            : Metric.Utilization,
          dimension: drilldown.dimension,
        })
        const [utilValue] =
          utilSeries && utilSeries.data.length > 0
            ? [
                utilSeries.data.reduce((sum, val) => sum + val, 0) /
                  utilSeries.data.length,
              ]
            : [0]
        const utilLabel = isNumber(utilValue) ? utilValue.toFixed(1) : ''

        const capability: IFlatCap | undefined = find(flatCapabilities, {
          id2: c.capId,
        })
        const site: ISite | undefined =
          c.drilldownId && drilldown.dimension === Dimension.CapabilitySite
            ? find(sites, { _id: c.drilldownId })
            : undefined
        const region: IRegion | undefined =
          c.drilldownId && drilldown.dimension === Dimension.CapabilityRegion
            ? find(regions, { _id: c.drilldownId })
            : undefined

        return {
          _id: joinedId,
          dimension: drilldown.dimension,
          name: capability ? capability.fullName : c.capId,
          image: capability && capability.image ? capability.image : '',
          description: site
            ? `${site.name} / ${getCountryCode(
                countryCodeMap,
                site.country
              )}, ${site.city}`
            : region
            ? region.name
            : '',
          ...topUtilsTile,
          available: availableLabel,
          availableValue: availableValue,
          demand: demandLabel,
          demandValue: demandValue,
          utilization: utilLabel,
          utilizationValue: utilValue,
          timeframe: availableSeries
            ? `${moment(availableSeries.start).format('YYYY MMM')} - ${moment(
                availableSeries.end
              ).format('YYYY MMM')}`
            : '',
        }
      }),
      (row) => {
        switch (drilldownTableSortType) {
          case Metric.CapacityDemand:
            return isNumber(row.demandValue) ? row.demandValue : -Infinity
          case Metric.CapacityAvailable:
            return isNumber(row.availableValue) ? row.availableValue : -Infinity
          case Metric.Utilization:
          default:
            return isNumber(row.utilizationValue)
              ? row.utilizationValue
              : -Infinity
        }
      }
    )

    if (drilldownTableSortDirection === 'desc') {
      sortedResult.reverse()
    }

    return sortedResult
    // "countFetchedDivisionCaps" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [
    drilldownTableSelection,
    selectedCapabilities,
    capsByDivision,
    countFetchedDivisionCaps,
    forecast,
    selectedSites,
    allSites,
    topUtilsTile,
    topUtilsSeries,
    countryCodeMap,
    drilldownTableSortType,
    drilldownTableSortDirection,
    rowsPerPageUtils,
  ])
  const getCurrentCapacity = getDebouncedFunction(
    chartStore?.getCurrentCapacity,
    chartStore,
    'getCurrentCapacity'
  )
  const getCurrentUtilization = getDebouncedFunction(
    chartStore?.getCurrentUtilization,
    chartStore,
    'getCurrentUtilization'
  )
  const getAvgUtilization = getDebouncedFunction(
    chartStore?.getAvgUtilization,
    chartStore,
    'getAvgUtilization'
  )
  const getUtilizationOverview = getDebouncedFunction(
    chartStore?.getUtilizationOverview,
    chartStore,
    'getUtilizationOverview'
  )
  const getTopSites = getDebouncedFunction(
    chartStore?.getTopSites,
    chartStore,
    'getTopSites'
  )
  const getTopUtils = getDebouncedFunction(
    chartStore?.getTopUtils,
    chartStore,
    'getTopUtils'
  )

  /**
   * P D F   C O N T E N T
   */
  const overviewChart = React.useRef(null)
  const pdfButtonDisabled = React.useMemo(
    () => chartStore?.anyLoading === true || !overviewChart.current,
    // "chartStore?.anyLoading" and "xxxx.current" is required to recognize if DOM elements are available
    // eslint-disable-next-line
    [chartStore?.anyLoading, overviewChart.current]
  )
  const getLabelByTimeframe = (timeframe: ITimeframe) => {
    const levelText = t(
      `capacity-management:timeframe-${timeframe.level.toLowerCase()}`
    )
    const spanText = t(
      `capacity-management:timeframe-${(timeframe.span || '').toLowerCase()}`
    )

    return t('capacity-management:timeframe-span-level', {
      span: spanText,
      level: levelText,
    })
  }

  const getPdfContent: () => Promise<Content> = async () => {
    const { current: overviewChartRef }: { current: any } = overviewChart

    const pdfContent: Content = [
      { text: 'HUEBNER • Global Report', style: 'header' },
      // TREND TILES
      { text: 'Overall Overview', style: 'subheader' },
      {
        style: 'table',
        table: {
          body: [
            trendTiles.map((t) => ({ text: t.title, style: 'th' })),
            trendTiles.map((t) => [
              t.label,
              {
                style: 'table',
                table: {
                  body: [
                    t.data.map((d) => ({ text: d.description, style: 'th' })),
                    t.data.map(
                      (d) =>
                        `${abbreviateNumber(parseFloat(d.value), 1)} ${d.unit}`
                    ),
                  ],
                },
              },
            ]),
          ],
        },
      },
      // OVERVIEW TILES
      overviewChartRef
        ? [
            { text: 'Utilization Overview', style: 'subheader' },
            {
              style: 'table',
              table: {
                body: [
                  [
                    { text: 'Drilldown', style: 'th' },
                    { text: 'Timeframe', style: 'th' },
                  ],
                  [
                    utilizationChartDrilldownItems[utilizationChartSelection]
                      .label,
                    getLabelByTimeframe(utilizationChartTimeframe),
                  ],
                ],
              },
            },
            ...getContentByChart(overviewChartRef),
          ]
        : [],
      // SITES TABLES
      [
        { text: 'Most Utilized Sites', style: 'subheader' },
        {
          style: 'table',
          table: {
            body: [
              [{ text: 'Timeframe', style: 'th' }],
              [getLabelByTimeframe(topSitesTimeframe)],
            ],
          },
        },
        {
          style: 'table',
          table: {
            body: [
              [
                { text: 'Sites', style: 'th' },
                { text: 'Utilization [%]', style: 'th' },
              ],
              ...topSitesDataRows.map((row) => [
                `${[row.name, row.description]
                  .filter((s) => s.length > 0)
                  .join(' / ')}`,
                row.utilization || 'n.a.',
              ]),
            ],
          },
        },
      ],
      // CAPABILITY TABLES
      [
        { text: 'Capability Utilization Overview', style: 'subheader' },
        {
          style: 'table',
          table: {
            body: [
              [{ text: 'Timeframe', style: 'th' }],
              [getLabelByTimeframe(topUtilsTimeframe)],
            ],
          },
        },
        {
          style: 'table',
          table: {
            body: [
              [
                {
                  text:
                    (
                      find(drilldownTableMenuItems, {
                        key: drilldownTableSelection,
                      }) || drilldownTableMenuItems[0]
                    ).label || '',
                  style: 'th',
                },
                { text: 'Demand [h]', style: 'th' },
                { text: 'Available [h]', style: 'th' },
                { text: 'Utilization [%]', style: 'th' },
              ],
              ...topUtilsDataRows.map((row) => [
                `${[row.name, row.description]
                  .filter((s) => s.length > 0)
                  .join(' / ')}`,
                row.demand || 'n.a.',
                row.available || 'n.a.',
                row.utilization || 'n.a.',
              ]),
            ],
          },
        },
      ],
    ]

    return pdfContent
  }

  /**
   * E F F E C T S
   */
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getCurrentCapacity) {
      getCurrentCapacity.cancel()
      getCurrentCapacity(parseForecast(params.forecast), filter)
    }
  }, [
    params.forecast,
    getCurrentCapacity,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getCurrentUtilization) {
      getCurrentUtilization.cancel()
      getCurrentUtilization(filter)
    }
  }, [getCurrentUtilization, generalFilters, siteFilter, forceRefreshTrigger])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getAvgUtilization) {
      getAvgUtilization.cancel()
      getAvgUtilization(filter)
    }
  }, [getAvgUtilization, generalFilters, siteFilter, forceRefreshTrigger])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    if (getUtilizationOverview) {
      getUtilizationOverview.cancel()
      getUtilizationOverview(
        utilizationChartDrilldownItems[utilizationChartSelection].key,
        utilizationChartTimeframe,
        parseForecast(params.forecast),
        filter
      )
    }
  }, [
    utilizationChartSelection,
    utilizationChartTimeframe,
    params.forecast,
    getUtilizationOverview,
    generalFilters,
    siteFilter,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)

    if (getTopSites) {
      getTopSites.cancel()
      getTopSites(topSitesTimeframe, parseForecast(params.forecast), filter)
    }
  }, [
    topSitesTimeframe,
    params.forecast,
    generalFilters,
    siteFilter,
    getTopSites,
    forceRefreshTrigger,
  ])
  React.useEffect(() => {
    const filter: IFilter[] = generalFilters
      .concat(siteFilter)
      .filter((f) => f.values.length > 0)
    const drilldown =
      find(drilldownTableMenuItems, { key: drilldownTableSelection }) ||
      drilldownTableMenuItems[0]
    const { dimension } = drilldown

    if (getTopUtils) {
      getTopUtils.cancel()
      getTopUtils(
        dimension,
        topUtilsTimeframe,
        parseForecast(params.forecast),
        filter
      )
    }
  }, [
    drilldownTableSelection,
    topUtilsTimeframe,
    params.forecast,
    generalFilters,
    siteFilter,
    getTopUtils,
    forceRefreshTrigger,
  ])

  /**
   * M E T H O D S
   */
  const onClearCache = async () => {
    const db = await indexedDB.open().catch((e) => console.error(e))

    if (db) {
      db.clearData()
        .then(() => {
          setForceRefreshTrigger(!forceRefreshTrigger)
          notify('capacity-dashboard:clear-cache-success', 'success')
        })
        .catch((e) => {
          console.error(e)
          notify('capacity-dashboard:clear-cache-error', 'error')
        })
    } else {
      notify('capacity-dashboard:clear-cache-error', 'error')
    }
  }

  const updateRoute = (config: IRouteParams) => {
    routerStore!.navigateTo(
      getDashboardRoute(Routes.CAPACITY_DASHBOARD_GLOBAL, params, config)
    )
  }

  const onSitesChange = (selectedSites: ISite[]) => {
    updateRoute({
      sites: selectedSites.map((site) => site._id),
    })
  }

  const onForecastChange = (isSelected: boolean) =>
    updateRoute({
      forecast: isSelected,
    })

  const onFilterChange = (filter: IFilter[]) =>
    updateRoute({
      filter: Object.fromEntries(
        filter.map((f) => [f.filterDimension as string, f.values])
      ),
    })

  const onTileConfigChange = (tileConfigChunk: { [key: string]: string[] }) =>
    updateRoute({
      tileConfig: assign(tileConfig, tileConfigChunk),
    })

  const onSaveConfiguration = async () => {
    const newConfig: P13nRouteConfig = {
      config: parseDashboardParams(params),
      timestamp: moment().format('YYYY-MM-DD'),
    }
    const userPatch = {
      p13n: {
        ...p13n,
        [P13nKey.GlobalConfig]: JSON.stringify(newConfig),
      },
    }
    const updatedUser: IUser = assign({}, currentUser, {
      sites: userSites.map((site) => site._id),
      ...userPatch,
    })

    await userService
      ?.updateUser(updatedUser, {
        successMsg: 'capacity-dashboard:configuration-saved',
      })
      .then(() => assign(currentUser, userPatch))
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filterbar
            pdfContent={getPdfContent}
            pdfDownloadDisabled={pdfButtonDisabled}
            forecastChecked={forecast}
            selectedSites={selectedSites}
            selectedCapabilities={selectedCapabilities}
            workcenterFilterDisabled
            timeframeEnabled={false}
            onClearCache={onClearCache}
            onSitesChange={onSitesChange}
            onForecastChange={onForecastChange}
            onFilterChange={onFilterChange}
            onSaveConfiguration={onSaveConfiguration}
          />
        </Grid>

        {trendTiles.map((trendTile, index) => (
          <Grid key={index} item xs={12} md={4}>
            <TrendTile
              title={trendTile.title}
              loading={trendTile.tile.loading}
              error={trendTile.tile.error}
              info={trendTile.label}
              metrics={trendTile.data}
              variant={trendTile.variant}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper className={styles['tile-chart']}>
            <Box p={2}>
              <Box height={52} pb={1} display="flex">
                <Typography className={styles['title']} color="textPrimary">
                  Utilization Overview
                </Typography>
                <Box display="flex" flexGrow={1}></Box>
                <FormControlLabel
                  className={styles['filterbar-item']}
                  control={
                    <Switch
                      color="primary"
                      checked={drilldownShowAllSites}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onTileConfigChange({
                          [TileConfigKey.GlobalDrilldownShowAllSites]: [
                            event.target.checked.toString(),
                          ],
                        })
                      }}
                    />
                  }
                  label={`Show ${utilizationChartDrilldownItems[
                    utilizationChartSelection
                  ].label.toLowerCase()} w/o capacities, too`}
                />
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ marginRight: '16px' }}>
                  <InputLabel id="t1-drilldown-select-label">
                    Drilldown
                  </InputLabel>
                  <Select
                    labelId="t1-drilldown-select-label"
                    id="t1-drilldown-select"
                    open={utilizationChartMenuOpen}
                    onOpen={() => {
                      setUtilizationChartMenuOpen(true)
                    }}
                    onClose={() => {
                      setUtilizationChartMenuOpen(false)
                    }}
                    value={utilizationChartSelection}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      onTileConfigChange({
                        [TileConfigKey.GlobalUtilizationChartSelection]: [
                          event.target.value as string,
                        ],
                      })
                      setUtilizationChartMenuOpen(false)
                    }}
                    label={'Drilldown'}>
                    {utilizationChartDrilldownItems.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={index}
                        disabled={utilizationChartSelection === index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TimeframeSelect
                  selectedKey={utilizationChartTimeframe.key || 0}
                  onTimeframeChange={(
                    event: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    onTileConfigChange({
                      [TileConfigKey.GlobalUtilizationChartTimeframe]: [
                        event.target.value as string,
                      ],
                    })
                  }}
                />
              </Box>
              <Box
                height={304}
                display="flex"
                alignItems="center"
                justifyContent="center">
                {utilOverviewTile.loading && <CircularProgress size={48} />}
                {utilOverviewTile.error && (
                  <ErrorOutlineIcon fontSize="large" color="error" />
                )}
                {utilOverviewTile.error === false &&
                  !utilOverviewTile.loading &&
                  utilOverviewTile.loaded && (
                    <Chart
                      chartInstance={(chart) => {
                        overviewChart.current = chart
                      }}
                      labels={utilOverviewTile.labels}
                      series={utilOverviewDataset}
                      horizontalLines={[horizintalLine]}
                      yAxesLabels={['Utilization [%]']}
                      yAxesConfig={[
                        { min: 0, max: 140, grid: true, display: true },
                        { display: false },
                      ]}
                    />
                  )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={styles['tile-chart']}>
            <Box pt={2} px={2}>
              <Box height={52} pb={1} display="flex">
                <Typography className={styles['title']} color="textPrimary">
                  Most Utilized Sites
                </Typography>
                <Box mt={0.25} mb={1} mx={1}>
                  <Tooltip
                    title={`The utilization metric is displayed red for values above 100% or below 70% and orange if the value is between 70% and 90%.`}
                    interactive>
                    <IconButton aria-label="legend information" size="small">
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" flexGrow={1}></Box>
                <TimeframeSelect
                  showTimelevel={false}
                  customPreset={OneSpanTimeframes}
                  selectedKey={topSitesTimeframe.key || 0}
                  onTimeframeChange={(
                    event: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    onTileConfigChange({
                      [TileConfigKey.GlobalTopSitesTimeframe]: [
                        event.target.value as string,
                      ],
                    })
                    setPageSites(0)
                  }}
                />
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Box height={30} display="flex" alignItems="center">
                          Sites
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sortDirection={topSitesTableSortDirection}>
                        <TableSortLabel
                          active={true}
                          direction={topSitesTableSortDirection}
                          onClick={() => {
                            onTileConfigChange({
                              [TileConfigKey.GlobalTopSitesTableSortDirection]: [
                                topSitesTableSortDirection === 'desc'
                                  ? 'asc'
                                  : 'desc',
                              ],
                            })
                          }}>
                          Utilization [%]
                        </TableSortLabel>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topSitesDataRows
                      .slice(
                        pageSites * rowsPerPageSites,
                        pageSites * rowsPerPageSites + rowsPerPageSites
                      )
                      .map((row) => (
                        <StyledTableRow key={row._id}>
                          <StyledTableCell component="th" scope="row">
                            <Tooltip
                              title={`${[row.name, row.description]
                                .filter((s) => s.length > 0)
                                .join(' / ')}`}>
                              <Box
                                width={150}
                                display="flex"
                                flexDirection="column"
                                flexShrink={1}>
                                <Typography variant="subtitle2" noWrap>
                                  {row.name}
                                </Typography>
                                <Typography variant="caption" noWrap>
                                  {row.description}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className={getStateClassByUtilization(
                              row.utilizationValue
                            )}
                            align="left">
                            {row.loading && <CircularProgress size={32} />}
                            {row.error && (
                              <ErrorOutlineIcon
                                fontSize="default"
                                color="error"
                              />
                            )}
                            {row.error === false && !row.loading && row.loaded && (
                              <Tooltip
                                title={
                                  row.utilization
                                    ? row.timeframe
                                    : 'no capacities available'
                                }>
                                <span>{row.utilization || 'n.a.'}</span>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10]}
                component="div"
                count={topSitesDataRows.length}
                rowsPerPage={rowsPerPageSites}
                page={pageSites}
                onChangePage={(event: unknown, newPage: number) => {
                  setPageSites(newPage)
                }}
                onChangeRowsPerPage={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  onTileConfigChange({
                    [TileConfigKey.GlobalRowsPerPageSites]: [
                      event.target.value as string,
                    ],
                  })
                  setPageSites(0)
                }}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper className={styles['tile-chart']}>
            <Box pt={2} px={2}>
              <Box height={52} pb={1} display="flex">
                <Typography className={styles['title']} color="textPrimary">
                  Capability Utilization Overview
                </Typography>
                <Box mt={0.25} mb={1} mx={1}>
                  <Tooltip
                    title={`The utilization metric is displayed red for values above 100% or below 70% and orange if the value is between 70% and 90%.`}
                    interactive>
                    <IconButton aria-label="legend information" size="small">
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" flexGrow={1}></Box>
                <TimeframeSelect
                  showTimelevel={false}
                  customPreset={OneSpanTimeframes}
                  selectedKey={topUtilsTimeframe.key || 0}
                  onTimeframeChange={(
                    event: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    onTileConfigChange({
                      [TileConfigKey.GlobalTopUtilsTimeframe]: [
                        event.target.value as string,
                      ],
                    })
                    setPageUtils(0)
                  }}
                />
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Button
                          className={styles['filterbar-item']}
                          variant="text"
                          size="small"
                          endIcon={<ArrowDropDownIcon />}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            setDrilldownTableMenuAnchorEl(event.currentTarget)
                          }}>
                          {
                            (
                              find(drilldownTableMenuItems, {
                                key: drilldownTableSelection,
                              }) || drilldownTableMenuItems[0]
                            ).label
                          }
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={drilldownTableMenuAnchorEl}
                          keepMounted
                          open={Boolean(drilldownTableMenuAnchorEl)}
                          onClose={() => {
                            setDrilldownTableMenuAnchorEl(null)
                          }}>
                          {drilldownTableMenuItems.map(
                            (item: any, index: number) => (
                              <MenuItem
                                key={index}
                                disabled={drilldownTableSelection === item.key}
                                onClick={() => {
                                  onTileConfigChange({
                                    [TileConfigKey.GlobalDrilldownTableSelection]: [
                                      item.key,
                                    ],
                                  })
                                  setPageUtils(0)
                                  setDrilldownTableMenuAnchorEl(null)
                                }}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Menu>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sortDirection={
                          drilldownTableSortType === Metric.CapacityDemand
                            ? drilldownTableSortDirection
                            : false
                        }>
                        <TableSortLabel
                          active={
                            drilldownTableSortType === Metric.CapacityDemand
                          }
                          direction={drilldownTableSortDirection}
                          onClick={() => {
                            onTileConfigChange({
                              [TileConfigKey.GlobalDrilldownTableSortType]: [
                                Metric.CapacityDemand,
                              ],
                              [TileConfigKey.GlobalDrilldownTableSortDirection]: [
                                drilldownTableSortDirection === 'asc' ||
                                drilldownTableSortType !== Metric.CapacityDemand
                                  ? 'desc'
                                  : 'asc',
                              ],
                            })
                          }}>
                          Demand [h]
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sortDirection={
                          drilldownTableSortType === Metric.CapacityAvailable
                            ? drilldownTableSortDirection
                            : false
                        }>
                        <TableSortLabel
                          active={
                            drilldownTableSortType === Metric.CapacityAvailable
                          }
                          direction={drilldownTableSortDirection}
                          onClick={() => {
                            onTileConfigChange({
                              [TileConfigKey.GlobalDrilldownTableSortType]: [
                                Metric.CapacityAvailable,
                              ],
                              [TileConfigKey.GlobalDrilldownTableSortDirection]: [
                                drilldownTableSortDirection === 'asc' ||
                                drilldownTableSortType !==
                                  Metric.CapacityAvailable
                                  ? 'desc'
                                  : 'asc',
                              ],
                            })
                          }}>
                          Available [h]
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sortDirection={
                          drilldownTableSortType === Metric.Utilization
                            ? drilldownTableSortDirection
                            : false
                        }>
                        <TableSortLabel
                          active={drilldownTableSortType === Metric.Utilization}
                          direction={drilldownTableSortDirection}
                          onClick={() => {
                            onTileConfigChange({
                              [TileConfigKey.GlobalDrilldownTableSortType]: [
                                Metric.Utilization,
                              ],
                              [TileConfigKey.GlobalDrilldownTableSortDirection]: [
                                drilldownTableSortDirection === 'asc' ||
                                drilldownTableSortType !== Metric.Utilization
                                  ? 'desc'
                                  : 'asc',
                              ],
                            })
                          }}>
                          Utilization [%]
                        </TableSortLabel>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topUtilsDataRows
                      .slice(
                        pageUtils * rowsPerPageUtils,
                        pageUtils * rowsPerPageUtils + rowsPerPageUtils
                      )
                      .map((row) => (
                        <StyledTableRow key={row._id}>
                          <StyledTableCell component="th" scope="row">
                            {row.loading && <CircularProgress size={32} />}
                            {row.error && (
                              <ErrorOutlineIcon
                                fontSize="default"
                                color="error"
                              />
                            )}
                            {row.error === false && !row.loading && row.loaded && (
                              <Tooltip
                                title={`${[row.name, row.description]
                                  .filter((s) => s.length > 0)
                                  .join(' / ')}`}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexShrink={1}>
                                  {row.image && (
                                    <Box p={1}>
                                      <Avatar
                                        src={row.image}
                                        className={styles['icon-small']}
                                      />
                                      {/* <img
                                        src={row.image}
                                        height="20px"
                                        style={{ marginRight: '5px' }}
                                      /> */}
                                    </Box>
                                  )}
                                  <Box
                                    height={40}
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                    flexShrink={1}>
                                    <Typography variant="subtitle2" noWrap>
                                      {row.name}
                                    </Typography>
                                    <Typography variant="caption" noWrap>
                                      {row.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className="info" align="left">
                            {row.loading && <CircularProgress size={32} />}
                            {row.error && (
                              <ErrorOutlineIcon
                                fontSize="default"
                                color="error"
                              />
                            )}
                            {row.error === false && !row.loading && row.loaded && (
                              <Tooltip
                                title={
                                  row.demand
                                    ? row.timeframe
                                    : 'no capacities available'
                                }>
                                <span>{row.demand || 'n.a.'}</span>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className="info" align="left">
                            {row.loading && <CircularProgress size={32} />}
                            {row.error && (
                              <ErrorOutlineIcon
                                fontSize="default"
                                color="error"
                              />
                            )}
                            {row.error === false && !row.loading && row.loaded && (
                              <Tooltip
                                title={
                                  row.available
                                    ? row.timeframe
                                    : 'no capacities available'
                                }>
                                <span>{row.available || 'n.a.'}</span>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            className={getStateClassByUtilization(
                              row.utilizationValue
                            )}
                            align="left">
                            {row.loading && <CircularProgress size={32} />}
                            {row.error && (
                              <ErrorOutlineIcon
                                fontSize="default"
                                color="error"
                              />
                            )}
                            {row.error === false && !row.loading && row.loaded && (
                              <Tooltip
                                title={
                                  row.utilization
                                    ? row.timeframe
                                    : 'no capacities available'
                                }>
                                <span>{row.utilization || 'n.a.'}</span>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 5, 10]}
                component="div"
                count={topUtilsDataRows.length}
                rowsPerPage={rowsPerPageUtils}
                page={pageUtils}
                onChangePage={(event: unknown, newPage: number) => {
                  setPageUtils(newPage)
                }}
                onChangeRowsPerPage={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  onTileConfigChange({
                    [TileConfigKey.GlobalRowsPerPageUtils]: [
                      event.target.value as string,
                    ],
                  })
                  setPageUtils(0)
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default inject(
  Stores.routerStore,
  Stores.capacityDashboardStore,
  Stores.authenticationStore,
  Stores.userManagementStore,
  Stores.capabilityStore,
  Stores.siteStore
)(observer(GlobalDashboard))

const drilldownTableMenuItems = [
  {
    label: 'Capability',
    key: 'capability',
    dimension: Dimension.Capability,
  },
  {
    label: 'Capability by Region',
    key: 'capability-region',
    dimension: Dimension.CapabilityRegion,
  },
  {
    label: 'Capability by Site',
    key: 'capability-site',
    dimension: Dimension.CapabilitySite,
  },
]

const utilizationChartDrilldownItems = [
  {
    label: 'Sites',
    key: Dimension.Site,
  },
  {
    label: 'Regions',
    key: Dimension.Region,
  },
  {
    label: 'Divisions',
    key: Dimension.Division,
  },
]

const horizintalLine = {
  value: 100,
  label: '100%',
  color: '#51BF30',
  yAxisRight: false,
  yAxisLabel: true,
}
