import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  FormControl,
  Tooltip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TextDisplayEdit from 'components/TextDisplayEdit'
import ImageUploadService from 'services/ImageUpload'
import { Capability, getGlobalCapability } from 'interfaces/Capabilities'
import { ConfirmButton } from 'styles/createMyTheme'
import TrashIcon from 'assets/icons/trash.svg'

import styles from 'styles/dialog.module.scss'

interface MaintainCapabilitiesDialogProps {
  open: boolean
  onClose: () => void
  initialCapability: Capability | undefined
  registerCapability: (capability: Capability) => void
  updateCapability: (
    capability: Capability,
    oldName?: string,
    oldDescription?: string
  ) => void
  deleteCapability: (capability: Capability) => void
  forceDeleteCapability: (Capability: Capability) => void
  createNewCapability: boolean
}

interface Validation {
  name: boolean
}

const CapabilityManagementDialog: React.FC<MaintainCapabilitiesDialogProps> = ({
  onClose,
  open,
  initialCapability,
  createNewCapability,
  registerCapability,
  updateCapability,
  deleteCapability,
  forceDeleteCapability,
}) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [capAlreadyExists, setCapAlreadyExists] = useState(false)
  const [capability, setCapability] = useState<Capability>(
    createNewCapability || !initialCapability
      ? getGlobalCapability()
      : { ...initialCapability }
  )
  const [validation, setValidation] = useState<Validation>({ name: false })
  const [confirmation, setConfirmation] = useState<boolean>(false)

  const getImage = (image: string) => {
    capability.image = image
  }

  const handleValueChange = (field: 'name' | 'description', value: any) => {
    const siteCopy = { ...capability }
    siteCopy[field] = value
    setCapability(siteCopy)
    if (field === 'name') {
      if (sameNameCapAlreadyExists(value)) setCapAlreadyExists(true)
      else setCapAlreadyExists(false)
    }
  }

  useEffect(() => {
    setCapability(
      createNewCapability || !initialCapability
        ? getGlobalCapability()
        : { ...initialCapability }
    )
    createNewCapability ? setEditMode(true) : setEditMode(false)
    setConfirmation(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const resetState = () => {
    setEditMode(false)
    setDeleteMode(false)
    setValidation({ name: false })
    setCapAlreadyExists(false)
    onClose()
  }

  const handleClose = () => {
    resetState()
  }

  const registerNewCapability = async () => {
    if (validData()) {
      if (initialCapability) {
        capability.level = initialCapability.level + 1
        if (initialCapability.level === 0)
          capability.level0Id = initialCapability._id!
        else capability.level0Id = initialCapability.level0Id
        await updateCapability(capability)
      } else {
        registerCapability(capability)
      }
      handleClose()
    }
  }

  const sameNameCapAlreadyExists = (newCapName: string) => {
    if (initialCapability) {
      const existingCapNames = initialCapability.children.map((cap) => cap.name)
      return existingCapNames.includes(newCapName)
    } else return false
  }

  const updateThisCapability = async () => {
    if (validData()) {
      updateCapability(capability)
      handleClose()
    }
  }

  const capabilityDeletion = async () => {
    if (validData()) {
      const response: string = (await deleteCapability(capability)) + ''
      if (response.match('400')) {
        setConfirmation(true)
      } else {
        handleClose()
      }
    }
  }

  const validData = () => {
    if (capability.name !== '') return true
    else setValidation({ name: true })
    return false
  }

  const secondDeletion = async () => {
    await forceDeleteCapability(capability)
    handleClose()
  }

  const renderInfo = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography>{t('capabilities:name')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextDisplayEdit
              fullWidth
              error={validation.name || capAlreadyExists}
              value={capability.name ? capability.name : ''}
              onChange={(event: any) =>
                handleValueChange('name', event.target.value)
              }
              edit={editMode}
            />
            {capAlreadyExists && editMode && (
              <Typography color="error">
                {t('capabilities:capNameAlreadyExists')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>{t('capabilities:description')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextDisplayEdit
              fullWidth
              value={capability.description ? capability.description : ''}
              onChange={(event: any) =>
                handleValueChange('description', event.target.value)
              }
              multiline
              edit={editMode}
            />
          </Grid>
          {!initialCapability ||
          (editMode &&
            initialCapability.level === 0 &&
            !createNewCapability) ? (
            <React.Fragment>
              <Grid item xs={4}>
                <Typography>{t('capabilities:iconUpload')}</Typography>
              </Grid>
              <ImageUploadService
                getImage={getImage}
                image={initialCapability && initialCapability.image}
              />
            </React.Fragment>
          ) : !editMode && initialCapability.level === 0 ? (
            <React.Fragment>
              <Grid item xs={4}>
                <Typography>{t('capabilities:icon')}</Typography>
              </Grid>
              <img src={initialCapability.image} alt="" height="35px" />
            </React.Fragment>
          ) : null}
          {!validation && (
            <Grid item xs={12}>
              <Typography color={'error'}>{t('sites:allFields')}</Typography>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  const getConfirmButton = () => {
    return (
      deleteMode && (
        <Box style={{ marginRight: '10px' }}>
          <ConfirmButton
            variant="outlined"
            color="secondary"
            onClick={() => capabilityDeletion()}>
            {t('confirm')}
          </ConfirmButton>
        </Box>
      )
    )
  }

  const getDialogActions = () => {
    if (editMode) {
      return (
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button variant="contained" onClick={handleClose}>
              {t('close')}
            </Button>
          </Box>
          {getConfirmButton()}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createNewCapability
                ? registerNewCapability()
                : updateThisCapability()
            }}
            disabled={capAlreadyExists}>
            {createNewCapability ? t('create') : t('save')}
          </Button>
        </div>
      )
    } else {
      return (
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button variant="contained" onClick={handleClose}>
              {t('close')}
            </Button>
          </Box>
          {getConfirmButton()}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditMode(true)
              setDeleteMode(false)
            }}>
            {t('edit')}
          </Button>
        </div>
      )
    }
  }

  const getNewCapabilityLevel = () => {
    return initialCapability ? initialCapability.level + 2 : 1
  }

  const getLenghtOfChildrens = () => {
    const testChildrens = () => {
      return (
        initialCapability &&
        (initialCapability.children.length === undefined ||
          initialCapability.children.length > 0)
      )
    }

    return (
      <div
        onClick={() => {
          !testChildrens() && setDeleteMode(true)
        }}
        style={{
          position: 'relative',
          marginTop: '10px',
          cursor: 'pointer',
        }}>
        <Tooltip
          title={`${
            testChildrens() ? t('capabilities:deleteChildrenFirst') : ''
          }`}>
          <img
            src={TrashIcon}
            alt="Delete"
            style={{
              width: 50,
              height: 40,
              filter: testChildrens() ? 'grayscale(100%) opacity(30%)' : '',
            }}
          />
        </Tooltip>
      </div>
    )
  }

  return !confirmation ? (
    <Dialog
      open={open}
      PaperProps={{ style: { borderRadius: '10px' } }}
      onBackdropClick={handleClose}
      maxWidth="sm">
      <Box display="flex" justifyContent="space-between">
        <DialogTitle className={styles['dialog-title']}>
          {createNewCapability
            ? t('capabilities:addNewCapability') + ' ' + getNewCapabilityLevel()
            : t('capabilities:capabilityInfo')}
        </DialogTitle>
        {!createNewCapability && !deleteMode && getLenghtOfChildrens()}
      </Box>
      <DialogContent>
        <Box minWidth="400px">{renderInfo()}</Box>
      </DialogContent>
      <DialogActions>{getDialogActions()}</DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={confirmation}
      PaperProps={{ style: { borderRadius: '10px' } }}
      onBackdropClick={handleClose}
      maxWidth="lg">
      <DialogTitle className={styles['dialog-title-deletion']}>
        {t('capabilities:confirmToDelete')}
      </DialogTitle>
      <DialogContent>
        <Box minWidth="400px">
          <FormControl>
            <Grid item xs={12}>
              <Typography>
                {`${t('capabilities:theCapability')} "${capability.name}" ${t(
                  'capabilities:isUsing1'
                )}`}
              </Typography>
              <Typography>
                {`${t('capabilities:isUsing2')} ${t(
                  'capabilities:deleteGlobally'
                )}`}
              </Typography>
            </Grid>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button variant="contained" onClick={handleClose}>
              {t('close')}
            </Button>
          </Box>
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              color="secondary"
              onClick={() => secondDeletion()}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CapabilityManagementDialog
