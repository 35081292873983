import React from 'react'
import { Typography, Avatar, Box } from '@material-ui/core'
import Map from './Map'
import Filter from 'components/Filter/Filter'
import { useTranslation } from 'react-i18next'
import AuthenticationStore from 'stores/AuthenticationStore'
import { Stores } from 'stores/stores'
import { inject, observer } from 'mobx-react'
import { Autocomplete } from '@material-ui/lab'
import { ISite } from '../interfaces/Site'
import MapStore from '../stores/MapStore'
import DivisionStore from '../stores/DivisionStore'
import { ILocalCapability } from '../interfaces/Capabilities'
import FilterTextField from '../components/Filter/FilterTextField'
import { getDivision, IDivision } from '../interfaces/Division'
import SiteStore from 'stores/SiteStore'

import styles from 'styles/map.module.scss'

interface MapProps {
  authenticationStore: AuthenticationStore
  mapStore: MapStore
  divisionStore: DivisionStore
  siteStore: SiteStore
}

interface IDivisionOption {
  title: string
  division: IDivision
  color: string
}

const initialDivisionOption: IDivisionOption = {
  title: '',
  division: getDivision(),
  color: '',
}

interface ICapabilityOption {
  title: string
  value: string
}

const initialDivisionListOption: IDivisionOption[] = []
const initialCapabilityOptions: ICapabilityOption[] = []

const SiteMap: React.FC<MapProps> = inject(
  Stores.divisionStore,
  Stores.mapStore,
  Stores.authenticationStore,
  Stores.siteStore
)(
  observer((props: MapProps) => {
    const { divisionStore, mapStore, authenticationStore, siteStore } = props
    const [filterOpen, setFilterOpen] = React.useState<boolean>(false)

    const [selectedProcesses, setSelectedProcesses] = React.useState<
      Array<ICapabilityOption>
    >(initialCapabilityOptions)
    const [selectedMethods, setSelectedMethods] = React.useState<
      Array<ICapabilityOption>
    >(initialCapabilityOptions)
    const [selectedDivision, setSelectedDivision] = React.useState<
      IDivisionOption
    >(initialDivisionOption)

    const [processOptionList, setProcessOptionList] = React.useState<
      Array<ICapabilityOption>
    >(initialCapabilityOptions)
    const [methodOptionList, setMethodOptionList] = React.useState<
      Array<ICapabilityOption>
    >(initialCapabilityOptions)
    const [divisionOptionList, setDivisionOptionList] = React.useState<
      Array<IDivisionOption>
    >(initialDivisionListOption)

    const { t } = useTranslation()

    const drawerWidthClosed = 73

    const resetProcessesAndMethods = () => {
      setSelectedProcesses(initialCapabilityOptions)
      setSelectedMethods(initialCapabilityOptions)
    }
    const resetData = () => {
      resetProcessesAndMethods()
      setSelectedDivision(initialDivisionOption)
      mapStore.resetFilter()
      divisionStore.setSelectedDivision()
    }

    React.useEffect(() => {
      props.siteStore!.setAllSites()
      // eslint-disable-next-line
    }, [])
    React.useEffect(() => {
      divisionOptionHandler()
      // eslint-disable-next-line
    }, [siteStore.allSites])

    const divisionOptionHandler = () => {
      const optionList: IDivisionOption[] = []
      siteStore.allSites.forEach((currentUserSite: ISite) => {
        let foundName: boolean = false
        optionList.forEach(
          (division: IDivisionOption) =>
            division.title === currentUserSite.division.name &&
            (foundName = true)
        )
        !foundName &&
          optionList.push({
            title: currentUserSite.division.name,
            division: currentUserSite.division,
            color: currentUserSite.division.colorCode,
          })
        foundName = false
      })
      setDivisionOptionList(optionList)
    }

    const processOptionHandler = (divisionOption: IDivisionOption) => {
      let optionList: ICapabilityOption[] = []
      if (divisionOption) {
        const matchingDivisionSites = authenticationStore.currentUser.sites.filter(
          (currentUserSite: ISite) =>
            divisionOption.title === currentUserSite.division.name
        )
        const processList = matchingDivisionSites
          .map((site: ISite) =>
            site.capabilities.map((process: ILocalCapability) => process.name)
          )
          .flat()
        const uniqueProcessList = Array.from(new Set(processList))
        optionList = uniqueProcessList.map((process: string) => ({
          title: process,
          value: process,
        }))
      }
      setProcessOptionList(optionList)
    }

    const methodOptionHandler = (
      processesOptions: Array<ICapabilityOption>
    ) => {
      let optionList: ICapabilityOption[] = []
      if (processesOptions.length > 0) {
        const matchingDivisionSites = authenticationStore.currentUser.sites.filter(
          (currentUserSite: ISite) =>
            selectedDivision.title === currentUserSite.division.name
        )
        const selectedProcessesValueList: string[] = processesOptions.map(
          (selectedProcess: any) => selectedProcess.value
        )
        const matchingProcessSites = matchingDivisionSites
          .map((site: ISite) =>
            site.capabilities.filter((process: ILocalCapability) =>
              selectedProcessesValueList.includes(process.name)
            )
          )
          .flat()
        const methodList = matchingProcessSites
          .map((process: ILocalCapability) =>
            process.children.map((method: ILocalCapability) => method.name)
          )
          .flat()
        const uniqueMethodList = Array.from(new Set(methodList))
        optionList = uniqueMethodList.map((method: string) => ({
          title: method,
          value: method,
        }))
      }
      setMethodOptionList(optionList)
    }

    const handleDivisionSelect = (divisionOption: IDivisionOption) => {
      setSelectedDivision(divisionOption)
      if (divisionOption) {
        divisionStore.setSelectedDivision(divisionOption.division)
        mapStore.filterSites(divisionOption.division)
      } else {
        mapStore.resetFilter()
        divisionStore.setSelectedDivision()
      }
      processOptionHandler(divisionOption)
      resetProcessesAndMethods()
    }

    const handleProcessSelect = (processOptions: ICapabilityOption[]) => {
      setSelectedProcesses(processOptions)
      const processes: string[] = processOptions.map(
        (processOption: ICapabilityOption) => processOption.title
      )
      if (processOptions.length > 0)
        mapStore.filterSites(selectedDivision.division, processes)
      else {
        mapStore.filterSites(selectedDivision.division)
        resetProcessesAndMethods()
      }
      methodOptionHandler(processOptions)
    }

    const handleMethodSelect = (methodOptions: ICapabilityOption[]) => {
      setSelectedMethods(methodOptions)
      const processes: string[] = selectedProcesses.map(
        (processOption: ICapabilityOption) => processOption.title
      )
      const methods: string[] = methodOptions.map(
        (methodOption: ICapabilityOption) => methodOption.title
      )
      if (methodOptions.length > 0)
        mapStore.filterSites(selectedDivision.division, processes, methods)
      else {
        mapStore.filterSites(selectedDivision.division, processes)
        setSelectedMethods(initialCapabilityOptions)
      }
    }

    return (
      <Box p={4}>
        <div className={styles['filter-map-container']}>
          <Filter open={filterOpen} setDrawer={setFilterOpen}>
            <div className={styles['reset-data']} onClick={resetData}>
              <Typography>{t('filter:reset')}</Typography>
            </div>
            <div className={styles['filter-input-wrapper']}>
              <Autocomplete
                id={`site-division-select`}
                options={divisionOptionList}
                getOptionLabel={(option: IDivisionOption) => option.title}
                value={selectedDivision}
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  handleDivisionSelect(value)
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Avatar
                      style={{
                        backgroundColor: option.color,
                        width: '20px',
                        height: '20px',
                        marginRight: '20px',
                      }}>
                      {' '}
                    </Avatar>
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <FilterTextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label={t('filter:division')}
                    placeholder={t('profile:siteFilter')}
                    InputProps={{ ...params.InputProps }}
                    InputLabelProps={{ ...params.InputLabelProps }}
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                multiple
                id="site-level-one-select"
                options={processOptionList}
                value={selectedProcesses}
                getOptionLabel={(option: ICapabilityOption) => option.title}
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  handleProcessSelect(value)
                }
                disableCloseOnSelect
                renderInput={(params: any) => (
                  <FilterTextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label={t('filter:selectedProcesses')}
                    InputProps={{ ...params.InputProps }}
                    InputLabelProps={{ ...params.InputLabelProps }}
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                multiple
                id={`site-level-two-select`}
                options={methodOptionList}
                getOptionLabel={(option: ICapabilityOption) => option.title}
                value={selectedMethods}
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  handleMethodSelect(value)
                }
                disableCloseOnSelect
                renderInput={(params) => (
                  <FilterTextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label={t('filter:selectedMethods')}
                    InputProps={{ ...params.InputProps }}
                    InputLabelProps={{ ...params.InputLabelProps }}
                    fullWidth
                  />
                )}
              />
            </div>
          </Filter>
          <div
            style={{
              marginLeft: drawerWidthClosed,
              marginTop: '-64px',
              maxHeight: '300px',
            }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">{t('map:title')}</Typography>
              <Box display="flex" alignItems="center" overflow="auto">
                {divisionOptionList.map((div) => (
                  <React.Fragment key={div.title}>
                    <Box
                      borderRadius="20px"
                      minHeight="20px"
                      minWidth="20px"
                      marginRight="5px"
                      marginLeft="15px"
                      marginBottom="5px"
                      style={{ backgroundColor: div.color }}
                    />
                    {div.title}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            <Map />
          </div>
        </div>
      </Box>
    )
  })
)

export default SiteMap
