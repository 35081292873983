import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { DoneAll, FiberManualRecord } from '@material-ui/icons'
import styles from 'styles/capacitiesManagement.module.scss'
import stylesSap from 'styles/lastImport.module.scss'
import { ISAPImportReport } from 'interfaces/SAPImportReport'
import OptionsSvgEnabled from 'assets/icons/option-coq-darkgrey.svg'
import OptionsSvgDisabled from 'assets/icons/option-coq-lightgrey.svg'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { Grid, Button, Menu, MenuItem } from '@material-ui/core'
import { TimeType, CapacityType } from 'interfaces/Capacity'
import ChangeMonthWeekViewDialog from 'components/ChangeMonthWeekViewDialog'
import { ISite } from 'interfaces/Site'

interface Props {
  tableStatus?: string
  sapReport?: ISAPImportReport | undefined
  viewFormer?: boolean
  isWorkDays?: boolean
  hasOptionButton?: boolean
  disableOptionButton?: boolean
  capacityType?: CapacityType
  timeType?: TimeType
  currentSite?: ISite
  onUpdateSite?: () => void
}

const Notifications: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    tableStatus = 'success',
    sapReport,
    viewFormer,
    isWorkDays,
    hasOptionButton,
    disableOptionButton,
    capacityType,
    timeType,
    currentSite,
    onUpdateSite,
  } = props
  const [message, setMessage] = React.useState('')
  const [optionsAnchor, setOptionsAnchor] = React.useState<any>(null)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}>
        <Grid item>
          {sapReport && !viewFormer && renderLastImport(sapReport, isWorkDays!)}
        </Grid>
        <Grid item>{hasOptionButton && renderOptionButton()}</Grid>
      </Grid>
      <ChangeMonthWeekViewDialog
        onClose={() => setModalClose()}
        open={isModalOpen}
        capacityType={capacityType}
        siteId={currentSite?._id}
        timeType={timeType}
        currentSite={currentSite}
        onUpdateSite={onUpdateSite}
      />
    </React.Fragment>
  )

  function getMessageColor(status: string) {
    switch (status) {
      case 'success':
        return {
          backgroundColor: '#edf8ea',
          color: '#41882e',
        }
    }
  }

  function onSetNewConfigClick() {
    // console.log('capacityType', capacityType)

    setIsModalOpen(true)
  }

  function setModalClose() {
    setIsModalOpen(false)
  }

  function renderOptionButton() {
    return (
      <Box
        className={`${styles['filter-options']} ${styles['filterbar-item']}`}
        display="flex"
        alignItems="center">
        <Button
          className={styles['controlbar-data-button']}
          disabled={disableOptionButton}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setOptionsAnchor(event.currentTarget)
          }>
          <img
            src={disableOptionButton ? OptionsSvgDisabled : OptionsSvgEnabled}
            alt="option_button"
            width={'24px'}
          />
        </Button>
        <Menu
          id="options-menu"
          anchorEl={optionsAnchor}
          keepMounted
          open={Boolean(optionsAnchor)}
          onClose={() => setOptionsAnchor(null)}>
          <MenuItem
            onClick={() => {
              onSetNewConfigClick()
              setOptionsAnchor(null)
            }}>
            {timeType && timeType === TimeType.month
              ? t('capacity-management:options-month-to-week')
              : t('capacity-management:options-week-to-month')}
          </MenuItem>
        </Menu>
      </Box>
    )
  }

  function renderLastImport(sapReport: ISAPImportReport, isWorkDays: boolean) {
    let text = t(`sapImportReport:lastImportNone`)
    let synced = false
    if (sapReport.timestamp !== 0) {
      const wasEnabledLast =
        sapReport.toggleConfig.includes('workcenters') ||
        sapReport.toggleConfig.includes('hierarchy')

      if (!wasEnabledLast) {
        const subject = isWorkDays
          ? t(`sapImportReport:workdays`)
          : t(`sapImportReport:capacities`)
        text = t('sapImportReport:lastImportDisabled')
        text = text.replace(`%0%`, subject)
      } else {
        const now = moment()
        const last = moment(sapReport.timestamp)
        synced = true

        let duration = moment.duration(now.diff(last)).asMonths()
        text = t('sapImportReport:lastImportMonths')
        if (duration < 1) {
          duration = moment.duration(now.diff(last)).asDays()
          text = t('sapImportReport:lastImportDays')
          if (duration < 1) {
            duration = moment.duration(now.diff(last)).asHours()
            text = t('sapImportReport:lastImportHours')
          }
        }
        text = text.replace('%0%', duration.toFixed())
      }
    }

    return (
      <React.Fragment>
        <span>
          <span className={stylesSap['sync-badge']}>
            <FiberManualRecord
              style={{ color: synced ? '#51bf30' : '#E0E0E0', height: '15px' }}
            />
          </span>
          <span className={stylesSap['sync-text']}>{text}</span>
        </span>
      </React.Fragment>
    )
  }
}

export default Notifications
