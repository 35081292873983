import React from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Grid,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import { IWorkcenter } from '../../interfaces/Workcenter'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ReferenceObject } from 'popper.js'
import { WorkcentersManagement } from '../../pages/MasterData/WorkcenterManagement'

interface WorkCenterPopperProps {
  anchorElem: ReferenceObject
  close: () => void
  workcenter: IWorkcenter
  siteFlatCaps?: any
}

const WorkCenterPopper: React.FC<any> = (props: WorkCenterPopperProps) => {
  const { t } = useTranslation()
  return (
    <ClickAwayListener onClickAway={props.close}>
      <Dialog
        open={Boolean(props.anchorElem)}
        maxWidth={'md'}
        onBackdropClick={props.close}
        style={{ minWidth: '350px' }}>
        <Paper elevation={2} style={{ width: '100%', height: '100%' }}>
          <Grid
            container
            direction={'column'}
            style={{ padding: '15px', width: '100%', height: '100%' }}
            justify={'space-evenly'}>
            <Typography variant={'h6'}>
              {`${props.workcenter.number} · ${props.workcenter.name}`}
            </Typography>
            <Typography variant={'caption'}>
              {`${t('workcenters:lastEdit')} ${moment(
                props.workcenter.lastEdit.lastEditedTime
              ).format('DD.MM.YYYY')} ${t('workcenters:By')} ${
                props.workcenter.lastEdit.lastEditedUser
              }`}
            </Typography>
            <Typography variant={'h6'}>
              {' '}
              {t('header:capabilities').toUpperCase()}{' '}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap">
              {WorkcentersManagement.renderCapabilitiesChips(
                props.workcenter,
                props.siteFlatCaps
              )}
            </Box>
            <Button
              onClick={props.close}
              variant="text"
              color="primary"
              style={{
                width: '50px',
                alignSelf: 'flex-end',
                marginTop: '15px',
              }}>
              OK
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    </ClickAwayListener>
  )
}

export default WorkCenterPopper
