// DualCellFormater should be created my memo
// columns should be created by memo, need to indentify all dependencies

import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import ReactDataGrid, {
  Column,
  DataGridHandle,
  Position as CellPosition,
  RowsUpdateEvent,
  UpdateActions,
} from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'
import difference from 'lodash/difference'
import defer from 'lodash/defer'
import * as clipboardy from 'clipboardy'
import moment from 'moment'

import RootRef from '@material-ui/core/RootRef'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'

import { Stores } from 'stores/stores'
import {
  CapacityLegendNames,
  CapacityType,
  getEmptyCapacity,
  ICapacity,
  ICapacityChanged,
  TimeType,
} from 'interfaces/Capacity'
import CapacityStore from 'stores/CapacityStore'
import CapHlp from 'utils/CapacityHelper'
import WorkcenterStore from 'stores/WorkcenterStore'
import Notifications from '../../../components/CapacityManagement/Notifications'
import Controlbar from '../../../components/CapacityManagement/Controlbar'
import styles from 'styles/capacitiesManagement.module.scss'
import WorkcenterDialog from 'components/WorkcenterDialog'
import AuthenticationStore from 'stores/AuthenticationStore'
import { getSite, ISite } from 'interfaces/Site'
import {
  getEmptyWorkcenter,
  IFlatCap,
  IWorkcenter,
  WorkcenterState,
  WorkcenterType,
} from 'interfaces/Workcenter'
import WorkcenterService from 'services/WorkcenterService'
import CapabilityStore from 'stores/CapabilityStore'
import { Capability } from 'interfaces/Capabilities'
import { notify } from '../../../components/Notification'
import SAPImportReportStore from 'stores/SAPImportReportStore'
import {
  getSAPImportReport,
  ISAPImportReport,
} from 'interfaces/SAPImportReport'
import WorkCenterPopper from '../../../components/CapacityManagement/WorkCenterPopper'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'

interface IRow {
  [key: string]: any
}

interface IColumnDate {
  year: string
  timeValue: string
}
interface IColumnsDates {
  [key: string]: IColumnDate
}

interface AvailableCapacityProps extends WithTranslation {
  siteId: string
  capacityType: CapacityType
  capacityStore?: CapacityStore
  workcenterStore?: WorkcenterStore
  authenticationStore?: AuthenticationStore
  capabilityStore?: CapabilityStore
  sapImportReportStore?: SAPImportReportStore
}

const lastCellPosition = {
  idx: 0,
  rowIdx: 0,
}
const currentCellPosition = {
  idx: 0,
  rowIdx: 0,
  inEditMode: false,
}

let $gridHandle: DataGridHandle | null = null

const AvailabilityTable: React.FC<AvailableCapacityProps> = inject(
  Stores.capacityStore,
  Stores.workcenterStore,
  Stores.authenticationStore,
  Stores.capabilityStore,
  Stores.sapImportReportStore
)(
  observer(
    ({
      siteId,
      capacityType,
      capacityStore,
      workcenterStore,
      authenticationStore,
      capabilityStore,
      sapImportReportStore,
    }) => {
      const { t } = useTranslation()
      const visibleWeekColumns = 53
      const [workcentersToAdd, setWorkcentersToAdd] = React.useState<string[]>(
        []
      )
      const [capabilities, setCapabilities] = React.useState<Capability[]>([])
      const [siteFlatCaps, setSiteFlatCaps] = React.useState<IFlatCap[]>([])
      const [WcAnchorEl, setWcAnchorEl] = React.useState<any>(null)
      const [selectedWc, setSelectedWc] = React.useState<
        IWorkcenter | undefined
      >(undefined)
      const [isTableModified, setIsTableModified] = React.useState(false)
      const [newWc, setNewWc] = React.useState('')
      const [copyPaste, setCopyPaste] = React.useState(false)
      const [isFormerView, setIsFormerView] = useState<boolean>(false)

      const [currentSite, setCurrentSite] = React.useState<ISite>(getSite())
      React.useEffect(() => {
        setCurrentSite(
          authenticationStore!.currentUser.sites.filter(
            (site) => site._id === siteId
          )[0]
        )
      }, [siteId])

      React.useEffect(() => {
        if (currentSite) {
          const site = loadSelectedSite()
          loadCapabilities(site)
        }
      }, [currentSite])

      React.useEffect(() => {
        if (capabilities) {
          setSiteFlatCaps(
            WorkcenterService.convertToFlatCaps(
              capabilities,
              currentSite.capabilities
            ).filter(
              (flatCap: IFlatCap) =>
                flatCap.internal && flatCap.internal === 1 && flatCap.parentId
            )
          )
        }
      }, [capabilities])

      const timeType: TimeType = currentSite
        ? currentSite.capacityMaintenance[
            capacityStore!.convertCapacityToCapMaintenance(capacityType)
          ]
        : TimeType.month

      const getRow = (timeFormat: TimeType, sum?: boolean) => {
        let row = {
          workcenter: '',
        }
        const value = sum ? '' : 0
        const timeSpan =
          timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12
        for (let i = 0; i < timeSpan; i++) {
          const key = timeFormat.toLowerCase() + (i + 1)
          row = {
            ...row,
            [key]: value,
          }
        }
        return row
      }

      const colIdx2Key: string[] = Object.keys(getRow(timeType))
      const colKey2Idx = (key: string) => colIdx2Key.indexOf(key)

      const DualCellFormatter = (props: any) => {
        const sumRow = t('capacity-management:sum')
        const value = props.row[props.column.key]
        const workcenter = props.row['workcenter']
        const { year, timeValue } = columnsDates[props.column.key]
        const compareCapacity = getFromCache(workcenter, year, timeValue)
        let changedCapacity = getFromChanged(workcenter, year, timeValue)
        let latestCap = getFromCacheLatest(workcenter, year, timeValue)

        const testValue = changedCapacity
          ? changedCapacity.value
          : latestCap
          ? latestCap.value
          : 0

        if (workcenter === sumRow) {
          return value //already formated in sumRow value
        } else if (testValue !== value) {
          return CapHlp.tableErrText()
        }

        const oldCap = compareCapacity // black
        const newCap = isFormerView ? latestCap : changedCapacity // blue
        return CapHlp.tableValue(value, oldCap, newCap)
      }

      const WorkcenterCellFormatter = (props: any) => {
        const value = props.row[props.column.key]
        const isSum = value === t('capacity-management:sum')
        if (isSum) {
          return tableWorkcenterValue(value, true, isSum)
        }
        if (workcentersToAdd.length) {
          for (let i = 0; i < workcentersToAdd.length; i++) {
            if (workcentersToAdd[i] === value)
              return tableWorkcenterValue(value, false, isSum)
          }
        }
        return tableWorkcenterValue(value, true, isSum)
      }

      const tableWorkcenterValue = (
        value: any,
        isOld: boolean,
        isSum: boolean
      ) => {
        const openWorkCenterModal = (workcenterToCreate: string) => {
          const newWorkCenter = getWorkcenter(workcenterToCreate)
          workcenterStore?.setModalNewForCapability(newWorkCenter)
        }

        const newWorkCenterTooltipContent = () => {
          return (
            <div>
              <Typography>Unknown Workcenter</Typography>
              <Typography>
                Click + to create workcenter or - to decline row
              </Typography>
            </div>
          )
        }

        const deleteSelectedWorkcenter = (workcenterToDelete: string) => {
          let workcenterAddListCopy = [...workcentersToAdd]
          setWorkcentersToAdd(
            workcenterAddListCopy.filter(
              (wc: string) => wc !== workcenterToDelete
            )
          )
        }

        const openWCPopper = (e: any, wc: IWorkcenter) => {
          setWcAnchorEl(e.currentTarget)
          setSelectedWc(wc)
        }

        let sValue: string = value as string
        const maxLength = isOld ? 15 : 8
        sValue =
          sValue.length > maxLength
            ? sValue.substr(0, maxLength) + '...'
            : sValue

        if (isSum) {
          return (
            <div className={styles['capacityGroupHeaderRowTableCell']}>
              <span className={styles['capacitiesTableCell-bold']}>
                {sValue}
              </span>
            </div>
          )
        } else if (isOld) {
          const workcenter = workcenterStore!.allActiveWorkcenter.filter(
            (wc) => wc.number === value
          )[0]
          return (
            <div>
              <span
                //style={(getCapabilityArrOfWcLength(value) > 0 ||  value === t('capacity-management:sum')) ? ({color: "black"}) : ({color: "lightgrey"})}
                onClick={(e) => openWCPopper(e, workcenter)}
                className={styles['capacitiesTableCell-bold']}>
                {sValue}
              </span>
            </div>
          )
        } else {
          return (
            <div>
              <Tooltip
                title={newWorkCenterTooltipContent()}
                placement={'bottom'}>
                <span className={styles['capacitiesTableCell-newWorkcenter']}>
                  {sValue}
                  <IconButton
                    style={{ color: '#00a6eb' }}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      openWorkCenterModal(value)
                    }}
                    size={'small'}>
                    <AddCircleOutline />
                  </IconButton>
                  <IconButton
                    style={{ color: '#00a6eb' }}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      deleteSelectedWorkcenter(value)
                    }}
                    size={'small'}>
                    <RemoveCircleOutline />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )
        }
      }

      const tableSumValues = (oldValue: any, newValue?: any) => {
        const oldVal = timeType === 'WEEK' ? oldValue.toFixed(2) : oldValue
        if (newValue) {
          const newVal = timeType === 'WEEK' ? newValue.toFixed(2) : newValue
          return (
            <div className={styles['capacityGroupHeaderRowTableCell']}>
              <span className={styles['capacitiesTableCell-blackValue']}>
                {`${oldVal}h`}
              </span>
              <span className={styles['capacitiesTableCell-newValue']}>
                {` › ${newVal}h`}
              </span>
            </div>
          )
        }
        return (
          <div className={styles['capacityGroupHeaderRowTableCell']}>
            <span className={styles['capacitiesTableCell-blackValue']}>
              {`${oldVal}h`}
            </span>
          </div>
        )
      }

      const dataColumnDef = {
        frozen: false,
        editable: !isFormerView,
        formatter: DualCellFormatter,
        headerCellClass: '',
        cellClass: '',
        minWidth: 50,
        unsafe_onCellInput: (event: any, row: any) => {
          onKeyDownAny(event)
          onKeyDownDelete(event)
        },
      }

      const workcenterColumnDef = {
        editable: !isFormerView,
        formatter: WorkcenterCellFormatter,
        headerCellClass: '',
        cellClass: '',
        minWidth: 150,
        unsafe_onCellInput: (event: any, row: any) => {
          onKeyDownAny(event)
        },
      }

      const [momentPeriod, setMomentPeriod] = React.useState<
        'month' | 'isoWeek'
      >(timeType === TimeType.month ? 'month' : 'isoWeek')
      const [changed, setChanged] = useState<ICapacityChanged>({})
      const [gridDataUpdateCount, setGridDataUpdateCount] = useState(0)
      const [compareDate, setCompareDate] = useState<Date>(
        moment(new Date()).endOf('day').toDate()
      )
      const currentDate = moment(new Date()).endOf('day').toDate()

      const currentColumnDate: IColumnDate = {
        year:
          timeType === TimeType.month
            ? moment(currentDate).format('YYYY')
            : moment(currentDate).isoWeekYear().toString(),
        timeValue:
          timeType === TimeType.month
            ? moment(currentDate).format('M')
            : moment(currentDate).format('W'),
      }

      const [controlDate, setControlDate] = useState<Date>(
        moment(new Date()).startOf(momentPeriod).toDate()
      )

      React.useEffect(() => {
        let newControlDate = new Date()
        newControlDate = moment(newControlDate).startOf(momentPeriod).toDate()
        setControlDate(newControlDate)
      }, [momentPeriod])

      const [referenceDate, setReferenceDate] = React.useState(
        moment(new Date()).startOf('day')
      )

      React.useEffect(() => {
        compareDateChange()
      }, [referenceDate])

      React.useEffect(() => {
        setMomentPeriod(timeType === TimeType.week ? 'isoWeek' : 'month')
      }, [timeType])

      React.useEffect(() => {
        if (!isFormerView) setReferenceDate(moment(new Date()).startOf('day'))
      }, [isFormerView])

      const sapReport: ISAPImportReport = React.useMemo(() => {
        if (sapImportReportStore && siteId) {
          return sapImportReportStore!.getReportById(siteId)
        } else {
          return getSAPImportReport()
        }
      }, [siteId])

      React.useEffect(() => {
        const init = async () => {
          await sapImportReportStore!.initImportReport()
        }
        if (sapImportReportStore) {
          init()
        }
      }, [])

      const getColumnValDef = (timeFormat: TimeType) => {
        let columns = [
          {
            key: 'workcenter',
            name: t('workcenters:WORKCENTER'),
            ...workcenterColumnDef,
          },
        ]
        const timeSpan =
          timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12
        for (let i = 0; i < timeSpan; i++) {
          const key = timeFormat.toLowerCase() + (i + 1)
          columns = [
            ...columns,
            {
              key: key,
              name: '',
              ...dataColumnDef,
            },
          ]
        }
        return columns
      }

      // const getCapabilityArrOfWcLength = (wc: string) => {
      //   const workcenter = workcenterStore!.allActiveWorkcenter.filter(
      //     (wrkC: IWorkcenter) => wrkC.number === wc
      //   )[0]
      //   return workcenter ? workcenter.capabilityIds.length : 0
      // }

      const getCompareCapacity = (
        capacities: ICapacity[]
      ): ICapacity | undefined => {
        const compareDateNumber = +moment(compareDate)
        let olderCapacities: ICapacity[] = capacities.filter(
          (capacity) => capacity.created.createdTime <= compareDateNumber
        )
        return olderCapacities.length > 0
          ? olderCapacities[olderCapacities.length - 1]
          : undefined
      }

      const getLatestCapacity = (
        capacities: ICapacity[]
      ): ICapacity | undefined => {
        const compareDateNumber = +moment(currentDate)
        let olderCapacities: ICapacity[] = capacities.filter(
          (capacity) => capacity.created.createdTime <= compareDateNumber
        )
        return olderCapacities.length > 0
          ? olderCapacities[olderCapacities.length - 1]
          : undefined
      }

      const getFromChanged = (
        workcenter: string,
        year: string,
        timeValue: string
      ): ICapacity | undefined => {
        let capacity: ICapacity | undefined = undefined
        const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
        try {
          capacity = changed[key]
        } catch (error) {
          capacity = undefined
        }
        return capacity
      }

      const getFromCache = (
        workcenter: string,
        year: string,
        timeValue: string
      ): ICapacity | undefined => {
        let capacity: ICapacity | undefined = undefined
        const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
        try {
          capacity = getCompareCapacity(capacityStore!.cache[key])
        } catch (error) {
          capacity = undefined
        }
        return capacity
      }

      const getFromCacheLatest = (
        workcenter: string,
        year: string,
        timeValue: string
      ): ICapacity | undefined => {
        let capacity: ICapacity | undefined = undefined
        const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
        try {
          capacity = getLatestCapacity(capacityStore!.cache[key])
        } catch (error) {
          capacity = undefined
        }
        return capacity
      }

      React.useEffect(() => {
        if (workcenterStore && siteId) {
          workcenterStore!.setAll(siteId)
        }
      }, [workcenterStore, siteId])

      const getPeriod = () =>
        momentPeriod === 'isoWeek' ? 'week' : momentPeriod

      const year1 = moment(controlDate).format('YYYY')
      const workcenters: string[] = React.useMemo(() => {
        let workCenterArr: string[] = []
        workcenterStore!.allActiveWorkcenter
          .slice()
          .sort()
          .forEach((wc) => {
            workCenterArr.push(wc.number)
          })
        if (workcentersToAdd.length > 0) {
          workCenterArr = difference(workCenterArr, workcentersToAdd)
        }
        return capacityStore!.sortByCapacities(
          workCenterArr,
          controlDate,
          capacityType
        )
      }, [
        capacityType,
        workcenterStore!.allActiveWorkcenter,
        workcentersToAdd,
        capacityStore!.cacheRenderCounter,
        year1,
      ])

      React.useEffect(() => {
        if (newWc && newWc !== '') {
          const newWcInActive = workcenterStore!.allActiveWorkcenter.filter(
            (wc) => wc.number === newWc
          )
          if (newWcInActive.length > 0) {
            const wcId = newWcInActive[0]._id
            const changedCopy = { ...changed }
            const changedTableKeys = Object.keys(changed)
            const affectedKeys = changedTableKeys.filter(
              (key: string) => key.split('_')[0] === newWc
            )
            affectedKeys.forEach((key: string) => {
              changedCopy[key].workcenterId = wcId
            })
            setChanged(changedCopy)
            setNewWc('')
          }
        }
      }, [newWc, workcenters])

      const columnsDates: IColumnsDates = React.useMemo(() => {
        const columnsDates: IColumnsDates = {}
        const timePeriodCount = colIdx2Key.length - 1

        columnsDates['workcenter'] = { year: '0000', timeValue: '0' }
        for (let i: number = 0; i < timePeriodCount; i++) {
          const momentDate = moment(controlDate).add(i, getPeriod())

          const columnDate = {
            year:
              timeType === TimeType.month
                ? momentDate.format('YYYY')
                : momentDate.isoWeekYear().toString(),
            timeValue:
              timeType === TimeType.month
                ? momentDate.format('M')
                : momentDate.format('W'),
          }
          columnsDates[colIdx2Key[i + 1]] = columnDate
        }
        return columnsDates
      }, [controlDate, timeType, momentPeriod])

      const columns = (() => {
        const columns: Column<any, any>[] = [...getColumnValDef(timeType)]
        const timePeriodCount = colIdx2Key.length - 1

        for (let i: number = 0; i < timePeriodCount; i++) {
          const colIdx = i + 1
          const momentColDate = moment(controlDate).add(i, getPeriod())

          const isActualPeriod =
            columnsDates[colIdx2Key[colIdx]].timeValue ===
              currentColumnDate.timeValue &&
            columnsDates[colIdx2Key[colIdx]].year === currentColumnDate.year
          const isLastPeriodYear =
            columnsDates[colIdx2Key[colIdx + 1]] &&
            columnsDates[colIdx2Key[colIdx]].year !==
              columnsDates[colIdx2Key[colIdx + 1]].year
          const isFirstPeriodYear =
            columnsDates[colIdx2Key[colIdx - 1]] &&
            columnsDates[colIdx2Key[colIdx]].year !==
              columnsDates[colIdx2Key[colIdx - 1]].year
          let colDateText =
            timeType === TimeType.month
              ? momentColDate.format('MMM')
              : 'CW' + momentColDate.format('W')
          if (
            i === 0 ||
            i === timePeriodCount - 1 ||
            isLastPeriodYear ||
            isFirstPeriodYear
          ) {
            colDateText +=
              " '" + columnsDates[colIdx2Key[colIdx]].year.substring(2, 4)
          }

          let columnClass = isLastPeriodYear
            ? styles['capacitiesTableHeader-lastCol']
            : isActualPeriod
            ? styles['capacitiesTableHeader-actualMonth']
            : ''
          columns[i + 1].name = colDateText
          columns[i + 1].headerCellClass = columnClass
          columns[i + 1].cellClass = isActualPeriod ? '' : columnClass
        }
        // freeze workcenter column
        columns[0].frozen = true
        return columns
      })()

      const rows: IRow[] = React.useMemo(() => {
        let newRows: any[] = [] // any[] needed for different sum row
        let sumRowOldValue: IRow = { ...getRow(timeType) }
        let sumRowChanged: IRow = { ...getRow(timeType) }

        const totalWorkCenterList = [...workcentersToAdd, ...workcenters]
        for (const workcenter of totalWorkCenterList) {
          let newRow: IRow = { ...getRow(timeType), workcenter: workcenter }
          for (const sColKey of Object.keys(newRow)) {
            let colKey: keyof IRow = sColKey as keyof IRow
            if (colKey !== 'workcenter') {
              let { year, timeValue } = columnsDates[sColKey]
              let changedCapacity = getFromChanged(workcenter, year, timeValue)
              let cachedCapacity = getFromCacheLatest(
                workcenter,
                year,
                timeValue
              )
              let capacity = changedCapacity
              if (!capacity) capacity = cachedCapacity
              newRow[colKey] = capacity ? capacity.value : 0

              if (changedCapacity) {
                sumRowChanged[colKey] =
                  sumRowChanged[colKey] + changedCapacity.value
              }
              if (cachedCapacity) {
                sumRowOldValue[colKey] =
                  sumRowOldValue[colKey] + cachedCapacity.value
                if (!changedCapacity) {
                  sumRowChanged[colKey] =
                    sumRowChanged[colKey] + cachedCapacity.value
                }
              }
            }
          }
          newRows.push(newRow)
        }

        // Adding empty row for a new workcenter
        newRows.push(getRow(timeType))

        // Adding sum row
        let sumRow: IRow = {
          ...getRow(timeType, true),
          workcenter: t('capacity-management:sum'),
        }
        for (const sColKey of Object.keys({ ...getRow(timeType) })) {
          let colKey: keyof IRow = sColKey as keyof IRow
          if (colKey !== 'workcenter') {
            if (sumRowOldValue[colKey] === sumRowChanged[colKey]) {
              sumRow[colKey] = tableSumValues(sumRowOldValue[colKey])
            } else {
              sumRow[colKey] = tableSumValues(
                sumRowOldValue[colKey],
                sumRowChanged[colKey]
              )
            }
          }
        }
        newRows.push(sumRow)

        setGridDataUpdateCount(gridDataUpdateCount + 1)
        return newRows
      }, [
        changed,
        capacityStore!.cache,
        compareDate,
        columnsDates,
        workcenters,
        workcentersToAdd,
        capacityType,
        timeType,
        capacityStore!.cacheRenderCounter,
      ])

      React.useEffect(() => {
        if (Object.keys(changed).length > 0)
          notify('notify:unsavedCapacities', 'warning')
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setChanged({})
        capacityStore!.clearCache()
      }, [siteId])

      let formatedControlDate = moment(controlDate).format('YYYYMMW')

      React.useEffect(() => {
        triggerLoadCapacities()
      }, [siteId, capacityType, momentPeriod, formatedControlDate])

      const triggerLoadCapacities = () => {
        if (!capacityStore!.rootStore.capacityLoading) {
          const year1 = moment(controlDate).format('YYYY')
          const year2 = moment(controlDate).add(1, 'years').format('YYYY')
          if (
            !capacityStore!.loadedYears.includes(year1) ||
            capacityType !== capacityStore!.loadedCapacityTab ||
            timeType !== capacityStore!.loadedTimeType
          ) {
            capacityStore!.loadSiteYearTypeCapacities(
              siteId,
              year1,
              [capacityType],
              timeType
            )
          }
          if (
            !capacityStore!.loadedYears.includes(year2) ||
            capacityType !== capacityStore!.loadedCapacityTab ||
            timeType !== capacityStore!.loadedTimeType
          ) {
            capacityStore!.loadSiteYearTypeCapacities(
              siteId,
              year2,
              [capacityType],
              timeType
            )
          }
        }
      }

      const onKeyDownAny = async (
        event: React.KeyboardEvent<HTMLDivElement>
      ) => {
        const editKeys = '01234567890,.'
        if (editKeys.includes(event.key) && !currentCellPosition.inEditMode) {
          if (dataGridWrapper.current) {
            const childPosX = 0 + currentCellPosition.idx
            const childPosY = 3 + currentCellPosition.rowIdx
            const rdgRef = dataGridWrapper.current

            if (rdgRef && rdgRef.children) {
              const ref: any = rdgRef.children[childPosY].children[childPosX]

              if (ref) {
                ref.dispatchEvent(
                  new MouseEvent('dblclick', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                  })
                )
              }
            }
          }
          return
        } else if (event.key === 'Enter' && !currentCellPosition.inEditMode) {
          currentCellPosition.inEditMode = true
          return
        } else if (event.key === 'Enter' && currentCellPosition.inEditMode) {
          currentCellPosition.inEditMode = false
          return
        }
        if (event.key === 'Escape') {
          currentCellPosition.inEditMode = false
          return
        }
        if ((event.key === 'v' && event.ctrlKey) || event.key === 'Meta') {
          if (
            !isFormerView &&
            !currentCellPosition.inEditMode &&
            !copyPaste &&
            !capacityStore!.rootStore.capacityLoading
          ) {
            capacityStore!.rootStore.setCapacityLoading(true)
            await paste()
            capacityStore!.rootStore.setCapacityLoading(false)
          }
        }
      }

      const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        if (!isFormerView && !capacityStore!.rootStore.capacityLoading) {
          capacityStore!.rootStore.setCapacityLoading(true)

          // Stop data actually being pasted into div
          event.stopPropagation()
          event.preventDefault()

          // Get pasted data via clipboard API
          const clipboardData = event.clipboardData
          const pastedData = clipboardData.getData('Text')

          // Do whatever with pasteddata
          if (pastedData) {
            const pos = { idx: colKey2Idx('workcenter'), rowIdx: 0 }
            const changedCopy = parseFromClipboard(pastedData, pos, {
              ...changed,
            })

            setGridDataUpdateCount(gridDataUpdateCount + 1)
            setChanged(changedCopy)
          }
          capacityStore!.rootStore.setCapacityLoading(false)
        }
        setCopyPaste(false)
      }

      const onKeyDownDelete = async (
        event: React.KeyboardEvent<HTMLDivElement>
      ) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const colName = colIdx2Key[currentCellPosition.idx]
          const rowIdx = currentCellPosition.rowIdx
          const inEditMode = currentCellPosition.inEditMode
          const e: RowsUpdateEvent<{ [key: string]: string }> = {
            action: UpdateActions.CELL_UPDATE,
            cellKey: colName,
            updated: { [colName]: '' },
            fromRow: rowIdx,
            toRow: rowIdx,
          }

          if (!inEditMode) {
            updateChanged(e)
          }
        }
      }

      const paste = async () => {
        let text

        setCopyPaste(true)

        try {
          text = await clipboardy.read()
        } catch (err) {
          console.error(`Failed pasting the text: ${text}`)
          console.error(err, err.message)
        }

        if (text) {
          const pos = { idx: colKey2Idx('workcenter'), rowIdx: 0 }
          const changedCopy = parseFromClipboard(text, pos, { ...changed })

          setGridDataUpdateCount(gridDataUpdateCount + 1)
          setChanged(changedCopy)
        }

        setCopyPaste(false)
      }

      const compareDateChange = () => {
        let date
        if (isFormerView) {
          date = referenceDate.toDate()
        } else {
          date = currentDate
        }
        setCompareDate(date)
      }

      const controlDateMod = (fast: boolean, subtract: boolean) => {
        const period = fast ? 'year' : getPeriod()
        let newDate: Date
        if (subtract) newDate = moment(controlDate).subtract(1, period).toDate()
        else newDate = moment(controlDate).add(1, period).toDate()
        setControlDate(newDate)
      }

      const confirmChanges = () => {
        // Execute data storing.
        capacityStore!.saveChangedCapacities(changed).then(() => {
          setChanged({})
          triggerLoadCapacities()
          setGridDataUpdateCount(gridDataUpdateCount + 1)
          setIsTableModified(false)
        })
      }

      const cancelChanges = () => {
        setWorkcentersToAdd([])
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setChanged({})
        setIsTableModified(false)
      }

      const updateOneCapacity = (
        workcenter: string,
        year: string,
        timeValue: string,
        newCapacityValue: any,
        changedCopy: ICapacityChanged
      ) => {
        const oldCapacity = getFromCache(workcenter, year, timeValue)
        let newCapacity: ICapacity

        const valueAsNumber =
          newCapacityValue == '' ? 0 : parseFloat(newCapacityValue)
        const isValid = !isNaN(valueAsNumber)

        if (isValid) {
          if (oldCapacity) {
            newCapacity = { ...oldCapacity, value: valueAsNumber }
          } else {
            const workcenterId = workcenterStore!.workcenterNumber2Id[
              workcenter
            ]
            newCapacity = getEmptyCapacity()
            newCapacity.capacityType = capacityType
            newCapacity.timeType = timeType
            newCapacity.timeValue = (timeValue as unknown) as number
            newCapacity.year = (year as unknown) as number
            newCapacity.workcenterId = workcenterId
            newCapacity.value = valueAsNumber
          }

          if (!oldCapacity || newCapacityValue !== oldCapacity!.value) {
            const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
            changedCopy[key] = newCapacity
          }
        }

        return changedCopy
      }

      const parseFromClipboard = (
        clipboard: string,
        pos: CellPosition | undefined,
        changedCopy: ICapacityChanged
      ) => {
        if (typeof clipboard === 'string') {
          let cellValues: string[][] = clipboard
            .trim()
            .replace(/[\r\n]/g, ' ')
            .replace(/[\n]/g, ' ')
            .split(' ')
            .filter((s: string) => s !== '')
            .map((row) => row.split('\t'))

          if (cellValues.length > 0 && cellValues[0].length > 0 && pos) {
            let startRowIdx = pos.rowIdx
            let endRowIdx = pos.rowIdx + cellValues.length - 1
            let startColIdx = pos.idx
            let endColIdx = pos.idx + cellValues[0].length - 1
            let colsTruncated = 0

            if (endColIdx >= columns.length) {
              endColIdx = columns.length - 1
              colsTruncated = endColIdx + 1 - columns.length
            }
            let missingRows = endRowIdx + 1 - rows.length
            for (; missingRows > 0; missingRows--) rows.push(getRow(timeType))

            const toAddCopy: string[] = [...workcentersToAdd]
            for (let row = startRowIdx; row <= endRowIdx; row++) {
              let workcenter = workcenters[row]
              for (let col = startColIdx; col <= endColIdx; col++) {
                const value: any = cellValues[row - pos.rowIdx][col - pos.idx]
                const { year, timeValue } = columnsDates[colIdx2Key[col]]
                if (colIdx2Key[col] === 'workcenter') {
                  workcenter = value
                  if (
                    toAddCopy.filter((wc) => wc === workcenter).length === 0 &&
                    workcenters.filter((wc) => wc === workcenter).length === 0
                  ) {
                    toAddCopy.push(workcenter)
                  }
                } else {
                  changedCopy = updateOneCapacity(
                    workcenter,
                    year,
                    timeValue,
                    value,
                    changedCopy
                  )
                }
              }
              setWorkcentersToAdd(toAddCopy)
            }
            if (colsTruncated > 0) {
              alert(`${colsTruncated} of columns was truncated!`)
            }
          }
        }
        return changedCopy
      }

      const updateChanged = (e: RowsUpdateEvent<{ [key: string]: string }>) => {
        // console.log('===== updateChanged e: ' + JSON.stringify(e))
        setIsTableModified(true)

        let changedCopy = { ...changed }

        const containsMoreValues =
          e.updated[e.cellKey].includes('\t') ||
          e.updated[e.cellKey].includes(' ')
        const updatesOnlyOneCell =
          Object.keys(e.updated).length === 1 && e.fromRow === e.toRow

        if (!updatesOnlyOneCell) {
          alert('Unsupported cell update')
          return
        }

        const pos = { idx: colKey2Idx(e.cellKey), rowIdx: e.fromRow }
        // console.log('===== pos: ' + JSON.stringify(pos))

        if (containsMoreValues) {
          changedCopy = parseFromClipboard(
            e.updated[e.cellKey],
            pos,
            changedCopy
          )
        } else {
          if (pos.idx === 0) {
            const newWorkCenter = e.updated[e.cellKey]
            const toAddCopy: string[] = [...workcentersToAdd]
            const workcenter = e.updated[e.cellKey]
            if (!newWorkCenter) notify('notify:wcNotEmpty', 'warning')
            else if (
              workcenters.filter((wc: string) => wc === newWorkCenter)
                .length === 0 &&
              workcentersToAdd.filter((wc: string) => wc === newWorkCenter)
                .length === 0
            ) {
              toAddCopy.push(workcenter)
              setWorkcentersToAdd(toAddCopy)
              // console.log('=== workcenters to add: ' + workcentersToAdd)
            } else notify(`notify:wcExists ${workcenter}`, 'warning')
          } else {
            const { year, timeValue } = columnsDates[e.cellKey]
            const workcenter = rows[e.fromRow].workcenter
            const newCapacityValue = e.updated[e.cellKey]

            changedCopy = updateOneCapacity(
              workcenter,
              year,
              timeValue,
              newCapacityValue,
              changedCopy
            )
          }
        }
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setChanged(changedCopy) // TODO: When this is triggered, the table refreshes and the selected cell is changing to the first cell of the table.
      }

      const handleSiteSelect = async (sites: ISite[]) => {
        // TODO: not needed?
      }

      const getWorkcenter = (wcNum?: string): IWorkcenter => {
        let workcenter: IWorkcenter = getEmptyWorkcenter()
        workcenter.siteId = siteId
        workcenter.type = WorkcenterType.workcenter
        workcenter.state = WorkcenterState.active
        workcenter.number = wcNum ? wcNum : workcentersToAdd[0]
        return workcenter
      }

      const loadSelectedSite = (): ISite => {
        return currentSite
      }

      const loadCapabilities = async (site: ISite) => {
        let divisionId =
          site.division && site.division._id ? site.division._id : ''
        // console.log('### SITE DIVISION ID: ' + site.division._id)

        await capabilityStore!.setAllCapabilitiesFromCache(divisionId)
        setCapabilities([...capabilityStore!.capabilities])
      }

      const updateWorkcenters = async () => {
        const workCenterToAdd = workcenterStore!.workcenterToAdd || {}
        if (workCenterToAdd && workCenterToAdd.status) {
          if (workCenterToAdd.status === 'success') {
            let newWorkcenterArr = workcentersToAdd.filter(
              (wc: string) => wc !== workCenterToAdd.number
            )
            setWorkcentersToAdd(newWorkcenterArr)
            setNewWc(workCenterToAdd.number)
            await workcenterStore!.setAll(siteId)
          }
        }
        await handleSiteSelect([loadSelectedSite()])
      }

      const enableConfirm = () => {
        return workcentersToAdd.length === 0 && !copyPaste
      }

      const closeWorkcenterPopper = () => {
        setSelectedWc(undefined)
        setWcAnchorEl(null)
      }

      const dataGridWrapper: React.MutableRefObject<HTMLElement | null> = React.useRef(
        null
      )
      const { current: dataGridRef }: { current: any } = dataGridWrapper
      const dataGridcrollPosition = dataGridRef ? dataGridRef.scrollTop || 0 : 0

      const restoreSelectionAndScrollPosition = () => {
        const { current: dataGridRef }: { current: any } = dataGridWrapper

        if ($gridHandle) {
          $gridHandle.selectCell(currentCellPosition)
        }

        // restore exact scroll position
        if (dataGridRef) {
          dataGridRef.scrollTop = dataGridcrollPosition
        }
      }

      React.useEffect(() => {
        if (
          $gridHandle &&
          (lastCellPosition.idx !== currentCellPosition.idx ||
            lastCellPosition.rowIdx !== currentCellPosition.rowIdx) &&
          !copyPaste
        ) {
          // momorize last cell position
          lastCellPosition.idx = currentCellPosition.idx
          lastCellPosition.rowIdx = currentCellPosition.rowIdx
          defer(() => {
            if ($gridHandle) {
              restoreSelectionAndScrollPosition()
            }
          })
        }
        // here it's important to use the same dependencies that are responsible to rerender the grid table
        // eslint-disable-next-line
      }, [
        copyPaste,
        capacityType,
        gridDataUpdateCount,
        // eslint-disable-next-line
        capacityStore!.cacheRenderCounter,
      ])

      React.useEffect(() => {
        lastCellPosition.idx = 0
        lastCellPosition.rowIdx = 0
        currentCellPosition.idx = 0
        currentCellPosition.rowIdx = 0
        currentCellPosition.inEditMode = false
        $gridHandle = null
      }, [siteId])

      const getCurrentTimeType = (): TimeType | undefined => {
        if (currentSite && currentSite.capacityMaintenance) {
          switch (capacityType) {
            case CapacityType.available:
              return currentSite.capacityMaintenance.available

            case CapacityType.demand:
              return currentSite.capacityMaintenance.demand

            case CapacityType.forecast:
              return currentSite.capacityMaintenance.forecast
          }
        }
        return undefined
      }

      const isEditView = () =>
        Object.keys(changed).length > 0 || workcentersToAdd.length > 0

      const onUpdateSite = () => {
        capacityStore!.clearCache()
        setCurrentSite(
          authenticationStore!.currentUser.sites.filter(
            (site) => site._id === siteId
          )[0]
        )
      }

      return (
        <div className={styles['container-box']}>
          <WorkcenterDialog
            open={workcenterStore!.modalOpen}
            onClose={() => workcenterStore!.setModalClose()}
            initialWorkcenter={getWorkcenter()}
            modalType={true}
            updateWorkcenters={async () => await updateWorkcenters()}
            workcenters={workcenterStore!.all}
            site={loadSelectedSite()}
            siteFlatCaps={siteFlatCaps}
            numberAndtypeDisabled={true}
          />

          <Notifications
            sapReport={
              capacityType === CapacityType.demand ? sapReport : undefined
            }
            isWorkDays={false}
            hasOptionButton={true}
            disableOptionButton={isEditView()}
            capacityType={capacityType}
            timeType={getCurrentTimeType()}
            currentSite={currentSite}
            onUpdateSite={onUpdateSite}
          />

          <Controlbar
            compareDateChange={compareDateChange}
            setIsFormerView={setIsFormerView}
            isFormerView={isFormerView}
            controlDate={controlDate}
            controlDateMod={controlDateMod}
            confirmChanges={confirmChanges}
            enableConfirm={enableConfirm()}
            timeType={timeType}
            cancelChanges={cancelChanges}
            referenceDate={referenceDate}
            setReferenceDate={setReferenceDate}
            isEditView={isEditView()}
            loadLegendValues={
              isTableModified
                ? [
                    CapacityLegendNames.ENTERED_HOURS,
                    CapacityLegendNames.NEW_CAPACITY,
                  ]
                : isFormerView
                ? [
                    CapacityLegendNames.ACTUAL_HOURS,
                    CapacityLegendNames.FORMER_HOURS,
                  ]
                : [CapacityLegendNames.ENTERED_HOURS]
            }
            infoText={`${t('capacity-management:detail-info-tooltip')} ${t(
              'capacity-management:clipboard-info-tooltip'
            )}`}
          />
          {WcAnchorEl && selectedWc && (
            <WorkCenterPopper
              workcenter={selectedWc}
              anchorElem={WcAnchorEl}
              close={closeWorkcenterPopper}
              siteFlatCaps={siteFlatCaps}
            />
          )}
          <div
            className={
              styles[
                isEditView()
                  ? 'capacitiesTabs-editModalHeaderOn'
                  : 'capacitiesTabs-editModalHeaderOff'
              ]
            }
          />
          <div
            className={
              styles[
                isEditView()
                  ? 'capacitiesTabs-editModalOn'
                  : 'capacitiesTabs-editModalOff'
              ]
            }
          />
          <div onPaste={onPaste}>
            <RootRef rootRef={dataGridWrapper}>
              <ReactDataGrid
                key={
                  `ReactDataGrid_key_${capacityType}` +
                  gridDataUpdateCount +
                  capacityStore!.cacheRenderCounter
                }
                ref={(gridHandle) => {
                  if (gridHandle) {
                    $gridHandle = gridHandle
                  }
                }}
                columns={columns}
                rows={rows}
                height={500}
                onRowsUpdate={updateChanged}
                enableCellCopyPaste
                /** Function called whenever selected cell is changed */
                onSelectedCellChange={({ idx, rowIdx }) => {
                  if (
                    !copyPaste &&
                    (currentCellPosition.idx !== idx ||
                      currentCellPosition.rowIdx !== rowIdx)
                  ) {
                    currentCellPosition.idx = idx
                    currentCellPosition.rowIdx = rowIdx
                    currentCellPosition.inEditMode = false
                  }
                }}
                onCheckCellIsEditable={(event: any) => {
                  const currentRow = rows[currentCellPosition.rowIdx]
                  const isSumRow =
                    currentRow.workcenter === t('capacity-management:sum')
                  const isCellOfEmptyWorkcenter =
                    !currentRow.workcenter && currentCellPosition.idx > 0
                  const isAnyEditEvent =
                    !event.status || event.status !== 'SELECT'

                  if (isSumRow || isCellOfEmptyWorkcenter) {
                    return false
                  }
                  if (isAnyEditEvent) {
                    currentCellPosition.inEditMode = true
                  }

                  return true
                }}
              />
            </RootRef>
          </div>
        </div>
      )
    }
  )
)

export default withTranslation()(AvailabilityTable)
