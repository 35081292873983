import { isArray } from 'lodash'

import { IWorkDays } from 'interfaces/WorkDays'
import { notify } from 'components/Notification'
import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { commonFunctions } from './ServiceConfig'

const { handleResponse } = commonFunctions
export default class WorkDayService {
  public getAllWorkDays = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/list`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:fetchWorkDay'
              : 'errors:server errors:fetchWorkDay',
            'error'
          )
      )
  }

  public getWorkDaysBySite = (siteId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/site/${siteId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:fetchWorkDay'
              : 'errors:server errors:fetchWorkDay',
            'error'
          )
      )
  }

  public getWorkDaysBySiteAndYear = (siteId: string, year: string | number) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/site/${siteId}/${year}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:fetchWorkDay'
              : 'errors:server errors:fetchWorkDay',
            'error'
          )
      )
  }

  public getWorkDaysBySiteYearAndMonth = (
    siteId: string,
    year: string | number,
    month: string | number
  ) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/site/${siteId}/${year}/${month}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:fetchWorkDay'
              : 'errors:server errors:fetchWorkDay',
            'error'
          )
      )
  }

  public deleteWorkDays = (workdayId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/delete/${workdayId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:deleteWorkDay', 'success')
          return response
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:deleteWorkDay'
              : 'errors:server errors:deleteWorkDay',
            'error'
          )
      )
  }

  public createWorkDays = (workday: any) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(workday),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:createWorkDay', 'success')
          return response
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:createWorkDay'
              : 'errors:server errors:createWorkDay',
            'error'
          )
      )
  }

  public updateWorkDays = (workday: IWorkDays) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(workday),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workdays/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:updateWorkDay', 'success')
          return response
        },
        (error: Error) =>
          notify(
            error.message !== '500'
              ? 'errors:updateWorkDay'
              : 'errors:server errors:updateWorkDay',
            'error'
          )
      )
  }
}
