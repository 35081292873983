import moment from 'moment'

export enum P13nKey {
  RegionalConfig = 'RegionalConfig',
  GlobalConfig = 'GlobalConfig',
  PinnedDimensionValues = 'PinnedDimensionValues',
}

export interface P13nRouteConfig {
  config: IRouteParams
  timestamp: string
}

export interface P13nPinnedDimensionValues {
  [dimension: string]: string[]
}

export interface ChartHorizontalLine {
  value: number
  color: string
  label?: string
  yAxisRight?: boolean
  yAxisLabel?: boolean
}

export interface ChartDataset {
  key: string
  label: string
  unit: string
  data: number[]
  color?: string
  type?: string
  group?: string
  yAxisRight?: boolean
  hidden?: boolean
  tooltipOnly?: boolean
  tooltip?: {
    sortByValue?: boolean
  }
}

export interface ChartAxisConfig {
  min?: number
  max?: number
  grid?: boolean
  display?: boolean
}

export interface MicroChartDataset {
  label: string
  unit: string
  data: number[]
  color: string
}

export enum Metric {
  CapacityAvailable = 'CAP_AVAILABLE',
  CapacityDemand = 'CAP_DEMAND',
  CapacityDemandWithForecast = 'CAP_DEMAND_W_FC',
  CapacityForecaset = 'CAP_FORECAST',
  Utilization = 'UTILIZATION',
  UtilizationWithForecast = 'UTILIZATION_W_FC',
  CapacityTransferIn = 'CAP_TRANSFER_IN',
  CapacityTransferOut = 'CAP_TRANSFER_OUT',
  CapacitySupermarket = 'CAP_SUPERMARKET',
  CapacitySupermarketReduction = 'CAP_SUPERMARKET_REDUCTION',
  CapacitySupermarketIncrease = 'CAP_SUPERMARKET_INCREASE',
  AccumulatedBacklog = 'ACC_BACKLOG',
  AccumulatedBacklogWithForecast = 'ACC_BACKLOG_W_FC',
  AccumulatedUtilization = 'ACC_UTILIZATION',
  AccumulatedUtilizationWithForecast = 'ACC_UTILIZATION_W_FC',
  CapacityOverload = 'CAP_OVERLOAD',
  CapacityOverloadWithForecast = 'CAP_OVERLOAD_W_FC',
  CapacityFree = 'CAP_FREE',
  CapacityFreeWithForecast = 'CAP_FREE_W_FC',
  AccumulatedCapacityOverload = 'ACC_CAP_OVERLOAD',
  AccumulatedCapacityOverloadWithForecast = 'ACC_CAP_OVERLOAD_W_FC',
  AccumulatedCapacityFree = 'ACC_CAP_FREE',
  AccumulatedCapacityFreeWithForecast = 'ACC_CAP_FREE_W_FC',
}

export enum FilterOperator {
  EQ = '=',
}

export enum Dimension {
  Site = 'SITE',
  Region = 'REGION',
  Division = 'DIVISION',
  Workcenter = 'WORKCENTER',
  Capability = 'CAPABILITY',
  CapabilitySite = 'CAPABILITY_SITE',
  CapabilityRegion = 'CAPABILITY_REGION',
}

export interface ISeries {
  metric: Metric
  drilldown: Dimension | string | null
}

export interface IFilter {
  filterDimension: Dimension
  operator: FilterOperator
  values: string[]
}

export interface ISeriesResult {
  metric: Metric
  dimensionId?: string | null
  dimension?: string | null
  label: string
  data: number[]
  start?: string
  end?: string
  timeLevel?: string
  message?: string | null
}

export const getSeriesResult: (metric: Metric) => ISeriesResult = (metric) => ({
  dimension: null,
  dimensionId: null,
  message: null,
  metric: metric,
  data: [],
  label: metric.toLowerCase(),
})

export interface IQueryResult {
  labels: string[]
  series: ISeriesResult[]
}

export interface IServiceQueryResult {
  metric: Metric
  dimensionId: string | null
  dimension: Dimension | null
  data: { value: number; label: string }[]
  start: string
  end: string
  timeLevel: TimeframeLevel
  message: string
}

export const getUnitByMetric = (metric: Metric) => {
  switch (metric) {
    case Metric.CapacitySupermarket:
    case Metric.CapacityFree:
    case Metric.CapacityFreeWithForecast:
    case Metric.AccumulatedCapacityFree:
    case Metric.AccumulatedCapacityFreeWithForecast:
    case Metric.CapacityTransferIn:
    case Metric.CapacitySupermarketIncrease:
    case Metric.CapacityOverload:
    case Metric.CapacityOverloadWithForecast:
    case Metric.AccumulatedCapacityOverload:
    case Metric.AccumulatedCapacityOverloadWithForecast:
    case Metric.CapacityTransferOut:
    case Metric.CapacitySupermarketReduction:
    case Metric.CapacityAvailable:
    case Metric.CapacityDemand:
    case Metric.CapacityDemandWithForecast:
    case Metric.CapacityForecaset:
      return 'h'
    case Metric.Utilization:
    case Metric.UtilizationWithForecast:
    case Metric.AccumulatedUtilization:
    case Metric.AccumulatedUtilizationWithForecast:
      return '%'
    case Metric.AccumulatedBacklog:
    case Metric.AccumulatedBacklogWithForecast:
      // TODO: read unit via i18n text
      return 'd'
    default:
      return ''
  }
}
export const getSeriesColorByMetric = (metric: Metric) => {
  switch (metric) {
    case Metric.CapacitySupermarket:
      return '#257C0B' // dark green
    case Metric.CapacityFree:
    case Metric.CapacityFreeWithForecast:
    case Metric.AccumulatedCapacityFree:
    case Metric.AccumulatedCapacityFreeWithForecast:
    case Metric.CapacityTransferIn:
    case Metric.CapacitySupermarketIncrease:
      return '#51BF30' // green
    case Metric.CapacityOverload:
    case Metric.CapacityOverloadWithForecast:
    case Metric.AccumulatedCapacityOverload:
    case Metric.AccumulatedCapacityOverloadWithForecast:
    case Metric.Utilization:
    case Metric.AccumulatedBacklog:
    case Metric.AccumulatedBacklogWithForecast:
    case Metric.CapacityTransferOut:
    case Metric.CapacitySupermarketReduction:
      return '#FF525A' // red
    case Metric.UtilizationWithForecast:
      return '#DBDD4C' // amber
    case Metric.CapacityAvailable:
      return '#C5C6C6' // grey
    case Metric.CapacityDemand:
    case Metric.CapacityDemandWithForecast:
    case Metric.AccumulatedUtilization:
    case Metric.AccumulatedUtilizationWithForecast:
      return '#003764' // indigo
    case Metric.CapacityForecaset:
      return '#00A6EB' // light blue
    default:
      return '#000000' // backlog
  }
}

export const getSeriesTypeByMetric = (metric: Metric) => {
  switch (metric) {
    case Metric.Utilization:
    case Metric.UtilizationWithForecast:
    case Metric.AccumulatedUtilization:
    case Metric.AccumulatedUtilizationWithForecast:
      return 'line'
    default:
      return 'bar'
  }
}

export const getStackGroupByMetric = (metric: Metric) => {
  switch (metric) {
    case Metric.CapacityFree:
    case Metric.CapacityFreeWithForecast:
    case Metric.CapacityOverload:
    case Metric.CapacityOverloadWithForecast:
      return 'delta'
    case Metric.CapacityTransferIn:
    case Metric.CapacityTransferOut:
      return 'transfer'
    case Metric.CapacitySupermarket:
    case Metric.CapacitySupermarketIncrease:
    case Metric.CapacitySupermarketReduction:
      return 'supermarket'
    case Metric.Utilization:
    case Metric.UtilizationWithForecast:
      return undefined
    case Metric.CapacityAvailable:
      return 'available'
    case Metric.CapacityDemand:
    case Metric.CapacityForecaset:
    default:
      return 'demand'
  }
}

export const getYAxisRightByMetric = (metric: Metric) => {
  switch (metric) {
    case Metric.Utilization:
    case Metric.UtilizationWithForecast:
    case Metric.AccumulatedUtilization:
    case Metric.AccumulatedUtilizationWithForecast:
      return true
    case Metric.CapacityAvailable:
    case Metric.CapacityDemand:
    case Metric.CapacityForecaset:
    default:
      return false
  }
}

export const getMetricType = (metric: Metric) => {
  switch (metric) {
    case Metric.Utilization:
    case Metric.UtilizationWithForecast:
    case Metric.AccumulatedUtilization:
    case Metric.AccumulatedUtilizationWithForecast:
      return 'utilization'
    case Metric.AccumulatedBacklog:
    case Metric.AccumulatedBacklogWithForecast:
      return 'backlog'
    default:
      return 'capacity'
  }
}

export enum TimeframeLevel {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Week = 'WEEK',
}

export enum TimeframeSpan {
  FiveYears = '5y',
  ThreeYears = '3y',
  OneYear = '1y',
  SixMonths = '6m',
  ThreeMonths = '3m',
  OneMonths = '1m',
}

export interface ITimeframe {
  key?: number
  start: Date | null
  end: Date | null
  span: TimeframeSpan | null
  level: TimeframeLevel
}

export interface IRouteParams {
  sites?: string[]
  forecast?: boolean
  timeframe?: ITimeframe
  filter?: {
    [key: string]: string[]
  }
  tileConfig?: {
    [key: string]: string[]
  }
}

export enum TileConfigKey {
  // Regional dashboard config keys
  RegionalMultiChartSelection = 'RegionalMultiChartSelection',
  RegionalDrilldownChartSelection = 'RegionalDrilldownChartSelection',
  RegionalDrilldownChartColumns = 'RegionalDrilldownChartColumns',
  RegionalDeltaCapacityAcc = 'RegionalDeltaCapacityAcc',
  RegionalTimetravelReferenceDate = 'RegionalTimetravelReferenceDate',
  RegionalDrilldownChartRowsPerPage = 'RegionalDrilldownChartRowsPerPage',

  // Global config keys
  GlobalUtilizationChartSelection = 'GlobalUtilizationChartSelection',
  GlobalUtilizationChartTimeframe = 'GlobalUtilizationChartTimeframe',
  GlobalRowsPerPageSites = 'GlobalRowsPerPageSites',
  GlobalTopSitesTimeframe = 'GlobalTopSitesTimeframe',
  GlobalTopSitesTableSortDirection = 'GlobalTopSitesTableSortDirection',
  GlobalRowsPerPageUtils = 'GlobalRowsPerPageUtils',
  GlobalTopUtilsTimeframe = 'GlobalTopUtilsTimeframe',
  GlobalDrilldownTableSelection = 'GlobalDrilldownTableSelection',
  GlobalDrilldownTableSortType = 'GlobalDrilldownTableSortType',
  GlobalDrilldownTableSortDirection = 'GlobalDrilldownTableSortDirection',
  GlobalDrilldownShowAllSites = 'GlobalDrilldownShowAllSites',
}

export const getInitialRouteTimeframe = () => ({
  span: TimeframeSpan.OneYear,
  level: TimeframeLevel.Month,
  start: null,
  end: null,
})

export const getInitialRouteParams: () => IRouteParams = () => ({
  sites: [],
  forecast: true,
  timeframe: getInitialRouteTimeframe(),
  filter: {},
  tileConfig: {},
})

const today = new Date()

export const DefaultTimeframes: ITimeframe[] = [
  {
    key: 0,
    start: new Date(today.getFullYear(), 0, 1),
    end: new Date(today.getFullYear() + 5, 0, 0),
    span: TimeframeSpan.FiveYears,
    level: TimeframeLevel.Year,
  },
  {
    key: 1,
    start: new Date(
      today.getFullYear(),
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3),
      1
    ),
    end: new Date(
      today.getFullYear() + 3,
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3),
      0
    ),
    span: TimeframeSpan.ThreeYears,
    level: TimeframeLevel.Quarter,
  },
  {
    key: 2,
    start: new Date(
      today.getFullYear(),
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3),
      1
    ),
    end: new Date(
      today.getFullYear() + 1,
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3),
      0
    ),
    span: TimeframeSpan.OneYear,
    level: TimeframeLevel.Quarter,
  },
  {
    key: 3,
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear() + 1, today.getMonth(), 0),
    span: TimeframeSpan.OneYear,
    level: TimeframeLevel.Month,
  },
  {
    key: 4,
    start: moment(today).startOf('week').toDate(),
    end: moment(new Date(new Date(today).setFullYear(today.getFullYear() + 1)))
      .endOf('week')
      .toDate(),
    span: TimeframeSpan.OneYear,
    level: TimeframeLevel.Week,
  },
  {
    key: 5,
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 6, 0),
    span: TimeframeSpan.SixMonths,
    level: TimeframeLevel.Month,
  },
  {
    key: 6,
    start: moment(today).startOf('week').toDate(),
    end: moment(new Date(new Date(today).setMonth(today.getMonth() + 6)))
      .endOf('week')
      .toDate(),
    span: TimeframeSpan.SixMonths,
    level: TimeframeLevel.Week,
  },
  {
    key: 7,
    start: moment(today).startOf('week').toDate(),
    end: moment(new Date(new Date(today).setMonth(today.getMonth() + 3)))
      .endOf('week')
      .toDate(),
    span: TimeframeSpan.ThreeMonths,
    level: TimeframeLevel.Week,
  },
  {
    key: 8,
    start: moment(today).startOf('week').toDate(),
    end: moment(new Date(new Date(today).setMonth(today.getMonth() + 1)))
      .endOf('week')
      .toDate(),
    span: TimeframeSpan.OneMonths,
    level: TimeframeLevel.Week,
  },
]

export const OneSpanTimeframes: ITimeframe[] = [
  {
    key: 0,
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear() + 1, today.getMonth(), 0),
    span: TimeframeSpan.OneYear,
    level: TimeframeLevel.Month,
  },
  {
    key: 1,
    start: new Date(
      today.getFullYear(),
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3),
      1
    ),
    end: new Date(
      today.getFullYear(),
      ~~(Math.ceil((today.getMonth() + 1) / 3 - 1) * 3) + 3,
      0
    ),
    span: TimeframeSpan.ThreeMonths,
    level: TimeframeLevel.Quarter,
  },
  {
    key: 2,
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    span: TimeframeSpan.OneMonths,
    level: TimeframeLevel.Month,
  },
]
