enum Routes {
  LOGIN = '/login',
  MAP = '/map',
  CAPACITY_DASHBOARD = '/capacity-dashboard',
  CAPACITY_DASHBOARD_REGIONAL = '/capacity-dashboard/regional/:sites?/:forecast?/:timeframe?/:filter?/:tileConfig?/:forceUrl?',
  CAPACITY_DASHBOARD_GLOBAL = '/capacity-dashboard/global/:sites?/:forecast?/:filter?/:tileConfig?/:forceUrl?',
  CAPABILITY_MATRIX = '/capability-matrix',
  LOCAL_CAPABILITIES = '/local-capabilities',
  CAPACITY_MANAGEMENT = '/capacity-management',
  CAPACITY_MANAGEMENT_AVAILABLE = '/capacity-management/available',
  CAPACITY_MANAGEMENT_DEMAND = '/capacity-management/demand',
  CAPACITY_MANAGEMENT_FORECAST = '/capacity-management/forecast',
  CAPACITY_MANAGEMENT_WORKDAYS = '/capacity-management/workdays',
  CAPACITY_MANAGEMENT_DEMAND_TRANSFER = '/capacity-management/demandTransfer',
  CAPACITY_MANAGEMENT_DEMAND_SUPERMARKET = '/capacity-management/demandSupermarket',
  CAPABILITY_MANAGEMENT = '/capability-management',
  SITE_MANAGEMENT = '/site-management',
  DIVISION_MANAGEMENT = '/division-management',
  WORKCENTER_MANAGEMENT = '/workcenter-management',
  USER_MANAGEMENT = '/user-management',
  SAP_IMPORT_REPORT = '/sap-import-report',
}

export default Routes
