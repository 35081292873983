import { action, observable } from 'mobx'
import flatten from 'lodash/flatten'

import { ISite, getSite } from 'interfaces/Site'
import RootStore from './RootStore'
import SiteService from 'services/SiteService'
import { IRegion, ICountry } from 'interfaces/Common'

export default class SiteStore {
  rootStore: RootStore
  @observable allSites: ISite[] = []
  @observable allLocalUserSites: ISite[] = []
  @observable modalOpen: boolean = false
  @observable site: ISite = getSite()
  @observable newSite: boolean = false
  @observable allRegions: Array<any> = []
  @observable countryCodeMap: {
    [countryName: string]: string
  } = {}

  siteService = new SiteService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.allSites = []
  }

  @action
  setAllSites() {
    this.rootStore.setSitesLoading(true)
    this.siteService.getAllSites().then((sites: any) => {
      this.allSites = sites
      this.allSites = this.allSites.sort((a, b) => (a.name < b.name ? -1 : 1))
      this.rootStore.setSitesLoading(false)
    })
  }

  @action
  setModalSite(value: any) {
    this.site = value
    this.newSite = false
    this.modalOpen = true
  }

  @action
  setModalNewSite() {
    this.site = getSite()
    this.newSite = true
    this.modalOpen = true
  }

  @action
  setModalClose() {
    this.modalOpen = false
  }

  @action
  setAllRegions() {
    this.rootStore.setSitesLoading(true)
    this.siteService.getAllRegions().then((regions: any) => {
      const allCountries: ICountry[] = flatten(
        (regions || []).map((region: IRegion) => region.countries)
      )
      const countryCodeMap = Object.fromEntries(
        allCountries.map((country: ICountry) => [country.name, country.code])
      )

      this.allRegions = regions || []
      this.countryCodeMap = countryCodeMap
      this.rootStore.setSitesLoading(false)
    })
  }

  @action
  setAllLocalUserSites(userId: string) {
    this.rootStore.setSitesLoading(true)
    this.siteService.setAllLocalUserSites(userId).then((sites: any) => {
      this.allLocalUserSites = sites
      this.rootStore.setSitesLoading(false)
    })
  }
}
