import html2canvas from 'html2canvas'
import moment from 'moment'

import pdfmake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { Content, ContentImage, StyleDictionary } from 'pdfmake/interfaces'
import { ChartDataSets } from 'chart.js'

// init pdf make fonts
pdfmake.vfs = pdfFonts.pdfMake.vfs

export const createPdf: (content: Content) => Promise<void> = async (
  content
) => {
  const timestamp = moment()
  const styles: StyleDictionary = {
    header: {
      fontSize: 18,
      bold: true,
      margin: [0, 32, 0, 10],
    },
    subheader: {
      fontSize: 15,
      bold: true,
      margin: [0, 24, 0, 8],
    },
    h1: {
      fontSize: 18,
      bold: true,
      margin: [0, 24, 0, 10],
    },
    h2: {
      fontSize: 15,
      bold: true,
      margin: [0, 24, 0, 8],
    },
    h3: {
      fontSize: 12,
      bold: true,
      margin: [0, 12, 0, 8],
    },
    table: {
      margin: [0, 0, 0, 8],
    },
    th: {
      bold: true,
      fontSize: 13,
    },
    small: {
      fontSize: 10,
    },
  }
  const docDefinition = {
    info: {
      title: 'Huebner Capacity Report',
      author: 'Huebner',
      subject: `Huebner Capacity Report from ${timestamp.format('L')}`,
      keywords: 'keywords for document',
    },
    content: content,
    styles: styles,
    defaultStyle: {
      font: 'Roboto',
    },
  }

  const pdf = pdfmake.createPdf(docDefinition)

  pdf.download(
    `huebner-capacity-report-${timestamp.format(
      'YYYY-MM-DD'
    )}-${timestamp.format('HH')}h-${timestamp.format('mm')}m.pdf`
  )
}

export const getContentByChart: (chart: any) => Content[] = (chart) => {
  const svgIcon = (
    color: string
  ) => `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="${color}"/>
      <stop offset="1" stop-color="${color}" stop-opacity="0.502"/>
    </linearGradient>
  </defs>
  <rect id="Label" width="10" height="10" rx="3" fill="url(#linear-gradient)"/>
</svg>`

  interface DatasetExtended extends ChartDataSets {
    $data?: any
  }

  const { config } = chart
  const { data } = config || {}
  const { datasets } = data || {}
  const cells = datasets.map((series: DatasetExtended) => {
    const { $data, borderColor = '#cccccc', label } = series || {}
    const { unit = '' } = $data || {}

    return [{ svg: svgIcon(borderColor.toString()) }, `${label} [${unit}]`]
  })
  const chartContent: Content[] = [
    {
      style: 'table',
      table: {
        body: [
          [
            { text: 'Label', style: 'th' },
            { text: 'Name', style: 'th' },
          ],
          ...cells,
        ],
      },
    },
    {
      image: chart.toBase64Image(),
      fit: [520, chart.height],
    },
  ]

  return chartContent
}

export const getContentImageByHtml: (
  element: any
) => Promise<ContentImage | Content> = async (element) => {
  if (!element) {
    return []
  }

  const rect = element.getBoundingClientRect()
  const canvas = await html2canvas(element as HTMLElement, {
    width: rect.width,
    height: rect.height,
  })
  const htmlAsImage: Content = {
    image: canvas.toDataURL('image/png'),
    fit: [520, rect.height],
  }

  return htmlAsImage
}
