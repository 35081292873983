import React, { useEffect, useState } from 'react'
import { getSite, ISite } from 'interfaces/Site'
import { IErrorMessage } from 'interfaces/Common'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ConfirmButton } from 'styles/createMyTheme'
import TrashIcon from 'assets/icons/trash.svg'
import { inject, observer } from 'mobx-react'
import { Stores } from '../stores/stores'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import styles from 'styles/dialog.module.scss'
import {
  IWorkcenter,
  WorkcenterState,
  WorkcenterType,
  getEmptyWorkcenter,
} from 'interfaces/Workcenter'
import CapacityStore from 'stores/CapacityStore'
import WorkcenterService from 'services/WorkcenterService'
import TextDisplayEdit from './TextDisplayEdit'

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
export const checkedIcon = <CheckBoxIcon fontSize="small" />

interface SiteDialogProps {
  siteId: string
  allSites: ISite[]
  refreshTable: () => void
  deleteDisable?: boolean
  capacityStore?: CapacityStore
}

const SiteDialog: React.FC<SiteDialogProps> = inject(
  Stores.capacityStore,
  Stores.authenticationStore
)(
  observer((props: SiteDialogProps) => {
    const {
      siteId,
      allSites,
      deleteDisable,
      refreshTable,
      capacityStore,
    } = props
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [selectedTargetSite, setSelectedTargetSite] = useState<ISite>(
      getSite()
    )
    const [selectedTargetWorkcenter, setSelectedTargetWorkcenter] = useState<
      IWorkcenter
    >(getEmptyWorkcenter())
    const [targetSites, setTargetSites] = useState<ISite[]>([])
    const [targetWorkcenters, setTargetWorkcenters] = useState<IWorkcenter[]>(
      []
    )
    const [transferFactor, setTransferFactor] = useState<string>('100')

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(
      false
    )
    const [errorMessages, setErrorMessages] = useState<IErrorMessage[]>([])

    const workcenterService = new WorkcenterService()

    useEffect(() => {
      // Load target sites
      const sites = allSites.filter((fSite) =>
        fSite.sitesForReceivingCapacities?.includes(siteId)
      )

      setTargetSites(sites)
      const selectedTargetSite = sites.filter(
        (site: ISite) =>
          site._id === capacityStore!.currentWorkcenterMapping.targetSiteId
      )[0]
      setSelectedTargetSite(selectedTargetSite)
    }, [allSites, siteId])

    useEffect(() => {
      // Info: This part leads to an error in the console because it's async. The code should be safe in this way but still triggers the error.
      if (
        capacityStore?.isModalOpen &&
        capacityStore!.currentWorkcenterMapping.targetSiteId
      ) {
        workcenterService
          .getSiteWorkcenters(
            capacityStore!.currentWorkcenterMapping.targetSiteId
          )
          .then((wcs) => {
            setTargetWorkcenters(
              wcs.filter(
                (wc: IWorkcenter) =>
                  wc.state === WorkcenterState.active &&
                  wc.type === WorkcenterType.workcenter
              )
            )

            const selectedTargetSite = targetSites.filter(
              (site: ISite) =>
                site._id ===
                capacityStore!.currentWorkcenterMapping.targetSiteId
            )[0]
            setSelectedTargetSite(selectedTargetSite)

            setSelectedTargetWorkcenter(
              wcs.filter(
                (wc: IWorkcenter) =>
                  wc._id ===
                  capacityStore!.currentWorkcenterMapping.targetWorkCenterId
              )[0]
            )
          })
      } else {
        setTargetWorkcenters([])

        setSelectedTargetSite(getSite())

        setSelectedTargetWorkcenter(getEmptyWorkcenter())
      }
    }, [capacityStore?.isModalOpen, targetSites])

    useEffect(() => {
      if (capacityStore?.isNewTargetWorkcenter) {
        setEditMode(true)
      } else {
        setEditMode(false)
      }
    }, [capacityStore?.isModalOpen])

    useEffect(() => {
      setTransferFactor(capacityStore!.currentWorkcenterMapping.transferFactor)
    }, [capacityStore!.currentWorkcenterMapping])

    const resetState = () => {
      capacityStore?.setModalClose()
      setSelectedTargetWorkcenter(getEmptyWorkcenter())
      setSelectedTargetSite(getSite())
      setEditMode(false)
      setDeleteMode(false)
    }

    const handleClose = () => {
      resetState()
    }

    const handleCloseWithRefresh = () => {
      refreshTable()
      resetState()
    }

    const addNewTargetWorkcenter = async () => {
      props.capacityStore!.currentWorkcenterMapping.targetSiteId =
        selectedTargetSite._id
      props.capacityStore!.currentWorkcenterMapping.targetWorkCenterId =
        selectedTargetWorkcenter._id
      props.capacityStore!.currentWorkcenterMapping.transferFactor = transferFactor

      if (props.capacityStore!.currentWorkcenterMapping._id === '') {
        await workcenterService.createWorkcenterMapping({
          originSiteId: props.capacityStore!.currentWorkcenterMapping
            .originSiteId,
          originWorkCenterId: props.capacityStore!.currentWorkcenterMapping
            .originWorkCenterId,
          targetSiteId: selectedTargetSite._id,
          targetWorkCenterId: selectedTargetWorkcenter._id,
          transferFactor: transferFactor,
        })
      } else {
        await workcenterService.createWorkcenterMapping(
          props.capacityStore!.currentWorkcenterMapping
        )
      }

      handleCloseWithRefresh()
    }

    const handleTargetWorkcenterUpdate = async () => {
      props.capacityStore!.currentWorkcenterMapping.targetSiteId =
        selectedTargetSite._id
      props.capacityStore!.currentWorkcenterMapping.targetWorkCenterId =
        selectedTargetWorkcenter._id
      props.capacityStore!.currentWorkcenterMapping.transferFactor = transferFactor

      await workcenterService.updateWorkcenterMapping(
        capacityStore!.currentWorkcenterMapping
      )
      handleCloseWithRefresh()
    }

    const targetWorkcenterDeletion = async () => {
      workcenterService.deleteWorkcenterMapping(
        capacityStore!.currentWorkcenterMapping._id
      )
      handleCloseWithRefresh()
    }

    function targetSiteToSave(name: string) {
      setSelectedTargetWorkcenter(getEmptyWorkcenter())

      const selectedTargetSite = targetSites.filter(
        (site: ISite) => site.name === name
      )[0]
      setSelectedTargetSite(selectedTargetSite)

      const targetSiteId: string = targetSites.filter(
        (site: ISite) => site.name === name
      )[0]._id

      workcenterService.getSiteWorkcenters(targetSiteId).then((wcs) => {
        setTargetWorkcenters(
          wcs.filter(
            (workcenter: IWorkcenter) =>
              workcenter.state === WorkcenterState.active &&
              workcenter.type === WorkcenterType.workcenter
          )
        )
      })
    }

    function targetWorkcenterToSave(name: string) {
      setSelectedTargetWorkcenter(
        targetWorkcenters.filter(
          (workcenter: IWorkcenter) => workcenter.name === name
        )[0]
      )
    }

    function transferFactorToSave(val: string) {
      setTransferFactor(val)
    }

    const renderSiteOptions = () => {
      return targetSites.map((site: ISite, index: number) => (
        <MenuItem key={index + site.name} value={site.name}>
          {site.name}
        </MenuItem>
      ))
    }

    const renderWorkcenterOptions = () => {
      return targetWorkcenters.map((workcenter: IWorkcenter, index: number) => (
        <MenuItem key={index + workcenter.name} value={workcenter.name}>
          {`${workcenter.number} - ${workcenter.name}`}
        </MenuItem>
      ))
    }

    const Mandatory = () => <span style={{ color: 'red' }}> *</span>

    const renderInfo = () => {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography>
                {t('capacity-management:target-site')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Select
                  fullWidth
                  labelId="target-site"
                  id="workcenter-target-site"
                  value={selectedTargetSite ? selectedTargetSite.name : ''}
                  onChange={(event: any) =>
                    targetSiteToSave(event.target.value)
                  }>
                  {renderSiteOptions()}
                </Select>
              ) : (
                <Typography>
                  {selectedTargetSite ? selectedTargetSite.name : ''}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <Typography>
                {t('capacity-management:target-workcenter')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Select
                  disabled={
                    selectedTargetSite === undefined ||
                    selectedTargetSite.name === ''
                  }
                  fullWidth
                  labelId="target-workcenter"
                  id="workcenter-target-workcenter"
                  value={
                    selectedTargetWorkcenter
                      ? selectedTargetWorkcenter.name
                      : ''
                  }
                  onChange={(event: any) =>
                    targetWorkcenterToSave(event.target.value)
                  }>
                  {renderWorkcenterOptions()}
                </Select>
              ) : (
                <Typography>
                  {selectedTargetWorkcenter
                    ? `${selectedTargetWorkcenter.number} - ${selectedTargetWorkcenter.name}`
                    : ''}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <Typography>
                {t('capacity-management:transfer-factor')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                unit="%"
                value={transferFactor}
                onChange={(event: any) =>
                  transferFactorToSave(event.target.value)
                }
                edit={editMode}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    const getConfirmButton = () => {
      return (
        deleteMode && (
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              onClick={() => setConfirmDeleteDialogOpen(true)}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        )
      )
    }

    const getDialogActions = () => {
      if (editMode) {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              disabled={
                selectedTargetSite === undefined ||
                selectedTargetWorkcenter === undefined ||
                selectedTargetSite.name === '' ||
                selectedTargetWorkcenter.name === ''
                  ? true
                  : false
              }
              onClick={() => {
                capacityStore?.isNewTargetWorkcenter
                  ? addNewTargetWorkcenter()
                  : handleTargetWorkcenterUpdate()
              }}>
              {capacityStore?.isNewTargetWorkcenter ? t('create') : t('save')}
            </Button>
          </div>
        )
      } else {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setDeleteMode(false)
              }}>
              {t('edit')}
            </Button>
          </div>
        )
      }
    }

    const getConfirmDeleteDialog = () => {
      return (
        <Dialog
          open={confirmDeleteDialogOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={() => setConfirmDeleteDialogOpen(false)}
          maxWidth="lg">
          <DialogTitle className={styles['dialog-title-deletion']}>
            {t('capabilities:confirmToDelete')}
          </DialogTitle>
          <DialogContent>
            <Box minWidth="400px">
              <Typography>
                {t('capacity-management:delete-target-workcenter')}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className={styles['action-button-container']}>
              <Box mr="auto">
                <Button
                  variant="contained"
                  onClick={() => setConfirmDeleteDialogOpen(false)}>
                  {t('close')}
                </Button>
              </Box>
              <Box style={{ marginRight: '10px' }}>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    targetWorkcenterDeletion()
                    setConfirmDeleteDialogOpen(false)
                  }}>
                  {t('confirm')}
                </ConfirmButton>
              </Box>
            </div>
          </DialogActions>
        </Dialog>
      )
    }

    return (
      <React.Fragment>
        {/* actual edit/create modal */}
        <Dialog
          open={!!capacityStore?.isModalOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={handleClose}>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle className={styles['dialog-title']}>
              {capacityStore?.isNewTargetWorkcenter
                ? `${t('capacity-management:add-new-target-workcenter-for')} 
                  ${
                    props.capacityStore!.rootStore!.workcenterStore!
                      .workcenterId2Number[
                      props.capacityStore!.currentWorkcenterMapping
                        .originWorkCenterId
                    ]
                  }`
                : `${t('capacity-management:target-workcenter-info-for')}
                  ${
                    props.capacityStore!.rootStore!.workcenterStore!
                      .workcenterId2Number[
                      props.capacityStore!.currentWorkcenterMapping
                        .originWorkCenterId
                    ]
                  }`}
            </DialogTitle>
            {!capacityStore?.isNewTargetWorkcenter &&
              !deleteMode &&
              !deleteDisable && (
                <div
                  onClick={() => setDeleteMode(true)}
                  style={{
                    position: 'relative',
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}>
                  <img
                    src={TrashIcon}
                    alt="Filter"
                    style={{ width: 50, height: 40 }}
                  />
                </div>
              )}
          </Box>
          <DialogContent>{renderInfo()}</DialogContent>
          <DialogActions>{getDialogActions()}</DialogActions>
        </Dialog>

        {getConfirmDeleteDialog()}
      </React.Fragment>
    )
  })
)

export default SiteDialog
