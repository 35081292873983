export interface ICapability {
  _id?: string | null
  capId: string
  image?: string
  level: number
  name: string
  open: boolean
  description: string
  children: (IGlobalCapability | ILocalCapability)[]
  level0Id: string
  parent: string
  sourceNotSet: boolean
  internal: 0 | 1 | 2 | 3 // 0 = all children no, 1 = all children yes, 2 = all children undefined, 3 = one or more children yes (apart from one)
  external: 0 | 1 | 2 | 3
  divisionId: string
}

export function getCapability(): Capability {
  return {
    _id: '',
    capId: '',
    level: 0,
    name: '',
    open: false,
    description: '',
    children: [],
    level0Id: '',
    parent: '',
    sourceNotSet: true,
    internal: 2,
    external: 2,
    divisionId: '',
  }
}

export interface IGlobalCapability extends ICapability {
  capableSites: number
  capableRegions: string[]
}

export function getGlobalCapability(): IGlobalCapability {
  const capability = getCapability() as IGlobalCapability
  capability.capableSites = 0
  capability.capableRegions = []
  return capability
}

export interface ILocalCapability extends ICapability {}

export type Capability = ILocalCapability | IGlobalCapability

export enum CapabilityPages {
  MaintainCapability = 'GLOBAL_CAPABILITY',
  AssignCapability = 'LOCAL_CAPABILITY',
  ProcessMatrix = 'PROCESS_MATRIX',
}
