import React from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { ChartOptions } from 'chart.js'
import ReactChart from 'react-chartjs-2'

import { find, merge } from 'lodash'

import {
  ChartDataset,
  ChartAxisConfig,
  ChartHorizontalLine,
} from 'interfaces/CapacityDashboard'
import {
  ChartPlugin,
  abbreviateNumber,
  colorToRgba,
  tooltipRenderer,
} from 'utils/Chart'

import styles from 'styles/capacityDashboard.module.scss'

interface Props {
  chartInstance?: (chart: any) => void
  series?: ChartDataset[]
  labels?: string[]
  horizontalLines?: ChartHorizontalLine[]
  yAxesLabels?: string[]
  yAxesConfig?: ChartAxisConfig[]
}

export const Chart: React.FunctionComponent<Props> = (props: Props) => {
  const {
    chartInstance = () => {},
    series = [],
    labels = [],
    horizontalLines = [],
    yAxesLabels = [],
    yAxesConfig = [],
  } = props

  /**
   * S T A T E S
   */
  const [hiddenSets, setHiddenSets] = React.useState<string[]>([])

  const processedData = React.useMemo(
    () => (canvas?: any) => {
      const ctx = canvas.getContext('2d')

      return {
        labels,
        datasets: series.map((set: ChartDataset) => {
          const isHidden = set.hidden || set.tooltipOnly
          const fillPatternGradient = ctx.createLinearGradient(
            0,
            0,
            0,
            canvas.height
          )

          fillPatternGradient.addColorStop(
            0,
            colorToRgba(set.color || '#ffffff', 0.5)
          )
          fillPatternGradient.addColorStop(
            0.5,
            colorToRgba(set.color || '#ffffff', 1)
          )

          return {
            label: set.label,
            data: set.data || [],
            type: set.type || 'bar',
            stack: set.group,
            yAxisID: set.yAxisRight ? 'right-y-axis' : undefined,
            backgroundColor: fillPatternGradient,
            borderColor: set.color,
            barPercentage: 0.95,
            categoryPercentage: 0.8,
            hidden: isHidden || hiddenSets.includes(set.key),
            hideInLegendAndTooltip: set.hidden,
            // add additional metadata
            $data: {
              unit: set.unit,
              tooltip: {
                ...set.tooltip,
                sortByValue: set.tooltip
                  ? set.tooltip.sortByValue || false
                  : false,
              },
            },
          }
        }) as any[],
      }
    },
    [labels, series, hiddenSets]
  )

  const processedOptions = React.useMemo(
    () =>
      merge({}, defaultOptions, {
        horizontalLines: horizontalLines.map((line) => ({
          ...line,
          label: !line.yAxisLabel ? line.label : '',
        })),
        scales: {
          xAxes: defaultOptions.scales?.xAxes?.map(
            (axis: any, index: number) => ({
              ...axis,
              stacked: series.some((set) => !!set.group),
              ticks: {
                // TODO: enable cutom color for current date (v3.0 required): https://github.com/chartjs/Chart.js/issues/2442
                // fontColor: labels.map((_l, i) =>
                //   // check shich label matches the current date
                //   i === 0 ? '#00A6EB' : 'rgba(0,0,0,0.85)'
                // ),
                fontColor: 'rgba(0,0,0,0.85)',
              },
            })
          ),
          yAxes: defaultOptions.scales?.yAxes?.map(
            (axis: any, index: number) => ({
              ...axis,
              display: yAxesConfig[index]?.display,
              scaleLabel: {
                ...axis.scaleLabel,
                labelString: yAxesLabels[index] || '',
                display: !!yAxesLabels[index],
              },
              gridLines: {
                ...axis.gridLines,
                display: yAxesConfig[index]?.grid,
              },
              ticks: {
                ...axis.ticks,
                // TODO: enable cutom color for 100% tick (v3.0 required): https://github.com/chartjs/Chart.js/issues/2442
                // fontColor: '#51BF30',
                min: yAxesConfig[index]?.min,
                max: yAxesConfig[index]?.max,
                callback: (value: number) => {
                  const line = find(horizontalLines, { value: value })
                  const { yAxisLabel, label, yAxisRight } = line || {}
                  const isCorrectAxis =
                    (index === 0 && !yAxisRight) || (index > 0 && yAxisRight)

                  return line && yAxisLabel && isCorrectAxis
                    ? label
                    : abbreviateNumber(value)
                },
              },
            })
          ),
        },
      }),
    [horizontalLines, yAxesLabels, yAxesConfig, series]
  )

  const toggleSet = (setName: string) => {
    if (hiddenSets.includes(setName)) {
      setHiddenSets(hiddenSets.filter((s) => s !== setName))
    } else {
      setHiddenSets(hiddenSets.concat(setName))
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box
        display="flex"
        height="auto"
        flexGrow={0}
        flexShrink={0}
        flexBasis="auto"
        justifyContent="center">
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {series
            .filter((set) => !set.hidden && !set.tooltipOnly)
            .map((set, index) => {
              return (
                <Button
                  key={index}
                  className={`${styles['chart-legend-button']} ${
                    hiddenSets.includes(set.key)
                      ? styles['chart-legend-button-hidden']
                      : ''
                  }`}
                  size="small"
                  onClick={() => toggleSet(set.key)}>
                  <span
                    className={styles['chart-legend-key']}
                    style={{
                      background: `linear-gradient(0deg, ${colorToRgba(
                        set.color || '#cccccc',
                        0.5
                      )} 0%, ${set.color || '#cccccc'} 100%)`,
                      borderColor: set.color || '#cccccc',
                    }}></span>
                  <span className={styles['chart-legend-text']}>
                    {set.label}
                  </span>
                </Button>
              )
            })}
        </Box>
      </Box>
      <Box
        key={`chart-container-${series
          .filter((set) => !set.hidden && !set.tooltipOnly)
          .map((set, index) => set.key)
          .join('-')}`}
        display="flex"
        height={250}
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto">
        <ReactChart
          datasetKeyProvider={(set: ChartDataset) =>
            set.label || ~~(Math.random() * 100000)
          }
          data={processedData}
          type="bar"
          options={processedOptions}
          plugins={[
            {
              afterDraw: chartInstance,
              afterDatasetsDraw: (chart: any, easing: any) => {
                ChartPlugin.afterDatasetsDraw.drawHorizontalLine(chart, easing)
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

const defaultOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    position: 'nearest',
    mode: 'label',
    // disable the on-canvas tooltip (replace by custom renderer)
    enabled: false,
    custom: tooltipRenderer,
  },
  legend: {
    display: false,
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        type: 'linear',
        scaleLabel: {
          display: true,
        },
        display: true,
        position: 'left',
        gridLines: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 8,
        },
      },
      {
        type: 'linear',
        scaleLabel: {
          display: true,
        },
        display: true,
        position: 'right',
        id: 'right-y-axis',
        gridLines: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 8,
          suggestedMin: 0,
          suggestedMax: 125,
        },
      },
    ],
  },
}
