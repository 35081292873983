import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

import styles from 'styles/dialog.module.scss'
import { TimeType, CapacityType } from 'interfaces/Capacity'
import CapacityService from 'services/CapacityService'
import SiteService from 'services/SiteService'
import { ISite, ICapacityMaintenance } from 'interfaces/Site'
import { Stores } from '../stores/stores'
import AuthenticationStore from '../stores/AuthenticationStore'

interface ChangeMonthWeekViewDialogProps {
  onClose: () => void
  open: boolean
  capacityType: CapacityType
  timeType: TimeType
  currentSite: ISite
  authenticationStore: AuthenticationStore
  onUpdateSite: () => void
}

const ChangeMonthWeekViewDialog: React.FC<any> = inject(
  Stores.authenticationStore
)(
  observer((props: ChangeMonthWeekViewDialogProps) => {
    const {
      onClose,
      open,
      capacityType,
      timeType,
      currentSite,
      authenticationStore,
      onUpdateSite,
    } = props
    const { t } = useTranslation()
    const capacityService = new CapacityService()
    const siteService = new SiteService()

    // useEffect(() => {

    // }, [open])

    const handleClose = () => {
      onClose()
    }

    function switchTimeType(timeType: TimeType) {
      if (timeType === TimeType.month) return TimeType.week
      return TimeType.month
    }

    function updateCapMaintenance(
      capMaint: ICapacityMaintenance
    ): ICapacityMaintenance {
      // console.log('capMaint INPUT', { ...capMaint })
      // console.log('capacityType', capacityType)

      switch (capacityType) {
        case CapacityType.available:
          capMaint.available = switchTimeType(timeType)
          break

        case CapacityType.demand:
          capMaint.demand = switchTimeType(timeType)
          break

        case CapacityType.forecast:
          capMaint.forecast = switchTimeType(timeType)
          break

        case CapacityType.demand_transfer:
          capMaint.transfer = switchTimeType(timeType)
          break

        case CapacityType.demand_supermarket_share:
          capMaint.supermarket = switchTimeType(timeType)
          break
      }

      // console.log('capMaint OUTPUT', { ...capMaint })
      return capMaint
    }

    const triggerTimeTypeChange = () => {
      // TODO: For demand supermarket also migrate: CapacityType: DEMAND_PREPRODUCTION_CHANGE
      handleClose()
      authenticationStore!.rootStore.setCapacityMigrateLoading(true)
      capacityService
        .migrate({
          capacityType: capacityType,
          siteId: currentSite._id,
          timeType: switchTimeType(timeType),
        })
        .then((response) => {
          if (!response.error) {
            if (capacityType === CapacityType.demand_supermarket_share) {
              capacityService.migrate({
                capacityType: CapacityType.demand_preproduction_change,
                siteId: currentSite._id,
                timeType: switchTimeType(timeType),
              })
            }

            // Update site only if migration was successful.
            let site: ISite = { ...currentSite }
            site.capacityMaintenance = updateCapMaintenance(
              site.capacityMaintenance
            )

            // console.log('site', site)

            siteService.updateSite(site).then(async () => {
              await authenticationStore!.JWTLogin()
              onUpdateSite()
            })
          }
          authenticationStore!.rootStore.setCapacityMigrateLoading(false)
        })
    }

    const renderInfo = () => {
      return (
        <React.Fragment>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
              <Typography component={'span'}>
                {t('capacity-management:change-capacity-time-level-text')
                  .replace('%0%', t(`capacity-management:${capacityType}`))
                  .replace(
                    '%1%',
                    timeType === TimeType.month
                      ? t('capacity-management:weekly').toUpperCase()
                      : t('capacity-management:monthly').toUpperCase()
                  )}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    const getDialogActions = () => {
      return (
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button variant="contained" onClick={handleClose}>
              {t('cancel')}
            </Button>
          </Box>

          <Box style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => triggerTimeTypeChange()}>
              {t('confirm')}
            </Button>
          </Box>
        </div>
      )
    }

    return (
      <Dialog
        open={open}
        PaperProps={{ style: { borderRadius: '10px' } }}
        onBackdropClick={handleClose}
        fullWidth>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle className={styles['dialog-title-division']}>
            {t('capacity-management:migrate-capacities').replace(
              '%0%',
              t(`capacity-management:${capacityType}`)
            )}
          </DialogTitle>
        </Box>
        <DialogContent>{renderInfo()}</DialogContent>
        <DialogActions>{getDialogActions()}</DialogActions>
      </Dialog>
    )
  })
)

export default ChangeMonthWeekViewDialog
