import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import merge from 'lodash/merge'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import flatten from 'lodash/flatten'
import assign from 'lodash/assign'
import uniqBy from 'lodash/uniqBy'
import slugify from 'slugify'
import moment from 'moment'

import { ThemeProvider, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { DateRangePicker, DateRange } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TuneIcon from '@material-ui/icons/Tune'
import GetAppIcon from '@material-ui/icons/GetApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  IFilter,
  ITimeframe,
  Dimension,
  TimeframeLevel,
  TimeframeSpan,
  DefaultTimeframes,
  FilterOperator,
} from 'interfaces/CapacityDashboard'
import { ISite } from 'interfaces/Site'
import { ICapability, getCapability } from 'interfaces/Capabilities'
import { IDivision } from 'interfaces/Division'
import {
  IWorkcenter,
  WorkcenterType,
  getEmptyWorkcenter,
  WorkcenterState,
} from 'interfaces/Workcenter'
import TimeframeSelect from 'components/Dashboard/TimeframeSelect'
import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import SiteStore from 'stores/SiteStore'
import DivisionStore from 'stores/DivisionStore'
import CapabilityStore from 'stores/CapabilityStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import { getDebouncedFunction } from 'utils/Chart'
import { Content } from 'pdfmake/interfaces'
import { createPdf } from 'utils/PDF'

import myTheme from 'styles/createMyTheme'
import styles from 'styles/capacityDashboard.module.scss'

interface RenderTree {
  id: string
  name: string
  number?: string
  info?: string
  forceSelected?: boolean
  selectable?: boolean
  children?: RenderTree[]
}

interface Props {
  forecastChecked?: boolean
  selectedSites?: ISite[]
  selectedWorkcenter?: string[]
  selectedCapabilities?: string[]
  allSitesDisabled?: boolean
  workcenterFilterDisabled?: boolean
  capabilityFilterDisabled?: boolean
  pdfDownloadDisabled?: boolean
  pdfDownloadHidden?: boolean
  timeframeEnabled?: boolean
  timeframe?: ITimeframe
  onClearCache?: () => void
  onSitesChange?: (selectedSites: ISite[]) => void
  onForecastChange?: (isSelected: boolean) => void
  onTimeframeChange?: (timeframe: ITimeframe) => void
  onFilterChange?: (filter: IFilter[]) => void
  onSaveConfiguration?: () => void
  pdfContent?: () => Promise<Content>
  authenticationStore?: AuthenticationStore
  siteStore?: SiteStore
  divisionStore?: DivisionStore
  capabilityStore?: CapabilityStore
  workcenterStore?: WorkcenterStore
}

const Filterbar: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    forecastChecked = false,
    selectedSites = [],
    selectedWorkcenter = [],
    selectedCapabilities = [],
    allSitesDisabled = false,
    workcenterFilterDisabled = false,
    capabilityFilterDisabled = false,
    pdfDownloadDisabled = false,
    pdfDownloadHidden = false,
    timeframeEnabled = true,
    timeframe = {
      start: null,
      end: null,
      level: TimeframeLevel.Month,
      span: TimeframeSpan.OneYear,
    },
    onClearCache = () => {},
    onSitesChange = () => {},
    onForecastChange = () => {},
    onTimeframeChange = () => {},
    onFilterChange = () => {},
    onSaveConfiguration = () => {},
    pdfContent = () => [],
    authenticationStore,
    siteStore,
    divisionStore,
    capabilityStore,
    workcenterStore,
  } = props

  /**
   * H E L P E R S
   */
  const getCountryCode = (
    map: {
      [countryName: string]: string
    },
    countryName: string
  ) => {
    return map[countryName] || countryName
  }

  /**
   * S T A T E S
   */
  const [siteSelectionAnchor, setSiteSelectionAnchor] = React.useState<any>(
    null
  )
  const [filterSelectionAnchor, setFilterSelectionAnchor] = React.useState<any>(
    null
  )
  const [timelevelSelectOpen, setTimelevelSelectOpen] = React.useState<boolean>(
    false
  )
  const [optionsAnchor, setOptionsAnchor] = React.useState<any>(null)
  const [filterExpanded, setFilterExpanded] = React.useState<Dimension | null>(
    !capabilityFilterDisabled ? Dimension.Capability : null
  )
  const [expandedCapabilities, setExpandedCapabilities] = React.useState<
    string[]
  >([])
  const [expandedWorkcenters, setExpandedWorkcenters] = React.useState<
    string[]
  >([])

  /**
   * M E M O S
   */
  const allSites: ISite[] = siteStore!.allSites || []
  const sites: ISite[] = React.useMemo(() => allSites, [allSites])
  const timeframePreset: ITimeframe = React.useMemo(() => {
    const foundPreset = find(DefaultTimeframes, {
      span: timeframe.span,
      level: timeframe.level,
    })

    return foundPreset
      ? foundPreset
      : {
          key: 99,
          start: timeframe.start,
          end: timeframe.end,
          span: null,
          level: timeframe.level,
        }
  }, [timeframe.span, timeframe.level, timeframe.start, timeframe.end])
  const countryCodeMap = siteStore?.countryCodeMap || {}
  const workcentersBySite = workcenterStore?.workcentersBySite || {}
  const capsByDivision = capabilityStore?.capsByDivision || {}

  // CAPABILITIES
  const allDivisions = divisionStore?.allDivisions
  const countFetchedDivisionCaps = Object.keys(capsByDivision).length
  const relevantDivisions: IDivision[] = React.useMemo(() => {
    const divisions: IDivision[] = uniqBy(
      selectedSites
        .map((site) => site.division)
        .filter((division) => division && division._id),
      '_id'
    )

    return divisions.length > 0
      ? divisions
      : (allDivisions || []).filter((division) => division && division._id)
  }, [selectedSites, allDivisions])
  const capabilityTreeData: RenderTree[] = React.useMemo(() => {
    const mapCapability: (capability: ICapability) => RenderTree = (
      capability
    ) => ({
      id: capability._id || capability.capId,
      name: capability.name,
      info:
        capability.children.length > 0
          ? capability.children.length.toString()
          : '',
      forceSelected: false,
      selectable: true,
      children: capability.children.map(mapCapability),
    })

    return relevantDivisions.map((division) => {
      const divisionId: string = division._id || slugify(division.name)
      const capabilities = isArray(capsByDivision[divisionId])
        ? capsByDivision[divisionId]
        : []

      return {
        id: divisionId,
        name: division.name,
        info: capabilities.length.toString(),
        forceSelected: true,
        selectable: false,
        children: capabilities.map(mapCapability),
      }
    })
    // "countFetchedDivisionCaps" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [relevantDivisions, capsByDivision, countFetchedDivisionCaps])
  const selectedCapabilityNodes: ICapability[] = React.useMemo(() => {
    const flattenCaps: (capabilities: ICapability[]) => ICapability[] = (
      capabilities: ICapability[]
    ) => {
      const allChildren = flatten(
        capabilities.map((capability) => capability.children)
      )
      return allChildren.length > 0
        ? capabilities.concat(flattenCaps(allChildren))
        : capabilities
    }
    const flattenCapRoots = flatten(Object.values(capsByDivision))
    const flattenedCaps: ICapability[] = flattenCaps(flattenCapRoots)

    return selectedCapabilities.map(
      (id) =>
        find(flattenedCaps, { _id: id }) ||
        find(flattenedCaps, { capId: id }) ||
        assign(getCapability(), { _id: id, name: id })
    )
    // "countFetchedDivisionCaps" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [selectedCapabilities, capsByDivision, countFetchedDivisionCaps])

  // WORKCENTER
  const countFetchedSiteWorkcenters = Object.keys(workcentersBySite).length
  const workcenterTreeData: RenderTree[] = React.useMemo(() => {
    const foldWorkcenterTree: (
      allWorkcenters: IWorkcenter[],
      parentId: string
    ) => RenderTree[] = (allWorkcenters, parentId) => {
      return allWorkcenters
        .filter(
          (workcenter) =>
            workcenter.parentId === parentId ||
            (!parentId && !workcenter.parentId)
        )
        .map((workcenter) => {
          const children = foldWorkcenterTree(allWorkcenters, workcenter._id)
          return {
            id: workcenter._id,
            name: workcenter.name,
            number: workcenter.number,
            info:
              workcenter.type === WorkcenterType.group
                ? children.length.toString()
                : '',
            forceSelected: false,
            selectable: true,
            children: children,
          }
        })
    }

    return selectedSites.map((site) => {
      const workcentersOfSite = isArray(workcentersBySite[site._id])
        ? workcentersBySite[site._id].filter(
            (workcenter) => workcenter.state === WorkcenterState.active
          )
        : []

      return {
        id: site._id,
        name: `${getCountryCode(countryCodeMap, site.country)}, ${site.city}`,
        info: site.name,
        forceSelected: true,
        selectable: false,
        children: foldWorkcenterTree(workcentersOfSite, ''),
      }
    })
    // "countFetchedSiteWorkcenters" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [selectedSites, workcentersBySite, countFetchedSiteWorkcenters])
  const selectedWorkcenterNodes: IWorkcenter[] = React.useMemo(() => {
    const allWorkcenter = flatten(Object.values(workcentersBySite)).filter(
      (workcenter) => workcenter.state === WorkcenterState.active
    )

    return selectedWorkcenter.map(
      (id) =>
        find(allWorkcenter, { _id: id }) ||
        assign(getEmptyWorkcenter(), {
          _id: id,
          name: id,
        })
    )
    // "countFetchedSiteWorkcenters" is a mandatory dependency to recognize changes
    // eslint-disable-next-line
  }, [selectedWorkcenter, workcentersBySite, countFetchedSiteWorkcenters])
  const getAllSites = getDebouncedFunction(
    siteStore?.setAllSites,
    siteStore,
    'setAllSites'
  )
  const getAllRegions = getDebouncedFunction(
    siteStore?.setAllRegions,
    siteStore,
    'setAllRegions'
  )
  const getAllDivisions = getDebouncedFunction(
    divisionStore?.setAllDivisions,
    divisionStore,
    'setAllDivisions'
  )
  const getAllCapabilities = getDebouncedFunction(
    capabilityStore?.setAllCapsByDivisions,
    capabilityStore,
    'setAllCapsByDivisions'
  )
  const getAllWorkcenterSites = getDebouncedFunction(
    workcenterStore?.setAllBySites,
    workcenterStore,
    'setAllBySites'
  )

  /**
   * E F F E C T S
   */
  React.useEffect(() => {
    if (getAllSites) {
      getAllSites()
    }
  }, [getAllSites])
  React.useEffect(() => {
    if (getAllRegions) {
      getAllRegions()
    }
  }, [getAllRegions])
  React.useEffect(() => {
    if (getAllDivisions && selectedSites.length === 0) {
      getAllDivisions()
    }
  }, [selectedSites, getAllDivisions])
  React.useEffect(() => {
    if (getAllCapabilities) {
      const divisionIDs = relevantDivisions.map((d) => d._id).filter(isString)
      getAllCapabilities.cancel()
      getAllCapabilities(divisionIDs)
    }
  }, [relevantDivisions, getAllCapabilities])
  React.useEffect(() => {
    const selectedSiteIDs = selectedSites.map((s) => s._id)
    if (getAllWorkcenterSites && selectedSiteIDs.length > 0) {
      getAllWorkcenterSites.cancel()
      getAllWorkcenterSites(selectedSiteIDs)
    }
  }, [selectedSites, getAllWorkcenterSites])

  /**
   * M E T H O D S
   */

  const onFilterNodeUpdate = (dimension: Dimension, nodeId: string) => {
    const newFilter: IFilter[] = [
      workcenterFilterDisabled ? null : Dimension.Workcenter,
      capabilityFilterDisabled ? null : Dimension.Capability,
    ]
      .filter(isString)
      .map((filterDimension) => ({
        filterDimension: filterDimension as Dimension,
        operator: FilterOperator.EQ,
        values:
          (() => {
            switch (filterDimension) {
              case Dimension.Workcenter:
                if (filterDimension !== dimension) {
                  return selectedWorkcenter
                }
                return selectedWorkcenter.includes(nodeId)
                  ? selectedWorkcenter.filter((id) => id !== nodeId)
                  : selectedWorkcenter.concat(nodeId)
              case Dimension.Capability:
              default:
                if (filterDimension !== dimension) {
                  return selectedCapabilities
                }
                return selectedCapabilities.includes(nodeId)
                  ? selectedCapabilities.filter((id) => id !== nodeId)
                  : selectedCapabilities.concat(nodeId)
            }
          })() || [],
      }))

    onFilterChange(newFilter)
  }

  const renderTree = (
    nodes: RenderTree[],
    selected: string[],
    dimension: Dimension
  ) =>
    nodes.map((node) => (
      <TreeItem
        key={slugify(node.id || node.name)}
        className={styles['filter-tree-item']}
        nodeId={node.id}
        label={
          <Box
            className={styles['filter-tree-item-labelContent']}
            ml={2}
            mr={1}
            height={64}
            display="flex"
            alignItems="center">
            <Checkbox
              color="primary"
              checked={selected.includes(node.id) || node.forceSelected}
              disabled={node.selectable === false}
              onChange={() => {
                onFilterNodeUpdate(dimension, node.id)
              }}
              inputProps={{ 'aria-label': node.name }}
            />
            <div style={{ display: 'flex', flexFlow: 'column' }}>
              <Typography variant="body1">{node.name}</Typography>
              <Typography variant="body2" style={{ color: 'grey' }}>
                {node.number}
              </Typography>
            </div>
            <Box display="flex" flexGrow="1" />
            <Box p={1} display="flex">
              <Typography variant="caption" color="inherit">
                {node.info}
              </Typography>
            </Box>
            <Box
              className={styles['filter-tree-item-iconContainer']}
              p={2}
              display="flex">
              <ExpandMoreIcon
                className={styles['filter-tree-item-expandIcon']}
              />
              <ChevronRightIcon
                className={styles['filter-tree-item-collapseIcon']}
              />
            </Box>
          </Box>
        }>
        {isArray(node.children)
          ? renderTree(node.children, selected, dimension)
          : null}
      </TreeItem>
    ))

  const downloadAsPdf = async () => {
    const { currentUser } = authenticationStore || {}
    const { firstname = '', lastname = '', username = '' } = currentUser || {}
    const timestamp = moment()
    const filterContent: Content = [
      // TODO: add global timeframe if set
      {
        style: 'table',
        table: {
          body: [
            [
              { text: 'Date', style: 'th' },
              { text: 'Created by', style: 'th' },
              { text: 'Link to Dashboard', style: 'th' },
            ],
            [
              timestamp.format('L'),
              `${firstname} ${lastname} (${username})`,
              document.location.href.length < 4000
                ? [
                    {
                      qr: document.location.href.endsWith(':forceUrl?')
                        ? document.location.href.replace(/:forceUrl\?$/, 'true')
                        : `${document.location.href}/true`,
                      version:
                        document.location.href.length < 500
                          ? 12
                          : document.location.href.length < 1500
                          ? 16
                          : 40,
                      eccLevel: 'L',
                      fit: 180,
                    },
                    {
                      text: 'Open dashboard configuration by scanning QR code.',
                      style: 'small',
                    },
                  ]
                : {
                    text:
                      'URL is too long to generate QR code, due to too many filters.',
                    style: 'small',
                  },
            ],
          ],
        },
      },
      { text: 'Report Selection Overview', style: 'subheader' },
      { text: 'Selected Sites', style: 'h3' },
      {
        style: 'table',
        table: {
          body: [
            [
              { text: 'Name', style: 'th' },
              { text: 'Plant ID', style: 'th' },
              { text: 'ID', style: 'th' },
            ],
            ...(selectedSites.length > 0 ? [] : [['All sites', '*', '*']]),
            ...selectedSites.map((site) => [
              `${getCountryCode(countryCodeMap, site.country)}, ${site.city} (${
                site.name
              })`,
              site.plantId || '',
              site._id || '',
            ]),
          ],
        },
      },
      { text: 'Selected Capabilities', style: 'h3' },
      {
        style: 'table',
        table: {
          body: [
            [
              { text: 'Name', style: 'th' },
              { text: 'ID', style: 'th' },
            ],
            ...(selectedCapabilityNodes.length > 0
              ? []
              : [['All capabilities', '*']]),
            ...selectedCapabilityNodes.map((capability) => [
              `${capability.name} • ${t(
                'capacity-dashboard:filter-capacility'
              )} (${t('capacity-dashboard:filter-capacility-level', {
                level: capability.level,
              })})`,
              capability._id || '',
            ]),
          ],
        },
      },
      { text: 'Selected Workcenters', style: 'h3' },
      {
        style: 'table',
        table: {
          body: [
            [
              { text: 'Name', style: 'th' },
              { text: 'ID', style: 'th' },
            ],
            ...(selectedWorkcenterNodes.length > 0
              ? []
              : [['All workcenters', '*']]),
            ...selectedWorkcenterNodes.map((workcenter) => [
              `${workcenter.name} (${
                workcenter.type === WorkcenterType.group
                  ? t('capacity-dashboard:filter-workcenterGroup')
                  : t('capacity-dashboard:filter-workcenter')
              })`,
              workcenter._id || '',
            ]),
          ],
        },
      },
    ]

    const content = await pdfContent()
    const updatedContent = (content as any[])
      .slice(0, 1)
      .concat(filterContent)
      .concat((content as any[]).slice(1))

    await createPdf(updatedContent)
  }

  const closeCapWCFilter = () => {
    if (filterSelectionAnchor) setFilterSelectionAnchor(null)
  }

  const closeSiteFilter = () => {
    if (siteSelectionAnchor) setSiteSelectionAnchor(null)
  }

  return (
    <Paper>
      <Box
        p={1}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={'flex-end'}>
        <Box
          pr={2}
          className={`${styles['site-selection']} ${styles['filterbar-item']}`}
          display="flex"
          alignItems="center">
          <ThemeProvider<Theme>
            theme={merge({}, myTheme, {
              palette: { type: 'dark', text: { primary: '#ffffff' } },
            })}>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setSiteSelectionAnchor(event.currentTarget)
              }>
              <TuneIcon htmlColor="white" />
            </IconButton>
            <Box pr={2}>
              <Typography color="textPrimary">
                {(selectedSites.length === 0
                  ? t('capacity-dashboard:site-count-zero')
                  : t('capacity-dashboard:site-count', {
                      count: selectedSites.length,
                    })
                ).toUpperCase()}
              </Typography>
            </Box>
            {selectedSites.map((site) => (
              <Tooltip
                key={site._id}
                title={`${getCountryCode(countryCodeMap, site.country)}, ${
                  site.city
                }`}
                aria-label={`${getCountryCode(countryCodeMap, site.country)}, ${
                  site.city
                }`}>
                <Chip
                  className={styles['site-selection-chip']}
                  label={site.name}
                  size="small"
                  onDelete={
                    allSitesDisabled && selectedSites.length < 2
                      ? undefined
                      : () => {
                          const newSelection = selectedSites.filter(
                            (siteCompare) => siteCompare._id !== site._id
                          )

                          onSitesChange(newSelection)
                        }
                  }
                />
              </Tooltip>
            ))}
          </ThemeProvider>
          <Menu
            id="site-selection-menu"
            anchorEl={siteSelectionAnchor}
            keepMounted
            open={Boolean(siteSelectionAnchor)}
            onClose={() => setSiteSelectionAnchor(null)}>
            {sites.map((site) => (
              <MenuItem
                key={site._id}
                value={site._id}
                onClick={() => {
                  const newSelection: ISite[] = selectedSites.some(
                    (siteCompare) => siteCompare._id === site._id
                  )
                    ? selectedSites.filter(
                        (siteCompare) => siteCompare._id !== site._id
                      )
                    : selectedSites.concat(site)

                  onSitesChange(newSelection)
                }}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={selectedSites.some(
                      (siteCompare) => siteCompare._id === site._id
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': `site-select-item-${slugify(
                        site._id
                      )}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`site-select-item-${slugify(site._id)}`}
                  primary={site.name}
                  secondary={`${getCountryCode(
                    countryCodeMap,
                    site.country
                  )}, ${site.city}`}
                />
              </MenuItem>
            ))}
            <MenuItem style={{ justifyContent: 'flex-end' }}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={closeSiteFilter}>
                {t('ok').toUpperCase()}
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {!pdfDownloadHidden && (
          <Button
            className={styles['filterbar-item']}
            variant="text"
            color="primary"
            startIcon={<GetAppIcon />}
            disabled={pdfDownloadDisabled}
            onClick={downloadAsPdf}>
            {t('capacity-dashboard:download-pdf')}
          </Button>
        )}

        <Box display="flex" flexGrow={1}></Box>

        <FormControlLabel
          className={styles['filterbar-item']}
          control={
            <Switch
              color="primary"
              checked={forecastChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onForecastChange(event.target.checked)
              }
            />
          }
          label={t('capacity-dashboard:incl-fc')}
        />

        {timeframeEnabled && (
          <TimeframeSelect
            customOption
            selectedKey={timeframePreset.key || 0}
            onTimeframeChange={(
              event: React.ChangeEvent<{ value: unknown }>
            ) => {
              const preset: ITimeframe = find(DefaultTimeframes, {
                key: event.target.value as number,
              }) || {
                key: 99,
                start: timeframePreset.start,
                end: timeframePreset.end,
                span: null,
                level: timeframePreset.level,
              }

              onTimeframeChange(preset)
            }}
          />
        )}
        {timeframe && timeframePreset.key === 99 && (
          <>
            <FormControl
              className={styles['filterbar-item']}
              variant="outlined"
              size="small">
              {/* TODO: make date aware of local browser */}
              <DateRangePicker
                inputFormat="DD.MM.YYYY"
                startText="Start Date"
                endText="End Date"
                value={[timeframePreset.start, timeframePreset.end]}
                onChange={(dateRange: DateRange) => {
                  const [start, end] = dateRange

                  onTimeframeChange({
                    ...timeframePreset,
                    start: start ? start.toDate() : null,
                    end: end ? end.toDate() : null,
                  })
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      className={styles['filterbar-item']}
                      size="small"
                      {...startProps}
                      helperText={null}
                    />
                    <TextField
                      className={styles['filterbar-item']}
                      size="small"
                      {...endProps}
                      helperText={null}
                    />
                  </>
                )}
              />
            </FormControl>
            <FormControl
              className={styles['filterbar-item']}
              variant="outlined"
              size="small">
              <InputLabel id="timelevel-select-label">
                {t('capacity-management:timelevel')}
              </InputLabel>
              <Select
                labelId="timelevel-select-label"
                id="timelevel-select"
                open={timelevelSelectOpen}
                onClose={() => setTimelevelSelectOpen(false)}
                onOpen={() => setTimelevelSelectOpen(true)}
                value={timeframePreset.level}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  onTimeframeChange({
                    ...timeframePreset,
                    level: event.target.value as TimeframeLevel,
                  })
                }}
                label={t('capacity-management:timelevel')}>
                {Object.values(TimeframeLevel).map((level, index) => {
                  const levelText = t(
                    `capacity-management:timeframe-${level.toLowerCase()}`
                  )

                  return (
                    <MenuItem key={index} value={level}>
                      {levelText.charAt(0).toUpperCase() + levelText.slice(1)}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )}

        {(!capabilityFilterDisabled || !workcenterFilterDisabled) && (
          <Box
            pr={2}
            className={`${styles['filter-selection']} ${styles['filterbar-item']}`}
            display="flex"
            alignItems="center">
            <IconButton
              onClick={() =>
                setFilterSelectionAnchor(
                  document.getElementById('more-options-button')
                )
              }>
              <TuneIcon />
            </IconButton>
            <Box pr={2}>
              <Typography color="textPrimary">
                {(selectedCapabilities.length === 0 &&
                selectedWorkcenter.length === 0
                  ? t('capacity-dashboard:filter-count-zero')
                  : t('capacity-dashboard:filter-count', {
                      count:
                        selectedCapabilities.length + selectedWorkcenter.length,
                    })
                ).toUpperCase()}
              </Typography>
            </Box>
            {selectedCapabilityNodes.map(({ _id, capId, name, level }) => (
              <Tooltip
                key={_id || capId}
                title={`${t('capacity-dashboard:filter-capacility')} (${t(
                  'capacity-dashboard:filter-capacility-level',
                  {
                    level,
                  }
                )})`}
                aria-label={`${t('capacity-dashboard:filter-capacility')} (${t(
                  'capacity-dashboard:filter-capacility-level',
                  {
                    level,
                  }
                )})`}>
                <Chip
                  className={styles['filter-selection-chip']}
                  label={name}
                  size="small"
                  onDelete={() =>
                    onFilterNodeUpdate(Dimension.Capability, _id || capId)
                  }
                />
              </Tooltip>
            ))}
            {selectedWorkcenterNodes.map(({ _id, name, type }) => (
              <Tooltip
                key={_id}
                title={`${
                  type === WorkcenterType.group
                    ? t('capacity-dashboard:filter-workcenterGroup')
                    : t('capacity-dashboard:filter-workcenter')
                }`}
                aria-label={
                  type === WorkcenterType.group
                    ? t('capacity-dashboard:filter-workcenterGroup')
                    : t('capacity-dashboard:filter-workcenter')
                }>
                <Chip
                  className={styles['filter-selection-chip']}
                  key={_id}
                  label={name}
                  size="small"
                  onDelete={() => onFilterNodeUpdate(Dimension.Workcenter, _id)}
                />
              </Tooltip>
            ))}
          </Box>
        )}
        <Menu
          id="filter-selection-menu"
          anchorEl={filterSelectionAnchor}
          keepMounted
          open={Boolean(filterSelectionAnchor)}
          onClose={() => setFilterSelectionAnchor(null)}>
          {!capabilityFilterDisabled && (
            <ExpansionPanel
              className={styles['filter-panel']}
              square
              expanded={
                filterExpanded === Dimension.Capability ||
                workcenterFilterDisabled
              }
              onChange={() =>
                setFilterExpanded(
                  filterExpanded === Dimension.Capability
                    ? null
                    : Dimension.Capability
                )
              }>
              <ExpansionPanelSummary
                expandIcon={
                  workcenterFilterDisabled ? null : <ExpandMoreIcon />
                }>
                <Typography className={styles['filter-panel-title']}>
                  {t('capacity-dashboard:filter-capacilities')}
                </Typography>
                <Typography variant="overline">
                  {selectedCapabilities.length === 0
                    ? t('capacity-dashboard:filter-entries-selected-zero')
                    : t('capacity-dashboard:filter-entries-selected', {
                        count: selectedCapabilities.length,
                      })}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={styles['filter-panel-details']}>
                {capabilityTreeData.length > 0 && (
                  <TreeView
                    className={styles['filter-tree-root']}
                    disableSelection
                    expanded={expandedCapabilities}
                    onNodeToggle={(
                      event: React.ChangeEvent<{}>,
                      nodeIds: string[]
                    ) => {
                      const clickTarget = event.target as HTMLElement
                      const isCheckboxToggle =
                        clickTarget && clickTarget.nodeName === 'INPUT'
                          ? (clickTarget.attributes.getNamedItem('type') || {})
                              .value === 'checkbox'
                          : false

                      // prevent toggle expand if slection via checkbox was changed
                      if (!isCheckboxToggle) {
                        setExpandedCapabilities(nodeIds)
                      }
                    }}>
                    {renderTree(
                      capabilityTreeData,
                      selectedCapabilities,
                      Dimension.Capability
                    )}
                  </TreeView>
                )}
                {capabilityTreeData.length === 0 && (
                  <Box px={4} py={2}>
                    <Typography>No workcenters available</Typography>
                  </Box>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          {!workcenterFilterDisabled && (
            <ExpansionPanel
              className={styles['filter-panel']}
              square
              expanded={
                filterExpanded === Dimension.Workcenter ||
                capabilityFilterDisabled
              }
              onChange={() =>
                setFilterExpanded(
                  filterExpanded === Dimension.Workcenter
                    ? null
                    : Dimension.Workcenter
                )
              }>
              <ExpansionPanelSummary
                expandIcon={
                  capabilityFilterDisabled ? null : <ExpandMoreIcon />
                }>
                <Typography className={styles['filter-panel-title']}>
                  {t('capacity-dashboard:filter-workcenters')}
                </Typography>
                <Typography variant="overline">
                  {selectedWorkcenter.length === 0
                    ? t('capacity-dashboard:filter-entries-selected-zero')
                    : t('capacity-dashboard:filter-entries-selected', {
                        count: selectedWorkcenter.length,
                      })}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={styles['filter-panel-details']}>
                {workcenterTreeData.length > 0 && (
                  <TreeView
                    className={styles['filter-tree-root']}
                    expanded={expandedWorkcenters}
                    onNodeToggle={(
                      event: React.ChangeEvent<{}>,
                      nodeIds: string[]
                    ) => {
                      const clickTarget = event.target as HTMLElement
                      const isCheckboxToggle =
                        clickTarget && clickTarget.nodeName === 'INPUT'
                          ? (clickTarget.attributes.getNamedItem('type') || {})
                              .value === 'checkbox'
                          : false

                      // prevent toggle expand if slection via checkbox was changed
                      if (!isCheckboxToggle) {
                        setExpandedWorkcenters(nodeIds)
                      }
                    }}>
                    {renderTree(
                      workcenterTreeData,
                      selectedWorkcenter,
                      Dimension.Workcenter
                    )}
                  </TreeView>
                )}
                {workcenterTreeData.length === 0 && (
                  <Box px={4} py={2}>
                    <Typography>No workcenters available</Typography>
                  </Box>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <MenuItem style={{ justifyContent: 'flex-end' }}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={closeCapWCFilter}>
              {t('ok').toUpperCase()}
            </Button>
          </MenuItem>
        </Menu>

        <Box
          className={`${styles['filter-options']} ${styles['filterbar-item']}`}
          display="flex"
          alignItems="center">
          <IconButton
            id={'more-options-button'}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setOptionsAnchor(event.currentTarget)
            }>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="options-menu"
            anchorEl={optionsAnchor}
            keepMounted
            open={Boolean(optionsAnchor)}
            onClose={() => setOptionsAnchor(null)}>
            <MenuItem
              onClick={() => {
                onSaveConfiguration()
                setOptionsAnchor(null)
              }}>
              {t('capacity-dashboard:options-save-as-config')}
            </MenuItem>
            <MenuItem onClick={onClearCache}>
              {t('capacity-dashboard:options-refresh')}
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Paper>
  )
}

export default inject(
  Stores.authenticationStore,
  Stores.siteStore,
  Stores.divisionStore,
  Stores.capabilityStore,
  Stores.workcenterStore
)(observer(Filterbar))
