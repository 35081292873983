import { GeoJSON } from 'react-leaflet'
import america from './america.json'
import asia from './asia.json'
import europe from './europe.json'
import africa from './africa.json'
import middleEast from './middleEast.json'

export interface IRegionGeo {
  title: string
  data: GeoJSON.FeatureCollection
}
const americaGeo: IRegionGeo = {
  title: 'america',
  data: america as GeoJSON.FeatureCollection,
}
const asiaGeo: IRegionGeo = {
  title: 'asia',
  data: asia as GeoJSON.FeatureCollection,
}
const europeGeo: IRegionGeo = {
  title: 'europe',
  data: europe as GeoJSON.FeatureCollection,
}
const africaGeo: IRegionGeo = {
  title: 'africa',
  data: africa as GeoJSON.FeatureCollection,
}
const middleEastGeo: IRegionGeo = {
  title: 'middleEast',
  data: middleEast as GeoJSON.FeatureCollection,
}

export const Regions: IRegionGeo[] = [
  americaGeo,
  asiaGeo,
  europeGeo,
  africaGeo,
  middleEastGeo,
]
