import { ISite } from 'interfaces/Site'
import { IDivision, getDivision } from './Division'

export interface IUser {
  _id: string
  firstname: string
  lastname: string
  role: UserRoles
  username: string
  userBlocked: boolean
  timeOfCreation: number
  lastUpdate: number
  recovery: boolean
  sites: ISite[]
  divisions: IDivision
  preferredDivision: string
  p13n: {
    [key: string]: string
  }
}

export enum UserRoles {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
  VIEWER = 'VIEWER',
}

export const User: any = {
  username: '',
  firstname: '',
  lastname: '',
  role: UserRoles.LOCAL,
  userBlocked: false,
  timeOfCreation: 0,
  sites: [],
  divisions: getDivision(),
  preferredDivision: '',
  p13n: {},
}

export const getUser = (): IUser => {
  return {
    _id: '',
    firstname: '',
    lastname: '',
    role: UserRoles.LOCAL,
    username: '',
    userBlocked: false,
    timeOfCreation: 0,
    lastUpdate: 0,
    recovery: false,
    sites: [],
    divisions: getDivision(),
    preferredDivision: '',
    p13n: {},
  }
}
