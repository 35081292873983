import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import uniq from 'lodash/uniq'
import differenceBy from 'lodash/differenceBy'
import cloneDeep from 'lodash/cloneDeep'

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import OpenIcon from 'assets/icons/open.svg'
import CloseIcon from 'assets/icons/close.svg'
import TrashIcon from 'assets/icons/trash.svg'
import { IErrorMessage } from 'interfaces/Common'
import { getUser, IUser, UserRoles } from 'interfaces/User'
import UserService from 'services/UserService'
import EmailTest from 'utils/EmailTest'
import { ConfirmButton } from 'styles/createMyTheme'
import { Stores } from 'stores/stores'
import SiteStore from 'stores/SiteStore'
import { ISite } from 'interfaces/Site'
import AuthenticationStore from 'stores/AuthenticationStore'
import TextDisplayEdit from 'components/TextDisplayEdit'

import styles from 'styles/dialog.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface UserDialogProps {
  open: boolean
  onClose: () => void
  initialUser: IUser
  modalType: boolean
  updateList: () => void
  currentUser: IUser
  siteStore?: SiteStore
  authenticationStore?: AuthenticationStore
}

const UserDialog: React.FunctionComponent<UserDialogProps> = inject(
  Stores.siteStore,
  Stores.authenticationStore
)(
  observer((props: UserDialogProps) => {
    const getInitialUser = () => {
      return initialUser._id ? cloneDeep(initialUser) : getUser()
    }
    const {
      onClose,
      open,
      initialUser,
      modalType,
      updateList,
      currentUser,
      siteStore,
      authenticationStore,
    } = props
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [user, setUser] = useState<any>(getInitialUser())
    const [validData, setValidaData] = useState<boolean>(true)
    const [userBlock, setUserBlock] = useState<boolean>(user.userBlocked)
    const [siteOptions, setSiteOptions] = useState<any[]>([])
    const [selectedSiteOptions, setSelectedSiteOptions] = useState<any[]>([])
    const [searchString, setSearchString] = useState<string>('')
    const [errorMessages, setErrorMessages] = useState<IErrorMessage[]>([])
    const userService = new UserService()

    const handleValueChange = (
      field: 'firstname' | 'lastname' | 'username' | 'role' | 'sites',
      value: any
    ) => {
      const userCopy = cloneDeep(user)

      userCopy[field] = value

      setUser(userCopy)
    }

    const userHasAllSites = (sites: ISite[]) => {
      // all sites is already loaded through user dialog
      const allSites = siteStore!.allSites || []
      const userSitesDiff = differenceBy(allSites, sites, '_id')
      const userHasAllSites = userSitesDiff.length === 0

      return userHasAllSites
    }

    useEffect(() => {
      siteStore && siteStore.setAllSites()
    }, [siteStore])

    useEffect(() => {
      siteStore!.allSites &&
        siteStore!.allSites.length > 0 &&
        setSiteOptions(getSitesAsOptions(siteStore!.allSites))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteStore!.allSites])

    useEffect(() => {
      setSelectedSiteOptions(getSitesAsOptions(initialUser.sites))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUser])

    useEffect(() => {
      setUser(getInitialUser())
      setUserBlock(initialUser.userBlocked)
      modalType ? setEditMode(true) : setEditMode(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
      const userCopy = { ...user }
      userCopy['userBlocked'] = userBlock
      setUser(userCopy)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userBlock])

    const resetState = () => {
      setEditMode(false)
      setDeleteMode(false)
      setValidaData(true)
      setSelectedSiteOptions(getSitesAsOptions(initialUser.sites))
      onClose()
    }

    const handleClose = () => {
      resetState()
    }

    const registerNewUser = async () => {
      if (validationTest()) {
        if (await userService.registerUser(user)) updateList()
        handleClose()
      } else setValidaData(false)
    }

    const updateThisUser = async () => {
      if (validationTest()) {
        const userPayload = {
          ...user,
          sites: user.sites.map((site: ISite) => site._id),
        }
        if (await userService.updateUser(userPayload)) {
          updateList()
          if (user._id === authenticationStore!.currentUser._id) {
            authenticationStore!.JWTLogin()
            authenticationStore!.rootStore.divisionStore.setSelectedDivisionByName(
              user.preferredDivision
            )
          }
        }
        handleClose()
      } else setValidaData(false)
    }

    const userDeletion = async () => {
      if (await userService.deleteUser(user._id)) updateList()
      handleClose()
    }

    const validationTest = () => {
      let errorMessages = []

      const hasSites = user.role === 'GLOBAL' || (user.sites || []).length > 0

      if (!user.username) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['username'],
        })
      } else {
        if (!EmailTest.test(user.username)) {
          errorMessages.push({ error: 'fieldFormat', parameters: ['username'] })
        }
      }
      if (!user.firstname) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['firstname'],
        })
      }
      if (!user.lastname) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['lastname'],
        })
      }
      if (!user.role) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['role'] })
      }

      if (!hasSites) {
        errorMessages.push({ error: 'fieldMandatory', parameters: ['sites'] })
      }
      setErrorMessages(errorMessages)
      return errorMessages.length === 0
    }

    const sameUser = () => currentUser._id !== initialUser._id

    function getSitesAsOptions(sites: ISite[]): any[] {
      return sites && sites.length > 0
        ? sites.map((site: ISite) => ({
            value: site,
            title: `${site.name}, ${site.city}, ${site.division.name}`,
          }))
        : []
    }

    function getPreferredDivisionOptions(): string[] {
      const userSites: ISite[] =
        user.role === 'GLOBAL'
          ? siteStore!.allSites
          : siteStore!.allSites.filter((site: ISite) =>
              user.sites.some((userSite: ISite) => userSite._id === site._id)
            )
      const userDivisions = userSites.map((site) => site.division.name)

      return uniq(userDivisions) || []
    }

    function isSelected(option: any) {
      const site = option as ISite
      const matches =
        selectedSiteOptions.length >= 1
          ? selectedSiteOptions.map(
              (selection) => selection.value._id === site._id
            )
          : []
      return matches.includes(true)
    }

    function handleSelectOption(selection: any) {
      if (editMode) {
        let selectedCopy = selection
        const duplicateList =
          selection.length > 1
            ? selection.filter(
                (option: any) =>
                  option.value._id === selection[selection.length - 1].value._id
              )
            : []
        if (duplicateList.length > 1)
          selectedCopy = selection.filter(
            (option: any) => option.value._id !== duplicateList[0].value._id
          )
        setSelectedSiteOptions(selectedCopy)
        const selectionArray = selectedCopy.map((option: any) => option.value)
        handleValueChange('sites', selectionArray)
      }
    }

    function handleFilterInputChange(value: string) {
      if (editMode) setSearchString(value)
    }

    const chipsOnNoEditMode = () => {
      return (selectedSiteOptions || []).map((option: any) => (
        <Chip
          key={option.title}
          label={option.title}
          style={{ margin: '5px' }}
        />
      ))
    }

    const Mandatory = () => <span style={{ color: 'red' }}> *</span>

    const renderInfo = () => {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography>
              {t('profile:username')}
              {editMode && <Mandatory />}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {modalType ? (
              <TextDisplayEdit
                fullWidth
                required
                value={user.username}
                onChange={(event: any) =>
                  handleValueChange('username', event.target.value)
                }
                edit={modalType}
              />
            ) : (
              <Typography style={{ fontWeight: 'bold' }}>
                {user.username}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>
              {t('profile:firstName')}
              {editMode && <Mandatory />}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextDisplayEdit
              fullWidth
              required
              value={user.firstname}
              onChange={(event: any) =>
                handleValueChange('firstname', event.target.value)
              }
              edit={editMode}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>
              {t('profile:lastName')}
              {editMode && <Mandatory />}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextDisplayEdit
              fullWidth
              required
              value={user.lastname}
              onChange={(event: any) =>
                handleValueChange('lastname', event.target.value)
              }
              edit={editMode}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>
              {t('profile:role')}
              {editMode && <Mandatory />}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {editMode && sameUser() ? (
              <Select
                fullWidth
                labelId="role-select"
                id="user-role-select"
                value={
                  user.role
                    ? user.role
                    : handleValueChange('role', UserRoles.GLOBAL)
                }
                onChange={(event: any) => {
                  handleValueChange('sites', [])
                  setSelectedSiteOptions([])
                  handleValueChange('role', event.target.value)
                }}>
                <MenuItem value={UserRoles.GLOBAL}>Global</MenuItem>
                <MenuItem value={UserRoles.LOCAL}>Local</MenuItem>
                <MenuItem value={UserRoles.VIEWER}>Viewer</MenuItem>
              </Select>
            ) : (
              <Typography>{user.role}</Typography>
            )}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography>
              {t('profile:sites')}
              {editMode && user.role !== UserRoles.GLOBAL && <Mandatory />}
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ maxHeight: '215px', overflow: 'auto' }}>
            {user.role === UserRoles.GLOBAL ? (
              <Tooltip
                title={
                  userHasAllSites(user.sites)
                    ? `${t('users:allInfo')}`
                    : `${t('users:allInfoWithMissingSites')}`
                }
                placement="top-start">
                <Typography>
                  {t('users:all')} {!userHasAllSites(user.sites) && <>*</>}
                </Typography>
              </Tooltip>
            ) : !editMode ? (
              chipsOnNoEditMode()
            ) : (
              <Autocomplete
                multiple
                disableCloseOnSelect
                aria-required
                id={`user-site-select`}
                options={siteOptions}
                getOptionLabel={(option: any) => option.title || ''}
                value={selectedSiteOptions}
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  handleSelectOption(value)
                }
                inputValue={searchString}
                onInputChange={(event: React.ChangeEvent<{}>, value: string) =>
                  handleFilterInputChange(value)
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      color="primary"
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected(option.value)}
                    />
                    {option.title}
                  </React.Fragment>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={option.title} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={editMode ? t('profile:siteFilter') : ''}
                    margin="normal"
                    InputProps={{ ...params.InputProps }}
                    InputLabelProps={{ ...params.InputLabelProps }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>{t('profile:preferredDivision')}</Typography>
          </Grid>
          <Grid item xs={8}>
            {editMode ? (
              <Autocomplete
                options={getPreferredDivisionOptions()}
                getOptionLabel={(option: string) => option}
                value={user.preferredDivision || ''}
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                  (user.preferredDivision = value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputProps={{ ...params.InputProps }}
                    InputLabelProps={{ ...params.InputLabelProps }}
                    fullWidth
                  />
                )}
              />
            ) : (
              <Typography>{user.preferredDivision || ''}</Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography>{t('profile:userActive')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              {
                <img
                  onClick={() => {
                    editMode && sameUser() && setUserBlock(!userBlock)
                  }}
                  style={{
                    width: '30px',
                    cursor: editMode && sameUser() ? 'pointer' : '',
                  }}
                  src={userBlock ? CloseIcon : OpenIcon}
                  alt="Lock"
                />
              }
              {
                <span style={{ marginLeft: '20px' }}>
                  {t(`profile:${userBlock ? 'inactive' : 'active'}`)}
                </span>
              }
            </Box>
          </Grid>
          {!validData && (
            <Grid item xs={12}>
              {getErrors()}
            </Grid>
          )}
        </Grid>
      )
    }

    const getErrors = () => {
      const textGroup = 'users'
      return errorMessages.map((errMsg) => (
        <Typography color={'error'}>
          {errMsg.parameters
            ? errMsg.parameters.reduce(
                (p, c, i, a) => p.replace(`%${i}%`, t(`${textGroup}:${c}`)),
                t(`${textGroup}:${errMsg.error}`)
              )
            : t(`${textGroup}:${errMsg.error}`)}
        </Typography>
      ))
    }

    const getConfirmButton = () => {
      return (
        deleteMode &&
        sameUser() && (
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              color="secondary"
              onClick={() => userDeletion()}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        )
      )
    }

    const getDialogActions = () => {
      if (editMode) {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                modalType ? registerNewUser() : updateThisUser()
              }}>
              {modalType ? t('create') : t('save')}
            </Button>
          </div>
        )
      } else {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setDeleteMode(false)
              }}>
              {t('edit')}
            </Button>
          </div>
        )
      }
    }

    return (
      <Dialog
        open={open}
        PaperProps={{ style: { borderRadius: '10px' } }}
        onBackdropClick={handleClose}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle className={styles['dialog-title-user']}>
            {modalType ? t('users:addNewUser') : t('users:userInfo')}
          </DialogTitle>
          {!modalType && !deleteMode && sameUser() && (
            <div
              onClick={() => setDeleteMode(true)}
              style={{
                position: 'absolute',
                marginLeft: '545px',
                marginTop: '10px',
                cursor: 'pointer',
              }}>
              <img
                src={TrashIcon}
                alt="Filter"
                style={{ width: 50, height: 40 }}
              />
            </div>
          )}
        </Box>
        <DialogContent>{renderInfo()}</DialogContent>
        <DialogActions>{getDialogActions()}</DialogActions>
      </Dialog>
    )
  })
)

export default UserDialog
