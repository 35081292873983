import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import Routes from 'interfaces/Routes'

const PrivateRoute = inject(Stores.authenticationStore)(
  observer(
    ({
      component: Component,
      authenticationStore,
      loginRoute,
      ...rest
    }: any) => {
      return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
          {...rest}
          render={(props) =>
            authenticationStore.loggedIn ? (
              <Component {...props} {...rest} />
            ) : (
              <Redirect to={Routes.LOGIN} />
            )
          }
        />
      )
    }
  )
)

export default PrivateRoute
