import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import {
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { ArrowLeft, ArrowRight, Check, History, Undo } from '@material-ui/icons'
import DoubleArrowLeft from 'assets/icons/double_arrow_left.svg'
import DoubleArrowRight from 'assets/icons/double_arrow_right.svg'
import InfoIcon from '@material-ui/icons/Info'
import { DatePicker } from '@material-ui/pickers'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import ChipInput from 'material-ui-chip-input'

import {
  CapacityLegendColors,
  CapacityLegendNames,
  ICapacityLegend,
  TimeType,
} from 'interfaces/Capacity'

import styles from 'styles/capacitiesManagement.module.scss'

interface Props {
  referenceDate?: moment.Moment
  setReferenceDate?: (date: moment.Moment) => void
  setIsFormerView?: (mode: boolean) => void
  isDeltaIncluded?: boolean
  simpleControlBar?: boolean
  setIncludeDelta?: () => void
  isFormerView?: boolean
  isEditView?: boolean
  toggleFormerView?: () => void
  includeForecast?: boolean
  forecastDelta?: boolean
  isWorkDays?: boolean
  controlDate?: Date
  compareDateChange?: () => void
  controlDateMod?: (fast: boolean, subtract: boolean) => void
  timeType?: TimeType
  confirmChanges?: () => void
  enableConfirm?: boolean
  cancelChanges?: () => void
  loadLegendValues?: CapacityLegendNames[]
  infoText?: string
}

const Controlbar: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    includeForecast,
    forecastDelta,
    simpleControlBar,
    setIncludeDelta,
    isFormerView,
    setIsFormerView,
    isWorkDays,
    isEditView,
    referenceDate,
    setReferenceDate,
    controlDateMod,
    loadLegendValues,
    timeType,
    infoText = '',
  } = props

  const legendEntries: ICapacityLegend[] = [
    {
      name: CapacityLegendNames.ENTERED_HOURS,
      text: t('capacity-management:entered-hours'),
      color: CapacityLegendColors.BLACK,
    },
    {
      name: CapacityLegendNames.NEW_CAPACITY,
      text: t('capacity-management:new-capacity'),
      color: CapacityLegendColors.BLUE,
    },
    {
      name: CapacityLegendNames.ACTUAL_HOURS,
      text: t('capacity-management:actual-hours'),
      color: CapacityLegendColors.BLUE,
    },
    {
      name: CapacityLegendNames.FORMER_HOURS,
      text: t('capacity-management:former-hours'),
      color: CapacityLegendColors.BLACK,
    },
    {
      name: CapacityLegendNames.ADDED_SUPERMARKET,
      text: t('capacity-management:added-to-supermarket'),
      color: CapacityLegendColors.GREEN,
    },
    {
      name: CapacityLegendNames.PICKED_SUPERMARKET,
      text: t('capacity-management:picked-from-supermarket'),
      color: CapacityLegendColors.RED,
    },
    {
      name: CapacityLegendNames.HOUR_FREE_OFFER,
      text: t('capacity-management:hour-free-to-offer'),
      color: CapacityLegendColors.GREEN,
    },
    {
      name: CapacityLegendNames.OVERLOAD,
      text: t('capacity-management:overload'),
      color: CapacityLegendColors.RED,
    },
    {
      name: CapacityLegendNames.WITH_PLANNED_CHANGE,
      text: t('capacity-management:includes-planned-change'),
      color: CapacityLegendColors.BLACK,
    },
  ]

  function controlDateAddYear(): void {
    if (controlDateMod) controlDateMod(true, false)
  }
  function controlDateAddPeriod(): void {
    if (controlDateMod) controlDateMod(false, false)
  }
  function controlDateSubYear(): void {
    if (controlDateMod) controlDateMod(true, true)
  }
  function controlDateSubPeriod(): void {
    if (controlDateMod) controlDateMod(false, true)
  }

  function getButtonDateFormatted() {
    return timeType === TimeType.week
      ? 'CW ' +
          moment(props.controlDate).format('W') +
          ' ' +
          moment(props.controlDate).weekYear().toString()
      : moment(props.controlDate).format(isWorkDays ? 'YYYY' : 'YYYY MMM')
  }
  return (
    <div className={styles['controlbar-container']}>
      <div style={{ display: 'flex' }}>
        <div className={styles['controlbar-month-selector']}>
          {!isWorkDays && (
            <IconButton color="primary" onClick={controlDateSubYear}>
              <img src={DoubleArrowLeft} alt="" height="24" />
            </IconButton>
          )}
          <IconButton color="primary" onClick={controlDateSubPeriod}>
            <ArrowLeft />
          </IconButton>
          <Typography
            color="primary"
            noWrap
            className={styles['controlbar-month-selector-label']}>
            {getButtonDateFormatted()}
          </Typography>
          <IconButton color="primary" onClick={controlDateAddPeriod}>
            <ArrowRight />
          </IconButton>
          {!isWorkDays && (
            <IconButton color="primary" onClick={controlDateAddYear}>
              <img src={DoubleArrowRight} alt="" height="24" />
            </IconButton>
          )}
        </div>
        {!isWorkDays && (
          <>
            <ChipInput
              className={styles['controlbar-leyend-input']}
              readOnly={true}
              value={getLegendValues(loadLegendValues)}
              variant="outlined"
              label={t('capacity-management:legend')}
              chipRenderer={({ value }, key) =>
                getLegendLabelChipArray(key, value)
              }
            />
            {infoText.length > 0 && (
              <Box m={1}>
                <Tooltip title={infoText} interactive>
                  <IconButton aria-label="legend information" size="small">
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        )}
      </div>
      {!isWorkDays && (
        <div style={{ display: 'flex' }}>
          {!includeForecast && !isEditView && !simpleControlBar && (
            <Button
              className={styles['controlbar-data-button']}
              onClick={() => {
                setIsFormerView !== undefined &&
                  isFormerView !== undefined &&
                  setIsFormerView(!isFormerView)
              }}>
              <History style={{ marginRight: '5px' }} />
              <Typography style={{ fontWeight: 'bold' }}>
                {isFormerView
                  ? t('capacity-management:leave-former-view').toUpperCase()
                  : t('capacity-management:view-former-data').toUpperCase()}
              </Typography>
            </Button>
          )}
          {includeForecast && !isEditView && !simpleControlBar && (
            <FormControlLabel
              className={styles['controlbar-item']}
              control={<Switch color="primary" />}
              label={t('capacity-management:incl-fc')}
              value={forecastDelta}
              onClick={setIncludeDelta}
            />
          )}
          {isFormerView && (
            <DatePicker
              autoOk
              inputFormat="DD.MM.YYYY"
              label={t(t('capacity-management:timeframe'))}
              value={referenceDate || moment()}
              onChange={
                (date) =>
                  setReferenceDate
                    ? setReferenceDate(
                        date
                          ? moment(date)
                          : referenceDate
                          ? referenceDate
                          : moment()
                      )
                    : undefined // console.log(date)
              }
              renderInput={(props) => (
                // @ts-ignore
                <TextField
                  style={{ marginRight: '10px' }}
                  variant="outlined"
                  size="small"
                  {...props}
                  helperText={null}
                />
              )}
            />
          )}
          {isEditView && (
            <React.Fragment>
              {props.enableConfirm === false && (
                <div className={styles['controlbar-data-button-message']}>
                  <Typography>
                    {t('capacity-management:confirmDisabled')}
                  </Typography>
                </div>
              )}
              <Button
                className={styles['controlbar-data-button']}
                onClick={props.cancelChanges}>
                <Undo style={{ marginRight: '5px' }} />
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('capacity-management:cancel')}
                </Typography>
              </Button>
              <Button
                className={styles['controlbar-data-button-confirm']}
                onClick={props.confirmChanges}
                disabled={!props.enableConfirm}>
                <Check style={{ marginRight: '5px' }} />
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('capacity-management:confirm')}
                </Typography>
              </Button>
            </React.Fragment>
          )}
          {/*          <FormControl
            className={styles['controlbar-item']}
            variant="outlined"
            size="small">
            <InputLabel id="controlbar-select-label">
              {t('capacity-management:timeframe')}
            </InputLabel>
            <Select
              labelId="timeframe-select-label"
              id="timeframe-select"
              open={timeframeSelectOpen}
              onClose={() => setTimeframeSelectOpen(false)}
              onOpen={() => setTimeframeSelectOpen(true)}
              value={selectedTimeframe}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setSelectedTimeframe(event.target.value as number)
              }
              label={t('capacity-management:timeframe')}>
              {DefaultTimeframes.map((preset, index) => {
                const levelText = t(
                  `capacity-management:timeframe-${preset.level}`
                )
                const spanText = t(
                  `capacity-management:timeframe-${preset.span}`
                )

                  return (
                    <MenuItem
                      key={index}
                      value={
                        preset.key
                      }>{`${spanText} [${levelText}]`}</MenuItem>
                  )
                })}
                <MenuItem value={99}>
                  {t('capacity-management:custom-timeframe')}
                </MenuItem>
            </Select>
          </FormControl>*/}
        </div>
      )}
    </div>
  )

  function getLegendValues(
    legendValuesToLoad: CapacityLegendNames[] | undefined
  ) {
    if (legendValuesToLoad === undefined || legendValuesToLoad.length === 0)
      return []

    const values: string[] = []
    for (let i = 0; i < legendValuesToLoad.length; i++) {
      legendEntries.forEach((item: ICapacityLegend) => {
        if (item.name === legendValuesToLoad[i]) values.push(item.text)
      })
    }

    return values
  }

  function getLegendLabelChipArray(key: string, value: string): Object {
    const legendEntry: ICapacityLegend = legendEntries.filter(
      (legendEntry) => legendEntry.text === value
    )[0]

    switch (legendEntry.color) {
      case CapacityLegendColors.BLUE:
        return (
          <Chip
            key={key}
            label={value}
            size={'small'}
            style={{
              marginRight: '0.7rem',
              color: '#00a6eb',
              backgroundColor: '#00a6eb1a',
            }}
          />
        )
      case CapacityLegendColors.GREEN:
        return (
          <Chip
            key={key}
            label={value}
            size={'small'}
            style={{
              marginRight: '0.7rem',
              color: '#51BF30',
              backgroundColor: '#51BF301A',
            }}
          />
        )
      case CapacityLegendColors.RED:
        return (
          <Chip
            key={key}
            label={value}
            size={'small'}
            style={{
              marginRight: '0.7rem',
              color: '#FF525A',
              backgroundColor: '#A8353A1A',
            }}
          />
        )
      case CapacityLegendColors.BLACK:
      default:
        return (
          <Chip
            key={key}
            label={value}
            size={'small'}
            style={{ marginRight: '0.7rem' }}
          />
        )
    }
  }
}

export default Controlbar
