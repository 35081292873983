import React from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import {
  Chip,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { DefaultTooltip, HeaderTableCell } from 'styles/createMyTheme'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import i18n from 'i18next'
import {
  IWorkcenter,
  WorkcenterManagementState,
  IHierarchyEntry,
  IFlatCap,
} from 'interfaces/Workcenter'
import { ISite, getSite } from 'interfaces/Site'
import { getSAPImportReport } from 'interfaces/SAPImportReport'
import { Stores } from 'stores/stores'
import SiteStore from 'stores/SiteStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import AuthenticationStore from 'stores/AuthenticationStore'
import CapabilityStore from 'stores/CapabilityStore'
import SAPImportReportStore from 'stores/SAPImportReportStore'
import PlusIcon from 'assets/icons/plus.svg'
import Arrow from 'assets/icons/arrow-blue.svg'
import SitePicker from 'components/SitePicker'
import WorkcenterDialog from 'components/WorkcenterDialog'

import styles from 'styles/workcenterManagement.module.scss'
import stylesSap from 'styles/lastImport.module.scss'
import WorkcenterService from 'services/WorkcenterService'
import { ISAPImportReport } from 'interfaces/SAPImportReport'

interface WorkcenterManagementProps extends WithTranslation {
  siteStore?: SiteStore
  workcenterStore?: WorkcenterStore
  authenticationStore?: AuthenticationStore
  capabilityStore?: CapabilityStore
  sapImportReportStore?: SAPImportReportStore
}

export const getSupportLabelByInternalInfo = (internal: number | undefined) => {
  // 0 = all children no, 1 = all children yes, 2 = all children undefined, 3 = one or more children yes (apart from one)
  switch (internal) {
    case 0:
      return i18n.t('workcenters:notSupported')
    case 1:
      return i18n.t('workcenters:supported')
    case 3:
      return i18n.t('workcenters:partlySupported')
    case 2:
    default:
      return i18n.t('workcenters:undefSupported')
  }
}

@inject(
  Stores.siteStore,
  Stores.workcenterStore,
  Stores.authenticationStore,
  Stores.capabilityStore,
  Stores.sapImportReportStore
)
@observer
export class WorkcentersManagement extends React.Component<
  WorkcenterManagementProps,
  WorkcenterManagementState
> {
  useMultipleSiteSelection = false
  showBadges = false

  siteStore: SiteStore
  workcenterStore: WorkcenterStore
  authenticationStore: AuthenticationStore
  capabilityStore: CapabilityStore
  sapImportReportStore: SAPImportReportStore
  workcentersCount: number
  siteFlatCaps: IFlatCap[]
  selectedReport: ISAPImportReport

  t: TFunction

  constructor(props: WorkcenterManagementProps) {
    super(props)
    this.siteStore = props.siteStore!
    this.workcenterStore = props.workcenterStore!
    this.authenticationStore = props.authenticationStore!
    this.capabilityStore = props.capabilityStore!
    this.sapImportReportStore = props.sapImportReportStore!
    this.t = props.t

    this.selectedReport = getSAPImportReport()

    this.siteFlatCaps = []

    this.state = {
      selectedSiteId: '',
      entries: [],
    }

    this.workcentersCount = this.state.entries.length
    this.handleSiteSelect = this.handleSiteSelect.bind(this)
  }

  async componentDidMount() {
    await this.sapImportReportStore.initImportReport()
    await this.handleSiteSelect([this.getSelectedSite()])
  }

  async getEntries(defaultSite?: ISite) {
    const workcenters = await this.getWorkcenters(defaultSite)
    return workcenters
      .map(
        (wrkCntr, index, allWrkCntrs): IHierarchyEntry => {
          const parentIndex = allWrkCntrs.findIndex(
            (anyWrkCntr) => anyWrkCntr._id === wrkCntr.parentId
          )
          return {
            index,
            workcenter: wrkCntr,
            parent: parentIndex === index ? -1 : parentIndex,
            level: 0,
            isLeaf: false,
            isFolded: true,
          }
        }
      )
      .map((wrkCntr, index, allWrkCntrs) => {
        const f = (
          allWrkCntrs: IHierarchyEntry[],
          index: number,
          level: number
        ): number => {
          return allWrkCntrs[index].parent === -1 ||
            allWrkCntrs[index].parent === index
            ? level
            : f(allWrkCntrs, allWrkCntrs[index].parent, level + 1)
        }
        wrkCntr.level = f(allWrkCntrs, index, 0)
        wrkCntr.isLeaf = allWrkCntrs.every(
          (anyWrkCntr) => anyWrkCntr.parent !== index
        )
        return wrkCntr
      })
  }

  getDialogWorkcenters(): IWorkcenter[] {
    return this.state.entries.map((entry) => entry.workcenter)
  }

  async getWorkcenters(defaultSite?: ISite) {
    let site = defaultSite ? defaultSite : this.getSelectedSite()

    await this.workcenterStore!.setAll(site._id)

    return this.workcenterStore!.all
  }

  getSites() {
    return this.authenticationStore!.currentUser.sites
  }

  getSelectedSite() {
    let selectedSiteId = this.state ? this.state.selectedSiteId : ''
    let sites: ISite[] = this.getSites().filter(
      (site) => site._id === selectedSiteId
    )
    if (!sites.length) sites = this.getSites()
    return sites.length ? sites[0] : getSite()
  }

  getOpenEntryIds() {
    return this.state.entries.reduce((r: string[], c, i, a): string[] => {
      if (!c.isFolded) r.push(c.workcenter._id)
      return r
    }, [])
  }

  renderLastImport() {
    let text = this.t(`sapImportReport:lastImportNone`)
    let synced = false
    if (this.selectedReport.timestamp !== 0) {
      const wasEnabledLast =
        this.selectedReport.toggleConfig.includes('workcenters') ||
        this.selectedReport.toggleConfig.includes('hierarchy')

      if (!wasEnabledLast) {
        text = this.t('sapImportReport:lastImportDisabled')
        text = text.replace(`%0%`, this.t(`sapImportReport:workcenter`))
      } else {
        const now = moment()
        const last = moment(this.selectedReport.timestamp)
        synced = true

        let duration = moment.duration(now.diff(last)).asMonths()
        text = this.t('sapImportReport:lastImportMonths')
        if (duration < 1) {
          duration = moment.duration(now.diff(last)).asDays()
          text = this.t('sapImportReport:lastImportDays')
          if (duration < 1) {
            duration = moment.duration(now.diff(last)).asHours()
            text = this.t('sapImportReport:lastImportHours')
          }
        }
        text = text.replace('%0%', duration.toFixed())
      }
    }

    return (
      <React.Fragment>
        <span className={stylesSap['sync-badge']}>
          {
            <FiberManualRecord
              style={{ color: synced ? '#51bf30' : '#E0E0E0', height: '15px' }}
            />
          }
        </span>
        <span className={stylesSap['sync-text']}>{text}</span>
      </React.Fragment>
    )
  }

  updateEntriesFolding(openEntryIds: string[]) {
    this.state.entries.map((entry) => {
      entry.isFolded = !openEntryIds.includes(entry.workcenter._id)
      return entry
    })
  }

  async handleSiteSelect(sites: ISite[]) {
    let site = sites.length ? sites[0] : getSite()

    await this.setSiteFlatCaps(site)

    this.selectedReport = this.sapImportReportStore.getReportById(site._id)

    this.setState({
      selectedSiteId: site._id,
      entries: await this.getEntries(site),
    })

    let openEntryIds = this.getOpenEntryIds()

    this.updateEntriesFolding(openEntryIds)

    this.workcentersCount = this.state.entries.length
  }

  async setSiteFlatCaps(site: ISite) {
    let divisionId =
      site!.division && site!.division._id ? site!.division._id : ''

    await this.capabilityStore.setAllCapabilities(divisionId)

    this.siteFlatCaps = WorkcenterService.convertToFlatCaps(
      this.capabilityStore.capabilities,
      site.capabilities || []
    ).filter(
      (flatCap) =>
        flatCap.internal && flatCap.internal === 1 && flatCap.parentId
    )
  }

  static getFlatCapsUsingIds(
    capabilityIds: string[],
    siteFlatCaps: IFlatCap[]
  ): IFlatCap[] {
    return (capabilityIds || []).reduce(
      (r: IFlatCap[], capId, i, a): IFlatCap[] =>
        r.concat(siteFlatCaps.filter((cap) => cap.id === capId) || []),
      []
    )
  }

  static renderCapabilitiesChips(
    workcenter: IWorkcenter,
    siteFlatCaps: IFlatCap[]
  ) {
    return WorkcentersManagement.getFlatCapsUsingIds(
      workcenter.capabilityIds || [],
      siteFlatCaps
    )
      .filter(
        (flatCap: IFlatCap) => flatCap.internal === 1 || flatCap.internal === 3
      )
      .map((flatCap: IFlatCap) => {
        return flatCap.image ? (
          <DefaultTooltip
            title={`${flatCap.fullName} (${getSupportLabelByInternalInfo(
              flatCap.internal
            )})`}
            placement="top"
            key={`tt_${workcenter._id}_${flatCap.id}`}>
            <Chip
              avatar={<Avatar src={flatCap.image} />}
              label={`${flatCap.shortName}`}
              style={{ margin: '2px' }}
              size="small"
            />
          </DefaultTooltip>
        ) : (
          <DefaultTooltip
            title={`${flatCap.fullName} (${getSupportLabelByInternalInfo(
              flatCap.internal
            )})`}
            placement="top"
            key={`tt_${workcenter._id}_${flatCap.id}`}>
            <Chip
              label={`${flatCap.shortName}`}
              style={{ margin: '2px' }}
              size="small"
            />
          </DefaultTooltip>
        )
      })
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('header:workcenterManagement')}
          </Typography>
          <div className="add-new-container">
            <Typography>
              {this.t('workcenters:workcentersInTotal')}{' '}
              {this.state.entries.length}
              {this.renderLastImport()}
            </Typography>
          </div>
          <div className={styles['site-picker-plus-container']}>
            <SitePicker
              handleSiteSelect={this.handleSiteSelect}
              sites={this.getSites()}
              selectedSites={[this.getSelectedSite()]}
              selectMultiple={this.useMultipleSiteSelection}
              showBadges={this.showBadges}
            />
            <div className={styles['plus-container']}>
              <DefaultTooltip
                title={`${this.t('workcenters:addNewWorkcenter')}`}
                placement="top">
                <img
                  src={PlusIcon}
                  className="add-new-icon"
                  alt=""
                  onClick={() => this.workcenterStore!.setModalNew()}
                />
              </DefaultTooltip>
            </div>
          </div>
          {this.getSites().length > 0 ? (
            <React.Fragment>
              <Table>
                <TableHead>{this.renderTableHeader()}</TableHead>
                <TableBody>{this.renderTableContent()}</TableBody>
              </Table>
              <WorkcenterDialog
                open={this.workcenterStore.modalOpen}
                onClose={() => this.workcenterStore.setModalClose()}
                initialWorkcenter={this.workcenterStore.workcenter}
                modalType={this.workcenterStore.new}
                updateWorkcenters={async () =>
                  await this.handleSiteSelect([this.getSelectedSite()])
                }
                workcenters={this.getDialogWorkcenters()}
                site={this.getSelectedSite()}
                siteFlatCaps={this.siteFlatCaps}
                numberAndtypeDisabled={false}
              />
            </React.Fragment>
          ) : (
            <Typography>{this.t('workcenters:userHasNoSites')}</Typography>
          )}
        </Box>
      </React.Fragment>
    )
  }

  renderTableHeader() {
    return (
      <React.Fragment>
        <TableRow>
          <HeaderTableCell align="left">
            {this.t('workcenters:name')}
          </HeaderTableCell>
          <HeaderTableCell align="left">
            {this.t('workcenters:number')}
          </HeaderTableCell>
          <HeaderTableCell align="left">
            {this.t('workcenters:state')}
          </HeaderTableCell>
          <HeaderTableCell align="left">
            {this.t('workcenters:capabilityIds')}
          </HeaderTableCell>
          <HeaderTableCell align="left">
            {this.t('workcenters:lastEdit')}
          </HeaderTableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  renderTableContent() {
    return this.state.entries
      .filter((entry) => entry.level === 0)
      .map((entry) => this.renderTableRow(entry))
  }

  renderTableRow(entry: IHierarchyEntry) {
    const renderEntryName = (entry: IHierarchyEntry) => (
      <div style={{ textAlign: 'left' }}>{entry.workcenter.name}</div>
    )

    const styles = {
      paddingLeft: '',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '400px',
      overflow: 'auto',
    }

    styles.paddingLeft = `${entry.level * 20}px`
    if (entry.level !== 0 && entry.isLeaf) {
      styles.paddingLeft = `${(entry.level + 1) * 20 + 10}px`
    }
    return (
      <React.Fragment key={'row' + entry.workcenter._id}>
        <TableRow>
          <TableCell
            align="center"
            className="clickable"
            onClick={() => {
              if (entry.isLeaf) {
                this.workcenterStore!.setModal(entry.workcenter)
              } else {
                this.toggleFolding(entry)
              }
            }}>
            <Box display="flex" alignItems="center">
              <Box whiteSpace="no-wrap">
                <span style={styles} className="clickable">
                  {this.renderImage(entry)}
                  {renderEntryName(entry)}
                </span>
              </Box>
            </Box>
          </TableCell>
          {this.renderRowContent(entry)}
        </TableRow>
        {!entry.isFolded &&
          this.state.entries
            .filter((children) => children.parent === entry.index)
            .map((entry) => this.renderTableRow(entry))}
      </React.Fragment>
    )
  }

  private toggleFolding(entry: IHierarchyEntry) {
    const entries = this.state.entries.slice()
    entries[entry.index].isFolded = !entry.isFolded
    this.setState({
      entries: entries,
      selectedSiteId: this.state.selectedSiteId,
    })
  }

  renderImage(entry: IHierarchyEntry) {
    const arrow = (
      <img
        src={Arrow}
        className={`${styles['arrow']} clickable ${
          entry.isFolded ? `${styles['right']}` : ''
        }`}
        alt=""
      />
    )
    return entry.isLeaf ? null : arrow
  }

  renderRowContent(entry: IHierarchyEntry) {
    return (
      <React.Fragment key={'row' + entry.workcenter._id}>
        <TableCell
          align="left"
          onClick={() => this.workcenterStore!.setModal(entry.workcenter)}
          className={'clickable'}>
          {entry.workcenter.number}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => this.workcenterStore!.setModal(entry.workcenter)}
          className={styles['wcs_' + entry.workcenter.state] + ' clickable'}>
          {this.t(`workcenters:${entry.workcenter.state}`)}
        </TableCell>
        <TableCell
          align="left"
          onClick={() => this.workcenterStore!.setModal(entry.workcenter)}
          className={'clickable'}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap">
            {WorkcentersManagement.renderCapabilitiesChips(
              entry.workcenter,
              this.siteFlatCaps
            )}
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={() => this.workcenterStore!.setModal(entry.workcenter)}
          className={'clickable'}>
          {moment(entry.workcenter.lastEdit.lastEditedTime).format(
            'DD/MM/YYYY'
          )}
          {' ' + this.t('workcenters:by') + ' '}
          {`${entry.workcenter.lastEdit.lastEditedUser}`}
        </TableCell>
      </React.Fragment>
    )
  }
}

export default withTranslation()(WorkcentersManagement)
