import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import { useTranslation } from 'react-i18next'

import styles from 'styles/login.module.scss'

interface LoginProps {
  authenticationStore?: AuthenticationStore
  history?: any
}

const Login: React.FunctionComponent<LoginProps> = inject(
  Stores.authenticationStore
)(
  observer(({ authenticationStore, history }: LoginProps) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [recoveryEmail, setRecoveryEmail] = useState('')
    const [password, setPassword] = useState('')
    const [renderRecover, setRenderRecover] = useState(false)

    useEffect(() => {
      authenticationStore!
        .JWTLogin()
        .then(
          (response: any) => response && history.push(authenticationStore!.path)
        )
    }, [authenticationStore, history])

    const handleLogin = async (e: React.FormEvent) => {
      e.preventDefault()
      if (email && password) {
        const response = await authenticationStore!.login(email, password)
        if (response) history.push(authenticationStore!.path)
        else loginReset()
      }
    }

    const handleRecoverPassword = (e: React.FormEvent) => {
      e.preventDefault()
      setRenderRecover(false)
      setRecoveryEmail('')
    }

    const submitRecoverEmail = () =>
      authenticationStore!.recoverPassword(recoveryEmail)

    const loginReset = () => {
      setEmail('')
      setPassword('')
    }

    const renderLogin = () => {
      return (
        <React.Fragment>
          <Box mb={2} maxWidth="300px" width="30%">
            <TextField
              fullWidth
              autoFocus
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              label={t('login:email')}
            />
          </Box>
          <Box maxWidth="300px" width="30%">
            <TextField
              fullWidth
              type="password"
              value={password}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              label={t('login:password')}
            />
          </Box>
          <Box marginTop="20px" maxWidth="70%" textAlign="center">
            <Typography>{t('login:confidentialDisclaimer')}</Typography>
          </Box>
          <Box mt={3} mb={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit">
              {t('login:login')}
            </Button>
          </Box>
          <Button color="primary" onClick={() => setRenderRecover(true)}>
            {t('login:recoverPassword')}
          </Button>
        </React.Fragment>
      )
    }

    const renderRecoverPassword = () => {
      return (
        <React.Fragment>
          <Box mb={2} className={styles['recover-password-box']}>
            <TextField
              fullWidth
              value={recoveryEmail}
              variant="outlined"
              onChange={(e) => setRecoveryEmail(e.target.value)}
              label={t('login:email')}
            />
            <div className={styles['recover-button-container']}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={submitRecoverEmail}>
                {t('login:recover')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={cancelRecover}>
                {t('login:cancel')}
              </Button>
            </div>
          </Box>
        </React.Fragment>
      )

      function cancelRecover() {
        setRenderRecover(false)
        setRecoveryEmail('')
      }
    }

    return authenticationStore!.logInWithJwt ? null : (
      <Box p={4}>
        <div className={styles['login-container']}>
          <form
            className={styles['login-form']}
            onSubmit={renderRecover ? handleRecoverPassword : handleLogin}>
            <Box mb={5} mt={5}>
              <img
                src={process.env.PUBLIC_URL + 'logo.svg'}
                alt="Logo"
                height="150px"
                width="150px"
              />
            </Box>
            {!renderRecover ? renderLogin() : renderRecoverPassword()}
          </form>
        </div>
      </Box>
    )
  })
)

export default Login
