import React from "react"
import {Button, Drawer, IconButton} from "@material-ui/core"
import FilterStyle from "./FilterStyle"
import clsx from "clsx"
import FilterIconClose from "assets/icons/arrow-white-open.svg"
import FilterIconOpen from "assets/icons/arrow-white-close.svg"
import {useTranslation} from "react-i18next"
import {inject, observer} from "mobx-react"
import {Stores} from "../../stores/stores"
import MapStore from "../../stores/MapStore"

interface IFilterProps {
	open: boolean
	setDrawer: (open: boolean) => void
	onSearch?: () => void
	mapStore?: MapStore
	children: any
}

const Filter: React.FunctionComponent<IFilterProps> = inject(Stores.mapStore)(observer((props: IFilterProps) => {
	const {t} = useTranslation()
	const {open} = props
	const classes = FilterStyle()

	const handleDrawer = () => {
		props.setDrawer(!open)
	}

	const iconSelection = () => {
		return (
			<div>
				<img src={open ? FilterIconClose : FilterIconOpen} alt="Filter" style={{width: 50, height: 40}}/>
			</div>
		)
	}

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open
			})}
			classes={{
				paper: clsx({
					[classes.drawerPaper]: true,
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open
				})
			}}
			open={open}
		>
			<div className={classes.filterContent}>
				<div className={classes.iconContainer}>
					<IconButton onClick={handleDrawer}>
						{iconSelection()}
					</IconButton>
				</div>
			</div>
			<div className={classes.inputs}>
				{open && props.children}
			</div>
			<div className={classes.selectBtn}>
				{open && (
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							if (props.mapStore?.selectMode) props.mapStore?.disableSelect()
							else props.mapStore?.enableMapSelect()
							handleDrawer()
						}}
					>
						{t("select")}
					</Button>
				)}
			</div>
		</Drawer>
	)
}))

export default Filter