// In the following line, you should include the prefixes of implementations you want to test.
const indexedDB =
  window.indexedDB ||
  // @ts-ignore
  window.mozIndexedDB ||
  // @ts-ignore
  window.webkitIndexedDB ||
  // @ts-ignore
  window.msIndexedDB
// DON'T use "var indexedDB = ..." if you're not in a function.
// Moreover, you may need references to some window.IDB* objects:
// @ts-ignore
window.IDBTransaction = window.IDBTransaction ||
  // @ts-ignore
  window.webkitIDBTransaction ||
  // @ts-ignore
  window.msIDBTransaction || { READ_WRITE: 'readwrite' } // This line should only be needed if it is needed to support the object's constants for older browsers
// @ts-ignore
window.IDBKeyRange =
  // @ts-ignore
  window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange
// (Mozilla has never prefixed these objects, so we don't need window.mozIDB*)

const DB_NAME = 'HuebnerPortalClientDB'
const DB_VERSION = 4

let db: IDBDatabase | null = null

enum Store {
  ChachedQuery = 'ChachedQuery',
}

// TODO: add much more events
export enum Event {
  ReadFromCache = 'ReadFromCache',
  ClearCache = 'ClearCache',
}

export interface QueryCache {
  requestKey: string
  timestamp: number
  timeToLive: number
  data: any
}

interface DatabaseStaticHandler {
  registerEventObserver: (event: Event, cb: Function) => void
  open: () => Promise<DatabaseInstanceHandler>
  delete: () => Promise<undefined>
}

interface DatabaseInstanceHandler {
  add: (object: any) => Promise<any>
  get: (key: string) => Promise<any>
  remove: (key: string) => Promise<any>
  clearData: () => Promise<any>
  cacheQuery: (
    requestKey: string,
    ttl: number,
    query: () => Promise<any>
  ) => Promise<any>
}

const eventObserver = {
  [Event.ReadFromCache.toString()]: [],
  [Event.ClearCache.toString()]: [],
}

const add: (object: any) => Promise<any> = (object) =>
  new Promise((resolve, reject) => {
    if (!db) {
      return reject(new Error('IndexedDB not initialized'))
    }

    const transaction = db.transaction(Store.ChachedQuery, 'readwrite')
    const objectStore = transaction.objectStore(Store.ChachedQuery)
    const request = objectStore.add(object)

    request.onsuccess = () => resolve(request.result)
    request.onerror = () => reject(request.error)
  })

const get: (key: string) => Promise<any> = (key) =>
  new Promise((resolve, reject) => {
    if (!db) {
      return reject(new Error('IndexedDB not initialized'))
    }

    const transaction = db.transaction(Store.ChachedQuery, 'readwrite')
    const objectStore = transaction.objectStore(Store.ChachedQuery)
    const request = objectStore.get(key)

    request.onsuccess = () => resolve(request.result)
    request.onerror = () => reject(request.error)
  })

const remove: (key: string) => Promise<any> = (key) =>
  new Promise((resolve, reject) => {
    if (!db) {
      return reject(new Error('IndexedDB not initialized'))
    }

    const transaction = db.transaction(Store.ChachedQuery, 'readwrite')
    const objectStore = transaction.objectStore(Store.ChachedQuery)
    const request = objectStore.delete(key)

    request.onsuccess = () => resolve(request.result)
    request.onerror = () => reject(request.error)
  })

const clearData: () => Promise<any> = () =>
  new Promise((resolve, reject) => {
    if (!db) {
      return reject(new Error('IndexedDB not initialized'))
    }

    const transaction = db.transaction(Store.ChachedQuery, 'readwrite')
    const objectStore = transaction.objectStore(Store.ChachedQuery)
    const request = objectStore.clear()

    request.onsuccess = () => {
      const observers: Function[] = eventObserver[Event.ClearCache.toString()]
      observers.forEach((observer) => observer())
      resolve()
    }
    request.onerror = () => reject(request.error)
  })

const cacheQuery = async (
  requestKey: string,
  ttl: number,
  query: () => Promise<any>
) => {
  const cacheEntry: QueryCache = await get(requestKey).catch((e) =>
    console.error(e)
  )
  const addToCache = async (dataPromise: Promise<any>) => {
    const data = await dataPromise
    const newCacheEntry: QueryCache = {
      requestKey,
      timestamp: new Date().getTime(),
      timeToLive: ttl,
      data,
    }

    add(newCacheEntry).catch((e) => console.error(e))
  }
  const removeFromCache = async () => {
    remove(requestKey).catch((e) => console.error(e))
  }
  const parallelRequests = {
    remove: Promise.resolve(),
  }

  if (cacheEntry) {
    if (new Date().getTime() - cacheEntry.timestamp > ttl) {
      parallelRequests.remove = removeFromCache()
    } else {
      const observers: Function[] =
        eventObserver[Event.ReadFromCache.toString()]
      observers.forEach((observer) => observer(cacheEntry.timestamp))
      return cacheEntry.data
    }
  }

  const dataPromise = query()

  // start add to cache in parallel to promise return
  parallelRequests.remove.then(() => addToCache(dataPromise))

  return dataPromise
}

const instanceHandlers: DatabaseInstanceHandler = {
  add: add,
  get: get,
  remove: remove,
  clearData: clearData,
  cacheQuery: cacheQuery,
}

const initObjectStore = (db: IDBDatabase) => {
  if (!db.objectStoreNames.contains(Store.ChachedQuery)) {
    db.createObjectStore(Store.ChachedQuery, {
      keyPath: 'requestKey',
    })
  }
}

const openDB: () => Promise<DatabaseInstanceHandler> = () =>
  new Promise((resolve, reject) => {
    if (db) {
      return resolve(instanceHandlers)
    } else if (!indexedDB) {
      return reject(new Error('IndexedDB not supported'))
    }

    const openRequest = indexedDB.open(DB_NAME, DB_VERSION)

    openRequest.onupgradeneeded = (event: any) => {
      db = event.target.result

      // Init db store and check version
      if (db) {
        switch (
          event.oldVersion // existing db version
        ) {
          case 0:
            // version 0 means that the client had no database: perform initialization
            return initObjectStore(db)
          case 1:
          case 2:
          case 3:
          default:
            // client had version 1-3
            return initObjectStore(db)
        }
      }
    }

    openRequest.onerror = (event: any) =>
      reject(new Error(event.target.errorCode))

    openRequest.onblocked = () => {
      // this event shouldn't trigger if we handle onversionchange correctly
      // it means that there's another open connection to same database
      // and it wasn't closed after db.onversionchange triggered for them
    }

    openRequest.onsuccess = (event: any) => {
      db = event.target.result

      if (db) {
        db.onversionchange = () => {
          if (db) {
            db.close()
            // alert('Database is outdated, please reload the page.')
          }
        }
      }

      resolve(instanceHandlers)
    }
  })

const deleteDB: () => Promise<undefined> = () =>
  new Promise((resolve, reject) => {
    if (!indexedDB) {
      return reject(new Error('IndexedDB not supported'))
    }

    const deleteRequest = indexedDB.deleteDatabase(DB_NAME)

    deleteRequest.onerror = (event: any) =>
      reject(new Error(event.target.errorCode))
    deleteRequest.onsuccess = (event: any) => resolve()
  })

const registerEventObserver = (event: Event, cb: Function) => {
  const observers: Function[] = eventObserver[event.toString()] || []

  observers.push(cb)
}

const staticHandlers: DatabaseStaticHandler = {
  open: openDB,
  delete: deleteDB,
  registerEventObserver: registerEventObserver,
}

export default staticHandlers
