import React from 'react'
import { useTranslation } from 'react-i18next'

import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { DefaultTimeframes, ITimeframe } from 'interfaces/CapacityDashboard'

import styles from 'styles/capacityDashboard.module.scss'

interface Props {
  selectedKey: number
  showTimelevel?: boolean
  customOption?: boolean
  customPreset?: ITimeframe[]
  onTimeframeChange?: (event: React.ChangeEvent<{ value: unknown }>) => void
}

const TimeframeSelect: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    customPreset,
    customOption = false,
    selectedKey = 0,
    showTimelevel = true,
    onTimeframeChange = () => {},
  } = props

  /**
   * S T A T E S
   */
  const [timeframeSelectOpen, setTimeframeSelectOpen] = React.useState<boolean>(
    false
  )

  return (
    <FormControl
      className={styles['filterbar-item']}
      variant="outlined"
      size="small">
      <InputLabel id="timeframe-select-label">
        {t('capacity-management:timeframe')}
      </InputLabel>
      <Select
        labelId="timeframe-select-label"
        id="timeframe-select"
        open={timeframeSelectOpen}
        onClose={() => setTimeframeSelectOpen(false)}
        onOpen={() => setTimeframeSelectOpen(true)}
        value={selectedKey}
        onChange={onTimeframeChange}
        label={t('capacity-management:timeframe')}>
        {(customPreset || DefaultTimeframes).map((preset, index) => {
          const levelText = t(
            `capacity-management:timeframe-${preset.level.toLowerCase()}`
          )
          const spanText = t(
            `capacity-management:timeframe-${(preset.span || '').toLowerCase()}`
          )

          return (
            <MenuItem key={index} value={preset.key}>
              {showTimelevel
                ? t('capacity-management:timeframe-span-level', {
                    span: spanText,
                    level: levelText,
                  })
                : spanText}
            </MenuItem>
          )
        })}
        {customOption && (
          <MenuItem value={99}>
            {t('capacity-management:custom-timeframe')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default TimeframeSelect
