import { ILocation } from 'interfaces/Common'

export default class CoordinatesTest {
  public static test = (location: ILocation) => {
    const lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
    const lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

    if (location) {
      const validLat = lat.test(location.lat)
      const validLon = lon.test(location.lon)
      return validLat && validLon
    } else {
      return false
    }
  }
}
