import React from 'react'
import { Select, Box, MenuItem } from '@material-ui/core'
import { IDivision } from 'interfaces/Division'
import DivisionStore from 'stores/DivisionStore'
import { Stores } from 'stores/stores'
import { inject, observer } from 'mobx-react'

import styles from 'styles/divisionSelect.module.scss'

interface DivisionSelectProps {
  divisionStore?: DivisionStore
  handleChange: (id: string) => void
  disabled?: boolean
}

const DivisionSelect: React.FC<DivisionSelectProps> = inject(
  Stores.divisionStore
)(
  observer(({ divisionStore, handleChange, disabled }) => {
    const getDivisionColorCircle = (division: IDivision) => {
      return (
        <Box
          borderRadius="20px"
          height="20px"
          width="20px"
          marginRight="5px"
          style={{
            backgroundColor: division.colorCode,
          }}
        />
      )
    }

    return (
      <Select
        className={styles['division-select-capability']}
        value={divisionStore!.selectedDivision._id}
        onChange={(e: any) => handleChange(e.target.value)}
        disabled={disabled}>
        {divisionStore!.allDivisions.map((division) => (
          <MenuItem value={division._id} key={division._id}>
            {getDivisionColorCircle(division)}
            {division.name}
          </MenuItem>
        ))}
      </Select>
    )
  })
)

export default DivisionSelect
