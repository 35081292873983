import { ICreated, ILastEdit } from './Common'
import { ICapability, getCapability } from './Capabilities'

export interface IWorkcenter {
  _id: string
  siteId: string
  number: string
  name: string
  parentId: string
  type: WorkcenterType
  state: WorkcenterState
  capabilityIds: string[] | null
  lastEdit: ILastEdit
  created: ICreated

  // artificially added attributes
  parentIdList?: string[]
}

export enum WorkcenterType {
  workcenter = 'WORKCENTER',
  group = 'GROUP',
}

export enum WorkcenterState {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export const Workcenter: any = {
  number: '',
  name: '',
  parentId: '',
  type: WorkcenterType.workcenter,
  state: WorkcenterState.active,
  capabilityIds: [],
  lastEdit: {
    lastEditedTime: 0,
    lastEditedUser: '',
  },
  created: {
    createdTime: 0,
    createdUser: '',
  },
}

export const getEmptyWorkcenter = (): IWorkcenter => {
  return { _id: '', siteId: '', ...Workcenter }
}

export interface WorkcenterManagementState {
  selectedSiteId: string
  entries: IHierarchyEntry[]
}

export interface IHierarchyEntry {
  workcenter: IWorkcenter
  index: number
  level: number
  parent: number
  isLeaf: boolean
  isFolded: boolean
}

export interface IFlatCap {
  id: string
  id2: string
  level0Id: string
  parentId: string
  name: string
  fullName: string
  shortName: string
  indexInSiteFlatCaps: number
  image: string
  capability?: ICapability
  internal?: 0 | 1 | 2 | 3
}

export function getFlatCap(): IFlatCap {
  return {
    id: '',
    id2: '',
    level0Id: '',
    parentId: '',
    name: '',
    fullName: '',
    shortName: '',
    indexInSiteFlatCaps: -1,
    image: '',
    capability: getCapability(),
    internal: 2,
  }
}

export interface INewWorkcenterMapping {
  originSiteId: string
  originWorkCenterId: string
  targetSiteId: string
  targetWorkCenterId: string
  transferFactor: string
}
export interface IWorkcenterMapping extends INewWorkcenterMapping {
  _id: string
}

export function getWorkcenterMapping(): IWorkcenterMapping {
  return {
    _id: '',
    originSiteId: '',
    originWorkCenterId: '',
    targetSiteId: '',
    targetWorkCenterId: '',
    transferFactor: '100',
  }
}

export interface IWorkcenterReduced {
  _id: string
  siteId: string
  number: string
}
