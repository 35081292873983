export interface ISAPImportReport {
  timestamp: number
  siteId: string
  status: boolean
  statusText: string
  executionParameters: Map<string, string>
  plantId: string
  toggleConfig: Array<string>
  importStats: ImportStats
  summary: Map<string, string>
}

export interface ImportStats {
  loadedWorkCenters: number
  newWorkCenters: number
  loadedHierarchies: number
  newHierarchies: number
  loadedWorkDays: number
  newWorkDays: number
  loadedCapacities: number
  newCapacities: number
}

export const getSAPImportReport = (): ISAPImportReport => {
  return {
    timestamp: 0,
    siteId: '',
    status: false,
    statusText: '',
    executionParameters: new Map<string, string>(),
    plantId: '',
    toggleConfig: [],
    importStats: {
      loadedWorkCenters: 0,
      newWorkCenters: 0,
      loadedHierarchies: 0,
      newHierarchies: 0,
      loadedWorkDays: 0,
      newWorkDays: 0,
      loadedCapacities: 0,
      newCapacities: 0,
    },
    summary: new Map<string, string>(),
  }
}
