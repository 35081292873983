import React from 'react'
import { useTranslation } from 'react-i18next'
import { amber, green, red } from '@material-ui/core/colors'
import {
  Snackbar,
  SnackbarContent,
  Theme,
  Typography,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircle, Close, Error, Info, Warning } from '@material-ui/icons'
import clsx from 'clsx'

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
}

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export interface Props {
  className?: string
  message?: string
  onClose?: () => void
  variant: keyof typeof variantIcon
}

function SnackbarContentWrapper(props: Props) {
  const classes = useStyles()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <Typography>{message}</Typography>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

const CustomSnackbar: React.FunctionComponent<any> = ({
  message,
  type,
  duration,
}) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const getMessage = (mesage: string) => {
    const messageArray = message.split(' ')
    const convertedMessage = messageArray.map(
      (content: string, index: number) =>
        index !== messageArray.length - 1 ? t(content) : ' ' + t(content)
    )
    return convertedMessage.join()
  }
  const { t } = useTranslation()
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleClose}>
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={type as any}
          message={getMessage(message)}
        />
      </Snackbar>
    </div>
  )
}
export default CustomSnackbar
