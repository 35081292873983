import React from "react";
import { TextField, withStyles } from "@material-ui/core";

const InputField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#00a6eb"
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ffffff"
			},
			"&:hover fieldset": {
				borderColor: "#ffffff"
			},
			"&.Mui-focused fieldset": {
				borderColor: "#00a6eb"
			},
			color: "white !important"
		}
	}
})(TextField);

const FilterTextField: React.FunctionComponent<any> = (props) => {
	return (
		<InputField {...props}
			InputProps={{
				...props.InputProps
			}}
			InputLabelProps={{
				...props.InputLabelProps, style: { color: "#ffffff" }
			}}
		/>
	);
};

export default FilterTextField;