import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { notify } from '../components/Notification'
import { commonFunctions } from './ServiceConfig'
import { isArray } from 'lodash'
import { IMigrate } from 'interfaces/Capacity'

const { handleResponse } = commonFunctions
export default class CapacityService {
  public getAllCapacities = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capacities/list`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getAllCapacities'
          notify(message, 'error')
          return []
        }
      )
  }

  public getSiteYearTypeCapacities = (
    siteId: string,
    year: string,
    capacityType: string
  ) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capacities/site-year-type/${siteId}/${year}/${capacityType}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getSiteYearTypeCapacities'
          notify(message, 'error')
          return []
        }
      )
  }

  public getWorkcenterCapacities = (workcenterId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capacities/workcenter/${workcenterId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getWorkcenterCapacities'
          notify(message, 'error')
          return []
        }
      )
  }

  public createCapacites = (capacities: any) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(capacities),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capacities/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return null
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:createCapacites'
          notify(message, 'error')
          return { error: error }
        }
      )
  }

  public migrate = (migrate: IMigrate) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(migrate),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/capacities/migrate`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:migrate'
          notify(message, 'error')
          return { error: error }
        }
      )
  }
}
