import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import SiteStore from 'stores/SiteStore'
import { ISite, Site } from 'interfaces/Site'
import moment from 'moment'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import SiteService from 'services/SiteService'
import PlusIcon from 'assets/icons/plus.svg'
import AuthenticationStore from 'stores/AuthenticationStore'
import SiteDialog from 'components/SiteDialog'
import { DefaultTooltip, HeaderTableCell } from 'styles/createMyTheme'
import { SortOrder, handleSort, renderTableSortHeader } from 'utils/TableSort'

import styles from 'styles/userManagement.module.scss'

interface SitesProps extends WithTranslation {
  siteStore?: SiteStore
  authenticationStore?: AuthenticationStore
}

interface SitesState {
  selectedColumn: keyof ISite | ''
  sortOrder: SortOrder
}

@inject(Stores.siteStore, Stores.authenticationStore)
@observer
class Sites extends React.Component<SitesProps, SitesState> {
  siteStore: SiteStore
  authenticationStore: AuthenticationStore
  siteService: SiteService

  Site: ISite = Site
  t: TFunction

  constructor(props: SitesProps) {
    super(props)
    this.state = {
      selectedColumn: '',
      sortOrder: 'desc',
    }
    this.siteStore = props.siteStore!
    this.authenticationStore = props.authenticationStore!
    this.siteService = new SiteService()
    this.t = props.t
  }

  componentDidMount() {
    this.siteStore.setAllSites()
    this.siteStore.setAllRegions()
  }

  filterBySitesAssignedToUser = (site: ISite) => {
    return this.authenticationStore.currentUser.sites.some(
      (userSite) => userSite._id === site._id
    )
  }

  sortBy = (header: keyof ISite) => {
    if (header !== 'capabilities') {
      this.setState({ selectedColumn: header })
      const sameHeader = header === this.state.selectedColumn
      this.setState(
        {
          sortOrder:
            this.state.sortOrder === 'asc' && sameHeader ? 'desc' : 'asc',
        },
        () => {
          const sortedList = handleSort(
            this.siteStore.allSites,
            header,
            this.state.sortOrder
          )
          if (sortedList) this.siteStore.allSites = sortedList
        }
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('header:siteManagement')}
          </Typography>
          <div className="add-new-container">
            <Typography>
              {this.t('sites:sitesInTotal')}{' '}
              {this.siteStore.allSites ? this.siteStore.allSites.length : 0}
            </Typography>
            <DefaultTooltip
              title={`${this.t('sites:addNewSite')}`}
              placement="top">
              <img
                src={PlusIcon}
                className="add-new-icon"
                alt=""
                onClick={() => this.siteStore.setModalNewSite()}
              />
            </DefaultTooltip>
          </div>
          {this.renderSitesTable()}
        </Box>
      </React.Fragment>
    )
  }

  renderSitesTable = () => {
    const headers = Object.keys(this.Site) as (keyof ISite)[]
    return (
      <div>
        <TableContainer style={{ maxHeight: 'calc(100vh - 240px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow color="primary">
                {headers.map((header) => (
                  <HeaderTableCell
                    key={header}
                    display="flex"
                    align={'left'}
                    onClick={() => this.sortBy(header)}>
                    <Box display="flex">
                      <Typography className="table-header">
                        {this.t(`sites:${header}`)}
                      </Typography>
                      {renderTableSortHeader(
                        this.state.selectedColumn,
                        header,
                        this.state.sortOrder
                      )}
                    </Box>
                  </HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.siteStore.allSites &&
                this.siteStore.allSites
                  .filter(this.filterBySitesAssignedToUser)
                  .map((site, index) => (
                    <TableRow key={site._id + index} hover={true}>
                      {headers.map((header) => (
                        <TableCell
                          onClick={() => this.siteStore.setModalSite(site)}
                          key={site._id.toString() + Math.random()}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'flex-start'}
                            minHeight="48px">
                            {this.renderCell(site, header)}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SiteDialog
          open={this.siteStore.modalOpen}
          onClose={() => this.siteStore.setModalClose()}
          initialSite={this.siteStore.site}
          modalType={this.siteStore.newSite}
          updateList={() => this.siteStore.setAllSites()}
          updateCurrentUser={() => this.authenticationStore.JWTLogin()}
          regions={this.siteStore.allRegions}
        />
      </div>
    )
  }

  renderCell = (site: ISite, header: keyof ISite) => {
    switch (header) {
      case 'lastEdit': {
        const value = site[header]
        const userEdit = value.lastEditedUser
        const editDate = moment(value.lastEditedTime).format('DD/MM/YYYY')
        return (
          <Typography>{`${editDate} ${this.t(
            'sites:by'
          )} ${userEdit}`}</Typography>
        )
      }
      case 'division': {
        return (
          <Box display="flex" alignItems="center">
            <div
              style={{
                height: '20px',
                width: '20px',
                backgroundColor: site.division.colorCode,
                borderRadius: '50%',
              }}
            />
            <div style={{ marginLeft: '5px' }}>
              <Typography>{site.division.name}</Typography>
            </div>
          </Box>
        )
      }
      case 'region': {
        const value = site[header]
        return <Typography>{value.name}</Typography>
      }
      case 'capabilities': {
        return <Typography>{this.capabilitiesCounter(site[header])}</Typography>
      }
      case 'name': {
        const value = site[header]
        return <Typography>{value}</Typography>
      }
      case 'plantId': {
        const value = site[header]
        return <Typography>{value}</Typography>
      }
      case 'numberOfEmployees': {
        const value = site[header] ? site[header] : 0
        return <Typography>{value}</Typography>
      }
      case 'complaintsRate': {
        const value = site[header] ? site[header] : 0
        return <Typography>{value} %</Typography>
      }
      case 'sitesForReceivingCapacities': {
        return (
          <Tooltip
            title={
              <Typography>
                {this.getCompleteRecSiteList(site[header])}
              </Typography>
            }>
            <Typography>
              {this.getFormattedRecSiteList(site[header])}
            </Typography>
          </Tooltip>
        )
      }
      default:
        return <Typography>{site[header]}</Typography>
    }
  }

  capabilitiesCounter = (value: any) => {
    let count = 0
    for (let index = 0; index < value.length; index++) {
      if (
        value[index].internal === 1 ||
        value[index].external === 1 ||
        value[index].internalChildren === 1 ||
        value[index].externalChildren === 1
      )
        count++
    }
    return `${count} / ${value.length}`
  }

  getCompleteRecSiteList = (recSiteIDs: string[]) => {
    if (!recSiteIDs) {
      return '-'
    }
    const recSiteArray: string[] = this.siteStore.allSites
      .filter((site) => (recSiteIDs || []).includes(site._id))
      .map(
        (site: ISite, i: number) =>
          `${i ? '•' : ''} ${site.name} - ${site.region.name} - ${
            site.country
          } - ${site.city}`
      )
    return recSiteArray.join('\n')
  }

  getFormattedRecSiteList = (recSiteIDs: string[]) => {
    if (!recSiteIDs) {
      return '-'
    }
    const recSiteString: string = this.getCompleteRecSiteList(recSiteIDs)
    return recSiteString.length > 20
      ? recSiteString.substring(0, 19) + '...'
      : recSiteString
  }
}

export default withTranslation()(Sites)
