import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import defer from 'lodash/defer'
import moment from 'moment'
import ReactDataGrid, {
  Column,
  DataGridHandle,
  RowsUpdateEvent,
  UpdateActions,
} from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'

import RootRef from '@material-ui/core/RootRef'

import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import Controlbar from 'components/CapacityManagement/Controlbar'
import styles from 'styles/capacitiesManagement.module.scss'
import {
  CapacityLegendNames,
  CapacityType,
  getEmptyCapacity,
  ICapacity,
  ICapacityChanged,
  TimeType,
} from 'interfaces/Capacity'
import CapHlp from 'utils/CapacityHelper'
import CapacityStore from 'stores/CapacityStore'
import WorkcenterStore from 'stores/WorkcenterStore'
import Notifications from 'components/CapacityManagement/Notifications'
import { notify } from 'components/Notification'
import { getSite, ISite } from 'interfaces/Site'

import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/core/main.css'
import { IWorkcenter } from 'interfaces/Workcenter'
import { fixedDecimalNumber } from 'utils/MiscFunctions'

//import CapHlp from 'utils/CapacityHelper'

interface DemandSuperMarketProps extends WithTranslation {
  siteId: string
  capacityType: CapacityType
  authenticationStore?: AuthenticationStore
  capacityStore?: CapacityStore
  workcenterStore?: WorkcenterStore
}

interface IRow {
  [key: string]: any
}

const currentDate = moment(new Date()).endOf('day').toDate()

interface IColumnDate {
  year: string
  timeValue: string
}
interface IColumnsDates {
  [key: string]: IColumnDate
}

const lastCellPosition = {
  idx: 0,
  rowIdx: 0,
}
const currentCellPosition = {
  idx: 0,
  rowIdx: 0,
  inEditMode: false,
}

let $gridHandle: DataGridHandle | null = null

const DemandSuperMarket: React.FC<DemandSuperMarketProps> = inject(
  Stores.authenticationStore,
  Stores.capacityStore,
  Stores.workcenterStore
)(
  observer((props: DemandSuperMarketProps) => {
    const {
      authenticationStore,
      capacityStore,
      workcenterStore,
      siteId,
      capacityType,
    } = props

    const capacityTypeArr = [
      capacityType,
      CapacityType.demand_preproduction_change,
      //CapacityType.demand
    ]
    const { t } = useTranslation()
    const visibleWeekColumns = 53
    const [changed, setChanged] = useState<ICapacityChanged>({})
    const [gridDataUpdateCount, setGridDataUpdateCount] = useState(0)
    const [currentSite, setCurrentSite] = React.useState<ISite>(getSite())
    React.useEffect(() => {
      setCurrentSite(
        authenticationStore!.currentUser.sites.filter(
          (site) => site._id === siteId
        )[0]
      )
    }, [siteId])

    const timeType: TimeType =
      currentSite.capacityMaintenance[
        capacityStore!.convertCapacityToCapMaintenance(capacityType)
      ]

    const [momentPeriod, setMomentPeriod] = React.useState<'month' | 'isoWeek'>(
      timeType === TimeType.month ? 'month' : 'isoWeek'
    )

    const currentColumnDate: IColumnDate = {
      year:
        timeType === TimeType.month
          ? moment(currentDate).format('YYYY')
          : moment(currentDate).isoWeekYear().toString(),
      timeValue:
        timeType === TimeType.month
          ? moment(currentDate).format('M')
          : moment(currentDate).format('W'),
    }

    const [controlDate, setControlDate] = useState<Date>(
      moment(new Date())
        .startOf(momentPeriod as any)
        .toDate()
    )

    React.useEffect(() => {
      let newControlDate = new Date()
      newControlDate = moment(newControlDate).startOf(momentPeriod).toDate()
      setControlDate(newControlDate)
    }, [momentPeriod])

    const [compareDate, setCompareDate] = useState<Date>(
      moment(new Date()).endOf('day').toDate()
    )
    const [isTableModified, setIsTableModified] = React.useState(false)

    React.useEffect(() => {
      setMomentPeriod(timeType === TimeType.week ? 'isoWeek' : 'month')
    }, [timeType])

    const colIdx2Key: string[] = Object.keys(getRow(timeType))
    const colKey2Idx = (key: string) => colIdx2Key.indexOf(key)

    const dataColumnDef = {
      editable: true,
      formatter: basicTableCellFormatter,
      headerCellClass: '',
      cellClass: '',
      minWidth: 100,
      unsafe_onCellInput: (event: any, row: any) => {
        onKeyDownAny(event)
        onKeyDownDelete(event)
      },
    }

    const workcenterColumnDef = {
      editable: false,
      formatter: workcenterTableCellFormatter,
      headerCellClass: '',
      cellClass: '',
      minWidth: 190,
    }

    const getPeriod = () => (momentPeriod === 'isoWeek' ? 'week' : momentPeriod)

    const year1 = moment(controlDate).format('YYYY')
    const workcenters: string[] = React.useMemo(() => {
      let workCenterArr: string[] = []
      workcenterStore!.allActiveWorkcenter
        .slice()
        .sort()
        .forEach((wc) => {
          workCenterArr.push(wc.number)
        })
      return capacityStore!.sortByCapacities(workCenterArr, controlDate, [
        CapacityType.demand_preproduction_change,
        CapacityType.demand_supermarket_share,
      ])
    }, [
      workcenterStore!.allActiveWorkcenter,
      capacityStore!.cacheRenderCounter,
      year1,
    ])

    const columnsDates: IColumnsDates = React.useMemo(() => {
      const columnsDates: IColumnsDates = {}
      const timePeriodCount = colIdx2Key.length - 1

      columnsDates['workcenter'] = { year: '0000', timeValue: '0' }
      for (let i: number = 0; i < timePeriodCount; i++) {
        const momentDate = moment(controlDate).add(i, getPeriod())
        const columnDate = {
          year:
            timeType === TimeType.month
              ? momentDate.format('YYYY')
              : momentDate.isoWeekYear().toString(),
          timeValue:
            timeType === TimeType.month
              ? momentDate.format('M')
              : momentDate.format('W'),
        }
        columnsDates[colIdx2Key[i + 1]] = columnDate
      }
      return columnsDates
    }, [controlDate, timeType, momentPeriod])

    const columns = (() => {
      const columns: Column<any, any>[] = [...getColumnValDef(timeType)]
      const timePeriodCount = colIdx2Key.length - 1

      for (let i: number = 0; i < timePeriodCount; i++) {
        const colIdx = i + 1
        const momentColDate = moment(controlDate).add(i, getPeriod())

        const isActualPeriod =
          columnsDates[colIdx2Key[colIdx]].timeValue ===
            currentColumnDate.timeValue &&
          columnsDates[colIdx2Key[colIdx]].year === currentColumnDate.year
        const isLastPeriodYear =
          columnsDates[colIdx2Key[colIdx + 1]] &&
          columnsDates[colIdx2Key[colIdx]].year !==
            columnsDates[colIdx2Key[colIdx + 1]].year
        const isFirstPeriodYear =
          columnsDates[colIdx2Key[colIdx - 1]] &&
          columnsDates[colIdx2Key[colIdx]].year !==
            columnsDates[colIdx2Key[colIdx - 1]].year
        let colDateText =
          timeType === TimeType.month
            ? momentColDate.format('MMM')
            : 'CW' + momentColDate.format('W')
        if (
          i === 0 ||
          i === timePeriodCount - 1 ||
          isLastPeriodYear ||
          isFirstPeriodYear
        ) {
          colDateText +=
            " '" + columnsDates[colIdx2Key[colIdx]].year.substring(2, 4)
        }

        let columnClass = isLastPeriodYear
          ? styles['capacitiesTableHeader-lastCol']
          : isActualPeriod
          ? styles['capacitiesTableHeader-actualMonth']
          : ''
        columns[i + 1].name = colDateText
        columns[i + 1].headerCellClass = columnClass
        columns[i + 1].cellClass = isActualPeriod ? '' : columnClass
      }
      // freeze workcenter column
      columns[0].frozen = true
      return columns
    })()

    const rows = React.useMemo(() => {
      let newRows: any[] = [] // any[] needed for different sum row
      let sumRowCurrent: IRow = { ...getRow(timeType) }
      let sumRowChanged: IRow = { ...getRow(timeType) }
      let sumRowDemandChange: IRow = { ...getRow(timeType) }
      const workCenterRowCaps = [
        '',
        CapacityType.demand_supermarket_share,
        CapacityType.demand_preproduction_change,
      ]
      const getNewRow = (workcenter: string, capType?: string): IRow => {
        const content = capType ? capType : workcenter
        return { ...getRow(timeType), workcenter: content }
      }

      for (const workcenter of workcenters) {
        for (const workCenterRowCap of workCenterRowCaps) {
          const capType = workCenterRowCap
            ? workCenterRowCap
            : CapacityType.demand
          let newRow = getNewRow(workcenter, workCenterRowCap)
          for (const sColKey of Object.keys(newRow)) {
            let colKey: keyof IRow = sColKey as keyof IRow
            if (colKey !== 'workcenter') {
              let { year, timeValue } = columnsDates[sColKey]
              let changedCapacity = getFromChanged(
                workcenter,
                year,
                timeValue,
                capType as CapacityType
              )
              let cachedCapacity = getFromCacheLatest(
                workcenter,
                year,
                timeValue,
                capType as CapacityType
              )
              let demandCap = {
                value: getCapFromMetricQueryData(year, timeValue, workcenter),
              }
              let capacity =
                capType === CapacityType.demand ? demandCap : changedCapacity
              if (!capacity) capacity = cachedCapacity
              newRow[colKey] = capacity ? capacity.value : 0

              if (capType === CapacityType.demand) {
                const { value } = demandCap
                const changeProdCapChange = getFromChanged(
                  workcenter,
                  year,
                  timeValue,
                  CapacityType.demand_preproduction_change
                )
                if (changeProdCapChange) {
                  const oldProdCap =
                    getFromCacheLatest(
                      workcenter,
                      year,
                      timeValue,
                      CapacityType.demand_preproduction_change
                    )?.value || 0
                  const capDifference = getCapDifference(
                    oldProdCap,
                    changeProdCapChange.value
                  )
                  const sumVal = value + capDifference
                  sumRowChanged[colKey] = fixedDecimalNumber(
                    parseFloat(sumRowChanged[colKey]) + sumVal
                  )
                  sumRowDemandChange[colKey] = fixedDecimalNumber(
                    parseFloat(sumRowDemandChange[colKey]) + capDifference
                  )
                } else {
                  sumRowCurrent[colKey] = fixedDecimalNumber(
                    parseFloat(sumRowCurrent[colKey]) + value
                  )
                  sumRowChanged[colKey] = fixedDecimalNumber(
                    parseFloat(sumRowChanged[colKey]) + value
                  )
                }
              }
            }
          }
          newRows.push(newRow)
        }
      }

      // Adding sum row
      let sumRow: IRow = {
        ...getRow(timeType, true),
        workcenter: t('capacity-management:sum'),
      }
      for (const sColKey of Object.keys({ ...getRow(timeType) })) {
        let colKey: keyof IRow = sColKey as keyof IRow
        if (colKey !== 'workcenter') {
          let { year, timeValue } = columnsDates[sColKey]
          let demandCap = {
            value: getCapFromMetricQueryData(year, timeValue, ''),
          }
          if (sumRowCurrent[colKey] === sumRowChanged[colKey]) {
            sumRow[colKey] = tableSumValues(demandCap.value)
          } else {
            sumRow[colKey] = tableSumValues(
              demandCap.value,
              fixedDecimalNumber(
                demandCap.value + parseFloat(sumRowDemandChange[colKey])
              )
            )
          }
        }
      }
      newRows.push(sumRow)

      setGridDataUpdateCount(gridDataUpdateCount + 1)

      return newRows
    }, [
      siteId,
      changed,
      capacityStore!.cache,
      capacityStore!.cacheRenderCounter,
      capacityStore!.metricQueryData,
      columnsDates,
      workcenters,
      capacityType,
      capacityStore!.cacheRenderCounter,
    ])

    const getCompareCapacity = (
      capacities: ICapacity[],
      capType: CapacityType
    ): ICapacity | undefined => {
      const compareDateNumber = +moment(compareDate)
      let olderCapacities: ICapacity[] = capacities.filter(
        (capacity: ICapacity) =>
          capacity.created.createdTime <= compareDateNumber &&
          capacity.capacityType === capType
      )
      return olderCapacities.length > 0
        ? olderCapacities[olderCapacities.length - 1]
        : undefined
    }

    function getLatestCapacity(
      capacities: ICapacity[],
      capType: CapacityType
    ): ICapacity | undefined {
      const compareDateNumber = +moment(currentDate)
      let olderCapacities: ICapacity[] = capacities.filter(
        (capacity: ICapacity) =>
          capacity.created.createdTime <= compareDateNumber &&
          capacity.capacityType === capType
      )
      return olderCapacities.length > 0
        ? olderCapacities[olderCapacities.length - 1]
        : undefined
    }

    function getFromChanged(
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType?: CapacityType
    ): ICapacity | undefined {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = changed[key]
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    const getFromCache = (
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType: CapacityType
    ): ICapacity | undefined => {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = getCompareCapacity(capacityStore!.cache[key], capacityType)
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    function getFromCacheLatest(
      workcenter: string,
      year: string,
      timeValue: string,
      capacityType: CapacityType
    ): ICapacity | undefined {
      let capacity: ICapacity | undefined = undefined
      const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
      try {
        capacity = getLatestCapacity(capacityStore!.cache[key], capacityType)
      } catch (error) {
        capacity = undefined
      }
      return capacity
    }

    React.useEffect(() => {
      if (workcenterStore && siteId) {
        workcenterStore!.setAll(siteId)
      }
    }, [workcenterStore, siteId])

    React.useEffect(() => {
      if (Object.keys(changed).length > 0)
        notify('Unsaved capacity data was lost!', 'warning')
      setGridDataUpdateCount(gridDataUpdateCount + 1)
      setChanged({})
      capacityStore!.clearCache()
    }, [siteId])

    let formatedControlDate = moment(controlDate).format('YYYYMMW')

    React.useEffect(() => {
      triggerLoadCapacities()
    }, [siteId, capacityType, timeType, formatedControlDate])

    function triggerLoadCapacities() {
      if (!capacityStore!.rootStore.capacityLoading) {
        const year1 = moment(controlDate).format('YYYY')
        const year2 = moment(controlDate).add(1, 'years').format('YYYY')

        if (
          !capacityStore!.loadedYears.includes(year1) ||
          capacityType !== capacityStore!.loadedCapacityTab ||
          timeType !== capacityStore!.loadedTimeType
        ) {
          capacityStore!.loadSiteYearTypeCapacities(
            siteId,
            year1,
            capacityTypeArr,
            timeType,
            true
          )
        }
        if (
          !capacityStore!.loadedYears.includes(year2) ||
          capacityType !== capacityStore!.loadedCapacityTab ||
          timeType !== capacityStore!.loadedTimeType
        ) {
          capacityStore!.loadSiteYearTypeCapacities(
            siteId,
            year2,
            capacityTypeArr,
            timeType,
            true
          )
        }
      }
    }

    const enableConfirm = () => {
      return isTableModified
    }

    const onKeyDownAny = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      const editKeys = '01234567890,.'
      if (editKeys.includes(event.key) && !currentCellPosition.inEditMode) {
        if (dataGridWrapper.current) {
          const childPosX = 0 + currentCellPosition.idx
          const childPosY = 3 + currentCellPosition.rowIdx
          const rdgRef = dataGridWrapper.current

          if (rdgRef && rdgRef.children) {
            const ref: any = rdgRef.children[childPosY].children[childPosX]

            if (ref) {
              ref.dispatchEvent(
                new MouseEvent('dblclick', {
                  view: window,
                  bubbles: true,
                  cancelable: true,
                  buttons: 1,
                })
              )
            }
          }
        }
        return
      } else if (event.key === 'Enter' && !currentCellPosition.inEditMode) {
        currentCellPosition.inEditMode = true
        return
      } else if (event.key === 'Enter' && currentCellPosition.inEditMode) {
        currentCellPosition.inEditMode = false
        return
      }
      if (event.key === 'Escape') {
        currentCellPosition.inEditMode = false
        return
      }
    }

    const onKeyDownDelete = async (
      event: React.KeyboardEvent<HTMLDivElement>
    ) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const colName = colIdx2Key[currentCellPosition.idx]
        const rowIdx = currentCellPosition.rowIdx
        const inEditMode = currentCellPosition.inEditMode
        const e: RowsUpdateEvent<{ [key: string]: string }> = {
          action: UpdateActions.CELL_UPDATE,
          cellKey: colName,
          updated: { [colName]: '' },
          fromRow: rowIdx,
          toRow: rowIdx,
        }

        if (!inEditMode) {
          updateChanged(e)
        }
      }
    }

    const controlDateMod = (fast: boolean, subtract: boolean) => {
      const period = fast ? 'year' : getPeriod()
      let newDate: Date
      if (subtract) newDate = moment(controlDate).subtract(1, period).toDate()
      else newDate = moment(controlDate).add(1, period).toDate()
      setControlDate(newDate)
    }

    const cancelChanges = () => {
      setGridDataUpdateCount(gridDataUpdateCount + 1)
      setChanged({})
      setIsTableModified(false)
    }

    const confirmChanges = () => {
      // Execute data storing.
      capacityStore!.saveChangedCapacities(changed).then(() => {
        setChanged({})
        triggerLoadCapacities()
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setIsTableModified(false)
      })
    }

    const updateOneCapacity = (
      workcenter: string,
      year: string,
      timeValue: string,
      newCapacityValue: any,
      capacityType: CapacityType,
      changedCopy: ICapacityChanged
    ) => {
      const currentCapacity = getFromCacheLatest(
        workcenter,
        year,
        timeValue,
        capacityType
      )
      let newCapacity: ICapacity

      const valueAsNumber =
        newCapacityValue === '' ? 0 : parseFloat(newCapacityValue)
      const isValid = !isNaN(valueAsNumber)

      if (isValid) {
        if (currentCapacity) {
          newCapacity = { ...currentCapacity, value: valueAsNumber }
        } else {
          const workcenterId = workcenterStore!.workcenterNumber2Id[workcenter]
          newCapacity = getEmptyCapacity()
          newCapacity.capacityType = capacityType
          newCapacity.timeType = timeType.toUpperCase() as TimeType
          newCapacity.timeValue = (timeValue as unknown) as number
          newCapacity.year = (year as unknown) as number
          newCapacity.workcenterId = workcenterId
          newCapacity.value = valueAsNumber
        }

        if (!currentCapacity || newCapacityValue !== currentCapacity!.value) {
          const key = `${workcenter}_${year}_${timeValue}_${capacityType}`
          changedCopy[key] = newCapacity
        }
      }

      return changedCopy
    }

    const updateChanged = (e: any) => {
      // console.log('===== updateChanged e: ' + JSON.stringify(e))
      setIsTableModified(true)

      const pos = { idx: colKey2Idx(e.cellKey), rowIdx: e.fromRow }
      // console.log('===== pos: ' + JSON.stringify(pos))

      const frontCol = rows[e.fromRow].workcenter
      const newCapacityValue = e.updated[e.cellKey]
      const capType: CapacityType =
        frontCol === CapacityType.demand_supermarket_share ||
        frontCol === CapacityType.demand_preproduction_change
          ? frontCol
          : CapacityType.demand
      if (pos.idx > 0 && capType !== CapacityType.demand) {
        let workcenter = ''
        let changedCopy = { ...changed }
        const { year, timeValue } = columnsDates[e.cellKey]
        if (capType === CapacityType.demand_supermarket_share) {
          workcenter = rows[e.fromRow - 1].workcenter
        } else workcenter = rows[e.fromRow - 2].workcenter

        changedCopy = updateOneCapacity(
          workcenter,
          year,
          timeValue,
          newCapacityValue,
          capType,
          changedCopy
        )
        setGridDataUpdateCount(gridDataUpdateCount + 1)
        setChanged(changedCopy)
        // TODO: When this is triggered, the table refreshes and the selected cell is changing to the first cell of the table.
      }
    }

    function getLegendEntries() {
      const legendEntries: CapacityLegendNames[] = []
      legendEntries.push(CapacityLegendNames.ADDED_SUPERMARKET)
      legendEntries.push(CapacityLegendNames.PICKED_SUPERMARKET)
      legendEntries.push(CapacityLegendNames.WITH_PLANNED_CHANGE)
      if (isTableModified) legendEntries.push(CapacityLegendNames.NEW_CAPACITY)

      return legendEntries
    }

    const dataGridWrapper: React.MutableRefObject<HTMLElement | null> = React.useRef(
      null
    )
    const { current: dataGridRef }: { current: any } = dataGridWrapper
    const dataGridcrollPosition = dataGridRef ? dataGridRef.scrollTop || 0 : 0

    const restoreSelectionAndScrollPosition = () => {
      const { current: dataGridRef }: { current: any } = dataGridWrapper

      if ($gridHandle) {
        $gridHandle.selectCell(currentCellPosition)
      }

      // restore exact scroll position
      if (dataGridRef) {
        dataGridRef.scrollTop = dataGridcrollPosition
      }
    }

    React.useEffect(() => {
      if (
        $gridHandle &&
        (lastCellPosition.idx !== currentCellPosition.idx ||
          lastCellPosition.rowIdx !== currentCellPosition.rowIdx)
      ) {
        // momorize last cell position
        lastCellPosition.idx = currentCellPosition.idx
        lastCellPosition.rowIdx = currentCellPosition.rowIdx
        defer(() => {
          if ($gridHandle) {
            restoreSelectionAndScrollPosition()
          }
        })
      }
      // here it's important to use the same dependencies that are responsible to rerender the grid table
      // eslint-disable-next-line
    }, [
      capacityType,
      gridDataUpdateCount,
      // eslint-disable-next-line
      capacityStore!.cacheRenderCounter,
    ])

    React.useEffect(() => {
      lastCellPosition.idx = 0
      lastCellPosition.rowIdx = 0
      currentCellPosition.idx = 0
      currentCellPosition.rowIdx = 0
      currentCellPosition.inEditMode = false
      $gridHandle = null
    }, [siteId])

    const isEditView = () => Object.keys(changed).length > 0
    return (
      <div className={styles['container-box']}>
        <Notifications
          isWorkDays={false}
          hasOptionButton={true}
          disableOptionButton={isEditView()}
          capacityType={capacityType}
          timeType={currentSite.capacityMaintenance.supermarket}
          currentSite={currentSite}
        />

        <Controlbar
          simpleControlBar
          controlDate={controlDate}
          controlDateMod={controlDateMod}
          timeType={timeType}
          confirmChanges={confirmChanges}
          enableConfirm={enableConfirm()}
          cancelChanges={cancelChanges}
          isEditView={isEditView()}
          loadLegendValues={getLegendEntries()}
        />
        <div
          className={
            styles[
              isEditView()
                ? 'capacitiesTabs-editModalHeaderOn'
                : 'capacitiesTabs-editModalHeaderOff'
            ]
          }
        />
        <div
          className={
            styles[
              isEditView()
                ? 'capacitiesTabs-editModalOn'
                : 'capacitiesTabs-editModalOff'
            ]
          }
        />

        <RootRef rootRef={dataGridWrapper}>
          <ReactDataGrid
            key={
              'ReactDataGrid_key_' +
              gridDataUpdateCount +
              capacityStore!.cacheRenderCounter
            }
            ref={(gridHandle) => {
              if (gridHandle) {
                $gridHandle = gridHandle
              }
            }}
            columns={columns}
            rows={rows}
            height={500}
            onRowsUpdate={updateChanged}
            // enableCellCopyPaste
            // enableCellDragAndDrop
            /** Function called whenever selected cell is changed */
            onSelectedCellChange={({ idx, rowIdx }) => {
              if (
                currentCellPosition.idx !== idx ||
                currentCellPosition.rowIdx !== rowIdx
              ) {
                currentCellPosition.idx = idx
                currentCellPosition.rowIdx = rowIdx
                currentCellPosition.inEditMode = false
              }
            }}
            onCheckCellIsEditable={(event: any) => {
              // disallow editing of workcenter group header rows
              const currentRow = rows[currentCellPosition.rowIdx]
              if (
                (currentRow.workcenter !==
                  CapacityType.demand_supermarket_share &&
                  currentRow.workcenter !==
                    CapacityType.demand_preproduction_change) ||
                currentRow.workcenter === t('capacity-management:sum')
              ) {
                return false
              }

              if (!event.status || event.status !== 'SELECT') {
                currentCellPosition.inEditMode = true
              }

              return true
            }}
          />
        </RootRef>
      </div>
    )

    function getRow(timeFormat: string, sum?: boolean) {
      let row = {
        workcenter: '',
      }
      const value = sum ? '' : 0
      const timeSpan =
        timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12
      for (let i = 0; i < timeSpan; i++) {
        const key = timeFormat + (i + 1)
        row = {
          ...row,
          [key]: value,
        }
      }
      return row
    }

    function getColumnValDef(timeFormat: string) {
      let columns = [
        {
          key: 'workcenter',
          name: t('workcenters:WORKCENTER'),
          ...workcenterColumnDef,
        },
      ]
      const timeSpan =
        timeFormat.toLowerCase() === 'week' ? visibleWeekColumns : 12
      for (let i = 0; i < timeSpan; i++) {
        const key = timeFormat + (i + 1)
        columns = [
          ...columns,
          {
            key: key,
            name: '',
            ...dataColumnDef,
          },
        ]
      }
      return columns
    }

    function getCapFromMetricQueryData(
      year: string,
      timeVal: string,
      wcNum: string
    ) {
      let cap = 0
      const metricQuery = capacityStore?.metricQueryData[year]
      const workcenterToMatch = workcenterStore?.allActiveWorkcenter.filter(
        (wc: IWorkcenter) => wc.number === wcNum
      )[0]
      const id = workcenterToMatch ? workcenterToMatch._id : null
      const correctedTimeValToColumn: number = parseInt(timeVal, 10) - 1
      const wcQuery = metricQuery
        ? metricQuery.series.filter((query: any) => query.dimensionId === id)
        : undefined

      if (wcQuery && wcQuery.length > 0) {
        cap = wcQuery[0].data[correctedTimeValToColumn]
      }
      return cap
    }

    function basicTableCellFormatter(props: any) {
      const value = props.row[props.column.key]
      const frontCol = props.row['workcenter']
      const isSumRow = frontCol === t('capacity-management:sum')
      if (isSumRow) {
        return value
      }
      const capType: CapacityType =
        frontCol === CapacityType.demand_supermarket_share ||
        frontCol === CapacityType.demand_preproduction_change
          ? frontCol
          : CapacityType.demand
      const workcenterNumber =
        frontCol === CapacityType.demand_supermarket_share
          ? rows[props.rowIdx - 1]['workcenter']
          : frontCol === CapacityType.demand_preproduction_change
          ? rows[props.rowIdx - 2]['workcenter']
          : frontCol

      const { year, timeValue } = columnsDates[props.column.key]
      const currentCapacity = getFromCacheLatest(
        workcenterNumber,
        year,
        timeValue,
        capType
      )

      let changedCapacity = getFromChanged(
        workcenterNumber,
        year,
        timeValue,
        capType
      )

      if (capType === CapacityType.demand) {
        // workcenter group header (cells with demand)
        const metricQuery = capacityStore?.metricQueryData[year]
        const workcenterToMatch = workcenterStore?.allActiveWorkcenter.filter(
          (wc: IWorkcenter) => wc.number === workcenterNumber
        )[0]
        const id = workcenterToMatch ? workcenterToMatch._id : ''
        const correctedTimeValToColumn: number = (timeValue as any) - 1
        const wcQuery = metricQuery
          ? metricQuery.series.filter(
              (query: any) => query.dimensionId && query.dimensionId === id
            )[0]
          : undefined
        const cap =
          wcQuery && wcQuery.data && wcQuery.data[correctedTimeValToColumn]
            ? wcQuery.data[correctedTimeValToColumn]
            : 0

        const changeProdCapChange = getFromChanged(
          workcenterNumber,
          year,
          timeValue,
          CapacityType.demand_preproduction_change
        )
        if (changeProdCapChange) {
          const oldProdCap =
            getFromCacheLatest(
              workcenterNumber,
              year,
              timeValue,
              CapacityType.demand_preproduction_change
            )?.value || 0
          const capDifference = getCapDifference(
            oldProdCap,
            changeProdCapChange.value
          )
          return capDifference !== 0
            ? tableDemandAndCalcDemand(cap, capDifference)
            : tableDemandAndCalcDemand(cap)
        }
        return tableDemandAndCalcDemand(cap)
      }

      const oldCap = currentCapacity //black
      const newCap = changedCapacity //blue
      return CapHlp.tableValue(value, oldCap, newCap)
    }

    function workcenterTableCellFormatter(props: any) {
      const value = props.row[props.column.key]
      const capType: CapacityType =
        value === CapacityType.demand_supermarket_share ||
        value === CapacityType.demand_preproduction_change
          ? value
          : CapacityType.demand

      return tableWorkcenterColumnCell(value, capType)
    }

    function tableWorkcenterColumnCell(value: any, capType: CapacityType) {
      const isWorkCenterGroupHeader = capType === CapacityType.demand
      const maxLength = 12
      let sValue: string = value as string
      if (sValue.length > maxLength)
        sValue = sValue.substr(0, maxLength) + '...'
      const newValue =
        capType === CapacityType.demand_supermarket_share
          ? t('capacity-management:share_sup')
          : capType === CapacityType.demand_preproduction_change
          ? t('capacity-management:production')
          : value === t('capacity-management:sum')
          ? `${value}`
          : `${sValue} • ${t('capacity-management:tab-demand')}`

      return (
        <div
          className={
            isWorkCenterGroupHeader
              ? styles['capacityGroupHeaderRowTableCell']
              : styles['capacityGroupRowTableCell']
          }>
          <span className={styles['capacitiesTableCell-bold']}>{newValue}</span>
        </div>
      )
    }

    function tableDemandCell(demandValue: any) {
      return (
        <span className={styles['capacitiesTableCell-blackValue']}>
          {`${CapHlp.formatValue(demandValue)}`}
        </span>
      )
    }

    function tableDemandAndCalcDemand(value: any, newValue?: any) {
      return (
        <div className={styles['capacityGroupHeaderRowTableCell']}>
          {CapHlp.formatValue(value)}
          <span className={styles['capacitiesTableCell-newValue']}>
            {newValue && ` › ${CapHlp.formatValue(newValue + value)}`}
          </span>
        </div>
      )
    }

    function tableSumValues(demandValue: any, newValue?: any) {
      if (newValue)
        return (
          <div className={styles['capacityGroupHeaderRowTableCell']}>
            {tableDemandCell(demandValue)}
            <span className={styles['capacitiesTableCell-newValue']}>
              {' › '}
              {`${newValue}${t('capacity-management:capacity-unit')}`}
            </span>
          </div>
        )
      return (
        <div className={styles['capacityGroupHeaderRowTableCell']}>
          {tableDemandCell(demandValue)}
        </div>
      )
    }

    function getCapDifference(oldCap: number, newCap: number) {
      const diff = oldCap > newCap ? oldCap - newCap : newCap - oldCap
      return oldCap <= newCap ? diff : -diff
    }
  })
)

export default withTranslation()(DemandSuperMarket)
