import React from 'react'
import { TFunction } from 'i18next'
import { withTranslation, WithTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import { Tooltip, Typography, withStyles, Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { Stores } from 'stores/stores'
import AuthenticationStore from 'stores/AuthenticationStore'
import SAPImportReportStore from 'stores/SAPImportReportStore'
import SAPImportReportService from 'services/SAPImportReportService'
import { ISite } from 'interfaces/Site'
import {
  ISAPImportReport,
  getSAPImportReport,
} from 'interfaces/SAPImportReport'
import SitePicker from 'components/SitePicker'

import styles from 'styles/sapImportReport.module.scss'

const useStyles = (theme: any) => ({
  listElement: {
    paddingLeft: '30px',
  },
  activeImport: {
    color: '#00a6eb',
  },
  checked: {
    color: '#00a6eb !important',
  },
})

const SwitchedOnSwitch = withStyles({
  disabled: { color: '#00a6eb !important' },
})(Switch)

interface SAPImportReportProps extends WithTranslation {
  authenticationStore?: AuthenticationStore
  sapImportReportStore?: SAPImportReportStore
  classes?: any
}

interface SAPImportReportState {
  selectedSites: ISite[]
  selectedSAPImportReport: ISAPImportReport
}

@inject(Stores.authenticationStore, Stores.sapImportReportStore)
@observer
class SAPImportReport extends React.Component<
  SAPImportReportProps,
  SAPImportReportState
> {
  authenticationStore: AuthenticationStore
  sapImportReportStore: SAPImportReportStore
  sapImportReportService: SAPImportReportService
  apiKey: string
  t: TFunction

  constructor(props: SAPImportReportProps) {
    super(props)
    this.authenticationStore = props.authenticationStore!
    this.sapImportReportStore = props.sapImportReportStore!
    this.sapImportReportService = new SAPImportReportService()
    this.t = props.t
    this.state = {
      selectedSites: [],
      selectedSAPImportReport: getSAPImportReport(),
    }
    this.apiKey =
      'Auj0bBxV9yPP7JbkxqJcekwvSH2TRe6QP34wuKmGsPT9EztdJlP4ykqK0eDhimg2gK3UqXuB2xJgpqIzCWQaMnYshwCPaOvecrKPcVqqxrpLk4zs5S6uNGLRH5OlWwqh'
    this.cpyApiKey = this.cpyApiKey.bind(this)
    this.cpySiteId = this.cpySiteId.bind(this)
  }

  componentDidMount() {
    this.sapImportReportStore.initImportReport()
  }

  componentDidUpdate() {
    const [firstSite] = this.authenticationStore!.currentUser.sites || []

    if (
      firstSite &&
      this.sapImportReportStore.importReportList &&
      (!this.state.selectedSites || this.state.selectedSites.length < 1)
    ) {
      this.handleSitesSelect([firstSite])
    }
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('sapImportReport:SAPImportReport')}
          </Typography>
          <Typography variant="caption">
            {this.t('sapImportReport:processTimeInfo')}
          </Typography>
          <div className="add-new-container" style={{ marginBottom: '20px' }}>
            <Typography>
              {this.getLastImport()
                ? this.t(`sapImportReport:lastImport`)
                : this.t(`sapImportReport:noImportFound`)}{' '}
              {this.getLastImport()}
            </Typography>
            <div className={styles['site-picker']}>
              <SitePicker
                handleSiteSelect={this.handleSitesSelect}
                sites={this.authenticationStore!.currentUser.sites || []}
                selectedSites={this.state.selectedSites}
              />
            </div>
          </div>
          <Box mb={2} display="flex">
            <Tooltip disableFocusListener title="Copy">
              <Box mb={1} mr={1}>
                <Chip
                  size="small"
                  icon={<FileCopyIcon />}
                  label={`Plant ID: ${this.getSitePlantId()}`}
                  onClick={this.cpyPlantId}
                />
              </Box>
            </Tooltip>
            <Tooltip disableFocusListener title="Copy">
              <Box mb={1} mr={1}>
                <Chip
                  size="small"
                  icon={<FileCopyIcon />}
                  label={`Technical Site ID: ${this.getSiteId()}`}
                  onClick={this.cpySiteId}
                />
              </Box>
            </Tooltip>
            <Tooltip disableFocusListener title="Copy">
              <Box mb={1} mr={1}>
                <Chip
                  size="small"
                  icon={<FileCopyIcon />}
                  label={`API Key: ${this.apiKey.substr(
                    0,
                    10
                  )}...${this.apiKey.substr(this.apiKey.length - 10, 10)}`}
                  onClick={this.cpyApiKey}
                />
              </Box>
            </Tooltip>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper>
                    {this.getLastImport() ? (
                      <List component="nav" aria-label="mailbox folders">
                        <ListItem>
                          <ListItemText
                            primary={this.t(
                              `sapImportReport:sapImportOverview`
                            )}
                          />
                        </ListItem>
                        <ListItem>
                          <FormControlLabel
                            disabled
                            control={
                              this.state.selectedSAPImportReport &&
                              this.state.selectedSAPImportReport.toggleConfig &&
                              this.state.selectedSAPImportReport.toggleConfig
                                .length > 0 &&
                              this.state.selectedSAPImportReport.toggleConfig.includes(
                                'workCenters'
                              ) ? (
                                <SwitchedOnSwitch checked />
                              ) : (
                                <Switch />
                              )
                            }
                            label=""
                          />
                          <ListItemText
                            className={classes.listElement}
                            primary={this.t(`sapImportReport:workcenter`)}
                          />
                          {this.getImportedWorkcentersSummary()}
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <FormControlLabel
                            disabled
                            control={
                              this.state.selectedSAPImportReport &&
                              this.state.selectedSAPImportReport.toggleConfig &&
                              this.state.selectedSAPImportReport.toggleConfig
                                .length > 0 &&
                              this.state.selectedSAPImportReport.toggleConfig.includes(
                                'hierarchy'
                              ) ? (
                                <SwitchedOnSwitch checked />
                              ) : (
                                <Switch />
                              )
                            }
                            label=""
                          />
                          <ListItemText
                            className={classes.listElement}
                            primary={this.t(
                              `sapImportReport:workcenterHierarchy`
                            )}
                          />
                          {this.getImportedWorkcentersHierarchySummary()}
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <FormControlLabel
                            disabled
                            control={
                              this.state.selectedSAPImportReport &&
                              this.state.selectedSAPImportReport.toggleConfig &&
                              this.state.selectedSAPImportReport.toggleConfig
                                .length > 0 &&
                              this.state.selectedSAPImportReport.toggleConfig.includes(
                                'workDays'
                              ) ? (
                                <SwitchedOnSwitch checked />
                              ) : (
                                <Switch />
                              )
                            }
                            label=""
                          />
                          <ListItemText
                            className={classes.listElement}
                            primary={this.t(`sapImportReport:workdays`)}
                          />
                          {this.getImportedWorkingDaysSummary()}
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <FormControlLabel
                            disabled
                            control={
                              this.state.selectedSAPImportReport &&
                              this.state.selectedSAPImportReport.toggleConfig &&
                              this.state.selectedSAPImportReport.toggleConfig
                                .length > 0 &&
                              this.state.selectedSAPImportReport.toggleConfig.includes(
                                'capacities'
                              ) ? (
                                <SwitchedOnSwitch checked />
                              ) : (
                                <Switch />
                              )
                            }
                            label=""
                          />
                          <ListItemText
                            className={classes.listElement}
                            primary={this.t(`sapImportReport:capacities`)}
                          />
                          {this.getImportedCapacitiesSummary()}
                        </ListItem>
                      </List>
                    ) : (
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={this.t(`sapImportReport:noImportFoundMsg`)}
                          />
                        </ListItem>
                      </List>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper>
                    <List component="nav" aria-label="mailbox folders">
                      <ListItem>
                        <ListItemText
                          primary={this.t(`capacity-management:title`)}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={this.t(`sapImportReport:demandPeriod`)}
                        />
                        {this.t(`capacity-management:${this.getSitePeriod()}`)}
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            {this.getLastImport() ? (
              <Grid item xs={8}>
                <Paper>
                  <List component="nav" aria-label="mailbox folders">
                    <ListItem>
                      <ListItemText
                        primary={this.t(`sapImportReport:sapImportSummary`)}
                      />
                    </ListItem>
                    <Divider />

                    {Array.from(
                      this.state?.selectedSAPImportReport?.summary?.keys() !=
                        null
                        ? this.state?.selectedSAPImportReport?.summary?.keys()
                        : []
                    ).map((key, index) => (
                      <ListItem key={key + '' + index}>
                        <ListItemText>
                          <Typography variant="h6">{key}</Typography>
                          {this.state.selectedSAPImportReport.summary
                            .get(key)
                            ?.split('\n')
                            .filter((s) => s.length > 0)
                            .map((result, resIndex) => (
                              <Typography key={key + '' + resIndex}>
                                {result}
                                <br />
                              </Typography>
                            ))}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Box>
      </React.Fragment>
    )
  }

  getLastImport = () => {
    let datetime = ''
    if (
      this.state.selectedSAPImportReport &&
      this.state.selectedSAPImportReport.timestamp != 0
    ) {
      datetime = moment(this.state.selectedSAPImportReport.timestamp).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    }
    return datetime
  }

  getSiteId = () => {
    if (this.state.selectedSites && this.state.selectedSites.length > 0) {
      return this.state.selectedSites[0]._id
    }
    return ''
  }

  getSitePlantId = () => {
    if (this.state.selectedSites && this.state.selectedSites.length > 0) {
      return this.state.selectedSites[0].plantId
    }
    return ''
  }

  getSitePeriod = () => {
    if (this.state.selectedSites && this.state.selectedSites.length > 0) {
      return this.state.selectedSites[0].capacityMaintenance.demand
    }
    return ''
  }

  getImportedWorkcentersSummary = () => {
    if (
      this.state.selectedSAPImportReport &&
      this.state.selectedSAPImportReport.siteId
    ) {
      return (
        this.state.selectedSAPImportReport.importStats.loadedWorkCenters +
        ' ' +
        this.t(`sapImportReport:loaded`) +
        ' / ' +
        this.state.selectedSAPImportReport.importStats.newWorkCenters +
        ' ' +
        this.t(`sapImportReport:new`)
      )
    }
    return ''
  }

  getImportedWorkcentersHierarchySummary = () => {
    if (
      this.state.selectedSAPImportReport &&
      this.state.selectedSAPImportReport.siteId
    ) {
      return (
        this.state.selectedSAPImportReport.importStats.loadedHierarchies +
        ' ' +
        this.t(`sapImportReport:loaded`) +
        ' / ' +
        this.state.selectedSAPImportReport.importStats.newHierarchies +
        ' ' +
        this.t(`sapImportReport:new`)
      )
    }
    return ''
  }

  getImportedWorkingDaysSummary = () => {
    if (
      this.state.selectedSAPImportReport &&
      this.state.selectedSAPImportReport.siteId
    ) {
      return (
        this.state.selectedSAPImportReport.importStats.loadedWorkDays +
        ' ' +
        this.t(`sapImportReport:loaded`) +
        ' / ' +
        this.state.selectedSAPImportReport.importStats.newWorkDays +
        ' ' +
        this.t(`sapImportReport:new`)
      )
    }
    return ''
  }

  getImportedCapacitiesSummary = () => {
    if (
      this.state.selectedSAPImportReport &&
      this.state.selectedSAPImportReport.siteId
    ) {
      return (
        this.state.selectedSAPImportReport.importStats.loadedCapacities +
        ' ' +
        this.t(`sapImportReport:loaded`) +
        ' / ' +
        this.state.selectedSAPImportReport.importStats.newCapacities +
        ' ' +
        this.t(`sapImportReport:new`)
      )
    }
    return ''
  }

  cpyPlantId = () => {
    navigator.clipboard.writeText(this.getSitePlantId())
  }
  cpySiteId = () => {
    navigator.clipboard.writeText(this.getSiteId())
  }
  cpyApiKey = () => {
    navigator.clipboard.writeText(this.apiKey)
  }

  handleSitesSelect = (sites: ISite[]) => {
    if (sites && sites.length > 0) {
      const [firstSite] = sites || []
      const { _id: siteID } = firstSite || {}
      const selectedReport = this.sapImportReportStore.getReportById(siteID)

      this.setState({
        selectedSAPImportReport: selectedReport
          ? selectedReport
          : getSAPImportReport(),
        selectedSites: sites,
      })
    }
  }
}

export default withTranslation()(withStyles(useStyles)(SAPImportReport))
