import { ILocalCapability } from 'interfaces/Capabilities'
import { getDivision, IDivision } from './Division'
import { ILastEdit, ILocation, IRegion, region } from './Common'
import { TimeType } from './Capacity'

export interface ICapacityMaintenance {
  [key: string]: any
  available: TimeType
  demand: TimeType
  forecast: TimeType
  supermarket: TimeType
  transfer: TimeType
}

export interface ISite {
  _id: string
  plantId: string
  name: string
  region: IRegion
  country: string
  countryCode: string
  city: string
  numberOfEmployees: number
  complaintsRate: number
  capabilities: ILocalCapability[]
  lastEdit: ILastEdit
  location: ILocation
  sitesForReceivingCapacities: string[]
  division: IDivision
  capacityMaintenance: ICapacityMaintenance
}

export const Site: any = {
  plantId: '',
  name: '',
  region,
  country: '',
  city: '',
  numberOfEmployees: 0,
  complaintsRate: 0,
  capabilities: [],
  sitesForReceivingCapacities: [],
  division: getDivision(),
  lastEdit: {
    lastEditedTime: 0,
    lastEditedUser: '',
  },
}

export const getSite = (): ISite => {
  return {
    _id: '',
    plantId: '',
    name: '',
    region,
    country: '',
    countryCode: '',
    city: '',
    numberOfEmployees: 0,
    complaintsRate: 0,
    capabilities: [],
    location: {
      lat: '',
      lon: '',
    },
    sitesForReceivingCapacities: [],
    division: getDivision(),
    lastEdit: {
      lastEditedTime: 0,
      lastEditedUser: '',
    },
    capacityMaintenance: {
      available: TimeType.month,
      demand: TimeType.month,
      forecast: TimeType.month,
      supermarket: TimeType.month,
      transfer: TimeType.month,
    },
  }
}
