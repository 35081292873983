import 'utils/polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.scss'
import App from './App'
import 'typeface-roboto'
import BackspaceDisabler from 'backspace-disabler'

BackspaceDisabler.disable()

ReactDOM.render(<App />, document.getElementById('root'))
