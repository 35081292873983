import _ from 'lodash'
import React from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box } from '@material-ui/core'

export type SortOrder = 'asc' | 'desc'

export function renderTableSortHeader(
  selectedColumn: string,
  header: string,
  sortOrder: SortOrder
) {
  if (selectedColumn === header) {
    if (sortOrder === 'asc') return <ExpandLessIcon />
    else return <ExpandMoreIcon />
  }
  return <Box width="24px" />
}

function getSortFunction(key: string) {
  let field = ''
  if (['division', 'region'].includes(key)) field = 'name'
  if (key === 'lastEdit') field = 'lastEditedTime'

  return (a: any, b: any) => {
    const aField = a[key][field]
    const bField = b[key][field]
    if (aField && bField) {
      if (aField < bField) return -1
      if (aField > bField) return 1
    }
    return 0
  }
}
export function handleSort(data: any[], key: string, sortOrder: SortOrder) {
  if (data.length !== 0) {
    if (typeof data[0][key] !== 'object') {
      return _.orderBy(data, key, sortOrder)
    } else {
      const sortFunction = getSortFunction(key)
      const sorted = data.slice().sort(sortFunction)
      if (sortOrder === 'desc') return sorted.reverse()
      return sorted
    }
  }
}

export function alphaNumSort(alphaNumArr: string[]) {
  return alphaNumArr.sort(sortAlphaNum)
  function sortAlphaNum(a: any, b: any) {
    const reA = /[^a-zA-Z]/g
    const reN = /[^0-9]/g
    const AInt = parseInt(a, 10)
    const BInt = parseInt(b, 10)

    if (isNaN(AInt) && isNaN(BInt)) {
      const aA = a.replace(reA, '')
      const bA = b.replace(reA, '')
      if (aA === bA) {
        const aN = parseInt(a.replace(reN, ''), 10)
        const bN = parseInt(b.replace(reN, ''), 10)
        return aN === bN ? 0 : aN > bN ? 1 : -1
      } else {
        return aA > bA ? 1 : -1
      }
    } else if (isNaN(AInt)) {
      //A is not an Int
      return 1 //to make alphanumeric sort first return -1 here
    } else if (isNaN(BInt)) {
      //B is not an Int
      return -1 //to make alphanumeric sort first return 1 here
    } else {
      return AInt > BInt ? 1 : -1
    }
  }
}
