import React from 'react'
import { ImagePicker } from 'react-file-picker'
import InfoIcon from 'assets/icons/info.svg'
import { Grid, Tooltip, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import 'styles/dialog.module.scss'

const ImageUploadService = (props) => {
  const [newIcon, setNewIcon] = React.useState(props.image)
  const [error, setSetError] = React.useState({ active: false, msg: '' })

  const errorHadler = async (errMsg) => {
    setSetError({ active: true, msg: errMsg })
    handleIconChanged('')
  }

  const handleIconChanged = (image) => {
    props.getImage(image)
    setNewIcon(image)
  }

  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 250,
      border: '1px solid #00A6EB',
    },
  }))(Tooltip)

  const tooltipRender = (type) => {
    return (
      <React.Fragment>
        <Typography color="inherit">Info</Typography>
        {type ? (
          <div>
            <p>Press to Delete</p>
          </div>
        ) : (
          <div>
            <p>Image extensions: 'jpg', 'jpeg', 'png' or 'svg'</p>
            <p>Image dimensions min 50px and max 500px</p>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <ImagePicker
          extensions={['jpg', 'jpeg', 'png', 'svg']}
          dims={{ minWidth: 50, maxWidth: 500, minHeight: 50, maxHeight: 500 }}
          onChange={(base64) => handleIconChanged(base64)}
          onError={(errMsg) => errorHadler(errMsg)}>
          <Button
            variant="contained"
            color={error.active ? 'secondary' : 'primary'}
            fullWidth>
            {error.active ? error.msg : 'Browser'}
          </Button>
        </ImagePicker>
      </Grid>

      <Grid item xs={2}>
        <HtmlTooltip
          title={newIcon ? tooltipRender(true) : tooltipRender(false)}>
          {newIcon ? (
            <div onClick={() => handleIconChanged('')}>
              <img
                className="image-upload"
                src={newIcon}
                style={{ width: '40px', marginLeft: '35px' }}
                alt="Info Icon"
              />
            </div>
          ) : (
            <img
              src={InfoIcon}
              style={{ width: '40px', marginLeft: '35px' }}
              alt="Info Icon"
            />
          )}
        </HtmlTooltip>
      </Grid>
    </React.Fragment>
  )
}

export default ImageUploadService
