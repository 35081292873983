import React from 'react'
import { Typography, Box } from '@material-ui/core'
import CapabilitiesTable from 'components/CapabilitiesTable'
import { CapabilityPages } from 'interfaces/Capabilities'
import { observer, inject } from 'mobx-react'
import { Stores } from 'stores/stores'
import DivisionStore from 'stores/DivisionStore'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import SiteStore from 'stores/SiteStore'

interface ProcessProps extends WithTranslation {
  divisionStore?: DivisionStore
  siteStore?: SiteStore
}

@inject(Stores.divisionStore, Stores.siteStore)
@observer
class CapabilityMatrix extends React.Component<ProcessProps> {
  divisionStore: DivisionStore
  siteStore: SiteStore
  t: TFunction

  constructor(props: ProcessProps) {
    super(props)
    this.divisionStore = props.divisionStore!
    this.siteStore = props.siteStore!
    this.t = props.t
  }

  componentDidMount() {
    this.divisionStore.initDivisions()
    this.siteStore.setAllSites()
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4" style={{ marginBottom: '15px' }}>
            {this.t('capabilities:capabilityMatrix')}
          </Typography>
          <CapabilitiesTable capabilityType={CapabilityPages.ProcessMatrix} />
        </Box>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CapabilityMatrix)
