import AuthenticationStore from 'stores/AuthenticationStore'
import UserManagementStore from './UserManagementStore'
import SiteStore from 'stores/SiteStore'
import CapabilityStore from './CapabilityStore'
import CapacityDashboardStore from './CapacityDashboardStore'
import { observable, action } from 'mobx'
import DivisionStore from './DivisionStore'
import MapStore from './MapStore'
import RouterStore from './RouterStore'
import WorkcenterStore from './WorkcenterStore'
import WorkDaysStore from './WorkDaysStore'
import CapacityStore from './CapacityStore'
import SapImportReportStore from './SAPImportReportStore'

export default class RootStore {
  authenticationStore: AuthenticationStore
  userManagementStore: UserManagementStore
  siteStore: SiteStore
  divisionStore: DivisionStore
  capabilityStore: CapabilityStore
  capacityDashboardStore: CapacityDashboardStore
  mapStore: MapStore
  routerStore: RouterStore
  workcenterStore: WorkcenterStore
  workDaysStore: WorkDaysStore
  capacityStore: CapacityStore
  sapImportReportStore: SapImportReportStore
  loadingRegistry: {
    [key: string]: boolean
  } = {}

  @observable anyLoading = false
  @observable loginLoading = false
  @observable capabilitiesLoading = false
  @observable sitesLoading = false
  @observable divisionLoading = false
  @observable usersLoading = false
  @observable workcentersLoading = false
  @observable workDaysLoading = false
  @observable capacityLoading = false
  @observable capacityMigrateLoading = false
  @observable sapImportReportLoading = false

  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.userManagementStore = new UserManagementStore(this)
    this.siteStore = new SiteStore(this)
    this.divisionStore = new DivisionStore(this)
    this.capabilityStore = new CapabilityStore(this)
    this.capacityDashboardStore = new CapacityDashboardStore(this)
    this.mapStore = new MapStore(this)
    this.routerStore = new RouterStore(this)
    this.workcenterStore = new WorkcenterStore(this)
    this.workDaysStore = new WorkDaysStore(this)
    this.capacityStore = new CapacityStore(this)
    this.sapImportReportStore = new SapImportReportStore(this)
  }

  @action
  setLoginLoading(loading: boolean) {
    this.loadingRegistry['login'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.loginLoading = loading
  }
  @action
  setCapabilitiesLoading(loading: boolean) {
    this.loadingRegistry['capabilities'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.capabilitiesLoading = loading
  }
  @action
  setSitesLoading(loading: boolean) {
    this.loadingRegistry['sites'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.sitesLoading = loading
  }
  @action
  setDivisionLoading(loading: boolean) {
    this.loadingRegistry['divisions'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.divisionLoading = loading
  }
  @action
  setUsersLoading(loading: boolean) {
    this.loadingRegistry['users'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.usersLoading = loading
  }
  @action
  setWorkcentersLoading(loading: boolean) {
    this.loadingRegistry['workcenters'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.workcentersLoading = loading
  }
  @action
  setWorkDaysLoading(loading: boolean) {
    this.loadingRegistry['workdays'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.workDaysLoading = loading
  }
  @action
  setCapacityLoading(loading: boolean) {
    this.loadingRegistry['capacities'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.capacityLoading = loading
  }
  @action
  setCapacityMigrateLoading(loading: boolean) {
    this.loadingRegistry['capacityMigrate'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.capacityMigrateLoading = loading
  }

  setSAPImportReportLoading(loading: boolean) {
    this.loadingRegistry['sapImportReport'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.sapImportReportLoading = loading
  }
}
