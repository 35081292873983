import React from 'react'
import { observer, Provider } from 'mobx-react'

import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core'

import myTheme from 'styles/createMyTheme'
import MyRouter from 'helpers/Router'
import stores from 'stores/stores'
import 'helpers/i18n'

const App: React.FC = observer(() => {
  return (
    // @ts-ignore
    <LocalizationProvider dateAdapter={MomentUtils}>
      <ThemeProvider theme={myTheme}>
        <CssBaseline>
          <Provider {...stores}>
            <MyRouter />
          </Provider>
        </CssBaseline>
      </ThemeProvider>
    </LocalizationProvider>
  )
})

export default App
