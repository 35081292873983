import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { notify } from '../components/Notification'
import { commonFunctions } from './ServiceConfig'
import { IDivision } from 'interfaces/Division'

const { handleResponse } = commonFunctions
export default class DivisionService {
  public getAllDivisions = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/divisions/getall`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:DivisionGetFailure'
          notify(message, 'error')
          return []
        }
      )
  }

  public deleteDivision = (divisionId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/divisions/delete?id=${divisionId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:divisionDelete', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:divisionDeleteFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public registerDivision = (division: any) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(division),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/divisions/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:divisionRegister', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:divisionRegisterFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public updateDivision = (division: IDivision) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(division),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/divisions/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:divisionUpdate', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:updateDivisionFailure'
          notify(message, 'error')
          return null
        }
      )
  }
}
