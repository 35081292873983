export interface IDivision {
  _id?: string
  name: string
  colorCode: string
  lastEdit: ILastEdit
}

export interface ILastEdit {
  lastEditedTime: number
  lastEditedUser: string
}

export const Division: IDivision = {
  name: '',
  colorCode: '',
  lastEdit: {
    lastEditedTime: 0,
    lastEditedUser: '',
  },
}

export const getDivision = (): IDivision => {
  return {
    _id: '',
    name: '',
    colorCode: '',
    lastEdit: {
      lastEditedTime: 0,
      lastEditedUser: '',
    },
  }
}
