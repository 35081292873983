import React from 'react'
import { Typography, Box } from '@material-ui/core'
import CapabilitiesTable from 'components/CapabilitiesTable'
import { CapabilityPages } from 'interfaces/Capabilities'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import CapabilityStore from 'stores/CapabilityStore'
import { DefaultTooltip } from 'styles/createMyTheme'
import { WithTranslation, withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Plus from 'assets/icons/plus.svg'
import DivisionStore from 'stores/DivisionStore'

interface CapabilitiesManagementProps extends WithTranslation {
  capabilityStore?: CapabilityStore
  divisionStore?: DivisionStore
}

@inject(Stores.capabilityStore, Stores.divisionStore)
@observer
class CapabilitiesManagement extends React.Component<
  CapabilitiesManagementProps
> {
  capabilityStore: CapabilityStore
  divisionStore: DivisionStore
  t: TFunction

  constructor(props: CapabilitiesManagementProps) {
    super(props)
    this.capabilityStore = props.capabilityStore!
    this.divisionStore = props.divisionStore!
    this.t = props.t
  }

  async componentDidMount() {
    this.divisionStore.initDivisions(true)
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">
            {this.t('header:capabilityManagement')}
          </Typography>
          <div className="add-new-container">
            <Typography>
              {this.t(`capabilities:capabilitiesInTotal`)}{' '}
              {this.capabilityStore!.capabilities.length}
            </Typography>
            <DefaultTooltip
              title={`${this.t('capabilities:addNewCapability')}`}
              placement="top">
              <img
                src={Plus}
                className="add-new-icon"
                alt=""
                onClick={() => this.capabilityStore.openCreateCapabilityModal()}
              />
            </DefaultTooltip>
          </div>
          <CapabilitiesTable
            capabilityType={CapabilityPages.MaintainCapability}
          />
        </Box>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CapabilitiesManagement)
