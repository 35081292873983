import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Box,
  Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DeleteButton } from 'styles/createMyTheme'

import styles from 'styles/dialog.module.scss'

interface LeavingWithoutSavingDialogProps {
  open: boolean
  onLeave: (save: boolean) => void
  onClose: () => void
}

const LeaveDialog: React.FC<LeavingWithoutSavingDialogProps> = ({
  open,
  onLeave,
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      PaperProps={{ style: { borderRadius: '10px' } }}
      onBackdropClick={onClose}>
      <DialogTitle className={styles['dialog-title']}>
        <Typography>{t('leaveDialog:areYouSure')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('leaveDialog:pageContainsUnsavedChanges')}</Typography>
      </DialogContent>
      <DialogActions>
        <div className={styles['action-button-container']}>
          <Box mr="auto">
            <Button variant="contained" onClick={onClose}>
              {t('cancel')}
            </Button>
          </Box>
          <DeleteButton
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => onLeave(false)}>
            {t('leaveDialog:leaveWithoutSave')}
          </DeleteButton>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onLeave(true)}>
            {t('leaveDialog:leaveWithSave')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default LeaveDialog
