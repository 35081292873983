import { observable, action, toJS } from 'mobx'
import assign from 'lodash/assign'
import isArray from 'lodash/isArray'

import RootStore from './RootStore'
import { getCapability, Capability, ICapability } from 'interfaces/Capabilities'
import CapabilityService from 'services/CapabilityService'

export default class CapabilityStore {
  rootStore: RootStore
  @observable capabilities: Capability[] = []
  capabilitiesCopy: Capability[] = []
  @observable capabilityToCreate = getCapability()
  @observable selectedCapability: Capability | undefined = undefined
  @observable createNewCapability: boolean = true
  @observable maintainCapabilityDialogOpen: boolean = false
  @observable capsByDivision: {
    [divisionId: string]: ICapability[]
  } = {}

  capabilityService = new CapabilityService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action.bound
  async setAllCapabilities(divisionId: string) {
    // after we tested a lot, we now are able to enable the capability cache by default
    return this.setAllCapabilitiesFromCache(divisionId, true)
  }

  @action.bound
  async setAllCapabilitiesFromCache(
    divisionId: string,
    useCache: boolean = true
  ) {
    this.createCapabilitiesCopy()
    this.rootStore.setCapabilitiesLoading(true)
    const capabilities = useCache
      ? await this.capabilityService.getAllCapabilitiesFromCache(divisionId)
      : await this.capabilityService.getAllCapabilities(divisionId)
    if (this.capabilitiesCopy.length !== 0) {
      this.applyOpenFromCopy(capabilities)
    }
    this.capabilities = capabilities
    this.rootStore.setCapabilitiesLoading(false)
    return capabilities
  }

  @action.bound
  async setAllCapsByDivisions(divisionIds: string[]) {
    this.rootStore.setCapabilitiesLoading(true)

    await divisionIds.forEach((divisionId) =>
      this.capabilityService
        .getAllCapabilities(divisionId)
        .then((capabilities) =>
          assign(this.capsByDivision, {
            [divisionId]: isArray(capabilities) ? capabilities : [],
          })
        )
        .catch(() =>
          assign(this.capsByDivision, {
            [divisionId]: [],
          })
        )
    )

    this.rootStore.setCapabilitiesLoading(false)
    return this.capsByDivision
  }

  applyOpenFromCopy(capabilities: Capability[]) {
    const applyCopyToOriginal = (copy: Capability, cap: Capability) => {
      cap.open = copy.open
      cap.children.forEach((capChild, index) => {
        if (copy.children[index]) {
          applyCopyToOriginal(copy.children[index], capChild)
        }
      })
    }

    capabilities.forEach((capability, index) => {
      if (this.capabilitiesCopy[index]) {
        applyCopyToOriginal(this.capabilitiesCopy[index], capability)
      }
    })
  }

  @action
  setCapabilities(list: Capability[]) {
    this.capabilities = list
  }

  @action
  setSelectedCapability(capability: Capability) {
    this.selectedCapability = capability
  }

  @action
  setCapabilityToCreate(capablity: Capability) {
    this.capabilityToCreate = capablity
  }

  @action
  setCreateNewCapability(createNew: boolean) {
    this.createNewCapability = createNew
  }

  @action
  setMaintainCapabilityDialogOpen(open: boolean) {
    this.maintainCapabilityDialogOpen = open
  }

  @action
  resetCapabilityCreation() {
    this.capabilityToCreate = getCapability()
    this.selectedCapability = undefined
    this.createNewCapability = false
  }

  @action
  openCreateCapabilityModal() {
    this.createNewCapability = true
    this.maintainCapabilityDialogOpen = true
  }

  @action
  openCreateNestedCapabilityModal(entry: Capability) {
    this.selectedCapability = entry
    this.createNewCapability = true
    this.maintainCapabilityDialogOpen = true
  }

  @action
  openEditCapabilityModal(entry: Capability) {
    this.selectedCapability = entry
    this.createNewCapability = false
    this.maintainCapabilityDialogOpen = true
  }

  createCapabilitiesCopy() {
    this.capabilitiesCopy = toJS(this.capabilities)
  }

  registerCapability = async (capability: Capability) => {
    this.rootStore.setCapabilitiesLoading(true)
    await this.capabilityService.registerCapablity(
      capability,
      this.rootStore.divisionStore.selectedDivision._id!
    )
    this.setAllCapabilities(this.rootStore.divisionStore.selectedDivision._id!)
    this.rootStore.authenticationStore.JWTLogin()
    this.rootStore.setCapabilitiesLoading(false)
  }

  @action
  updateCapability = async (capability: Capability) => {
    this.rootStore.setCapabilitiesLoading(true)
    // Update existing capability
    if (!this.createNewCapability && this.selectedCapability) {
      await this.capabilityService.updateExistingCapability(capability)

      // Create new nested capability
    } else if (this.createNewCapability && this.selectedCapability) {
      capability.sourceNotSet = true
      capability.parent = this.selectedCapability.capId
      await this.capabilityService.updateCapability(capability)
    }
    this.setAllCapabilities(this.rootStore.divisionStore.selectedDivision._id!)
    this.rootStore.authenticationStore.JWTLogin()
    this.rootStore.setCapabilitiesLoading(false)
  }

  deleteCapability = async (capability: Capability) => {
    this.rootStore.setCapabilitiesLoading(true)
    const response = await this.capabilityService.deleteCapability(capability)
    this.setAllCapabilities(this.rootStore.divisionStore.selectedDivision._id!)
    this.rootStore.authenticationStore.JWTLogin()
    this.rootStore.setCapabilitiesLoading(false)
    return response
  }

  forceDeleteCapability = async (capability: Capability) => {
    this.rootStore.setCapabilitiesLoading(true)
    await this.capabilityService.forceDeleteCapability(capability)
    this.setAllCapabilities(this.rootStore.divisionStore.selectedDivision._id!)
    this.rootStore.authenticationStore.JWTLogin()
    this.rootStore.setCapabilitiesLoading(false)
  }
}
