import { IUser } from 'interfaces/User'
import { action, observable } from 'mobx'
import RootStore from './RootStore'
import UserService from 'services/UserService'

export default class UserManagementStore {
  rootStore: RootStore
  @observable allUsers: IUser[] = []
  @observable modalOpen: boolean = false
  @observable user: any = []
  @observable newUser: boolean = false

  userService = new UserService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.allUsers = []
  }

  @action
  setAllUsers() {
    this.rootStore.setUsersLoading(true)
    this.userService.getAllUsers().then((users: any) => {
      this.allUsers = users
      this.rootStore.setUsersLoading(false)
    })
  }

  @action
  setModalUser(value: any) {
    this.user = value
    this.newUser = false
    this.modalOpen = true
  }

  @action
  setModalNewUser() {
    this.user = []
    this.newUser = true
    this.modalOpen = true
  }

  @action
  setModalClose() {
    this.modalOpen = false
  }
}
