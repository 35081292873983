import { observable, action } from 'mobx'
import { ISite } from 'interfaces/Site'
import RootStore from './RootStore'
import { IDivision } from '../interfaces/Division'
import { ILocalCapability } from '../interfaces/Capabilities'

export default class MapStore {
  rootStore: RootStore

  @observable selectedMapSites: ISite[] = []
  @observable allSites: ISite[] = []
  @observable filteredSites: ISite[] = []
  @observable selectMode: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  setAllSites(sites: ISite[]) {
    this.allSites = sites
    this.setFilteredSites(this.allSites)
  }

  @action
  setFilteredSites(sites: ISite[]) {
    this.filteredSites = sites
  }

  @action
  setSelectedMapSites(sites: ISite[]) {
    this.selectedMapSites = sites
  }

  @action
  enableMapSelect() {
    this.selectMode = true
  }

  @action
  disableSelect() {
    this.selectMode = false
  }

  @action
  filterSites(division: IDivision, processes?: string[], methods?: string[]) {
    const capabilityExternallyCapable = (
      capability: ILocalCapability
    ): boolean => capability.external === 1 || capability.external === 3
    const capabilityInternallyCapable = (
      capability: ILocalCapability
    ): boolean => capability.internal === 1 || capability.internal === 3
    const capabilityIsCapable = (capability: ILocalCapability): boolean =>
      capabilityExternallyCapable(capability) ||
      capabilityInternallyCapable(capability)
    let sites: ISite[] = this.allSites.filter(
      (site: ISite) => site.division._id === division._id
    )
    if (processes) {
      sites = sites.filter((site: ISite) => {
        const processArray: ILocalCapability[] = site.capabilities.filter(
          (process: ILocalCapability) => processes.includes(process.name)
        )
        const processCheckArray: ILocalCapability[] = processArray.filter(
          (process: ILocalCapability) => capabilityIsCapable(process)
        )
        return (
          processCheckArray.length > 0 &&
          processCheckArray.length === processArray.length
        )
      })
      if (methods) {
        sites = sites.filter((site: ISite) => {
          const methodArray: ILocalCapability[] = site.capabilities
            .map((process: ILocalCapability) =>
              process.children.map((method: ILocalCapability) => method)
            )
            .flat()
            .filter((method: ILocalCapability) => methods.includes(method.name))
          const checkedMethodArray: ILocalCapability[] = methodArray.filter(
            (method: ILocalCapability) => capabilityIsCapable(method)
          )
          return (
            checkedMethodArray.length > 0 &&
            checkedMethodArray.length === methodArray.length
          )
        })
      }
    }
    this.setFilteredSites(sites)
  }

  @action
  resetFilter() {
    this.setFilteredSites(this.allSites)
  }
}
