import { action, observable } from 'mobx'
import RootStore from './RootStore'
import { IDivision, getDivision } from 'interfaces/Division'
import DivisionService from 'services/DivisionService'

export default class DivisionStore {
  rootStore: RootStore
  @observable allDivisions: IDivision[] = []
  @observable modalOpen: boolean = false
  @observable division: IDivision = getDivision()
  @observable newDivision: boolean = false
  @observable selectedDivision: IDivision = getDivision()

  divisionService = new DivisionService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.allDivisions = []
  }

  @action
  setAllDivisions() {
    return new Promise((resolve) => {
      this.rootStore.setDivisionLoading(true)
      this.divisionService.getAllDivisions().then((divisions: any) => {
        this.allDivisions = divisions
        this.rootStore.setDivisionLoading(false)
        resolve()
      })
    })
  }

  @action async initDivisions(refreshCapabilities?: boolean) {
    if (
      this.allDivisions.length === 0 ||
      this.rootStore.capabilityStore.capabilities.length === 0
    ) {
      await this.setAllDivisions()
      if (this.allDivisions.length > 0) {
        const prefferedDivison = this.rootStore.authenticationStore.currentUser
          .preferredDivision
        const defaultDivision = prefferedDivison
          ? this.allDivisions.find((div) => div.name === prefferedDivison)
          : this.allDivisions[0]
        if (this.selectedDivision._id === '') {
          this.setSelectedDivision(defaultDivision)
        }
        if (refreshCapabilities)
          this.rootStore.capabilityStore.setAllCapabilities(
            this.selectedDivision._id!
          )
      }
    }
  }

  @action setSelectedDivisionById(id: string) {
    const selectedDivision = this.allDivisions.find(
      (division) => division._id === id
    )
    this.setSelectedDivision(selectedDivision!)
    this.rootStore.capabilityStore.setAllCapabilities(id)
  }

  @action setSelectedDivisionByName(name: string) {
    const selectedDivision = this.allDivisions.find(
      (division) => division.name === name
    )
    this.setSelectedDivision(selectedDivision!)
    if (selectedDivision)
      this.rootStore.capabilityStore.setAllCapabilities(selectedDivision!._id!)
  }

  @action resetSelectedDivision() {
    this.setSelectedDivision(getDivision())
  }
  @action
  setModalDivision(value: any) {
    this.division = value
    this.newDivision = false
    this.modalOpen = true
  }

  @action
  setModalNewDivision() {
    this.division = getDivision()
    this.newDivision = true
    this.modalOpen = true
  }

  @action
  setModalClose() {
    this.modalOpen = false
  }

  @action
  setSelectedDivision(division?: IDivision) {
    division
      ? (this.selectedDivision = division)
      : (this.selectedDivision = getDivision())
  }
}
