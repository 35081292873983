import { action, observable } from 'mobx'
import { getUser, IUser } from 'interfaces/User'
import RootStore from './RootStore'
import AuthenticationService from 'services/AuthenticationService'
import Routes from 'interfaces/Routes'

export default class AuthenticationStore {
  rootStore: RootStore

  @observable loggedIn = false
  @observable logInWithJwt = true
  @observable currentUser: IUser = getUser()
  @observable badge: boolean = false
  authenticationService = new AuthenticationService()
  public path: string = Routes.MAP

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    if (window.location.pathname !== Routes.LOGIN) {
      this.path = window.location.pathname
    }
  }

  @action
  setCurrentUser(user: IUser) {
    this.currentUser = { ...user }
    this.currentUser.sites = this.currentUser.sites.sort((a, b) =>
      a.name < b.name ? -1 : 1
    )
  }

  @action
  setUserProperties(authResponse: any) {
    this.loggedIn = true
    const tempUser = { ...this.currentUser }
    Object.assign(tempUser, authResponse.user)
    this.currentUser = tempUser
    this.currentUser.sites = this.currentUser.sites.sort((a, b) =>
      a.name < b.name ? -1 : 1
    )
  }

  @action
  async JWTLogin() {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      this.rootStore.setLoginLoading(true)
      const auth = await this.authenticationService.authenticateWithToken(jwt)
      if (auth) this.setUserProperties(auth)
      else this.logInWithJwt = false
      this.rootStore.setLoginLoading(false)
      return auth
    } else this.logInWithJwt = false
    return null
  }

  @action
  async login(email: string, password: string) {
    this.rootStore.setLoginLoading(true)
    const response = await this.authenticationService.authenticate(
      email,
      password
    )
    if (response) {
      this.setUserProperties(response)
    }
    this.rootStore.setLoginLoading(false)
    return response
  }

  @action
  recoverPassword(recoveryEmail: string) {
    this.authenticationService.sendRecoveryPassword(recoveryEmail)
  }

  @action
  setBadge(value: boolean) {
    this.badge = value
  }

  cleanLoginData() {
    this.authenticationService.removeUser()
    this.currentUser = getUser()
    this.logInWithJwt = true
    this.loggedIn = false
  }
}
