import React from 'react'
import {
  TextField,
  StandardTextFieldProps,
  Typography,
  InputAdornment,
} from '@material-ui/core'

interface TextDisplayEditProps extends StandardTextFieldProps {
  edit: boolean
  unit?: string
}

const TextDisplayEdit: React.FC<TextDisplayEditProps> = (props) => {
  const values = { ...props }
  delete values.edit
  delete values.unit
  if (props.edit) {
    if (props.unit) {
      return (
        <TextField
          {...values}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{props.unit!}</InputAdornment>
            ),
          }}
        />
      )
    } else {
      return <TextField {...values} />
    }
  } else {
    return (
      <Typography style={{ whiteSpace: 'pre-wrap' }}>
        {props.value as string}
        {props.unit && ' ' + props.unit}
      </Typography>
    )
  }
}

export default TextDisplayEdit
