import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Capability } from 'interfaces/Capabilities'
// import CapabilityService from "services/CapabilityService"

import styles from 'styles/dialog.module.scss'

interface SetAllModalProps {
  open: boolean
  onClose: () => void
  capability: Capability
  capable: 0 | 1
  onConfirm: () => void
}

const SetAllModal: React.FC<SetAllModalProps> = ({
  onClose,
  open,
  capability,
  onConfirm,
  capable,
}) => {
  const { t } = useTranslation()
  // const capabilityService = new CapabilityService()

  const setAllHandler = () => {
    onClose()
    onConfirm()
  }

  const renderDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            {t('capabilities:setAllText')}
            <span style={{ fontWeight: 'bold' }}> {capability.name} </span>
            <span> {t('capabilities:to')} </span>
            <span style={{ fontWeight: 'bold' }}>
              {t('capabilities:' + (capable === 0 ? 'notCapable' : 'capable')) +
                '.'}
            </span>
          </Typography>
          <Typography>{t('capabilities:areYourSure')}</Typography>
        </Grid>
      </Grid>
    )
  }

  const getDialogActions = () => {
    return (
      <div className={styles['action-button-container']}>
        <Box mr="auto">
          <Button variant="contained" onClick={() => onClose()}>
            {t('close')}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAllHandler()}>
          {t('confirm')}
        </Button>
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { borderRadius: '10px' } }}
      onBackdropClick={() => onClose()}>
      <DialogTitle className={styles['dialog-title']}>
        {t('capabilities:information')}
      </DialogTitle>
      <DialogContent>{renderDetails()}</DialogContent>
      <DialogActions>{getDialogActions()}</DialogActions>
    </Dialog>
  )
}

export default SetAllModal
