import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import { ISite } from 'interfaces/Site'
import { notify } from '../components/Notification'
import { commonFunctions } from './ServiceConfig'

const { handleResponse } = commonFunctions
export default class SiteService {
  public getAllSites = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/getall`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:siteGetFailure'
          notify(message, 'error')
          return []
        }
      )
  }

  public deleteSite = (siteId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/delete?id=${siteId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:siteDelete', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:siteDeleteFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public registerSite = (site: any) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(site),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:siteRegister', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:siteRegisterFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public updateSite = (site: ISite) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(site),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:siteUpdate', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:updateSiteFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public getAllRegions = () => {
    const requestOptions = {
      method: 'GET',
    }

    return fetch(
      `${EndpointService.getEndpoint()}/regions/getall`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:updateSiteFailure'
          notify(message, 'error')
          return null
        }
      )
  }

  public setAllLocalUserSites = (userId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/getUserSites?userId=${userId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          return []
        }
      )
  }

  public getCSVFile = (sitesList: Array<string>) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(sitesList),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/sites/csv`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          return []
        }
      )
  }
}
