import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CapabilitiesTable from 'components/CapabilitiesTable'
import { CapabilityPages } from '../../interfaces/Capabilities'
import { observer, inject } from 'mobx-react'
import { Stores } from '../../stores/stores'
import CapabilityStore from '../../stores/CapabilityStore'
import AuthenticationStore from 'stores/AuthenticationStore'

interface LocalCapabilitiesProps {
  capabilityStore?: CapabilityStore
  authenticationStore?: AuthenticationStore
}

const LocalCapabilities: React.FC<LocalCapabilitiesProps> = inject(
  Stores.capabilityStore,
  Stores.authenticationStore
)(
  observer(({ capabilityStore, authenticationStore }) => {
    const { t } = useTranslation()
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography variant="h4">{t('header:localCapabilities')}</Typography>
          {authenticationStore!.currentUser.sites.length > 0 ? (
            <React.Fragment>
              <CapabilitiesTable
                capabilityType={CapabilityPages.AssignCapability}
              />
            </React.Fragment>
          ) : (
            <Typography>{t('capabilities:userHasNoSites')}</Typography>
          )}
        </Box>
      </React.Fragment>
    )
  })
)

export default LocalCapabilities
