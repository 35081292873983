import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme'
import { withStyles, createStyles } from '@material-ui/styles'
import { TableCell, Tooltip, Button, Badge } from '@material-ui/core'

export const huebnerBlue = '#00A6EB'

const myTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: huebnerBlue, // Huebner Primary
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#EE7523', // Huebner Orange
      contrastText: '#ffffff',
    },
    error: {
      main: '#a8353a', // Huebner Red
    },
    info: {
      main: '#8a8a8a', // Huebner Gray
    },
  },
} as any)

export const HeaderTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#8a8a8a',
      color: theme.palette.common.white,
      borderBottom: '0px',
    },
  })
)(TableCell)

export const DefaultTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(16),
    color: '#fffff',
  },
}))(Tooltip)

export const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#e30935',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
}))(Button)

export const AllBelowButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 10,
    height: 30,
    margin: '0px 5px 0px 5px',
  },
}))(Button)

export const ConfirmButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderColor: '#e30935',
    color: '#e30935',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
}))(Button)

export const GreyBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#8a8a8a',
      color: '#fff',
    },
  })
)(Badge)

export default myTheme
