import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { Stores } from 'stores/stores'
import moment from 'moment'
import { withTranslation, WithTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import PlusIcon from 'assets/icons/plus.svg'
import AuthenticationStore from 'stores/AuthenticationStore'
import { DefaultTooltip, HeaderTableCell } from 'styles/createMyTheme'
import DivisionStore from 'stores/DivisionStore'
import { IDivision, Division } from 'interfaces/Division'
import DivisionService from 'services/DivisionService'
import DivisionDialog from 'components/DivisionDialog'
import { SortOrder, handleSort, renderTableSortHeader } from 'utils/TableSort'

interface DivisionsManagementProps extends WithTranslation {
  divisionStore?: DivisionStore
  authenticationStore?: AuthenticationStore
}

interface DivisionManagementState {
  selectedColumn: keyof IDivision | ''
  sortOrder: SortOrder
}

@inject(Stores.divisionStore, Stores.authenticationStore)
@observer
class DivisionsManagement extends React.Component<
  DivisionsManagementProps,
  DivisionManagementState
> {
  divisionStore: DivisionStore
  authenticationStore: AuthenticationStore
  divisionService: DivisionService

  Division: IDivision = Division
  t: TFunction

  constructor(props: DivisionsManagementProps) {
    super(props)
    this.state = {
      selectedColumn: '',
      sortOrder: 'desc',
    }
    this.divisionStore = props.divisionStore!
    this.authenticationStore = props.authenticationStore!
    this.divisionService = new DivisionService()
    this.t = props.t
  }

  componentDidMount() {
    this.divisionStore.initDivisions()
  }

  sortBy = (header: keyof IDivision) => {
    this.setState({ selectedColumn: header })
    const sameHeader = header === this.state.selectedColumn
    this.setState(
      {
        sortOrder:
          this.state.sortOrder === 'asc' && sameHeader ? 'desc' : 'asc',
      },
      () => {
        const sortedList = handleSort(
          this.divisionStore.allDivisions,
          header,
          this.state.sortOrder
        )
        if (sortedList) this.divisionStore.allDivisions = sortedList
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4}>
          <Typography component={'span'} variant="h4">
            {this.t('header:divisionManagement')}
          </Typography>
          <div className="add-new-container">
            <Typography component={'span'}>
              {this.t('division:divisionsInTotal')}{' '}
              {this.divisionStore.allDivisions
                ? this.divisionStore.allDivisions.length
                : 0}
            </Typography>
            <DefaultTooltip
              title={`${this.t('division:addNewDivision')}`}
              placement="top">
              <img
                src={PlusIcon}
                className="add-new-icon"
                alt=""
                onClick={() => this.divisionStore.setModalNewDivision()}
              />
            </DefaultTooltip>
          </div>
          {this.renderDivisionsTable()}
        </Box>
      </React.Fragment>
    )
  }

  renderDivisionsTable = () => {
    const headers = Object.keys(this.Division) as (keyof IDivision)[]
    return (
      <div>
        <TableContainer style={{ maxHeight: 'calc(100vh - 240px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow color="primary">
                {headers.map((header) => (
                  <HeaderTableCell
                    key={header}
                    onClick={() => this.sortBy(header)}>
                    <Box display="flex">
                      <Typography component={'span'} className="table-header">
                        {this.t(`division:${header}`)}
                      </Typography>
                      {renderTableSortHeader(
                        this.state.selectedColumn,
                        header,
                        this.state.sortOrder
                      )}
                    </Box>
                  </HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.divisionStore.allDivisions &&
                this.divisionStore.allDivisions.map((division, index) => (
                  <TableRow key={division._id! + index} hover={true}>
                    {headers.map((header) => (
                      <TableCell
                        onClick={() =>
                          this.divisionStore.setModalDivision(division)
                        }
                        key={division._id!.toString() + Math.random()}>
                        <Box display="flex" alignItems="center">
                          <Typography component={'span'}>
                            {this.renderCell(division, header)}
                          </Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DivisionDialog
          open={this.divisionStore.modalOpen}
          onClose={() => this.divisionStore.setModalClose()}
          initialDivision={this.divisionStore.division}
          modalType={this.divisionStore.newDivision}
          updateList={() => this.divisionStore.setAllDivisions()}
        />
      </div>
    )
  }

  renderCell = (division: IDivision, header: keyof IDivision) => {
    switch (header) {
      case 'lastEdit': {
        const value = division[header]
        const userEdit = value.lastEditedUser
        const editDate = moment(value.lastEditedTime).format('DD/MM/YYYY')
        return `${editDate} ${this.t('division:by')} ${userEdit}`
      }

      case 'name': {
        const value = division[header]
        if (value.length > 20)
          return (
            <Tooltip title={value} placement="top">
              <span>{`${value.slice(0, 19)}...`}</span>
            </Tooltip>
          )
        else return `${value}`
      }

      case 'colorCode': {
        const value = division[header]
        return (
          <Box display="flex" alignItems="center">
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: value,
                borderRadius: '50%',
              }}
            />
            <div style={{ marginLeft: '5px' }}>{value}</div>
          </Box>
        )
      }
      default:
        return division[header]
    }
  }
}

export default withTranslation()(DivisionsManagement)
