import React from 'react'
import styles from 'styles/capacitiesManagement.module.scss'
import i18n from 'i18next'
import { ICapacity, CapacityType, TimeType } from 'interfaces/Capacity'
import { fixedDecimalNumber } from 'utils/MiscFunctions'
import { Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'

export class CapHlp extends React.Component {
  private static errorStyle = styles['capacitiesTableCell-errorValue']
  private static noStyle = styles['capacitiesTableCell-noValue']
  private static oldStyle = styles['capacitiesTableCell-blackValue']
  private static newStyle = styles['capacitiesTableCell-newValue']
  private static plusStyle = styles['capacitiesTableCell-greenValue']
  private static minusStyle = styles['capacitiesTableCell-redValue']

  public static tableValue(value: any, oldCap?: ICapacity, newCap?: ICapacity) {
    const hasCapacityChanged =
      newCap && oldCap && oldCap!.value !== newCap!.value

    if (newCap && oldCap && hasCapacityChanged)
      return CapHlp.tableNewValue(value, oldCap)
    if (newCap && !oldCap) return CapHlp.tableNewValue(value)
    if (!newCap && !oldCap) return CapHlp.tableNoValue()

    //newCap && oldCap && !hasCapacityChanged || !newCap && oldCap
    return CapHlp.tableOldValue(value, oldCap!)
  }

  public static tableErrValue(value: any) {
    return (
      <div>
        <span className={this.errorStyle}>{CapHlp.formatValue(value)}</span>
      </div>
    )
  }

  public static tableErrText() {
    return (
      <div>
        <span className={this.errorStyle}>
          {i18n.t('capacity-management:errorValue')}
        </span>
      </div>
    )
  }

  public static tableNoValue() {
    const tooltipRenderer = () => {
      return (
        <div>
          <Typography variant={'body2'} display={'block'}>
            {i18n.t('capacity-management:no-value-tooltip')}
          </Typography>
        </div>
      )
    }
    return (
      <Tooltip title={tooltipRenderer()}>
        <div className={styles['capacitiesTableCell-noValueFill']}>
          <span className={CapHlp.noStyle}>
            {i18n.t('capacity-management:no-value')}
          </span>
        </div>
      </Tooltip>
    )
  }

  public static tableOldValue(value: any, oldCap: ICapacity) {
    let oldStyle = CapHlp.oldStyle
    if (
      oldCap &&
      oldCap.capacityType === CapacityType.demand_preproduction_change
    ) {
      if (value > 0) oldStyle = CapHlp.plusStyle
      if (value < 0) oldStyle = CapHlp.minusStyle
    }

    return (
      <Tooltip title={CapHlp.capacityTooltipRenderer(oldCap)}>
        <div>
          <span className={oldStyle}>{CapHlp.formatValue(value)}</span>
        </div>
      </Tooltip>
    )
  }

  public static tableNewValue(newValue: any, oldCap?: ICapacity) {
    let newStyle = CapHlp.newStyle
    let oldStyle = CapHlp.oldStyle
    if (
      oldCap &&
      oldCap.capacityType === CapacityType.demand_preproduction_change
    ) {
      if (oldCap.value > 0) oldStyle = CapHlp.plusStyle
      if (oldCap.value < 0) oldStyle = CapHlp.minusStyle
    }

    const formatedOldValue = oldCap ? (
      <span className={oldStyle}>{CapHlp.formatValue(oldCap.value)}</span>
    ) : (
      ''
    )

    const formatedValue = (
      <div>
        {formatedOldValue}
        <span className={newStyle}>
          {formatedOldValue && ' › '}
          {CapHlp.formatValue(newValue)}
        </span>
      </div>
    )
    if (oldCap)
      return (
        <Tooltip title={CapHlp.capacityTooltipRenderer(oldCap)}>
          {formatedValue}
        </Tooltip>
      )
    else return formatedValue
  }

  public static formatValue(value: any) {
    return (
      fixedDecimalNumber(value) + i18n.t('capacity-management:capacity-unit')
    )
  }

  private static capacityTooltipRenderer(cap: ICapacity) {
    const createdTime = moment(new Date(cap.created.createdTime)).format(
      'DD.MM.YYYY HH:mm:ss'
    )
    const createdBy = cap.created.createdBy
    return (
      <div>
        <Typography variant={'caption'}>
          {` ${
            cap.timeType === TimeType.month
              ? moment(cap.timeValue, 'MM').format('MMMM')
              : 'CW' + cap.timeValue
          } ${cap.year} • ${cap.value} ${i18n.t(
            'capacity-management:capacity-unit-long'
          )}`}
        </Typography>
        <Typography variant={'body2'} display={'block'}>
          {i18n
            .t('capacity-management:last-updated')
            .replace('%0%', createdBy)
            .replace('%1%', createdTime)}
        </Typography>
      </div>
    )
  }
}

export default CapHlp
