import { isArray, find } from 'lodash'

import BrokenImageIcon from '@material-ui/icons/BrokenImage'

import EndpointService from './EndpointService'
import HeaderService from './HeaderService'
import {
  IWorkcenter,
  IFlatCap,
  getFlatCap,
  IWorkcenterMapping,
  INewWorkcenterMapping,
} from 'interfaces/Workcenter'
import { notify } from '../components/Notification'
import { commonFunctions } from './ServiceConfig'
import { ICapability, ILocalCapability } from 'interfaces/Capabilities'

const { handleResponse } = commonFunctions
const maxCapabilityNameLength = 15

export default class WorkcenterService {
  public getAllWorkcenters = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/list`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getAllWorkcenters'
          notify(message, 'error')
          return []
        }
      )
  }

  public getSiteWorkcenters = (siteId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/list/site/${siteId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getSiteWorkcenters'
          notify(message, 'error')
          return []
        }
      )
  }

  public getWorkcenter = (workcenterId: string) => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/list/${workcenterId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getWorkcenter'
          notify(message, 'error')
          return []
        }
      )
  }

  public deleteWorkcenter = (workcenterId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/delete/${workcenterId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:deleteWorkcenter', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:deleteWorkcenter'
          notify(message, 'error')
          return null
        }
      )
  }

  public registerWorkcenter = (workcenter: any) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(workcenter),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:registerWorkcenter', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:registerWorkcenter'
          notify(message, 'error')
          return null
        }
      )
  }

  public updateWorkcenter = (workcenter: IWorkcenter) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(workcenter),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:updateWorkcenter', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:updateWorkcenter'
          notify(message, 'error')
          return null
        }
      )
  }

  public getWorkcenterMappings = () => {
    const requestOptions = {
      method: 'GET',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/mapping/list`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return isArray(response) ? response : []
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:getSiteWorkcenters'
          notify(message, 'error')
          return []
        }
      )
  }

  public createWorkcenterMapping = (
    mapping: IWorkcenterMapping | INewWorkcenterMapping
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: HeaderService.headers,
      body: JSON.stringify(mapping),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/mapping/create`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:createWorkcenterMapping'
          return null
        }
      )
  }

  public updateWorkcenterMapping = (mapping: IWorkcenterMapping) => {
    const requestOptions = {
      method: 'PUT',
      headers: HeaderService.headers,
      body: JSON.stringify(mapping),
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/mapping/update`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:updateWorkcenterMapping', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:updateWorkcenterMapping'
          notify(message, 'error')
          return null
        }
      )
  }

  public deleteWorkcenterMapping = (workcenterId: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: HeaderService.headers,
    }

    return fetch(
      `${EndpointService.getEndpoint()}/workcenters/mapping/delete/${workcenterId}`,
      requestOptions as any
    )
      .then(handleResponse)
      .then(
        (response: any) => {
          notify('notify:deleteWorkcenterMapping', 'success')
          return response
        },
        (error: Error) => {
          let message = error.message !== '500' ? '' : 'errors:server '
          message += 'errors:deleteWorkcenterMapping'
          notify(message, 'error')
          return null
        }
      )
  }
  // ===== Capabilities helper methods =====
  public static convertToFlatCaps = (
    capabilities: ICapability[],
    localCapabilities?: ILocalCapability[]
  ): IFlatCap[] => {
    const flatLocalCaps = WorkcenterService.toFlatCaps(localCapabilities || [])
    const flatCaps = WorkcenterService.toFlatCaps(capabilities || [])

    return flatCaps.map((flatCap, i, siteFlatCaps) =>
      WorkcenterService.fillCapabilityInfo(flatCap, siteFlatCaps, flatLocalCaps)
    )
  }

  public static toFlatCaps = (capabilities: ICapability[]) => {
    return capabilities.reduce((flatCapList: IFlatCap[], cap): IFlatCap[] => {
      flatCapList.push({
        id: cap.capId,
        id2: cap._id ? cap._id : cap.capId,
        level0Id: cap.level0Id,
        parentId: cap.parent,
        name: cap.name,
        fullName: '',
        shortName: '',
        indexInSiteFlatCaps: 0,
        image: cap.image || cap.level === 0 ? BrokenImageIcon.toString() : '',
        internal: cap.internal,
      })
      WorkcenterService.toFlatCaps(cap.children).map((cap) =>
        flatCapList.push({ ...cap })
      )
      return flatCapList
    }, [])
  }

  private static fillCapabilityInfo = (
    flatCap: IFlatCap,
    flatCapList: IFlatCap[],
    flatLocalCaps?: IFlatCap[]
  ): IFlatCap => {
    const truncName = (name: string) =>
      name.length > maxCapabilityNameLength
        ? name.substring(0, maxCapabilityNameLength) + '...'
        : name
    let image = ''
    let shortName = truncName(flatCap.name)
    let fullName = ''
    let indexInSiteFlatCaps = flatCapList.findIndex(
      (cap, i, a) => cap.id === flatCap.id
    )

    let cursorCap: IFlatCap = { ...flatCap }
    do {
      if (!cursorCap.parentId && cursorCap.image) {
        image = cursorCap.image || ''
      }
      fullName = cursorCap.name + (fullName ? ' > ' + fullName : '')
      cursorCap =
        flatCapList.find((cap) => cap.id === cursorCap.parentId) || getFlatCap()
    } while (cursorCap.id !== '')

    const localCap = find(flatLocalCaps, { id: flatCap.id })

    return {
      ...flatCap,
      indexInSiteFlatCaps: indexInSiteFlatCaps,
      image: image,
      shortName: shortName,
      fullName: fullName,
      internal: localCap ? localCap.internal : flatCap.internal,
    }
  }
}
