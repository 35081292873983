import React from 'react'
import {
  IconButton,
  createStyles,
  withStyles,
  Badge,
  Theme,
} from '@material-ui/core'

const StyledBadge: any = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      border: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: '#e53935',
    },
  })
)(Badge)

interface BadgeCustomProps {
  pointer?: boolean
}

export default class BadgeCustom extends React.Component<BadgeCustomProps> {
  render() {
    return (
      <IconButton
        aria-label="cart"
        style={this.props.pointer ? {} : { cursor: 'default' }}>
        <StyledBadge badgeContent={''} color="secondary"></StyledBadge>
      </IconButton>
    )
  }
}
