import { action, observable } from 'mobx'
import RootStore from './RootStore'
import {
  ISAPImportReport,
  getSAPImportReport,
} from 'interfaces/SAPImportReport'
import SAPImportReportService from 'services/SAPImportReportService'

export default class SAPImportReportStore {
  rootStore: RootStore
  @observable importReportList: ISAPImportReport[]
  @observable initializing: boolean = false
  @observable isInitialized: boolean = false

  sapImportReportService = new SAPImportReportService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.importReportList = []
  }

  @action
  setImportReportList() {
    return new Promise((resolve) => {
      this.rootStore.setSAPImportReportLoading(true)
      this.sapImportReportService
        .getLastReportList()
        .then((importReportResult: ISAPImportReport[]) => {
          this.importReportList = importReportResult
          if (this.importReportList && this.importReportList.length > 0) {
            this.importReportList.forEach((irl) => {
              if (irl.summary)
                irl.summary = new Map(Object.entries(irl.summary))
            })
          }
          this.rootStore.setSAPImportReportLoading(false)
          resolve()
        })
        .catch(resolve)
    })
  }

  @action async initImportReport() {
    if (
      !this.importReportList ||
      this.importReportList.length == 0 ||
      !this.rootStore.sapImportReportStore.importReportList
    ) {
      this.initializing = true
      await this.setImportReportList()
      this.initializing = false
      this.isInitialized = true
    }
  }

  getReportById(siteId: String): ISAPImportReport {
    this.setImportReportList()
    if (this.importReportList && this.importReportList.length > 0) {
      let sapIR = this.importReportList.find(
        (element) => element.siteId == siteId
      )
      if (sapIR) return sapIR
    }
    return getSAPImportReport()
  }
}
