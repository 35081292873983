import { ICreated } from './Common'

export interface ICapacity {
  _id: string
  capacityType: CapacityType
  created: ICreated
  timeType: TimeType
  timeValue: number
  value: number
  workcenterId: string
  workcenterMappingId: string
  year: number
}
export interface ICapacityCache {
  // workcenter_year_timeValue_capacityType
  [concatenated_key: string]: ICapacity[]
}

export interface ICapacityChanged {
  // workcenter_year_timeValue_capacityType
  [concatenated_key: string]: ICapacity
}

export interface ICapacityLegend {
  name: CapacityLegendNames
  text: string
  color: CapacityLegendColors
}

export enum CapacityLegendNames {
  ENTERED_HOURS = 'ENTERED_HOURS',
  NEW_CAPACITY = 'NEW_CAPACITY',
  ACTUAL_HOURS = 'ACTUAL_HOURS',
  FORMER_HOURS = 'FORMER_HOURS',
  ADDED_SUPERMARKET = 'ADDED_SUPERMARKET',
  PICKED_SUPERMARKET = 'PICKED_SUPERMARKET',
  HOUR_FREE_OFFER = 'HOUR_FREE_OFFER',
  OVERLOAD = 'OVERLOAD',
  WITH_PLANNED_CHANGE = 'WITH_PLANNED_CHANGE',
}

export enum CapacityLegendColors {
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum CapacityType {
  available = 'AVAILABLE',
  demand = 'DEMAND',
  forecast = 'FORECAST',
  demand_supermarket_share = 'DEMAND_SUPERMARKET_SHARE',
  demand_preproduction_change = 'DEMAND_PREPRODUCTION_CHANGE',
  demand_transfer = 'DEMAND_TRANSFER',
}

export enum TimeType {
  week = 'WEEK',
  month = 'MONTH',
}

export const Capacity: any = {
  capacityType: CapacityType.available,
  created: {
    createdTime: 0,
    createdUser: '',
  },
  timeType: TimeType.month,
  timeValue: 0,
  value: 0,
  workcenterId: '',
  workcenterMappingId: '',
  year: 0,
}

export const getEmptyCapacity = (): ICapacity => {
  return { _id: '', ...Capacity }
}

export enum WorkcenterType {
  NONE = 'NONE',
  ORIGIN = 'ORIGIN',
  TARGET = 'TARGET',
}

export interface IMigrate {
  capacityType: CapacityType
  siteId: string
  timeType: TimeType
}
