import RootStore from 'stores/RootStore'

export enum Stores {
  rootStore = 'rootStore',
  authenticationStore = 'authenticationStore',
  userManagementStore = 'userManagementStore',
  divisionStore = 'divisionStore',
  siteStore = 'siteStore',
  capabilityStore = 'capabilityStore',
  capacityDashboardStore = 'capacityDashboardStore',
  mapStore = 'mapStore',
  routerStore = 'routerStore',
  workcenterStore = 'workcenterStore',
  workDaysStore = 'workDaysStore',
  capacityStore = 'capacityStore',
  sapImportReportStore = 'sapImportReportStore',
}

const rootStore = new RootStore()

const stores = {
  [Stores.rootStore]: rootStore,
  [Stores.authenticationStore]: rootStore.authenticationStore,
  [Stores.userManagementStore]: rootStore.userManagementStore,
  [Stores.siteStore]: rootStore.siteStore,
  [Stores.divisionStore]: rootStore.divisionStore,
  [Stores.capabilityStore]: rootStore.capabilityStore,
  [Stores.capacityDashboardStore]: rootStore.capacityDashboardStore,
  [Stores.mapStore]: rootStore.mapStore,
  [Stores.routerStore]: rootStore.routerStore,
  [Stores.workcenterStore]: rootStore.workcenterStore,
  [Stores.workDaysStore]: rootStore.workDaysStore,
  [Stores.capacityStore]: rootStore.capacityStore,
  [Stores.sapImportReportStore]: rootStore.sapImportReportStore,
}

export default stores
