import { action, observable } from 'mobx'
import _ from 'lodash'

import {
  CapacityType,
  ICapacity,
  ICapacityCache,
  ICapacityChanged,
  TimeType,
} from 'interfaces/Capacity'
import {
  getWorkcenterMapping,
  IWorkcenter,
  IWorkcenterMapping,
} from 'interfaces/Workcenter'
import {
  ISeries,
  IQueryResult,
  Dimension,
  Metric,
  ITimeframe,
  FilterOperator,
  TimeframeLevel,
} from 'interfaces/CapacityDashboard'
import CapacityService from 'services/CapacityService'
import WorkcenterService from 'services/WorkcenterService'
import CapacityDashboardService from 'services/CapacityDashboardService'
import { alphaNumSort } from '../utils/TableSort'
import RootStore from './RootStore'
import {
  ChartTileState,
  getInitialChartTileState,
  updateTileState,
} from './CapacityDashboardStore'

export default class CapacityStore {
  rootStore: RootStore

  @observable cache: ICapacityCache = {}
  @observable loadedYears: string[] = []
  @observable cacheRenderCounter: number = 0
  @observable loadedCapacityTab: CapacityType = CapacityType.available
  @observable loadedTimeType: TimeType = TimeType.month
  @observable isModalOpen: boolean = false
  @observable isNewTargetWorkcenter: boolean = false
  @observable currentDemand: ChartTileState = getInitialChartTileState()
  @observable currentDeltaDemand: ChartTileState = getInitialChartTileState()
  @observable
  currentWorkcenterMapping: IWorkcenterMapping = getWorkcenterMapping()
  @observable metricQueryData: { [year: string]: ChartTileState } = {}
  @observable forecastDelta: boolean = false

  capacityService = new CapacityService()
  workcenterService = new WorkcenterService()
  capacityDashboardService = new CapacityDashboardService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.cache = {}
    this.loadedYears = []
  }

  @action
  clearCache() {
    this.cache = {}
    this.loadedYears = []
    this.metricQueryData = {}
  }

  clearCacheChangedYears = (changed: ICapacityChanged) => {
    const changedTableKeys = Object.keys(changed)
    let changedYears: string[] = []
    changedTableKeys.forEach((key: string) => {
      const year: string = changed[key].year.toString()
      if (!changedYears.includes(year)) changedYears.push(year)
    })

    let newLoadedYears: string[] = []
    this.loadedYears.forEach((year) => {
      if (!changedYears.includes(year)) {
        newLoadedYears.push(year)
      }
    })
    this.loadedYears = newLoadedYears

    const cacheTableKeys = Object.keys(this.cache)
    changedYears.forEach((year) => {
      cacheTableKeys.forEach((key: string) => {
        if (key.match(`[^_]*_${year}_.*`)) {
          delete this.cache[key]
        }
      })
    })
  }

  @action
  async loadSiteYearTypeCapacities(
    siteId: string,
    year: string,
    capacityTypes: CapacityType[],
    timeType?: TimeType,
    metricQuery?: boolean,
    wForecast?: boolean
  ) {
    this.rootStore.setCapacityLoading(true)

    // await this.rootStore.workcenterStore.setAll(siteId)

    const capsByType = await Promise.all(
      capacityTypes.map((type: CapacityType) =>
        this.capacityService.getSiteYearTypeCapacities(siteId, year, type)
      )
    )

    capsByType.forEach((caps) => this.addToCache(caps, this.cache))

    if (!this.loadedYears.includes(year)) {
      this.loadedYears.push(year)
    }
    this.loadedCapacityTab = capacityTypes[0]
    this.loadedTimeType =
      timeType !== undefined ? timeType : this.loadedTimeType

    if (metricQuery) {
      if (capacityTypes.includes(CapacityType.demand_transfer)) {
        // load capacity delta
        this.forecastDelta = wForecast === true ? true : false
        await this.getDeltaDemands(
          wForecast === true ? true : false,
          siteId,
          {
            start: new Date(parseInt(year, 10), 0, 1),
            end: new Date(parseInt(year, 10), 11, 31),
            span: null,
            level:
              timeType === TimeType.week
                ? TimeframeLevel.Week
                : TimeframeLevel.Month,
          },
          year
        )
      } else if (
        capacityTypes.includes(CapacityType.demand_supermarket_share) ||
        capacityTypes.includes(CapacityType.demand_preproduction_change)
      ) {
        // load capacity demand
        await this.getDemands(
          siteId,
          {
            start: new Date(parseInt(year, 10), 0, 1),
            end: new Date(parseInt(year, 10), 11, 31),
            span: null,
            level:
              timeType === TimeType.week
                ? TimeframeLevel.Week
                : TimeframeLevel.Month,
          },
          year
        )
      }
    }

    this.cacheRenderCounter++
    this.rootStore.setCapacityLoading(false)
  }

  addToCache(
    capacities: ICapacity[],
    capacityCache: ICapacityCache
  ): ICapacityCache {
    return capacities.reduce((p: ICapacityCache, c) => {
      if (!this.loadedYears.includes(c.year.toString()))
        this.loadedYears.push(c.year.toString())
      const workcenter = this.rootStore!.workcenterStore!.workcenterId2Number[
        c.workcenterId
      ]
      const key = `${workcenter}_${c.year}_${c.timeValue}_${c.capacityType}`

      if (!p[key]) p[key] = []
      p[key].push(c)
      p[key]
        .slice()
        .sort((a, b) => a.created.createdTime - b.created.createdTime)
      return p
    }, capacityCache)
  }

  @action
  async saveChangedCapacities(changed: ICapacityChanged) {
    this.rootStore.setCapacityLoading(true)

    const capacities: any[] = Object.values(changed)
      .filter((value) => !!value.workcenterId)
      .map((value) => ({
        workcenterId: value.workcenterId,
        capacityType: value.capacityType,
        timeType: value.timeType,
        timeValue: value.timeValue,
        value: value.value,
        year: value.year,
        workcenterMappingId: value.workcenterMappingId,
      }))

    this.clearCacheChangedYears(changed)

    await this.capacityService.createCapacites(capacities)

    this.rootStore.setCapacityLoading(false)
  }

  @action
  async getWorkcenterId(siteId: string, workcenterNumber: string) {
    return this.workcenterService
      .getSiteWorkcenters(siteId)
      .then((wcs: IWorkcenter[]) => {
        const redWcs: IWorkcenter[] = wcs.filter(
          (wc: IWorkcenter) => wc.number === workcenterNumber
        )
        if (redWcs.length > 0 && redWcs[0]._id) {
          return redWcs[0]._id
        }
        return ''
      })
  }

  @action
  setOriginWcMapping(siteId: string, workcenterId: string) {
    this.currentWorkcenterMapping.originSiteId = siteId
    this.currentWorkcenterMapping.originWorkCenterId = workcenterId
  }

  @action
  setModalNewTargetWorkcenter(siteId: string, wcNumber: string) {
    this.setOriginWcMapping(
      siteId,
      this.rootStore.workcenterStore.workcenterNumber2Id[wcNumber]
    )

    this.isNewTargetWorkcenter = true
    this.isModalOpen = true
  }

  @action
  setModalEditTargetWorkcenter(siteId: string, wcNumber: string) {
    const wcMapping: IWorkcenterMapping = this.rootStore.workcenterStore.workcenterMappings.filter(
      (wcMap: IWorkcenterMapping) =>
        wcMap.originSiteId === siteId &&
        wcMap.originWorkCenterId ===
          this.rootStore.workcenterStore.workcenterNumber2Id[wcNumber]
    )[0]

    this.currentWorkcenterMapping = { ...wcMapping }
    this.isNewTargetWorkcenter = false
    this.isModalOpen = true
  }

  @action
  setModalClose() {
    this.isModalOpen = false
    this.currentWorkcenterMapping = getWorkcenterMapping()
  }

  @action
  sortByCapacities(
    workcenterArr: string[],
    date: Date,
    capacityType: CapacityType | CapacityType[]
  ) {
    const capCacheKeys = Object.keys(this.cache)
    const maintainedSameYearCaps = capCacheKeys.filter((key: string) => {
      const [, year, , ...capCacheTypeParts] = key.split('_')
      const capCacheType = capCacheTypeParts.join('_')

      return (
        this.cache[key].length > 0 &&
        year === date.getFullYear().toString() &&
        (_.isArray(capacityType)
          ? capacityType.includes(capCacheType as CapacityType)
          : capacityType === capCacheType)
      )
    })
    const workCentersMaintained: string[] = _.uniq(
      maintainedSameYearCaps.map((key: string) => key.split('_')[0])
    )
    return alphaNumSort(workcenterArr).sort((a: string, b: string) => {
      if (
        workCentersMaintained.includes(a) &&
        workCentersMaintained.includes(b)
      )
        return 0
      else if (!workCentersMaintained.includes(a)) return 1
      else return -1
    })
  }

  @action
  async getDemands(siteId: string, timeframe: ITimeframe, year: string) {
    this.currentDemand.loading = true
    this.rootStore.setCapacityLoading(true)

    const series: ISeries[] = [
      {
        metric: Metric.CapacityDemand,
        drilldown: null,
      },
      {
        metric: Metric.CapacityDemand,
        drilldown: Dimension.Workcenter,
      },
    ]
    const filter = [
      {
        filterDimension: Dimension.Site,
        operator: FilterOperator.EQ,
        values: [siteId],
      },
    ]
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter,
        false
      )) || {}

    const metricData = updateTileState(result, this.currentDemand)

    this.setMetricQueryData(metricData, year)
    this.currentDemand = getInitialChartTileState()
    this.currentDemand.loading = false
    this.cacheRenderCounter++
    this.rootStore.setCapacityLoading(false)

    return metricData
  }

  @action
  resetMetricQueryData() {
    this.metricQueryData = {}
  }

  @action
  setMetricQueryData(newMetricData: ChartTileState, year: string) {
    this.metricQueryData = {
      ...this.metricQueryData,
      [year]: newMetricData,
    }
  }

  @action
  async getDeltaDemands(
    forecaset: boolean,
    siteId: string,
    timeframe: ITimeframe,
    year: string
  ) {
    this.currentDeltaDemand.loading = true
    this.rootStore.setCapacityLoading(true)

    const series: ISeries[] = [
      {
        metric: forecaset
          ? Metric.CapacityFreeWithForecast
          : Metric.CapacityFree,
        drilldown: null,
      },
      {
        metric: forecaset
          ? Metric.CapacityOverloadWithForecast
          : Metric.CapacityOverload,
        drilldown: null,
      },
      {
        metric: forecaset
          ? Metric.CapacityFreeWithForecast
          : Metric.CapacityFree,
        drilldown: Dimension.Workcenter,
      },
      {
        metric: forecaset
          ? Metric.CapacityOverloadWithForecast
          : Metric.CapacityOverload,
        drilldown: Dimension.Workcenter,
      },
    ]
    const filter = [
      {
        filterDimension: Dimension.Site,
        operator: FilterOperator.EQ,
        values: [siteId],
      },
    ]
    const today = new Date()
    const result: IQueryResult | Error =
      (await this.capacityDashboardService.getMetricsQuery(
        series,
        timeframe,
        today,
        filter,
        false
      )) || {}

    const metricData = updateTileState(result, this.currentDeltaDemand)

    this.setMetricQueryData(metricData, year)
    this.currentDeltaDemand = getInitialChartTileState()
    this.currentDeltaDemand.loading = false
    this.cacheRenderCounter++
    this.rootStore.setCapacityLoading(false)

    return metricData
  }

  convertCapacityToCapMaintenance = (cap: CapacityType): string => {
    switch (cap) {
      case CapacityType.available:
        return 'available'
      case CapacityType.demand:
        return 'demand'
      case CapacityType.forecast:
        return 'forecast'
      case CapacityType.demand_transfer:
        return 'transfer'
      case CapacityType.demand_supermarket_share:
        return 'supermarket'
      default:
        return ''
    }
  }
}
