import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TextDisplayEdit from 'components/TextDisplayEdit'
import { ConfirmButton } from 'styles/createMyTheme'
import TrashIcon from 'assets/icons/trash.svg'
import DivisionService from 'services/DivisionService'
import { IDivision } from 'interfaces/Division'
import { TwitterPicker } from 'react-color'
import AuthenticationStore from '../stores/AuthenticationStore'
import { inject, observer } from 'mobx-react'
import { Stores } from '../stores/stores'

import styles from 'styles/dialog.module.scss'

interface DivisionDialogProps {
  open: boolean
  onClose: () => void
  initialDivision: IDivision
  modalType: boolean
  updateList: () => void
  authenticationStore: AuthenticationStore
}

const DivisionDialog: React.FC<any> = inject(Stores.authenticationStore)(
  observer((props: DivisionDialogProps) => {
    const {
      onClose,
      open,
      initialDivision,
      modalType,
      updateList,
      authenticationStore,
    } = props
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [division, setDivision] = useState<IDivision>(initialDivision)
    const [validData, setValidaData] = useState<boolean>(true)

    const divisionService = new DivisionService()

    const handleValueChange = (field: 'name' | 'colorCode', value: string) => {
      const divisionCopy = { ...division }
      divisionCopy[field] = value
      setDivision(divisionCopy)
    }

    useEffect(() => {
      setDivision(initialDivision)
      modalType && handleValueChange('colorCode', '#00a6eb')
      modalType && handleValueChange('name', '')
      modalType ? setEditMode(true) : setEditMode(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const resetState = () => {
      setEditMode(false)
      setDeleteMode(false)
      setValidaData(true)
      onClose()
    }

    const handleClose = () => {
      resetState()
    }

    const registerNewDivision = async () => {
      const newDivision = {
        name: division.name,
        colorCode: division.colorCode,
      }
      if (validationTest()) {
        if (await divisionService.registerDivision(newDivision)) {
          updateList()
          await authenticationStore.JWTLogin()
        }
        handleClose()
      } else {
        setValidaData(false)
      }
    }

    const updateThisDivision = async () => {
      if (validationTest()) {
        if (await divisionService.updateDivision(division)) {
          updateList()
          await authenticationStore.JWTLogin()
        }
        handleClose()
      } else {
        setValidaData(false)
      }
    }

    const divisionDeletion = async () => {
      if (await divisionService.deleteDivision(division._id!)) {
        updateList()
        await authenticationStore.JWTLogin()
      }
      handleClose()
    }

    const validationTest = () => {
      return division.name !== '' && division.colorCode !== ''
    }

    const renderInfo = () => {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography component={'span'}>{t('division:name')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={division.name ? division.name : ''}
                onChange={(event: any) =>
                  handleValueChange('name', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography component={'span'}>
                {t('division:colorCode')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  backgroundColor: division.colorCode
                    ? division.colorCode
                    : '#00a6eb',
                  borderRadius: '50%',
                  position: 'absolute',
                  margin: '3px',
                }}></div>
            </Grid>
            {editMode && (
              <Grid item xs={6}>
                <TwitterPicker
                  color={division.colorCode ? division.colorCode : '#00a6eb'}
                  onChangeComplete={(e: any) =>
                    handleValueChange('colorCode', e.hex)
                  }
                  triangle="hide"
                />
              </Grid>
            )}

            {!validData && (
              <Grid item xs={12}>
                <Typography component={'span'} color={'error'}>
                  {t('division:allFields')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )
    }

    const getConfirmButton = () => {
      return (
        deleteMode && (
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              onClick={() => divisionDeletion()}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        )
      )
    }

    const getDialogActions = () => {
      if (editMode) {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                modalType ? registerNewDivision() : updateThisDivision()
              }}>
              {modalType ? t('create') : t('save')}
            </Button>
          </div>
        )
      } else {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setDeleteMode(false)
              }}>
              {t('edit')}
            </Button>
          </div>
        )
      }
    }

    const getTrashCan = () => {
      let hasSitesAssigned = false
      authenticationStore!.currentUser.sites.forEach((site) => {
        if (site.division._id === initialDivision._id) hasSitesAssigned = true
      })

      return (
        <div
          onClick={() => !hasSitesAssigned && setDeleteMode(true)}
          style={{
            marginTop: '10px',
            cursor: 'pointer',
          }}>
          <Tooltip
            title={`${hasSitesAssigned ? t('division:unassignSites') : ''}`}>
            <img
              src={TrashIcon}
              alt="Filter"
              style={{
                width: 50,
                height: 40,
                filter: hasSitesAssigned ? 'grayscale(100%) opacity(30%)' : '',
              }}
            />
          </Tooltip>
        </div>
      )
    }

    return (
      <Dialog
        open={open}
        PaperProps={{ style: { borderRadius: '10px' } }}
        onBackdropClick={handleClose}
        fullWidth>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle className={styles['dialog-title-division']}>
            {modalType
              ? t('division:addNewDivision')
              : t('division:divisionInfo')}
          </DialogTitle>
          {!modalType && !deleteMode && getTrashCan()}
        </Box>
        <DialogContent>{renderInfo()}</DialogContent>
        <DialogActions>{getDialogActions()}</DialogActions>
      </Dialog>
    )
  })
)

export default DivisionDialog
