import React, { useEffect, useState } from 'react'
import { IRegion, ICountry } from 'interfaces/Common'
import { getSite, ISite } from 'interfaces/Site'
import { IErrorMessage } from 'interfaces/Common'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TextDisplayEdit from 'components/TextDisplayEdit'
import SiteService from '../services/SiteService'
import CoordinatesTest from 'utils/CoordinatesTest'
import { ConfirmButton } from 'styles/createMyTheme'
import TrashIcon from 'assets/icons/trash.svg'
import { Autocomplete } from '@material-ui/lab'
import { IDivision } from '../interfaces/Division'
import DivisionStore from '../stores/DivisionStore'
import SiteStore from '../stores/SiteStore'
import { inject, observer } from 'mobx-react'
import { Stores } from '../stores/stores'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import styles from 'styles/dialog.module.scss'

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
export const checkedIcon = <CheckBoxIcon fontSize="small" />

interface SiteDialogProps {
  open: boolean
  onClose: () => void
  initialSite: ISite
  modalType: boolean
  updateList: () => void
  regions: Array<IRegion>
  siteStore?: SiteStore
  updateCurrentUser: () => Promise<any>
  divisionStore?: DivisionStore
}

const SiteDialog: React.FC<SiteDialogProps> = inject(
  Stores.divisionStore,
  Stores.siteStore
)(
  observer((props: SiteDialogProps) => {
    const getInitialSite = () => {
      return initialSite._id ? { ...initialSite } : getSite()
    }
    const getDivisionAsOption = (division: IDivision) => {
      return division && division._id
        ? [
            {
              value: division,
              title: division.name,
            },
          ]
        : []
    }
    const getDivisionArrayAsOption = (divisions: IDivision[]) => {
      return divisions && divisions.length > 0
        ? divisions.map((elem: IDivision) => ({
            value: elem,
            title: elem.name,
          }))
        : []
    }
    const {
      onClose,
      open,
      initialSite,
      modalType,
      updateList,
      regions,
      siteStore,
      updateCurrentUser,
      divisionStore,
    } = props
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [site, setSite] = useState<any>(getInitialSite())
    const [recSiteOptions, setRecSiteOptions] = useState<any[]>([])
    const [selectedRecSiteOptions, setSelectedRecSiteOptions] = useState<any[]>(
      []
    )
    const [recSiteSearchString, setRecSiteSearchString] = useState<string>('')
    const [validData, setValidaData] = useState<boolean>(true)
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [region, setRegion] = useState<IRegion>(initialSite.region)
    const [countryList, setCountryList] = useState<ICountry[]>(
      (initialSite.region.countries || []).sort()
    )
    const [division, setDivision] = useState<any>({})
    const [divisionOptions, setDivisionOptions] = useState<any[]>([])
    const [searchString, setSearchString] = useState<string>('')
    const [divisionChangedModalOpen, setDivisionChangedModalOpen] = useState(
      false
    )
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(
      false
    )
    const [errorMessages, setErrorMessages] = useState<IErrorMessage[]>([])

    const siteService = new SiteService()

    const handleValueChange = (
      field:
        | 'plantId'
        | 'name'
        | 'city'
        | 'country'
        | 'numberOfEmployees'
        | 'complaintsRate'
        | 'division'
        | 'region'
        | 'capabilities'
        | 'sitesForReceivingCapacities'
        | 'location',
      value: any
    ) => {
      const siteCopy = { ...site }
      let valueAsString
      if (value && field === 'complaintsRate') {
        valueAsString = '' + value
        value = valueAsString.replace(',', '.')
      }
      if (value && field === 'location') {
        if (value.lat) {
          valueAsString = '' + value.lat
          value.lat = valueAsString.replace(',', '.')
        }
        if (value.lon) {
          valueAsString = '' + value.lon
          value.lon = valueAsString.replace(',', '.')
        }
      }
      siteCopy[field] = value
      setSite(siteCopy)
    }

    useEffect(() => {
      divisionStore && divisionStore.initDivisions()
    }, [divisionStore])

    useEffect(() => {
      divisionStore!.allDivisions &&
        divisionStore!.allDivisions.length > 0 &&
        setDivisionOptions(
          getDivisionArrayAsOption(divisionStore!.allDivisions)
        )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divisionStore!.allDivisions])

    useEffect(() => {
      siteStore && siteStore.setAllSites()
    }, [siteStore])

    useEffect(() => {
      siteStore!.allSites &&
        siteStore!.allSites.length > 0 &&
        setRecSiteOptions(getSitesAsOptions(siteStore!.allSites))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteStore!.allSites])

    useEffect(() => {
      setDivision(getDivisionAsOption(initialSite.division))
      setSelectedRecSiteOptions(
        getRecSitesAsOptions(initialSite.sitesForReceivingCapacities)
      )
    }, [initialSite])

    useEffect(() => {
      setSite(initialSite)
      setLatitude(initialSite.location ? initialSite.location.lat : '')
      setLongitude(initialSite.location ? initialSite.location.lon : '')
      modalType ? setEditMode(true) : setEditMode(false)
      modalType
        ? setRegion({ _id: '', name: '', countries: [] })
        : setRegion(initialSite.region)
      modalType ? setCountryList([]) : loaderCountries(initialSite.region)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
      handleValueChange('location', { lat: latitude, lon: longitude })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude, longitude])

    useEffect(() => {
      region
        ? handleValueChange('region', { _id: region._id, name: region.name })
        : handleValueChange('region', {
            _id: initialSite.region._id,
            name: initialSite.region.name,
          })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region])

    const resetState = () => {
      setEditMode(false)
      setDeleteMode(false)
      setValidaData(true)
      setLatitude('')
      setLongitude('')
      setDivision(getDivisionAsOption(initialSite.division))
      setSelectedRecSiteOptions(
        getRecSitesAsOptions(initialSite.sitesForReceivingCapacities)
      )
      onClose()
    }

    const handleClose = () => {
      resetState()
    }

    const registerNewSite = async () => {
      const newSite = {
        plantId: site.plantId,
        name: site.name,
        city: site.city,
        country: site.country,
        countryCode: site.countryCode,
        region: site.region,
        location: site.location,
        division: site.division,
        numberOfEmployees: site.numberOfEmployees,
        complaintsRate: site.complaintsRate,
        sitesForReceivingCapacities: site.sitesForReceivingCapacities,
        capabilities: [],
      }
      if (validationTest()) {
        if (await siteService.registerSite(newSite)) {
          updateList()
          await updateCurrentUser()
        }
        handleClose()
      } else {
        setValidaData(false)
      }
    }

    const updateThisSite = async (deleteCapabilities?: boolean) => {
      if (deleteCapabilities) site.capabilities = []
      if (validationTest()) {
        if (await siteService.updateSite(site)) {
          updateList()
          await updateCurrentUser()
        }
        handleClose()
      } else {
        setValidaData(false)
      }
    }

    const handleSiteUpdate = async () => {
      if (initialSite.division._id !== site.division._id)
        setDivisionChangedModalOpen(true)
      else await updateThisSite()
    }

    const siteDeletion = async () => {
      if (await siteService.deleteSite(site._id)) {
        updateList()
        await updateCurrentUser()
      }
      handleClose()
    }

    const regionToSave = (name: string) => {
      const newRegion: IRegion = regions.find((region) => region.name === name)!
      setCountryList(newRegion.countries)
      countryToSave('')
      setRegion(newRegion)
    }

    const loaderCountries = async (name: any) => {
      const newRegion: IRegion = await regions.find(
        (region) => region._id === name._id
      )!
      newRegion && setCountryList(newRegion.countries.sort())
    }

    const countryToSave = (name: string) => {
      handleValueChange('country', name)
    }

    const validationTest = () => {
      let errorMessages = []

      if (countryByRegionTest() && !site.country) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['country'],
        })
      }
      if (!site.region) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['region'],
        })
      }
      if (!site.city) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['city'],
        })
      }
      if (!site.name) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['name'],
        })
      }
      if (!site.division._id || site.division._id === '') {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['division'],
        })
      }
      if (site.complaintsRate && typeof site.complaintsRate === 'string') {
        const valueAsNumber: number = parseFloat(site.complaintsRate)
        const isValid =
          !isNaN(valueAsNumber) &&
          site.complaintsRate === valueAsNumber.toString()
        if (!isValid) {
          errorMessages.push({
            error: 'fieldFormat',
            parameters: ['complaintsRate'],
          })
        }
      }
      if (site.numberOfEmployees) {
        const valueAsNumber: number = parseInt(site.numberOfEmployees)
        let isValid = false
        if (typeof site.numberOfEmployees === 'string') {
          isValid =
            !isNaN(valueAsNumber) &&
            site.numberOfEmployees === valueAsNumber.toString()
        }
        if (typeof site.numberOfEmployees === 'number') {
          isValid =
            !isNaN(valueAsNumber) && site.numberOfEmployees === valueAsNumber
        }
        if (!isValid) {
          errorMessages.push({
            error: 'fieldFormat',
            parameters: ['numberOfEmployees'],
          })
        }
      }

      if (!site.location.lat) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['lat'],
        })
      }
      if (!site.location.lon) {
        errorMessages.push({
          error: 'fieldMandatory',
          parameters: ['lon'],
        })
      }
      if (!CoordinatesTest.test(site.location)) {
        errorMessages.push({
          error: 'fieldFormat',
          parameters: ['location'],
        })
      }

      setErrorMessages(errorMessages)
      return errorMessages.length === 0
    }

    const renderRegionOptions = () => {
      return regions.map((region: IRegion, index: number) => (
        <MenuItem key={index + region.name} value={region.name}>
          {region.name}
        </MenuItem>
      ))
    }

    const renderCountryOptions = () => {
      return countryList.map((country, index) => (
        <MenuItem key={index + country.name} value={country.name}>
          {country.name}
        </MenuItem>
      ))
    }

    const countryByRegionTest = () => {
      return region.countries && region.countries.length === 0
    }

    const isSelected = (option: any) => {
      return division.length > 0 ? division[0].value._id === option._id : false
    }

    const handleDivisionSelect = (selection: any) => {
      if (editMode && selection.length > 0) {
        setDivision([selection[selection.length - 1]])
        handleValueChange('division', selection[selection.length - 1].value)
      } else {
        setDivision([])
        handleValueChange('division', [])
      }
    }

    const handleFilterInputChange = (value: string) => {
      if (editMode) setSearchString(value)
    }

    function getSitesAsOptions(sites: ISite[]): any[] {
      return sites && sites.length > 0
        ? sites.map((site: ISite) => ({
            title: `${site.name}, ${site.city}, ${site.division.name}`,
            value: site,
          }))
        : []
    }

    function getRecSitesAsOptions(recSiteIDs: string[]): any[] {
      return getSitesAsOptions(
        siteStore!.allSites.filter((site) =>
          (recSiteIDs || []).includes(site._id)
        )
      )
    }

    function isRecSiteSelected(option: any) {
      const site = option as ISite
      const list = selectedRecSiteOptions.filter(
        (selection) => selection.value._id === site._id
      )
      return list.length > 0
    }

    function handleSelectRecSiteOption(selection: any) {
      if (editMode) {
        let selectedCopy = selection
        const duplicateList =
          selection.length > 1
            ? selection.filter(
                (option: any) =>
                  option.value._id === selection[selection.length - 1].value._id
              )
            : []
        if (duplicateList.length > 1)
          selectedCopy = selection.filter(
            (option: any) => option.value._id !== duplicateList[0].value._id
          )
        setSelectedRecSiteOptions(selectedCopy)
        const selectionArray = selectedCopy.map(
          (option: any) => option.value._id
        )
        handleValueChange('sitesForReceivingCapacities', selectionArray)
      }
    }

    function handleFilterRecSiteInputChange(value: string) {
      if (editMode) setRecSiteSearchString(value)
    }

    const sitesForReceivingCapacitiesChipsOnNoEditMode = () => {
      return selectedRecSiteOptions.map((option: any) => (
        <Chip
          key={option.title}
          label={option.title}
          style={{ margin: '5px' }}
        />
      ))
    }

    const Mandatory = () => <span style={{ color: 'red' }}> *</span>

    const renderInfo = () => {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography>{t('sites:plantId')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={site.plantId ? site.plantId : ''}
                onChange={(event: any) =>
                  handleValueChange('plantId', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('sites:name')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={site.name ? site.name : ''}
                onChange={(event: any) =>
                  handleValueChange('name', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('sites:region')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Select
                  fullWidth
                  labelId="region-select"
                  id="site-region-select"
                  value={region.name}
                  onChange={(event: any) => regionToSave(event.target.value)}>
                  {renderRegionOptions()}
                </Select>
              ) : (
                <Typography>{site.region.name}</Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('sites:country')}
                {editMode && !countryByRegionTest() && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Tooltip
                  title={`${t('sites:countryInfo')}`}
                  disableHoverListener={!countryByRegionTest()}
                  arrow={true}>
                  <Select
                    fullWidth
                    labelId="country-select"
                    id="site-country-select"
                    value={site.country}
                    disabled={site.country === '' && countryByRegionTest()}
                    onChange={(event: any) =>
                      countryToSave(event.target.value)
                    }>
                    {renderCountryOptions()}
                  </Select>
                </Tooltip>
              ) : (
                <Typography>{site.country}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography>
                {t('sites:division')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {editMode ? (
                <Autocomplete
                  multiple
                  disabled={!editMode}
                  id={`site-division-select`}
                  options={divisionOptions}
                  getOptionLabel={(option: any) => option.title}
                  value={division}
                  onChange={(event: React.ChangeEvent<{}>, value: any) =>
                    handleDivisionSelect(value)
                  }
                  inputValue={searchString}
                  onInputChange={(
                    event: React.ChangeEvent<{}>,
                    value: string
                  ) => handleFilterInputChange(value)}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        color="primary"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={isSelected(option.value)}
                        disabled={!editMode}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option.title}
                        disabled={!editMode}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={editMode ? t('profile:siteFilter') : ''}
                      margin="normal"
                      InputProps={{ ...params.InputProps }}
                      InputLabelProps={{ ...params.InputLabelProps }}
                      fullWidth
                      disabled={!editMode}
                    />
                  )}
                />
              ) : (
                <Typography>{site.division.name}</Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('sites:city')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={site.city ? site.city : ''}
                onChange={(event: any) =>
                  handleValueChange('city', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t('sites:numberOfEmployees')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                value={site.numberOfEmployees ? site.numberOfEmployees : '0'}
                onChange={(event: any) =>
                  handleValueChange('numberOfEmployees', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t('sites:complaintsRate')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextDisplayEdit
                fullWidth
                unit="%"
                value={site.complaintsRate ? site.complaintsRate : 0}
                onChange={(event: any) =>
                  handleValueChange('complaintsRate', event.target.value)
                }
                edit={editMode}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography>{t('sites:sitesForReceivingCapacities')}</Typography>
            </Grid>
            <Grid item xs={8} style={{ maxHeight: '215px', overflow: 'auto' }}>
              {!editMode ? (
                sitesForReceivingCapacitiesChipsOnNoEditMode()
              ) : (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id={`user-site-select`}
                  options={recSiteOptions}
                  getOptionLabel={(option: any) => option.title || ''}
                  value={selectedRecSiteOptions}
                  onChange={(event: React.ChangeEvent<{}>, value: any) =>
                    handleSelectRecSiteOption(value)
                  }
                  inputValue={recSiteSearchString}
                  onInputChange={(
                    event: React.ChangeEvent<{}>,
                    value: string
                  ) => handleFilterRecSiteInputChange(value)}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        color="primary"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={isRecSiteSelected(option.value)}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip {...getTagProps({ index })} label={option.title} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={editMode ? t('profile:siteFilter') : ''}
                      margin="normal"
                      InputProps={{ ...params.InputProps }}
                      InputLabelProps={{ ...params.InputLabelProps }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {t('sites:lat')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextDisplayEdit
                fullWidth
                value={site.location ? site.location.lat : ''}
                onChange={(event: any) => setLatitude(event.target.value)}
                edit={editMode}
                placeholder={'51.279922'}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {t('sites:lon')}
                {editMode && <Mandatory />}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextDisplayEdit
                fullWidth
                value={site.location ? site.location.lon : ''}
                onChange={(event: any) => setLongitude(event.target.value)}
                edit={editMode}
                placeholder={'9.513989'}
              />
            </Grid>
            {!validData && (
              <Grid item xs={12}>
                {getErrors()}
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )
    }

    const getErrors = () => {
      const textGroup = 'sites'
      return errorMessages.map((errMsg) => (
        <Typography color={'error'}>
          {errMsg.parameters
            ? errMsg.parameters.reduce(
                (p, c, i, a) => p.replace(`%${i}%`, t(`${textGroup}:${c}`)),
                t(`${textGroup}:${errMsg.error}`)
              )
            : t(`${textGroup}:${errMsg.error}`)}
        </Typography>
      ))
    }

    const getConfirmButton = () => {
      return (
        deleteMode && (
          <Box style={{ marginRight: '10px' }}>
            <ConfirmButton
              variant="outlined"
              onClick={() => setConfirmDeleteDialogOpen(true)}>
              {t('confirm')}
            </ConfirmButton>
          </Box>
        )
      )
    }

    const getDialogActions = () => {
      if (editMode) {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                modalType ? registerNewSite() : handleSiteUpdate()
              }}>
              {modalType ? t('create') : t('save')}
            </Button>
          </div>
        )
      } else {
        return (
          <div className={styles['action-button-container']}>
            <Box mr="auto">
              <Button variant="contained" onClick={handleClose}>
                {t('close')}
              </Button>
            </Box>
            {getConfirmButton()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditMode(true)
                setDeleteMode(false)
              }}>
              {t('edit')}
            </Button>
          </div>
        )
      }
    }

    const getConfirmDeleteDialog = () => {
      return (
        <Dialog
          open={confirmDeleteDialogOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={() => setConfirmDeleteDialogOpen(false)}
          maxWidth="lg">
          <DialogTitle className={styles['dialog-title-deletion']}>
            {t('capabilities:confirmToDelete')}
          </DialogTitle>
          <DialogContent>
            <Box minWidth="400px">
              <Typography>
                {t('capabilities:deleteSiteWithCapabilities')}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className={styles['action-button-container']}>
              <Box mr="auto">
                <Button
                  variant="contained"
                  onClick={() => setConfirmDeleteDialogOpen(false)}>
                  {t('close')}
                </Button>
              </Box>
              <Box style={{ marginRight: '10px' }}>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    siteDeletion()
                    setConfirmDeleteDialogOpen(false)
                  }}>
                  {t('confirm')}
                </ConfirmButton>
              </Box>
            </div>
          </DialogActions>
        </Dialog>
      )
    }

    return (
      <React.Fragment>
        {/* actual edit/create modal */}
        <Dialog
          open={open}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={handleClose}>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle className={styles['dialog-title']}>
              {modalType ? t('sites:addNewSite') : t('sites:siteInfo')}
            </DialogTitle>
            {!modalType && !deleteMode && (
              <div
                onClick={() => setDeleteMode(true)}
                style={{
                  position: 'relative',
                  marginTop: '10px',
                  cursor: 'pointer',
                }}>
                <img
                  src={TrashIcon}
                  alt="Filter"
                  style={{ width: 50, height: 40 }}
                />
              </div>
            )}
          </Box>
          <DialogContent>{renderInfo()}</DialogContent>
          <DialogActions>{getDialogActions()}</DialogActions>
        </Dialog>

        {/* warning dialog for updating */}
        <Dialog
          open={divisionChangedModalOpen}
          PaperProps={{ style: { borderRadius: '10px' } }}
          onBackdropClick={() => setDivisionChangedModalOpen(false)}
          maxWidth="lg">
          <DialogTitle className={styles['dialog-title-deletion']}>
            {t('capabilities:confirmToSave')}
          </DialogTitle>
          <DialogContent>
            <Box minWidth="400px">
              <Typography>
                {t('capabilities:changingDivisionWillDeleteCapabilities')}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div className={styles['action-button-container']}>
              <Box mr="auto">
                <Button
                  variant="contained"
                  onClick={() => setDivisionChangedModalOpen(false)}>
                  {t('close')}
                </Button>
              </Box>
              <Box style={{ marginRight: '10px' }}>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    updateThisSite(true)
                    setDivisionChangedModalOpen(false)
                  }}>
                  {t('confirm')}
                </ConfirmButton>
              </Box>
            </div>
          </DialogActions>
        </Dialog>
        {getConfirmDeleteDialog()}
      </React.Fragment>
    )
  })
)

export default SiteDialog
