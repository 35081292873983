import React, { useState } from 'react'
import { Tooltip, makeStyles, createStyles } from '@material-ui/core'
import profileWhite from 'assets/icons/profile-white.svg'
import { useTranslation } from 'react-i18next'
import { UserModalEntryPoints } from 'interfaces/EntryPoints'
import UserModal from 'components/UserModal'
import { inject } from 'mobx-react'
import { Stores } from 'stores/stores'
import { observer } from 'mobx-react-lite'
import AuthenticationStore from 'stores/AuthenticationStore'
import UserManagementStore from 'stores/UserManagementStore'

import styles from 'styles/header.module.scss'

interface ProfileDialogProps {
  authenticationStore?: AuthenticationStore
  userManagementStore?: UserManagementStore
}

const ProfileDialog: React.FC<ProfileDialogProps> = inject(
  Stores.authenticationStore,
  Stores.userManagementStore
)(
  observer(({ authenticationStore, userManagementStore }) => {
    const [profileOpen, setProfileOpen] = useState(
      authenticationStore!.currentUser.recovery
    )
    const { t } = useTranslation()

    const useStyles = makeStyles(() =>
      createStyles({
        custom: {
          backgroundColor: '#00A6EB',
          textAlign: 'center',
          color: '#fff',
          minWidth: '62px',
          maxWidth: '100px',
          paddingBottom: '10px',
          minHeight: '30px',
          fontSize: '15px',
          marginTop: '0px',
          borderRadius: '0% 0% 100% 100%',
        },
      })
    )
    const classes = useStyles()

    return (
      <React.Fragment>
        <Tooltip
          title={`${t('header:profile')}`}
          classes={{ tooltip: classes.custom }}>
          <div
            className={styles['icon-container']}
            onClick={() => setProfileOpen(true)}>
            <img
              src={profileWhite}
              alt={t('header:profile')}
              className={styles['menu-icon']}
            />
          </div>
        </Tooltip>
        <UserModal
          open={profileOpen}
          entryPoint={UserModalEntryPoints.Profile}
          onClose={() => setProfileOpen(false)}
          initialUser={authenticationStore!.currentUser}
          updateList={() => userManagementStore!.setAllUsers()}
        />
      </React.Fragment>
    )
  })
)

export default ProfileDialog
